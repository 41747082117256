import { FC } from "react";
import styles from "./DateRangePickerLegends.module.css";
import { useBreakPoints } from "../../hooks/useBreakPoints";
import { getDateColorFromLevel } from "./DateRangePicker";

export interface DateRangePickerLegendItem {
  label: string;
  level: number;
}

export interface DateRangePickerLegendsProps {
  items: DateRangePickerLegendItem[];
}

export const DateRangePickerLegends: FC<DateRangePickerLegendsProps> = ({
  items,
}) => {
  const breakPoints = useBreakPoints();
  const itemWidth = breakPoints.mobileOnly
    ? `${100 / (items.length <= 4 ? items.length : 4)}%`
    : "auto";

  return (
    <div className={styles.root}>
      <ul className={styles.items}>
        {items.map((item, index) => (
          <li key={index} style={{ width: itemWidth }}>
            <div
              style={{ background: getDateColorFromLevel(item.level).base }}
              className={styles.item}
            >
              {item.label}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
