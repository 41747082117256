import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconBreakfast: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        fill="#1F1F1F"
        d="M4.344 28.3V26h20.64v2.3H4.344zm5.024-4.2c-1.387 0-2.57-.5-3.552-1.5-.982-1-1.472-2.234-1.472-3.7V3.7h21.408a2.09 2.09 0 011.568.666c.426.444.64.989.64 1.633v5.334c0 .622-.214 1.155-.64 1.6a2.09 2.09 0 01-1.568.666h-2.976v5.3c0 1.467-.49 2.7-1.472 3.7s-2.166 1.5-3.552 1.5H9.368zm0-2.267h8.384c.746 0 1.408-.295 1.984-.884.576-.589.864-1.272.864-2.05V6H6.52v12.9c0 .778.293 1.461.88 2.05.586.59 1.242.884 1.968.884zm13.408-10.5h2.976V5.999h-2.976v5.334z"
      ></path>
    </svg>
  );
};
