import { FC } from "react";
import { Spacer, Text } from "@newt/ui";
import { CommentsWithProfile_MicroCMSCommentFragment } from "@graphql/types";
import styles from "./CommentsWithLargeProfile.module.css";

interface Props {
  comments: CommentsWithProfile_MicroCMSCommentFragment[];
}

export const CommentsWithLargeProfile: FC<Props> = (props) => {
  const { comments } = props;

  return (
    <>
      {comments.map((comment, index) => {
        const { person } = comment;
        return (
          <div key={index} className={styles.root}>
            <div className={styles.first}>
              <div className={styles.imageWrapper}>
                <img
                  className={styles.image}
                  src={person.icon.url}
                  alt={person.name}
                  loading="lazy"
                />
              </div>
              <div className={styles.content}>
                <Text
                  as="h2"
                  size={{ mobile: "lg", tablet: "2xl" }}
                  weight="bold"
                >
                  プロフィール
                </Text>
                <Spacer y="2x" />
                {person.title && (
                  <Text weight="bold" size={{ mobile: "sm", tablet: "base" }}>
                    {person.title}
                  </Text>
                )}
                <Text weight="bold" size={{ mobile: "lg", tablet: "2xl" }}>
                  {person.name}
                </Text>
                {person.description && (
                  <div className={styles.description}>
                    <Text
                      size={{ mobile: "sm", tablet: "base" }}
                      color={"black"}
                    >
                      {person.description}
                    </Text>
                  </div>
                )}

                <div className={"tabletWithUp"}>
                  <Spacer y="8x" />
                  <Comment comment={comment.comment} />
                </div>
              </div>
            </div>
            <div className={"mobileOnly"}>
              <Spacer y="4x" />
              <Comment comment={comment.comment} />
            </div>
          </div>
        );
      })}
    </>
  );
};

const Comment: FC<{ comment?: string | null }> = ({ comment }) => (
  <div>
    <Text as="h2" size={{ mobile: "lg", tablet: "2xl" }} weight="bold">
      コメント
    </Text>
    <Spacer y="2x" />
    <Text size={"base"} color={"black"}>
      {comment}
    </Text>
  </div>
);
