import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconFlightSeat: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#757575"
        d="M6.326 4.508a1.67 1.67 0 010-2.358 1.67 1.67 0 012.358 0c.65.65.65 1.708 0 2.358a1.655 1.655 0 01-2.358 0zM5 13.333v-7.5H3.334v7.5c0 2.3 1.867 4.167 4.167 4.167h5v-1.667h-5a2.497 2.497 0 01-2.5-2.5zm11.666 3.392L12.442 12.5H9.584V9.433c1.167.958 3 1.8 4.583 1.8v-1.8c-1.383.017-3.008-.725-3.891-1.7L9.109 6.44a1.788 1.788 0 00-.575-.416 1.854 1.854 0 00-.8-.192h-.025a1.878 1.878 0 00-1.875 1.875V12.5c0 1.383 1.117 2.5 2.5 2.5h4.225l2.917 2.916 1.191-1.191z"
      ></path>
    </svg>
  );
};
