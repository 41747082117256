import { useBreakPoints } from "./useBreakPoints";
import { ResponsiveProp, ResponsivePropObj } from "../types/component.type";

export const useResponsiveProp = <T>(
  prop: ResponsiveProp<T>,
  defaultProp: T
): T => {
  const { activeKey } = useBreakPoints();

  if (typeof prop === "object") {
    const obj = prop as ResponsivePropObj<T>;
    if (obj[activeKey]) return obj[activeKey] as T;

    if (activeKey === "mobile") return defaultProp;
    if (activeKey === "tablet") return obj["mobile"] || defaultProp;
    if (activeKey === "laptop")
      return obj["tablet"] || obj["mobile"] || defaultProp;
    return obj["laptop"] || obj["tablet"] || obj["mobile"] || defaultProp;
  }

  return prop;
};
