export const InstagramIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#1F1F1F"
        d="M11.998 6.098c1.923 0 2.15.007 2.91.042.702.032 1.083.15 1.337.248.336.13.576.286.828.538.252.252.408.492.538.828.099.254.216.635.248 1.337.035.76.042.987.042 2.91 0 1.922-.007 2.15-.042 2.91-.032.701-.15 1.082-.248 1.336-.13.336-.286.576-.538.828a2.227 2.227 0 01-.828.538c-.254.1-.635.216-1.337.248-.76.035-.987.042-2.91.042-1.922 0-2.15-.007-2.909-.042-.702-.032-1.083-.149-1.337-.248a2.235 2.235 0 01-.828-.538 2.227 2.227 0 01-.538-.828c-.099-.254-.216-.635-.248-1.337-.035-.759-.042-.987-.042-2.91 0-1.922.007-2.15.042-2.909.032-.702.15-1.083.248-1.337.13-.336.286-.576.538-.828.252-.252.492-.408.828-.538.254-.099.635-.216 1.337-.248.76-.035.987-.042 2.91-.042zm0-1.297c-1.955 0-2.2.008-2.968.043-.766.035-1.29.157-1.748.335-.473.184-.875.43-1.275.83-.4.4-.646.802-.83 1.275-.178.459-.3.982-.335 1.748-.035.768-.043 1.013-.043 2.969 0 1.955.008 2.2.043 2.968.035.767.157 1.29.335 1.748.184.474.43.875.83 1.275.4.4.802.647 1.276.83.457.179.981.3 1.747.335.768.035 1.013.044 2.969.044 1.955 0 2.2-.009 2.968-.044.767-.035 1.29-.156 1.748-.334.474-.184.875-.43 1.275-.83.4-.401.647-.802.83-1.276.179-.458.3-.981.335-1.748.035-.768.044-1.013.044-2.968 0-1.956-.009-2.201-.044-2.969-.035-.766-.156-1.29-.334-1.748a3.525 3.525 0 00-.83-1.275c-.401-.4-.802-.646-1.276-.83-.458-.178-.982-.3-1.748-.335-.768-.035-1.013-.043-2.969-.043z"
      ></path>
      <path
        fill="#1F1F1F"
        d="M12 8.305a3.697 3.697 0 100 7.394 3.697 3.697 0 000-7.394zm0 6.097a2.4 2.4 0 110-4.8 2.4 2.4 0 010 4.8zM15.84 9.021a.864.864 0 100-1.728.864.864 0 000 1.728z"
      ></path>
    </svg>
  );
};
