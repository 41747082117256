import { BasicHeader, Container, FormFooter } from "@newt/ui";
import { ROUTES } from "@routes";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, ReactNode, useEffect } from "react";
import styles from "./FormLayout.module.css";
import { MarketingService } from "@utils/marketing";

interface FormLayoutProps {
  children: ReactNode;
}

export const FormLayout: FC<FormLayoutProps> = ({ children }) => {
  const router = useRouter();
  const isBookingForm = router.asPath.includes("/book");

  useEffect(() => {
    if (isBookingForm && router.query.tourId) {
      MarketingService.logEvent.brazeTourBookingView({
        tourId: Number(router.query.tourId),
      });
    }
  }, [isBookingForm, router.query.tourId]);

  return (
    <div className={styles.root}>
      {isBookingForm ? (
        <BasicHeader />
      ) : (
        <Link href={ROUTES.home()} legacyBehavior>
          <BasicHeader />
        </Link>
      )}

      <div className={styles.body}>
        <Container>{children}</Container>
      </div>
      <FormFooter />
    </div>
  );
};
