import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconPoint: FC<IconTypeProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9894 9.42966H10.6094V12.7197H11.9894C12.2146 12.7496 12.4437 12.7293 12.6602 12.6603C12.8767 12.5912 13.0753 12.475 13.2416 12.3202C13.4079 12.1653 13.5379 11.9756 13.6223 11.7646C13.7066 11.5536 13.7432 11.3265 13.7294 11.0997C13.753 10.8684 13.7237 10.6348 13.6435 10.4165C13.5633 10.1983 13.4345 10.0012 13.2668 9.84021C13.099 9.67923 12.8968 9.5586 12.6755 9.48746C12.4541 9.41632 12.2195 9.39654 11.9894 9.42966Z"
        fill="black"
      />
      <path
        d="M12 2C10.0222 2 8.08878 2.58649 6.44429 3.6853C4.7998 4.78412 3.51809 6.3459 2.76121 8.17316C2.00434 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6726 4.92894 19.0711C6.32746 20.4696 8.10927 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34784 20.9464 6.80429 19.0711 4.92892C17.1957 3.05356 14.6522 2 12 2ZM15.66 11C15.6778 11.4214 15.6066 11.8417 15.451 12.2337C15.2954 12.6257 15.0589 12.9804 14.757 13.2748C14.455 13.5693 14.0944 13.7967 13.6986 13.9423C13.3028 14.0879 12.8808 14.1485 12.46 14.12H10.61V16.99H8.69999V7.92001H12.46C12.8799 7.89164 13.301 7.95192 13.6961 8.09695C14.0912 8.24199 14.4513 8.4685 14.7531 8.76181C15.0549 9.05512 15.2916 9.40867 15.4479 9.79944C15.6041 10.1902 15.6764 10.6095 15.66 11.03V11Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};
