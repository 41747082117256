import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconDeck: FC<IconTypeProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 22V9H2L12 2L22 9H13V22H11ZM12 7H15.65H8.35H12ZM3 22V16.75L2.2 12.35L4.15 12L4.9 16H9V22H7V18H5V22H3ZM15 22V16H19.1L19.85 12L21.8 12.35L21 16.75V22H19V18H17V22H15ZM8.35 7H15.65L12 4.45L8.35 7Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};
