import { FC, ReactNode } from "react";
import styles from "./DropdownItem.module.css";
import { Icon, IconType } from "../../../Icon";
import { Text } from "../../../Text";

export interface DropdownItemProps {
  children: ReactNode;
  icon?: IconType;
  onClick?: () => void;
  href?: string;
  target?: HTMLAnchorElement["target"];
}

export const DropdownItem: FC<DropdownItemProps> = ({
  children,
  icon,
  href,
  onClick,
  target,
  ...props
}) => {
  return (
    <a
      className={styles.root}
      href={href}
      target={target}
      onClick={onClick}
      {...props}
    >
      {icon && (
        <div className={styles.icon}>
          <Icon icon={icon} color="gray-80" />
        </div>
      )}
      <div className={styles.body}>
        <Text color="default" weight="bold">
          {children}
        </Text>
      </div>
    </a>
  );
};
