import { Paragraph, Spacer, PageTitle, Logo, Text, Stack } from "@newt/ui";
import { ROUTES } from "@routes";
import Link from "next/link";
import { FC } from "react";
import { SignInButton } from "../SignInButton";
import { useAppleAuth, useGoogleAuth, usePhoneAuth } from "./useSignInMethods";

interface SignInMethodsProps {
  isInsideBookingPage?: boolean;
}

export const SignInMethods: FC<SignInMethodsProps> = ({
  isInsideBookingPage,
}) => {
  const { signInWithGoogle } = useGoogleAuth();
  const { signInWithApple } = useAppleAuth();
  const { signInWithPhone } = usePhoneAuth();

  return (
    <>
      <PageTitle align="center" size={{ mobile: "2xl", tablet: "4xl" }}>
        ログイン / 新規登録
      </PageTitle>
      {!isInsideBookingPage && (
        <Stack align="center" direction="column">
          <Spacer y={{ mobile: "12x", laptop: "6x" }} />
          <Logo width={120} height={94} />
          <Spacer y={{ mobile: "20x", laptop: "14x" }} />
          <Text size="lg" weight="bold">
            会員登録でもっと便利、もっとおトク🎊
          </Text>
          <Spacer y="6x" />
        </Stack>
      )}
      <SignInButton provider="google" onClick={signInWithGoogle} />
      <Spacer />
      <SignInButton provider="apple" onClick={signInWithApple} />
      <Spacer />
      <SignInButton
        provider="phone"
        onClick={() => signInWithPhone(isInsideBookingPage)}
      />
      <Spacer y="6x" />
      <Paragraph size="sm">
        ログインまたは新規登録で、NEWTの
        <Link href={ROUTES.terms()} className="text-link" target="_blank">
          利用規約
        </Link>
        と
        <Link href={ROUTES.privacy()} className="text-link" target="_blank">
          プライバシーポリシー
        </Link>
        に同意したものとみなされます。
      </Paragraph>
    </>
  );
};
