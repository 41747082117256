import { DateTime } from "luxon";

export const getDateFormat = (
  dateISO: string,
  format = "yyyy/MM/dd",
  zone = "Asia/Tokyo",
  locale = "ja"
) => {
  return DateTime.fromISO(dateISO, { zone }).setLocale(locale).toFormat(format);
};

export const getDateTimeFormat = (
  dateISO: string,
  format = "yyyy/MM/dd t",
  zone = "Asia/Tokyo"
) => {
  return DateTime.fromISO(dateISO, { zone }).toFormat(format);
};

export const toDate = (str: string) => {
  return str ? new Date(str) : undefined;
};
