import { FC } from "react";
import NextHead from "next/head";
import NextScript from "next/script";
import { NextSeo, NextSeoProps, DefaultSeo } from "next-seo";
import {
  DEFAULT_SEO,
  GTM_ID,
  SITE_NAME,
  SITE_NAME_KANA,
  SITE_NAME_LONG,
  SITE_URL,
} from "@constants";
import { useRouter } from "next/router";
import { Icons } from "./Icons";
import { useIsTrackingAllowed } from "@utils/useIsTrackingAllowed";
import { addPageQuery } from "@utils/pagination";

const getCanonicalPath = (path: string) => {
  if (path === "/") {
    return "";
  }

  if (path === "/index") {
    return "";
  }

  const url = new URL(path, SITE_URL);
  const searchParams = url.searchParams;
  return addPageQuery(url.pathname, searchParams.get("page") || 1);
};

export const Head: FC<
  NextSeoProps & {
    absoluteTitle?: string;
  }
> = (props) => {
  const { asPath } = useRouter();
  const { canonical, ...rest } = props; // TODO: we can use NextSeo instead of NextHead for canonical

  const pageTitle =
    props.absoluteTitle || (props.title && `${props.title} | ${SITE_NAME}`);
  const ogpTitle =
    props.absoluteTitle || (props.title && `${SITE_NAME} | ${props.title}`);
  const description =
    props.description || (props.title && `${props.title} | ${SITE_NAME_LONG}`);

  return (
    <>
      <NextSeo
        {...rest}
        title={pageTitle}
        description={description}
        openGraph={{
          type: "website",
          url: `${SITE_URL}${getCanonicalPath(asPath)}`,
          title: ogpTitle,
          description,
          siteName: SITE_NAME_KANA,
          ...props.openGraph,
        }}
      />
      <NextHead>
        <link
          rel="canonical"
          href={`${SITE_URL}${getCanonicalPath(canonical || asPath)}`}
        />
      </NextHead>
    </>
  );
};

export const DefaultHead = () => {
  const SEO = {
    ...DEFAULT_SEO,
  };
  const isTrackingAllowed = useIsTrackingAllowed(); // TODO: remove

  if (!isTrackingAllowed) {
    return (
      <NextHead>
        <meta name="robots" content="noindex, nofollow" />
      </NextHead>
    );
  }

  return (
    <>
      <DefaultSeo {...SEO} />
      <NextHead>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta
          name="facebook-domain-verification"
          content="n8ilms54qhe8fjredh8vcyfqomptdk"
        />
        <meta
          name="facebook-domain-verification"
          content="wepzwhgnjduet43i5i9udydsaij568"
        />
        <Icons />
      </NextHead>

      {/* Google Tag Manager - Global base code */}
      <NextScript
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
        }}
      />
    </>
  );
};
