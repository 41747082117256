import { useRouter } from "next/router";
import { useEffect } from "react";
import { SessionStorageService } from "./webStorage";
import { SESSION_INSURANCE_FORM_KEY } from "@components/pages/insurance/store/sessionStorageForInsuranceForm";

export const useDeleteInsuranceFormSession = () => {
  const router = useRouter();
  useEffect(() => {
    if (router.isReady && !router.pathname.includes("/insurance")) {
      SessionStorageService.removeItem(SESSION_INSURANCE_FORM_KEY);
    }
  }, [router.isReady, router.pathname]);
};
