import { ReactNode, FC } from "react";
import styles from "./Tag.module.css";

export interface TagProps {
  color?:
    | "primary"
    | "default"
    | "danger"
    | "disabled"
    | "positiveRed"
    | "gray-50"
    | "blue"
    | "white";
  variant?: "solid" | "outline" | "ghost";
  size?: "sm" | "base";
  children: ReactNode;
}

export const Tag: FC<TagProps> = ({
  children,
  color = "primary",
  variant = "solid",
  size = "base",
  ...props
}) => {
  const classes = [
    styles.root,
    styles[`${color}--${variant}`],
    styles[`size--${size}`],
  ].join(" ");
  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};
