import { FC, useEffect, useState } from "react";
import Image from "next/image";
import { Button, Checkbox, Modal, useBreakPoints } from "@newt/ui";
import { MarketingService } from "@utils/marketing";
import { useAuth } from "@utils/auth";
import ConfettiBall from "./assets/confetti_ball.webp";
import RegistrationCompleteText from "./assets/registration_complete_text.webp";
import styles from "./NewsletterModal.module.css";
import { useUpdateNewsletter } from "./useUpdateNewsletter";

interface SignInNewsletterModalProps {
  onClose: () => void;
  moduleType: "via_one_tap" | "via_signin";
}

export const NewsletterModal: FC<SignInNewsletterModalProps> = (props) => {
  const { onClose, moduleType } = props;
  const auth = useAuth();
  const { mobileOnly } = useBreakPoints();
  const [isOpen, setIsOpen] = useState(false);
  const [needNewsletter, setNeedNewsletter] = useState(true);
  const { mutateAsync } = useUpdateNewsletter();

  const handleAction = async () => {
    await mutateAsync(needNewsletter);
    MarketingService.logEvent.setMailNotification({
      is_mail_notification_subscribed: needNewsletter,
      module_type: moduleType,
    });
    setIsOpen(false);
    onClose();
  };

  const handleClose = () => {
    // PCのみ
    setIsOpen(false);
    onClose();
  };

  useEffect(() => {
    if (auth.isLoading) return;
    if (auth.user) {
      setIsOpen(true);
      MarketingService.logEvent.viewModule({
        module_id: "signup_email_notification",
        module_type: moduleType,
      });
    } else {
      onClose();
    }
  }, [auth.user]);

  return mobileOnly ? (
    <>
      {/**** MOBILE VIEW ***/}
      <Modal
        rootClassName={styles.spModalRoot}
        showHeader={false}
        isActive={isOpen}
        fullScreen={true}
      >
        <div className={styles.spRoot}>
          <div className={styles.confettiBall}>
            <Image
              src={ConfettiBall}
              alt="くす玉"
              fill={true}
              style={{ objectFit: "cover" }}
              quality={90}
            />
          </div>
          <div className={styles.textImage}>
            <Image
              src={RegistrationCompleteText}
              alt="NEWT会員登録が完了しました"
              fill={true}
              style={{ objectFit: "cover" }}
              quality={90}
            />
          </div>
          <div className={styles.buttonWrapper}>
            <Button size="lg" onClick={handleAction}>
              いますぐ利用する
            </Button>
          </div>
          <div className={styles.checkbox}>
            <Checkbox
              label="NEWTからのおトク情報を希望しません"
              checked={!needNewsletter}
              onChange={() => setNeedNewsletter(!needNewsletter)}
            />
          </div>
        </div>
      </Modal>
    </>
  ) : (
    <>
      {/**** TABLET/DESKTOP VIEW ****/}
      <Modal
        title="NEWT会員登録が完了しました🌟"
        isActive={isOpen}
        onClose={handleClose}
        action={
          <div>
            <Button size="lg" onClick={handleAction}>
              いますぐ利用する
            </Button>
            <div className={styles.pcCheckbox}>
              <Checkbox
                label="NEWTからのおトク情報を希望しません"
                checked={!needNewsletter}
                onChange={() => setNeedNewsletter(!needNewsletter)}
              />
            </div>
          </div>
        }
      >
        <div className={styles.pcRoot}>
          <Image
            src={ConfettiBall}
            alt="くす玉"
            width={260}
            height={224}
            quality={90}
          />
        </div>
      </Modal>
    </>
  );
};
