import { useEffect } from "react";

interface Config {
  readonly enable?: boolean;
}

export const useReloadOnPersistedPage = (
  { enable }: Config = { enable: true }
) => {
  useEffect(() => {
    if (typeof window === "undefined" || !enable) return;

    const onPageShow: (event: PageTransitionEvent) => void = (event) => {
      if (!event.persisted) return;
      window.location.reload();
    };

    window.addEventListener("pageshow", onPageShow);
    return () => window.removeEventListener("pageshow", onPageShow);
  }, [enable]);
};
