import { FC } from "react";
import styles from "./CompanyProfile.module.css";
import { Spacer, Text } from "@newt/ui";

const ITEMS = [
  { category: "社名", content: "株式会社 令和トラベル" },
  { category: "創業", content: "2021年4月5日" },
  { category: "代表", content: "篠塚孝哉" },
  { category: "登録番号", content: "観光庁長官登録旅行業第2123号" },
  { category: "資本金", content: "70.2億円（資本剰余金を含む）" },
  {
    category: "住所",
    content: "〒150-0031\n東京都渋谷区桜丘町16-13 桜丘フロントⅡビル 6F",
  },
];

export const CompanyProfile: FC = () => {
  return (
    <ul className={styles.root}>
      {ITEMS.map((item) => {
        return (
          <li key={item.category}>
            <div className={styles.category}>
              <Text size={"lg"}>{item.category}</Text>
              <Spacer y={{ mobile: "2x", tablet: "0" }} />
            </div>
            <div className={styles.content}>
              {item.content.split("\n").map((line) => (
                <Text key={line} size={"lg"}>
                  {line}
                </Text>
              ))}
            </div>
          </li>
        );
      })}
    </ul>
  );
};
