import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconRestaurant: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <g clipPath="url(#clip0_5057_42151)">
        <path
          fill="#1F1F1F"
          d="M15.167 12.001H12.5V2.668H9.833v9.333H7.167V2.668H4.5v9.333c0 2.827 2.213 5.12 5 5.294v12.04h3.333v-12.04c2.787-.174 5-2.467 5-5.294V2.668h-2.666v9.333zm6.666-4v10.667h3.334v10.667H28.5V2.668c-3.68 0-6.667 2.987-6.667 5.333z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_5057_42151">
          <path fill="#fff" d="M0 0H32V32H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
