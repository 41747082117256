import { useAuth } from "@utils/auth";
import { firebase } from "@utils/firebase";
import { mapProviderDataToAuthMethod } from "@utils/mapProviderDataToAuthMethod";
import { MarketingService } from "@utils/marketing";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

declare global {
  interface Window {
    readonly dataLayer?: Record<string, string>[];
  }
}

let GTM_firstPageLoad = true;

export const usePageViewEvent = () => {
  const router = useRouter();
  const { user, isLoading } = useAuth();
  const [previousPath, setPreviousPath] = useState("");
  const authMethod = firebase.auth.currentUser
    ? mapProviderDataToAuthMethod(firebase.auth.currentUser?.providerData)
    : "";

  /** [First Load (e.g. top, tour detail, <a> tag) ] wait for AuthContext to be fetched **/
  useEffect(() => {
    if (GTM_firstPageLoad && !isLoading) {
      const pathname = document.referrer
        ? new URL(document.referrer).pathname
        : "UNKNOWN";

      MarketingService.logEvent.pageView({
        user_id: user?.id.toString(),
        previous_page: pathname,
        auth_method: authMethod,
      });
      GTM_firstPageLoad = false;
      setPreviousPath(location.pathname);
    }
  }, [authMethod, isLoading, user]);

  useEffect(() => {
    const onHistoryChange = (url: string, options: { shallow: boolean }) => {
      // dismiss shallow change (e.g. date change on tour detail page)
      if (!options?.shallow) {
        MarketingService.logEvent.pageView({
          user_id: user?.id.toString(),
          previous_page: previousPath,
        });
      }
      setPreviousPath(location.pathname);
    };
    router.events.on("routeChangeComplete", onHistoryChange);
    return () => {
      router.events.off("routeChangeComplete", onHistoryChange);
    };
  }, [router.events, user, previousPath]);
};
