import { FC, CSSProperties } from "react";
import styles from "./LazyImage.module.css";
import { useResponsiveProp } from "../../hooks/useResponsiveProp";

interface LazyImageProps {
  imgClassName?: string;
  src: string;
  title?: string;
  alt?: string;
  loading?: "eager" | "lazy" | undefined;
  aspectRatio?: CSSProperties["aspectRatio"];
  maxWidth?: string | number;
  width?: string | number;
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  imgAttr?: React.ImgHTMLAttributes<HTMLImageElement>;
}
export const LazyImage: FC<LazyImageProps> = ({
  imgClassName,
  src,
  title,
  alt,
  loading = "lazy",
  aspectRatio,
  maxWidth,
  width,
  onError,
}) => {
  const responsiveWidth = useResponsiveProp(width, undefined);
  const responsiveMaxWidth = useResponsiveProp(maxWidth, undefined);
  const responsiveAspectRatio = useResponsiveProp(aspectRatio, "auto");

  return (
    <figure
      className={styles.root}
      style={{
        aspectRatio: responsiveAspectRatio,
        maxWidth: responsiveMaxWidth,
        width: responsiveWidth,
      }}
    >
      <img
        className={[styles.img, imgClassName || ""].join(" ")}
        src={src}
        alt={alt}
        loading={loading}
        onError={onError || undefined}
      />
      {title && <p className={styles.title}>{title}</p>}
    </figure>
  );
};
