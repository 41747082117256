import { createElement, FC, ReactNode, Children } from "react";
import styles from "./Stack.module.css";
import { ResponsiveProp, Spacings } from "../../types/component.type";
import { useResponsiveProp } from "../../hooks/useResponsiveProp";

interface StackProps {
  children?: ReactNode;
  spacing?: ResponsiveProp<Spacings>;
  direction?: ResponsiveProp<
    "column" | "row" | "column-reverse" | "row-reverse"
  >;
  align?: ResponsiveProp<
    "start" | "center" | "end" | "stretch" | "baseline" | "normal"
  >;
  justify?: ResponsiveProp<"start" | "center" | "end" | "between">;
  as?: "ul" | "ol" | "div";
}

const defaultProps: Required<Omit<StackProps, "children">> = {
  spacing: "base",
  direction: "row",
  align: "start",
  justify: "start",
  as: "ul",
};

export const Stack: FC<StackProps> = ({
  children,
  spacing = defaultProps.spacing,
  direction = defaultProps.direction,
  align = defaultProps.align,
  justify = defaultProps.justify,
  as = defaultProps.as,
}) => {
  const childAs = as === "div" ? "div" : "li";

  const responsiveSpacing = useResponsiveProp(spacing, defaultProps.spacing);
  const responsiveDirection = useResponsiveProp(
    direction,
    defaultProps.direction
  );
  const responsiveAlign = useResponsiveProp(align, defaultProps.align);
  const responsiveJustify = useResponsiveProp(justify, defaultProps.justify);

  const classes = [
    styles.root,
    styles[`spacing--${responsiveSpacing}`],
    styles[`direction--${responsiveDirection}`],
    styles[`align--${responsiveAlign}`],
    styles[`justify--${responsiveJustify}`],
  ].join(" ");

  return children
    ? createElement(
        as,
        { className: classes },
        Children.map(children, (child) => {
          if (!child) {
            return null;
          }

          return createElement(childAs, { className: styles.item }, child);
        })
      )
    : null;
};
