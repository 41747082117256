export interface GraphQLError {
  message: string;
  code: string;
  fields?: Record<string, string>;
  type?: string;
}

export interface GraphQLErrorFormat {
  response?: {
    errors?: GraphQLError[];
  };
}

export class FieldErrors extends Error {
  public response: GraphQLErrorFormat["response"] = {};

  constructor(fields: Record<string, string>) {
    super("FIELD_ERRORS");

    this.response = {
      errors: [
        {
          message: "FIELD_ERRORS",
          code: "VALIDATION_ERROR",
          fields,
        },
      ],
    };
  }
}
