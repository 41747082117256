import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconCheckOutline: FC<IconTypeProps> = () => {
  return (
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_20617_54784)">
        <path
          d="M22.1307 6.03409L23.6863 7.58965L9.53073 21.7452L3.30851 15.523L4.86406 13.9674L9.53073 18.6341L22.1307 6.03409ZM22.1307 2.88965L9.53073 15.4896L4.86406 10.823L0.164062 15.523L9.53073 24.8896L26.8307 7.58965L22.1307 2.88965Z"
          fill="#00CD68"
        />
      </g>
      <defs>
        <clipPath id="clip0_20617_54784">
          <rect
            width="26.6667"
            height="26.6667"
            fill="white"
            transform="translate(0.164062 0.667969)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
