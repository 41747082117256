import { gtm } from "../../gtm";

export interface TourDetailViewEventVariables {
  tourId: number;
  originAirportCode?: string;
  destinationCodes: string[];
  hotelGrade?: number;
  multiDestination: boolean;
}

export const tourDetailViewBrazeEvent = (
  variables: TourDetailViewEventVariables
) => {
  gtm.pushEvent("braze_tour_detail_view", variables);
};
