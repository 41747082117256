import * as Sentry from "@sentry/nextjs";
import { RequestMiddleware } from "graphql-request";

interface Headers {
  app_id: string;
  app_path: string;
  authorization: string;
  "newt-anonymous-token"?: string;
}

export const sentryRequestMiddleware: RequestMiddleware = (request) => {
  const { operationName, variables } = request;
  const headers = request.headers as Headers | undefined;

  if (headers) {
    const appId = headers.app_id;
    const appPath = headers.app_path;
    const hasAuthorization = !!headers.authorization;
    const hasAnonymousToken = !!headers["newt-anonymous-token"];
    Sentry.setTags({ appId, appPath, hasAuthorization, hasAnonymousToken });
  }

  Sentry.addBreadcrumb({
    category: "graphql",
    level: "info",
    data: { operationName, variables },
  });
  return request;
};
