import { BrowserOptions } from "@sentry/nextjs";

const IGNORE_MESSAGES = [
  // https://github.com/vercel/next.js/issues/43088
  "Invariant: attempted to hard navigate to the same URL",
  // NEWT-WEB-2
  "Non-Error promise rejection captured with value: Timeout",
  // NEWT-WEB-G
  "UnknownError: Connection to Indexed Database server lost. Refresh the page to try again",
  // NEWT-WEB-DN
  "AbortError: AbortError",
];

const IGNORE_FILES = [
  // NEWT-WEB-16H
  // "@sentry/utils/esm/instrument",
  // NEWT-WEB-BJW
  "app:///gtag/js",
  // NEWT-WEB-BZF
  "app:///gtm.js?id=GTM-TSR6QX9",
  // NEWT-WEB-BHQ
  "app:///ajax/libs/jquery",
  // NEWT-WEB-BZ3
  "@firebase/auth/dist/esm2017/index",
  // NEWT-WEB-BVS
  "app:///en_US/fbevents.js",
] as string[];

export const beforeSend: BrowserOptions["beforeSend"] = (event) => {
  const firstValue = event?.exception?.values?.[0];
  const firstFrame = event?.exception?.values?.[0].stacktrace?.frames?.[0];

  /** filtering with error message */
  for (const message of IGNORE_MESSAGES) {
    if (firstValue?.value?.match(message)) {
      return null;
    }
  }

  /** filtering with stack trace */
  for (const url of IGNORE_FILES) {
    if (firstFrame?.module?.match(url)) {
      return null;
    }
  }

  /** specific filter **/
  // NEWT-WEB-342
  if (firstFrame?.function?.match("doubleclick.net")) {
    return null;
  }

  return event;
};
