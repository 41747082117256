import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";

export const addCountryCodeToPhoneNumber = (phoneNumber: string) => {
  // + → なし
  if (phoneNumber[0] === "+") {
    return phoneNumber;
  }

  // 81 → +81
  if (phoneNumber.slice(0, 2) == "81") {
    return `+${phoneNumber}`;
  }

  //なし → +81
  return `+81${phoneNumber}`;
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
  const number = phoneUtil.parseAndKeepRawInput(
    addCountryCodeToPhoneNumber(phoneNumber)
  );
  return phoneUtil.format(number, PhoneNumberFormat.NATIONAL);
};

export const phoneValidation = (phoneNumber: string) => {
  const phoneNumberWithCountryCode = addCountryCodeToPhoneNumber(phoneNumber);
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneUtil.parse(phoneNumberWithCountryCode);
    if (
      !(
        phoneUtil.isValidNumber(parsedNumber) &&
        phoneUtil.isPossibleNumber(parsedNumber)
      )
    ) {
      return false;
    }
  } catch {
    return false;
  }

  return true;
};
