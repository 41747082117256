import { gtm } from "../../gtm";

export interface TourListViewEventVariables {
  searchHitCounts: number;
  travelDateFrom?: string;
  travelDateTo?: string;
  destinationCodes?: string[];
  originAirportCode?: string;
}

export const tourListViewBrazeEvent = (
  variables: TourListViewEventVariables
) => {
  gtm.pushEvent("braze_tour_list_view", variables);
};
