import styles from "./TourCardSkeleton.module.css";
import { FC } from "react";
import { Skeleton } from "../../Skeleton";
import { useBreakPoints } from "../../../hooks/useBreakPoints";

type TourCardSkeletonProps = {};

export const TourCardSkeleton: FC<TourCardSkeletonProps> = ({}) => {
  const breakPoints = useBreakPoints();
  return (
    <div className={styles.root}>
      <div className={styles.figure}>
        <Skeleton width="100%" height={breakPoints.laptopWithUp ? 184 : 140} />
      </div>
      <ul className={styles.rows}>
        <li>
          <Skeleton width="100%" height={24} />
        </li>
        <li>
          <Skeleton width="100%" height={24} />
        </li>
      </ul>
    </div>
  );
};
