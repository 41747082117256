import { useState } from "react";
import { createRoot } from "react-dom/client";
import { Dialog } from "../components/Dialog";
import { FC } from "react";

export interface AlertProps {
  message?: string | React.ReactNode;
  title?: string;
  acceptLabel?: string;
  hideClose?: boolean;
  onAccept?(): void;
}

const Alert: FC<AlertProps> = ({
  onAccept = () => {},
  title,
  message,
  acceptLabel,
  hideClose,
}) => {
  const [isActive, setIsActive] = useState(true);

  const handleAccept = () => {
    onAccept();
    setIsActive(false);
  };
  return (
    <Dialog
      type="alert"
      isActive={isActive}
      title={title}
      message={message}
      acceptLabel={acceptLabel}
      hideClose={hideClose}
      onAccept={handleAccept}
    />
  );
};

const ELEMENT_ID = "dialog-alert";
export interface AlertOptions {
  acceptLabel?: string;
  title?: string;
  hideClose?: boolean;
  onAccept?(): void;
}

export const alert = async (
  message: string | React.ReactNode,
  options: Partial<AlertOptions> = {}
) => {
  let wrapper = document.getElementById(ELEMENT_ID) as HTMLDivElement;

  if (!wrapper) {
    const element = document.createElement("div");
    element.setAttribute("id", ELEMENT_ID);
    wrapper = document.body.appendChild(element);
  }

  const Alerted = new Promise<boolean>((resolve) => {
    const root = createRoot(wrapper);
    root.render(
      <Alert onAccept={() => resolve(true)} message={message} {...options} />
    );
  });

  const dispose = () => {
    setTimeout(() => {
      if (document.body.contains(wrapper)) {
        document.body.removeChild(wrapper);
      }
    }, 300);
  };

  return Alerted.then((result) => {
    dispose();
    return result;
  });
};
