import { FC, ReactNode } from "react";
import { createPortal } from "react-dom";
import { useBodyScroll } from "../../hooks/useBodyScroll";
import { LoadingLogo } from "../LoadingLogo/LoadingLogo";
import styles from "./LoadingLogoOverlay.module.css";

interface LoadingLogoOverlayProps {
  withText?: ReactNode | string;
}

export const LoadingLogoOverlay: FC<LoadingLogoOverlayProps> = ({
  withText,
}) => {
  useBodyScroll();

  if (typeof window !== "object") {
    return null;
  }

  return createPortal(
    <div className={styles.root}>
      <LoadingLogo withText={withText} />
    </div>,
    document.body
  );
};
