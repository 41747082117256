export const LineIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        fill="#1F1F1F"
        d="M19.797 10.531c0-3.38-3.365-6.133-7.5-6.133-4.136 0-7.5 2.752-7.5 6.133 0 3.032 2.668 5.571 6.272 6.05.244.054.577.163.661.372.076.19.05.49.024.683l-.106.647c-.033.19-.151.747.649.408s4.319-2.563 5.893-4.387c1.087-1.2 1.607-2.42 1.607-3.773zM9.652 12.335a.146.146 0 01-.146.147H7.403a.145.145 0 01-.102-.041l-.001-.002-.002-.002a.144.144 0 01-.041-.102V9.044c0-.081.065-.147.146-.147h.525c.08 0 .146.066.146.147v2.615h1.43c.08 0 .146.066.146.147v.53h.002zm1.266.002a.146.146 0 01-.145.147h-.526a.146.146 0 01-.146-.147V9.044c0-.081.065-.147.146-.147h.526c.08 0 .145.066.145.147v3.293zm3.623 0a.146.146 0 01-.146.147h-.526a.131.131 0 01-.037-.005h-.002l-.01-.004-.006-.001c-.001-.002-.005-.002-.007-.004-.001-.002-.005-.002-.006-.003l-.004-.002a.04.04 0 01-.008-.005s-.002 0-.002-.002a.13.13 0 01-.038-.036l-1.498-2.041v1.956a.146.146 0 01-.146.147h-.527a.146.146 0 01-.146-.147V9.044c0-.081.065-.147.146-.147h.537c.003 0 .005 0 .007.002h.007l.008.001c.002 0 .004 0 .005.002.004 0 .006.002.009.004l.005.001.009.004.005.002.008.005.006.003a.024.024 0 01.006.005c.002.002.004.002.004.004.003.002.005.003.008.007l.004.001.008.01h.002l.012.016 1.497 2.038V9.044c0-.081.065-.147.146-.147h.525c.081 0 .146.066.146.147v3.293h-.002zm2.904-2.764a.146.146 0 01-.146.147h-1.43v.557h1.43c.08 0 .146.066.146.147v.53a.146.146 0 01-.146.146h-1.43v.557h1.43c.08 0 .146.066.146.147v.53a.146.146 0 01-.146.146h-2.101a.142.142 0 01-.101-.041l-.002-.002-.002-.001a.144.144 0 01-.04-.102v-3.29c0-.04.014-.074.04-.102l.002-.004.002-.001a.142.142 0 01.101-.042h2.101c.08 0 .146.066.146.147v.531z"
      ></path>
    </svg>
  );
};
