import { useMutation } from "@tanstack/react-query";
import { useAuth } from "@utils/auth";
import { graphql } from "@utils/graphql";
import { gql } from "graphql-request";

const _USE_UPDATE_NEWSLETTER = gql`
  mutation UseUpdateNewsletter_UpdateUser($input: UpdateUserInput!) {
    UpdateUser(input: $input) {
      __typename
    }
  }
`;

export const useUpdateNewsletter = () => {
  const auth = useAuth();
  const { mutateAsync } = useMutation({
    mutationFn: async (sendNewsletter: boolean) => {
      try {
        await graphql.UseUpdateNewsletter_UpdateUser({
          input: {
            newsletter: sendNewsletter,
          },
        });
        auth.refetch();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  });

  return { mutateAsync };
};
