/************************************************************************************
 * 共通鍵暗号方式で暗号化・復号化を行う。
 * 大まかな流れはこのブログの通り：
 *    Webアプリや拡張機能（アドオン）で、Web Crypto APIを使ってローカルに保存されるデータを暗号化する ©2019 結城
 *    [CC BY 4.0](https://creativecommons.org/licenses/by-sa/4.0/deed.ja)
 *    [GFDL](https://www.gnu.org/licenses/fdl-1.3.html)
 *    https://www.clear-code.com/blog/2019/1/30.html
 *
 *
 * 他参考：
 * https://medium.com/@tony.infisical/guide-to-web-crypto-api-for-encryption-decryption-1a2c698ebc25
 * https://qiita.com/dojyorin/items/2fd99491f4b459f937a4
 ***********************************************************************************/

interface EncryptedData {
  salt: number[];
  iv: number[];
  data: string;
}

/**
 * 暗号化関数：環境変数等に設定したsecretHashを元に鍵を生成し、暗号化を行う
 * @param inputValue 保存したい値
 * @param secretHash 本来的にはユーザーが入力したパスワード等。今回は環境変数に設定した値を使用。
 * @returns 保存したい値を暗号化した文字列
 */
export async function aesEncrypt(inputValue: any, secretHash: string) {
  try {
    // 鍵の生成 (saltは難読化のために使用)
    const salt = crypto.getRandomValues(new Uint8Array(16));
    const secretKey = await getSecretKey(secretHash, salt);

    // 初期ベクトル
    const iv = crypto.getRandomValues(new Uint8Array(16));

    // inputValueのエンコード
    const stringified = JSON.stringify(inputValue);
    const encoded = new TextEncoder().encode(stringified);

    // 暗号化
    const encryptedArrayBuffer = await crypto.subtle.encrypt(
      { name: "AES-GCM", iv },
      secretKey,
      encoded
    );

    // ArrayBuffer → Binary String
    const encryptedBinary = Array.from(
      new Uint8Array(encryptedArrayBuffer),
      (char) => String.fromCharCode(char)
    ).join("");

    // Binary String →  Base64
    const encryptedBase64String = window.btoa(encryptedBinary);

    const encryptedData: EncryptedData = {
      salt: Array.from(salt),
      iv: Array.from(iv),
      data: encryptedBase64String,
    };

    return JSON.stringify(encryptedData);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw e;
  }
}

/**
 * 復号化関数：環境変数等に設定したsecretHashを元に鍵を生成し、復号化を行う
 * @param storedData 暗号化された文字列
 * @param secretHash
 * @returns
 */
export async function aesDecrypt(encrypted: string, secretHash: string) {
  const parsedEncrypted = JSON.parse(encrypted) as EncryptedData;

  const encryptedBase64String = parsedEncrypted.data;
  const iv = Uint8Array.from(parsedEncrypted.iv);
  const salt = Uint8Array.from(parsedEncrypted.salt);

  // 鍵の生成
  const secretKey = await getSecretKey(secretHash, salt);

  // Base64 → Binary String
  const encryptedBinary = window.atob(encryptedBase64String);

  // Binary String → Typed Array
  const encryptedUint8Array = Uint8Array.from(
    encryptedBinary.split(""),
    (char) => char.charCodeAt(0)
  );

  // 復号化
  const decryptedArrayBuffer = await crypto.subtle.decrypt(
    { name: "AES-GCM", iv },
    secretKey,
    encryptedUint8Array
  );

  // デコード
  const decoded = new TextDecoder().decode(
    new Uint8Array(decryptedArrayBuffer)
  );

  return JSON.parse(decoded);
}

/** 鍵の生成関数 */
async function getSecretKey(secretHash: string, salt: Uint8Array) {
  // secretHashをエンコード
  const encoded = new TextEncoder().encode(secretHash);
  // ハッシュ値の計算
  const digest = await crypto.subtle.digest({ name: "SHA-256" }, encoded);

  // 鍵の素材
  const baseKey = await crypto.subtle.importKey(
    "raw",
    digest,
    { name: "PBKDF2" },
    false,
    ["deriveKey"]
  );

  // Web Crypto API用の鍵に変換
  const pbkdf2Params = {
    name: "PBKDF2",
    salt,
    iterations: 100000,
    hash: "SHA-256",
  };
  const derivedKeyAlgorithm = { name: "AES-GCM", length: 256 };
  const secretKey = await crypto.subtle.deriveKey(
    pbkdf2Params,
    baseKey,
    derivedKeyAlgorithm,
    false,
    ["encrypt", "decrypt"]
  );

  return secretKey;
}
