import { useState, useEffect } from "react";
import { firebase } from "../firebase";
import { RecaptchaVerifier, User as FirebaseUser } from "firebase/auth";
import { cookies } from "@utils/cookies";
import { COOKIE_TOKEN, FIREBASE_TESTING_FLAG } from "@constants";

export interface AuthUser {
  uid: string;
  emailVerified?: boolean;
}

const formatUser = (user: FirebaseUser) => {
  return {
    uid: user.uid,
    emailVerified: user.emailVerified,
  };
};

const useCaptcha = (buttonId: string) => {
  useEffect(() => {
    (<any>window).recaptchaVerifier = new RecaptchaVerifier(
      firebase.auth,
      buttonId,
      {
        size: "invisible",
      }
    );

    return () => (<any>window).recaptchaVerifier.clear();
  }, [buttonId]);

  return buttonId;
};

const signInWithPhoneNumber = (phoneNumber: string) => {
  const appVerifier = (<any>window).recaptchaVerifier;
  return firebase.signInWithPhoneNumber(
    firebase.auth,
    phoneNumber,
    appVerifier
  );
};

export const useFirebaseAuth = (token?: string) => {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const onIdTokenChanged = async (fbUser: FirebaseUser | null) => {
    if (token) {
      return;
    }

    if (!fbUser) {
      setUser(null);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const formattedUser = formatUser(fbUser);
    setUser(formattedUser);
    setIsLoading(false);
    cookies().set(COOKIE_TOKEN, await fbUser.getIdToken());
  };
  useEffect(() => {
    if (token) {
      setUser({ uid: "token" });
      setIsLoading(false);
    }
    const unsubscribe = firebase.auth.onIdTokenChanged(onIdTokenChanged);
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  // Firebase Testing Mode
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_ENV !== "production") {
      const isE2ETest = cookies().get(FIREBASE_TESTING_FLAG);
      const FIREBASE_TESTING = isE2ETest?.toString() === "true";
      firebase.auth.settings.appVerificationDisabledForTesting =
        FIREBASE_TESTING;
    }
  }, []);

  return {
    user,
    isLoading,
    signOut: () => firebase.auth.signOut(),
    signInWithPhoneNumber,
    useCaptcha,
  };
};
