import { FC } from "react"
import { IconTypeProps } from '../Icon'

export const IconAlarmOutline: FC<IconTypeProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_352_0)">
        <path d="M22 5.70078L17.4 1.80078L16.1 3.30078L20.7 7.20078L22 5.70078ZM7.9 3.40078L6.6 1.90078L2 5.70078L3.3 7.20078L7.9 3.40078ZM12.5 8.00078H11V14.0008L15.7 16.9008L16.5 15.7008L12.5 13.3008V8.00078ZM12 4.00078C7 4.00078 3 8.00078 3 13.0008C3 18.0008 7 22.0008 12 22.0008C17 22.0008 21 18.0008 21 13.0008C21 8.00078 17 4.00078 12 4.00078ZM12 20.0008C8.1 20.0008 5 16.9008 5 13.0008C5 9.10078 8.1 6.00078 12 6.00078C15.9 6.00078 19 9.10078 19 13.0008C19 16.9008 15.9 20.0008 12 20.0008Z" fill="#757575"/>
      </g>
      <defs>
        <clipPath id="clip0_352_0">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}
