import { FC } from "react";
import { HistoryContent_UserTourSearchHistoryFragment } from "@graphql/types";
import { Button, Divider, Text } from "@newt/ui";
import { SearchHistory } from "../SearchHistory";
import {
  DestinationChipSelect,
  AreaDestination,
} from "../DestinationChipSelect";
import styles from "./SearchBoxDestination.module.css";

interface SearchBoxDestinationProps {
  showSearchHistory: boolean;
  histories?: HistoryContent_UserTourSearchHistoryFragment[];
  selectedIds: number[];
  areaDestinations: AreaDestination[];
  onSelected: (destinationIds?: number[]) => void;
  onClear: () => void;
  onSubmit: () => void;
}

export const SearchBoxDestination: FC<SearchBoxDestinationProps> = (props) => {
  const {
    showSearchHistory,
    histories,
    selectedIds,
    areaDestinations,
    onSelected,
    onClear,
    onSubmit,
  } = props;
  return (
    <div className={styles.fieldWrapper} data-testid="searchBox-destinations">
      <div className={styles.destinationWrapper}>
        {showSearchHistory && <SearchHistory history={histories?.[0]} />}
        <DestinationChipSelect
          selectedIds={selectedIds}
          areaDestinations={areaDestinations}
          onSelected={onSelected}
        />
      </div>
      <Divider />
      <div>
        <ul className={styles.pcActionWrapper}>
          <li className={styles.clearButton} onClick={onClear}>
            <Text size="base" weight="bold">
              目的地をクリア
            </Text>
          </li>
          <li>
            <Button
              size={"md"}
              onClick={onSubmit}
              disabled={selectedIds.length === 0}
              width="content"
            >
              決定する
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export const SearchBoxDestinationSpAction: FC<{
  hasValue: boolean;
  onClear: () => void;
  onSubmit: () => void;
}> = (props) => {
  const { hasValue, onClear, onSubmit } = props;
  return (
    <ul className={styles.spActionWrapper}>
      <li className={styles.clearButton}>
        <Button size="lg" color="default" variant="outline" onClick={onClear}>
          クリア
        </Button>
      </li>
      <li>
        <Button size="lg" onClick={onSubmit} disabled={!hasValue}>
          決定する
        </Button>
      </li>
    </ul>
  );
};
