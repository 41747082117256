import { FC } from "react";

export const IconFixtures: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.61683 14.8817V5.29347C1.61683 4.44354 2.29893 3.72642 3.10734 3.72642H5.98732L5.60838 5.47939C5.58311 5.63875 5.63364 5.79811 5.75995 5.87779L7.57889 7.15269C7.73046 7.25893 7.78099 7.49797 7.67994 7.65733L6.341 9.80871C6.23995 9.96807 6.26521 10.154 6.39153 10.2868L7.98309 11.8804C8.05888 11.9601 8.10941 12.0929 8.08415 12.2257L7.73046 14.0849C7.65468 14.4833 8.13467 14.6958 8.36204 14.3771L9.87782 11.9867C9.97887 11.8538 9.9536 11.6679 9.85255 11.5351L8.79151 10.3134C8.66519 10.1806 8.66519 9.96807 8.79151 9.80871L10.7873 7.44485C10.9389 7.28549 10.9136 7.01989 10.7368 6.91364L9.0694 5.69187C8.91782 5.58563 8.89256 5.39971 8.94309 5.21379L9.65045 3.72642H13.3894C14.1978 3.72642 14.8799 4.44354 14.8799 5.29347V7.52453C15.3599 7.20581 15.9157 6.99332 16.4967 6.9402V5.26691C16.4967 3.46081 15.1072 2 13.3894 2H3.10734C1.38946 2.02656 0 3.48737 0 5.29347V14.8817C0 16.6878 1.38946 18.1486 3.10734 18.1486H8.41256C8.43783 18.0955 8.46309 18.0424 8.48835 17.9893L9.37256 16.4488H3.10734C2.29893 16.4488 1.61683 15.7316 1.61683 14.8817Z"
        fill="url(#paint0_linear_23825_56904)"
      />
      <path
        d="M23.7218 18.9456L18.5176 9.78229C18.1639 9.1714 17.5576 8.79956 16.8755 8.79956C16.1934 8.79956 15.5871 9.1714 15.2334 9.78229L10.0292 18.9456C9.67557 19.583 9.6503 20.3267 10.004 20.9641C10.3577 21.6016 10.964 22 11.6713 22H22.0797C22.787 22 23.3934 21.6282 23.747 20.9641C24.1007 20.3267 24.0755 19.583 23.7218 18.9456ZM16.8755 20.1939C16.3703 20.1939 15.966 19.7689 15.966 19.2377C15.966 18.7065 16.3703 18.2816 16.8755 18.2816C17.3808 18.2816 17.785 18.7065 17.785 19.2377C17.785 19.7689 17.3808 20.1939 16.8755 20.1939ZM17.8608 12.8633L17.5323 16.8473C17.5071 17.2192 17.1787 17.5113 16.825 17.4848C16.4966 17.4582 16.2439 17.1926 16.2187 16.8473L15.8903 12.8633C15.8397 12.3055 16.2439 11.8009 16.7745 11.7477C17.305 11.6946 17.785 12.1196 17.8355 12.7039C17.8608 12.757 17.8608 12.8102 17.8608 12.8633Z"
        fill="url(#paint1_linear_23825_56904)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_23825_56904"
          x1="12.028"
          y1="21.6503"
          x2="11.8071"
          y2="6.05907"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0520833" stopColor="#FF99EF" />
          <stop offset="0.95" stopColor="#F7DE00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_23825_56904"
          x1="12.028"
          y1="21.6503"
          x2="11.8071"
          y2="6.05907"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0520833" stopColor="#FF99EF" />
          <stop offset="0.95" stopColor="#F7DE00" />
        </linearGradient>
      </defs>
    </svg>
  );
};
