import { ItemListId } from "@utils/marketing/events/viewItemListEvent";
import RouterQueryUtils, {
  RouterQueryInput,
  RouterQueryParams,
} from "@utils/router/routerQuery";

export interface TourPageQuery extends RouterQueryInput {
  adults?: number;
  airportId?: number;
  children?: number;
  children_without_bed?: number;
  infants?: number;
  rooms?: number;
  from?: string;
  to?: string;
  t?: string;
  destinationId?: number;
  itemListId?: ItemListId;
}

export const toTourPageQuery = (query: RouterQueryParams): TourPageQuery => {
  const destinationIds = RouterQueryUtils.getArrayNumber(query.destinationIds);

  return {
    adults: RouterQueryUtils.getNumber(query.adults),
    airportId: RouterQueryUtils.getNumber(query.airportId),
    children: RouterQueryUtils.getNumber(query.children),
    children_without_bed: RouterQueryUtils.getNumber(
      query.children_without_bed
    ),
    infants: RouterQueryUtils.getNumber(query.infants),
    rooms: RouterQueryUtils.getNumber(query.rooms),
    from: RouterQueryUtils.getString(query.from || query.travelDateFrom),
    to: RouterQueryUtils.getString(query.to || query.travelDateTo),
    t: RouterQueryUtils.getString(query.t),
    ...(destinationIds
      ? { destinationIds }
      : query.destinationId // deprecated: 古いメールからの遷移を考慮して残す
        ? { destinationId: RouterQueryUtils.getNumber(query.destinationId) }
        : {}),
  };
};
