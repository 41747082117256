import { useToast } from "@newt/ui";
import { ROUTES } from "@routes";
import { useAuth } from "@utils/auth";
import { useIsWebview } from "@utils/webview";
import { useRouter } from "next/router";
import { useEffect } from "react";

export const useAuthenticateIfNotWebview = () => {
  const isWebview = useIsWebview();
  const { user, isLoading } = useAuth();
  const router = useRouter();
  const toast = useToast();

  useEffect(() => {
    if (isWebview === undefined || isWebview) return;
    if (isLoading || !!user) return;

    toast.error("このページの閲覧権限がありません");
    router.push(
      ROUTES.signin.index({
        redirectTo: router.pathname,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoading]);
};
