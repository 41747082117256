export const LOCAL_STORAGE_APP_BANNER_KEY = "appBannerClosedDate";

export const DURATION_DATES_TO_SHOW_APP_BANNER = 30;

export const DOWNLOAD_APP_BANNER_LINK = "https://app.adjust.com/16ji1f5d";

// mac os => apple store ,  windows => google play
export const DOWNLOAD_APP_BANNER_LINK_SP =
  DOWNLOAD_APP_BANNER_LINK +
  "?redirect_macos=https%3A%2F%2Fapps.apple.com%2Fjp%2Fapp%2Fid1585901351&redirect_windows=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dnet.newt";
