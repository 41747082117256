import { FC } from "react";
import styles from "./Progress.module.css";

interface ProgressProps {
  text: string;
  overall: number;
}

export const Progress: FC<ProgressProps> = ({ text, overall }) => {
  const percent = (overall / 5) * 100;
  return (
    <div className={styles.progress}>
      <div className={styles.header}>
        <span>{text}</span>
        <span className={styles.overall}>{overall.toFixed(1)} / 5</span>
      </div>
      <div className={styles.outer}>
        <div className={styles.inner}>
          <div
            className={styles.background}
            style={{ width: `${percent}%`, height: "8px" }}
          ></div>
        </div>
      </div>
    </div>
  );
};
