import { useState } from "react";
import { useSafeLayoutEffect } from "../utils/useSafeLayoutEffect";

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  useSafeLayoutEffect(() => {
    const mediaQuery = window.matchMedia(query);
    setMatches(mediaQuery.matches);

    const handler = (event: MediaQueryListEvent) => setMatches(event.matches);

    mediaQuery.addEventListener("change", handler);
    return () => mediaQuery.removeEventListener("change", handler);
  }, [query]);

  return matches;
};
