import {
  ANONYMOUS_USER_TOKEN,
  API_HOST,
  COOKIE_APP_ID,
  COOKIE_APP_VERSION,
} from "@constants";
import { SdkFunctionWrapper, getSdk } from "@graphql/types";
import { LocalStorageService } from "@utils/webStorage";
import {
  GraphQLClient,
  RequestMiddleware,
  ResponseMiddleware,
} from "graphql-request";
import {
  sentryRequestMiddleware,
  sentryResponseMiddleware,
} from "./graphqlMiddleware";

import { getAuthorizationToken } from "./auth";
import { firebase } from "./firebase";
import { cookies } from "./cookies";

const requestMiddleware: RequestMiddleware = (request) => {
  sentryRequestMiddleware(request);
  return request;
};

const responseMiddleware: ResponseMiddleware = (response) =>
  sentryResponseMiddleware(response);

const client = new GraphQLClient(API_HOST, {
  requestMiddleware,
  responseMiddleware,
});

const getAppPath = () => {
  if (typeof window === "undefined") {
    return "unknown";
  }

  return window.location.pathname;
};

const getAppHeaders = () => {
  const appId = cookies().get(COOKIE_APP_ID);
  const appVersion = cookies().get(COOKIE_APP_VERSION);

  return {
    app_id: appId ? `${appId}-webview` : "newt-web",
    app_version: appVersion || "1.0.0",
    app_path: getAppPath(),
  };
};

const setHeaders: SdkFunctionWrapper = async <T>(action: () => Promise<T>) => {
  const token =
    getAuthorizationToken() || (await firebase.auth.currentUser?.getIdToken());
  const anonymousUserToken = token
    ? undefined
    : await LocalStorageService.getItem<string>(ANONYMOUS_USER_TOKEN);

  client.setHeaders({
    authorization: token || "",
    ...getAppHeaders(),
  });

  if (anonymousUserToken) {
    client.setHeader("newt-anonymous-token", anonymousUserToken);
  }

  return action();
};

export const graphql = getSdk(client, setHeaders);
