import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconRoomService: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        fill="#1F1F1F"
        d="M3.164 26.099v-2.267h27.067v2.267H3.164zm1-4.267v-.767c0-3.466.983-6.266 2.95-8.4 1.967-2.133 4.428-3.533 7.383-4.2v-.933c0-.644.212-1.172.634-1.583.422-.411.944-.617 1.566-.617.623 0 1.145.206 1.567.617.422.41.633.939.633 1.583v.933c2.956.667 5.417 2.067 7.384 4.2 1.966 2.134 2.95 4.934 2.95 8.4v.767H4.164zm2.4-2.267h20.267c-.311-2.8-1.44-4.994-3.384-6.583-1.944-1.589-4.205-2.383-6.783-2.383-2.578 0-4.828.794-6.75 2.383-1.922 1.589-3.039 3.783-3.35 6.583z"
      ></path>
    </svg>
  );
};
