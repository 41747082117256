import { useRef } from "react";

export const useTimeout = (defaultMs = 500) => {
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const exec = (callback: () => void, ms = defaultMs) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      callback();
      timeoutId.current = null;
    }, ms);
  };

  return {
    exec,
  };
};
