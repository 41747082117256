import { gtm } from "../gtm";

export interface ViewModuleEventVariables {
  // モジュールID
  module_id?: string;
  // モジュールタイプ
  module_type?: string;
  // モジュールタップ数
  view_auth_banner_count?: 1;
  view_referral_banner_count?: 1;
}

export const viewModuleEvent = (variables?: ViewModuleEventVariables) => {
  gtm.pushEvent("view_module", variables);
};
