import { useState, useEffect } from "react";
import { LocalStorageService } from "./webStorage";

export function useLocalStorage<T>(key: string) {
  const [value, setValue] = useState<T | null | undefined>();

  useEffect(() => {
    LocalStorageService.getItem<T>(key).then((value) => {
      setValue(value);
    });
  }, [key]);

  return value;
}
