import { UserStatus } from "@graphql/types";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "@utils/auth";
import { graphql } from "@utils/graphql";
import { gql } from "graphql-request";

const _RESERVATION_CHECKOUT_PAGE_QUERY = gql`
  query UseFetchReservation_Query($reservationId: Int!) {
    me {
      bookingById(id: $reservationId) {
        tour {
          ...GetHighlightedTlUrl_TourSnapshot
          ...TourTicket_TourSnapshot
        }
        productType
      }
    }
  }
`;

export const useFetchReservation = (reservationId?: number) => {
  const { user } = useAuth();

  const { data, isPending } = useQuery({
    queryKey: ["UseFetchReservation_Query"],
    enabled: user?.status === UserStatus.ACTIVE && !!reservationId,
    queryFn: async () => {
      try {
        const data = await graphql.UseFetchReservation_Query({
          reservationId: reservationId!,
        });
        return data;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    select: (data) => data?.me?.bookingById,
  });

  return { data, isPending };
};
