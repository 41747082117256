import { Icon } from "../Icon";
import { MouseEvent, ReactNode, useEffect, useState } from "react";
import styles from "./Accordion.module.css";

interface SummaryOptions {
  justifyContent?: "space-between" | "center";
  showCloseButton?: boolean;
}

interface AccordionProps {
  summary: ReactNode;
  content: ReactNode;
  summaryOptions?: SummaryOptions;
  isExpanded?: boolean;
  allowToggle?: boolean;
}

export const Accordion = ({
  summary,
  content,
  summaryOptions = {
    justifyContent: "space-between",
    showCloseButton: false,
  },
  isExpanded = false,
  allowToggle = true,
}: AccordionProps) => {
  const [open, setOpen] = useState(isExpanded);
  const showCloseButton = summaryOptions.showCloseButton && open;
  const onToggle = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    allowToggle && setOpen(!open);
  };

  useEffect(() => {
    setOpen(isExpanded);
  }, [isExpanded]);

  return (
    <div className={styles.root}>
      <details
        open={open}
        onClick={onToggle}
        className={styles.details}
        data-testid="newt-ui-accordion-details"
      >
        <summary
          className={`${styles.summary} ${allowToggle && styles.allowToggle}`}
        >
          {!showCloseButton && (
            <div
              className={[
                styles.summaryInner,
                summaryOptions.justifyContent === "space-between"
                  ? styles.summaryInnerSpaceBetween
                  : styles.summaryInnerCenter,
                summaryOptions.showCloseButton && open ? styles.hide : "",
              ].join(" ")}
            >
              {summary}
              {allowToggle && (
                <div className={styles.icon}>
                  <Icon icon={open ? "chevronUp" : "chevronDown"} size="md" />
                </div>
              )}
            </div>
          )}
        </summary>
        <div className={styles.content} onClick={(e) => e.stopPropagation()}>
          {content}
        </div>
      </details>
      {showCloseButton && (
        <div className={styles.closeSummary}>
          <button
            className={[styles.closeButton, styles.summaryInner].join(" ")}
            onClick={() => setOpen(false)}
          >
            とじる
            <div className={styles.icon}>
              <Icon icon={"chevronUp"} size="md" />
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

Accordion.displayName = "ArticleCard";
