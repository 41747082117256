import { Text } from "@newt/ui";
import { FC, ReactNode } from "react";
import styles from "./CreditCard.module.css";

export const CreditCard: FC<{
  icon: ReactNode;
  label: string;
  placeholder: string;
}> = (props) => (
  <div className={styles.root}>
    <div className={styles.icon}>{props.icon}</div>
    <div>
      <div className={styles.label}>{props.label}</div>
      <Text size="sm" weight="bold" color="gray">
        {props.placeholder}
      </Text>
    </div>
  </div>
);
