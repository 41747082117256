const IMAGE_BASE_URL = "https://newt.imgix.net/destinations/0";
const IMAGE_SIZE_QUERY = "w=160&amp;h=160&amp;auto=format";

export const airports = [
  {
    id: "tyo",
    name: "東京(羽田+成田)",
    imageUrl: `${IMAGE_BASE_URL}/destination_tokyo_4.jpg?${IMAGE_SIZE_QUERY}`,
  },
  {
    id: "hnd",
    name: "羽田空港",
    imageUrl: `${IMAGE_BASE_URL}/destination_tokyo_5.jpg?${IMAGE_SIZE_QUERY}`,
  },
  {
    id: "nrt",
    name: "成田空港",
    imageUrl: `${IMAGE_BASE_URL}/destination_tokyo_3.jpg?${IMAGE_SIZE_QUERY}`,
  },
  {
    id: "kix",
    name: "関西国際空港",
    imageUrl: `${IMAGE_BASE_URL}/destination_kansai_1.jpg?${IMAGE_SIZE_QUERY}`,
  },
  {
    id: "ngo",
    name: "中部国際空港",
    imageUrl: `${IMAGE_BASE_URL}/destination_nagoya.jpg?${IMAGE_SIZE_QUERY}`,
  },
  {
    id: "fuk",
    name: "福岡空港",
    imageUrl: `${IMAGE_BASE_URL}/destination_hakata.jpg?${IMAGE_SIZE_QUERY}`,
  },
];
