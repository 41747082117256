import { COOKIE_AUTHORIZATION, COOKIE_TOKEN } from "@constants";
import { cookies } from "@utils/cookies";
import { useEffect, useState } from "react";

export const useAuthorizationToken = () => {
  const [token, setToken] = useState<string | undefined>();

  useEffect(() => {
    const authorizationToken = cookies().get(COOKIE_AUTHORIZATION);

    if (authorizationToken) {
      setToken(authorizationToken);
      cookies().set(COOKIE_TOKEN, authorizationToken);
    }
  }, []);

  return token;
};

export const getAuthorizationToken = () => {
  return cookies().get(COOKIE_AUTHORIZATION);
};
