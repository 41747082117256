import { useMemo } from "react";
import {
  SearchBoxArea_DestinationFragment,
  SearchBoxCountry_DestinationFragment,
  SearchBoxCity_DestinationFragment,
  SearchBoxDisplayDestination_DestinationFragment,
  DestinationType,
} from "@graphql/types";
import { SearchDataset } from "./useSearchData";

export const useDestinations = (
  destinations: SearchDataset<SearchBoxArea_DestinationFragment>
) => {
  const { countries, cities } = useMemo(() => {
    const countries: SearchBoxCountry_DestinationFragment[] = [];
    const cities: SearchBoxCity_DestinationFragment[] = [];

    destinations.destinations?.forEach((area) => {
      area.children.forEach((country) => {
        countries.push(country);
        cities.push(...country.children);
      });
    });

    return { countries, cities };
  }, [destinations]);

  const mappedDestinations = useMemo(() => {
    const mappedDestinations = new Map<
      number,
      SearchBoxCountry_DestinationFragment | SearchBoxCity_DestinationFragment
    >();
    destinations.destinations?.forEach((area) => {
      area.children.forEach((country) => {
        mappedDestinations.set(country.id, country);
        country.children.forEach((city) => {
          mappedDestinations.set(city.id, city);
        });
      });
    });

    return mappedDestinations;
  }, [destinations]);

  const findDestination = (destinationId: string | number | undefined) => {
    if (!destinationId) return null;

    return (
      countries.find(
        (country) => String(country.id) === String(destinationId)
      ) ||
      cities.find((city) => String(city.id) === String(destinationId)) ||
      null
    );
  };

  const findDestinationByCode = (destinationCode: string | undefined) => {
    if (!destinationCode) return null;

    return (
      countries.find((country) => country.code === destinationCode) ||
      cities.find((city) => city.code === destinationCode) ||
      null
    );
  };

  const findDestinationsByIds = (
    destinationIds?: number[]
  ): SearchBoxDisplayDestination_DestinationFragment[] => {
    if (!destinationIds || destinationIds.length === 0) return [];

    const foundDestinations = destinationIds
      .map((id) => mappedDestinations.get(id))
      .filter((v) => v) as SearchBoxDisplayDestination_DestinationFragment[];
    return foundDestinations;
  };

  const findDestinationCodes = (destinationIds?: number[]) => {
    if (!destinationIds || destinationIds.length === 0) return [];

    const destinationCodes = destinationIds.reduce((acc, id) => {
      const destination = mappedDestinations.get(id);
      if (destination?.type === DestinationType.COUNTRY) {
        // @ts-expect-error cityにはchildrenが無いと怒られるのでignore TODO: use type guard
        const cityCodes = destination.children?.map((city) => city.code) || [];
        acc.push(...cityCodes);
      } else if (destination?.type === DestinationType.CITY) {
        acc.push(destination.code);
      }
      return acc;
    }, [] as string[]);

    return destinationCodes;
  };

  return {
    findDestination,
    findDestinationByCode,
    findDestinationsByIds,
    findDestinationCodes,
  };
};
