import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconCoupon: FC<IconTypeProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3455_5368)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7967 2.90217C11.5417 2.28796 12.5143 2.10261 13.3893 2.34119C13.3897 2.3413 13.3902 2.34142 13.3906 2.34153L20.2809 4.20627C20.691 4.31727 20.9862 4.67535 21.017 5.09914L21.5338 12.2185C21.5338 12.219 21.5338 12.2194 21.5339 12.2198C21.6012 13.1243 21.2339 14.0437 20.4889 14.6579L12.4586 21.2663C11.2351 22.2743 9.41199 22.1153 8.39516 20.882L2.38096 13.5873C1.36404 12.3538 1.55498 10.5342 2.77815 9.52539C2.77828 9.52528 2.77802 9.52549 2.77815 9.52539L10.7967 2.90217ZM12.069 4.44533C12.0689 4.4454 12.0691 4.44525 12.069 4.44533L4.0515 11.0677C3.67017 11.382 3.61803 11.9437 3.92412 12.315L9.93831 19.6097C10.2444 19.981 10.805 20.0375 11.1864 19.7231L19.2166 13.1148C19.4487 12.9234 19.5598 12.6403 19.5394 12.368L19.5392 12.3657L19.0736 5.95149L12.8636 4.27085C12.6003 4.19894 12.3011 4.25412 12.069 4.44533Z"
          fill="#1F1F1F"
        />
        <circle cx="15.5" cy="8.5" r="1.5" fill="#1F1F1F" />
      </g>
      <defs>
        <clipPath id="clip0_3455_5368">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
