import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconAirConditioning: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        fill="#1F1F1F"
        d="M15.404 29.433v-6.367l-4.867 4.867L8.97 26.4l6.434-6.434V17.1h-2.867l-6.3 6.3-1.6-1.5 4.8-4.8H3.07v-2.2h6.367l-4.9-4.9 1.567-1.567 6.433 6.467h2.867V12l-6.3-6.3 1.533-1.6 4.767 4.8V2.566h2.2V8.9l4.9-4.9 1.533 1.566L17.604 12v2.9h2.9l6.333-6.334 1.567 1.534-4.8 4.8h6.333v2.2h-6.333l4.833 4.866-1.5 1.567-6.433-6.433h-2.9v2.866l6.433 6.467-1.5 1.567-4.933-4.934v6.367h-2.2z"
      ></path>
    </svg>
  );
};
