import { FC, ReactNode } from "react";
import styles from "./SearchBoxPeople.module.css";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { InputStepper } from "@newt/ui/src/components/InputStepper";
import { SearchFormValues } from "@components/common/SearchBox/hooks/useSearchForm";
import { alert, Icon, IconType, Text } from "@newt/ui";
import { TourSelectFormValues } from "@components/pages/tours/[tourId]/useTourSelectForm";

interface SearchFieldProps {
  control: Control<SearchFormValues>;
  setValue: UseFormSetValue<SearchFormValues>;
  getValues: UseFormGetValues<SearchFormValues>;
  maxTotalPeoples: number;
}

interface SearchFieldRowProps {
  children: ReactNode;
  label: string;
  icon: IconType;
  caption?: string;
  vertical?: boolean;
}

interface SearchFieldRowChildProps {
  children: ReactNode;
  label: string;
}

type SearchFieldPeopleKey =
  | "infants"
  | "rooms"
  | "adults"
  | "children"
  | "children_without_bed";

const PEOPLE_KEYS: SearchFieldPeopleKey[] = [
  "adults",
  "children",
  "children_without_bed",
  "infants",
];

const Row: FC<SearchFieldRowProps> = ({
  icon,
  label,
  caption,
  vertical = false,
  children,
}) => {
  const rootClasses = [
    styles.row,
    vertical ? styles["row--vertical"] : "",
  ].join(" ");

  return (
    <div className={rootClasses}>
      <div className={styles.rowIcon}>
        <Icon icon={icon} size="lg" color="gray-50" />
      </div>
      <div className={styles.rowBody}>
        <dl className={styles.rowDefinition}>
          <dt>
            <Text size="lg">{label}</Text>
            {caption && (
              <Text size="sm" color="gray">
                {caption}
              </Text>
            )}
          </dt>
          <dd>{children}</dd>
        </dl>
      </div>
    </div>
  );
};

const RowChild: FC<SearchFieldRowChildProps> = ({ label, children }) => {
  return (
    <dl className={styles.rowChild}>
      <dt>
        <Text>{label}</Text>
      </dt>
      <dd>{children}</dd>
    </dl>
  );
};

export const SearchBoxPeople: FC<SearchFieldProps> = ({
  control,
  setValue,
  getValues,
  maxTotalPeoples,
}) => {
  const notes = [
    "予約する合計の部屋数と人数を入力してください。",
    "人数は、出発日時点での年齢をもとに入力してください。",
    "子どものベッドあり人数に関わらず、ベッドの台数はツアーによります。ツアーの詳細をご確認ください。",
  ];
  const countTotalPeople = () => {
    let count = 0;
    const values = getValues();
    PEOPLE_KEYS.forEach((v) => (count += values[v]));
    return count;
  };

  const setValueByKey: UseFormSetValue<
    Pick<TourSelectFormValues, SearchFieldPeopleKey>
  > = (name, value: number) => {
    const beforeVal = getValues()[name];

    if (PEOPLE_KEYS.includes(name)) {
      const totalPeoples = countTotalPeople();
      if (totalPeoples === maxTotalPeoples && value > beforeVal) {
        alert(
          "旅行人数は6名までです。ご予約を分けていただくか、トラベルコンシェルジュまでお問い合わせください。"
        );
        return;
      }
    }

    setValue<SearchFieldPeopleKey>(name, value);
  };

  return (
    <div className={styles.root}>
      <Row label="部屋" icon="bedOutline" caption="予約する合計の部屋数">
        <Controller
          name="rooms"
          control={control}
          render={({ field }) => (
            <InputStepper
              name="rooms"
              value={field.value}
              ref={field.ref}
              min={1}
              max={6}
              onChange={(val) => setValueByKey("rooms", val)}
            />
          )}
        />
      </Row>
      <Row label="おとな" icon="personAdultOutline" caption="12歳以上">
        <Controller
          name="adults"
          control={control}
          render={({ field }) => (
            <InputStepper
              name="adults"
              value={field.value}
              ref={field.ref}
              min={1}
              max={6}
              onChange={(val) => setValueByKey("adults", val)}
            />
          )}
        />
      </Row>
      <Row
        label="子ども"
        icon="personChildOutline"
        caption="2 - 11歳"
        vertical={true}
      >
        <RowChild label="ベッドあり">
          <Controller
            name="children"
            control={control}
            render={({ field }) => (
              <InputStepper
                name="children"
                value={field.value}
                ref={field.ref}
                max={6}
                onChange={(val) => setValueByKey("children", val)}
              />
            )}
          />
        </RowChild>
        <RowChild label="ベッドなし">
          <Controller
            name="children_without_bed"
            control={control}
            render={({ field }) => (
              <InputStepper
                name="children_without_bed"
                value={field.value}
                ref={field.ref}
                max={6}
                onChange={(val) => setValueByKey("children_without_bed", val)}
              />
            )}
          />
        </RowChild>
      </Row>
      <Row
        label="乳幼児"
        icon="personInfantOutline"
        caption="2歳未満（ベッドなし）"
      >
        <Controller
          name="infants"
          control={control}
          render={({ field }) => (
            <InputStepper
              name="infants"
              value={field.value}
              ref={field.ref}
              max={6}
              onChange={(val) => setValueByKey("infants", val)}
            />
          )}
        />
      </Row>
      <div className={styles.note}>
        {notes.map((note, index) => (
          <div className={styles.noteBody} key={index}>
            <Text size="sm">
              <span className={styles.noteIcon}>※</span>
              {note}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
};
