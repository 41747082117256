import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconPrice: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#757575"
        d="M23 12l-2.44-2.79.34-3.69-3.61-.82-1.89-3.2L12 2.96 8.6 1.5 6.71 4.69 3.1 5.5l.34 3.7L1 12l2.44 2.79-.34 3.7 3.61.82L8.6 22.5l3.4-1.47 3.4 1.46 1.89-3.19 3.61-.82-.34-3.69L23 12zm-12.91 4.72l-3.8-3.81 1.48-1.48 2.32 2.33 5.85-5.87 1.48 1.48-7.33 7.35z"
      ></path>
      <path fill="#757575" d="M6 7h12v10H6V7z"></path>
      <path
        fill="#fff"
        style={{ fill: "#FFF" }}
        d="M14.124 12.387v.825H9.749v-.825h4.375zm0 1.416v.82H9.749v-.82h4.375zm-2.456-1.265l1.436-3.647h1.518l-1.977 4.37h-.806l-.171-.723zm-.771-3.647l1.464 3.691-.19.679h-.81L9.377 8.89h1.518zm1.816 3.403V16h-1.46v-3.706h1.46z"
      ></path>
    </svg>
  );
};
