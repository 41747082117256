import { FC, MouseEventHandler, ReactNode } from "react";
import { createPortal } from "react-dom";
import { useIsMounted } from "../../hooks/useIsMounted";
import { Button, ButtonProps } from "../Button";
import { Icon } from "../Icon";
import { Overlay, OVERLAY_DURATION } from "../Overlay";
import { Text } from "../Text";
import styles from "./Dialog.module.css";

export interface DialogProps {
  children?: ReactNode;
  isActive: boolean;
  type?: "alert" | "confirm";
  title?: string;
  message?: string | ReactNode;
  acceptLabel?: string;
  acceptColor?: ButtonProps["color"];
  acceptVariant?: ButtonProps["variant"];
  rejectLabel?: string;
  rejectColor?: ButtonProps["color"];
  rejectVariant?: ButtonProps["variant"];
  hideClose?: boolean;
  onAccept?(): void;
  onReject?(): void;
  onClose?(): void;
}

const DialogContainer: FC<DialogProps> = ({
  type,
  title,
  message,
  acceptLabel = "OK",
  rejectLabel = "キャンセル",
  acceptColor = "default",
  acceptVariant = "outline",
  rejectColor = "default",
  rejectVariant = "outline",
  hideClose = false,
  onAccept = () => {},
  onReject = () => {},
  onClose = () => {},
  children,
}) => {
  const { isMounted } = useIsMounted();

  const handleAccept: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onAccept();
    onClose();
  };
  const handleReject: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onReject();
    onClose();
  };
  const handleClose: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (type === "confirm") {
      handleReject(e);
    } else {
      handleAccept(e);
    }
  };

  return (
    <section
      className={[styles.root, isMounted ? styles.opened : styles.init].join(
        " "
      )}
      onClick={(e) => e.stopPropagation()}
    >
      <header className={styles.header}>
        {title && (
          <Text size="lg" weight="bold" align="center">
            {title}
          </Text>
        )}
      </header>
      {!hideClose && (
        <div className={styles.close}>
          <Icon icon="close" onClick={handleClose} />
        </div>
      )}
      <div className={styles.body}>
        <Text weight="bold">{message || children}</Text>
      </div>
      <ul className={styles.actions}>
        {type === "confirm" && (
          <li>
            <Button
              color={rejectColor}
              size="md"
              variant={rejectVariant}
              onClick={handleReject}
            >
              {rejectLabel}
            </Button>
          </li>
        )}
        <li>
          <Button
            color={acceptColor}
            size="md"
            variant={acceptVariant}
            onClick={handleAccept}
            autoFocus
          >
            {acceptLabel}
          </Button>
        </li>
      </ul>
    </section>
  );
};

export const Dialog: FC<DialogProps> = (props) => {
  if (typeof document === "undefined") return null;

  return createPortal(
    props.isActive ? (
      <Overlay>
        <DialogContainer {...props} />
      </Overlay>
    ) : null,
    document.body
  );
};
