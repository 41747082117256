import { gtm } from "../gtm";

export interface SignupEventVariables {
  // NEWTユーザーID
  uid?: string;
  // NEWTユーザーID
  user_id?: string;
  // 会員ステータス
  user_status: "member" | "guest";
  // 特定のview_moduleイベントからsignupした時に指定
  previous_module_type?: string;
  // 認証の手法
  auth_method?: string;
}

export const signupEvent = (variables?: SignupEventVariables) => {
  gtm.pushEvent("signup", variables);
};
