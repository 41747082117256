import styles from "./Chips.module.css";
import { IconType } from "../Icon";
import { Chip, ChipValue } from "./Chip";

export type ChipsOption<T extends ChipValue = string> = {
  label: string;
  value: T;
  iconLeft?: IconType;
  iconRight?: IconType;
};

export type ChipsProps<T extends ChipValue = string> = {
  options: ChipsOption<T>[];
  checked: T[];
  size?: "sm" | "md";
  onChange: (val: T[]) => void;
};

export const Chips = <T extends ChipValue = string>({
  options,
  checked,
  size,
  onChange,
}: ChipsProps<T>) => {
  const optionsWithChecked: ((typeof options)[number] & {
    checked: boolean;
  })[] = options.map((option) => ({
    ...option,
    checked: checked.includes(option.value),
  }));

  const handleChange = (values: T[]) => {
    const filtered = values.filter((value) =>
      options.some((option) => option.value === value)
    ) as T[];
    onChange(filtered);
  };
  return (
    <ul className={styles.root}>
      {optionsWithChecked.map((option) => (
        <li key={option.value}>
          <Chip
            label={option.label}
            value={option.value}
            iconLeft={option.iconLeft}
            iconRight={option.iconRight}
            size={size}
            checked={checked}
            onChange={handleChange}
          />
        </li>
      ))}
    </ul>
  );
};
