import { FC, MouseEventHandler } from "react";
import { ResponsiveProp } from "../../types/component.type";
import { useResponsiveProp } from "../../hooks/useResponsiveProp";
import styles from "./IconForPopAndWind.module.css";
import { IconHeart } from "./icons/IconHeart";
import { IconFixtures } from "./icons/IconFixtures";
import { IconPossessions } from "./icons/IconPossessions";

export type IconForPopAndWindType = keyof typeof iconsForPopAndWind;

interface IconForPopAndWindProps {
  icon: IconForPopAndWindType;
  size?: ResponsiveProp<"xs" | "sm" | "base" | "md" | "lg" | "xl" | "2xl">;
  onClick?: MouseEventHandler<HTMLElement>;
}

export const iconsForPopAndWind = {
  heart: IconHeart,
  fixtures: IconFixtures,
  possessions: IconPossessions,
};

export const IconForPopAndWind: FC<IconForPopAndWindProps> = ({
  icon,
  size = "md",
  onClick,
}) => {
  const IconComponent = iconsForPopAndWind[icon];
  const responsiveSize = useResponsiveProp(size, "md");

  const hasOnClick = onClick !== undefined;
  const rootClasses = [
    styles.root,
    styles[`size--${responsiveSize}`],
    hasOnClick ? styles.clickable : "",
  ].join(" ");

  return (
    <i className={rootClasses} onClick={onClick ? onClick : undefined}>
      <IconComponent />
    </i>
  );
};
