import {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useCallback,
} from "react";
import { Toast } from ".";
import { ToastProps, ToastVariants } from "./Toast";

interface Callbacks {
  onRevert?: () => void;
  onClose?: () => void;
}
interface ContextProps {
  create: (message: string, variant?: ToastVariants) => void;
  success: (message: string, callbacks?: Callbacks) => void;
  error: (message: string) => void;
  close: () => void;
}

interface ToastProviderProps {
  children: ReactNode;
}

const TOAST_DELAY = 2000;

export const ToastContext = createContext<ContextProps>({
  create: () => null,
  success: () => null,
  error: () => null,
  close: () => null,
});

export const ToastProvider: FC<ToastProviderProps> = ({
  children,
  ...props
}) => {
  const [toast, setToast] = useState<
    Pick<ToastProps, "id" | "children" | "variant"> & Callbacks
  >();

  const createToast = (
    message: string,
    variant: ToastVariants = "success",
    callbacks?: Callbacks
  ) => {
    if (toast?.onClose) {
      // if there is previous data, resolve it
      toast?.onClose();
    }

    setToast({
      id: (Math.random() + 1).toString(36),
      children: message,
      variant,
      onClose: callbacks?.onClose,
      onRevert: callbacks?.onRevert,
    });
  };

  const handleClose = useCallback(() => {
    setToast((prev) => {
      prev?.onClose?.();
      return undefined;
    });
  }, [setToast]);

  const handleRevert = useCallback(() => {
    setToast((prev) => {
      prev?.onRevert?.();
      return undefined;
    });
  }, [setToast]);

  const value: ContextProps = {
    create: createToast,
    success: (message: string, callbacks) =>
      createToast(message, "success", callbacks),
    error: (message: string) => createToast(message, "error"),
    close: handleClose,
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleClose();
    }, TOAST_DELAY);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [toast, handleClose]);

  return (
    <ToastContext.Provider value={value} {...props}>
      {toast && (
        <Toast
          key={toast.id}
          variant={toast.variant}
          onClose={handleClose}
          onRevert={toast.onRevert && handleRevert}
        >
          {toast.children}
        </Toast>
      )}
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext<ContextProps>(ToastContext);
};
