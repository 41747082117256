import { LocalStorageService } from "@utils/webStorage";
import { RegisteredModalId, LOCAL_STORAGE_KEY } from "./ModalOnLoadHandler";

export const storeModalIdAtEndForNextPageLoad = (
  modalId: RegisteredModalId
) => {
  LocalStorageService.getItem<RegisteredModalId[]>(LOCAL_STORAGE_KEY).then(
    (modalDataArr) => {
      // if there is no flag on local storage, set it
      if (modalDataArr) {
        // set at end if there is no same modalId
        if (!modalDataArr.includes(modalId)) {
          LocalStorageService.setItem(LOCAL_STORAGE_KEY, [
            ...modalDataArr,
            modalId,
          ]);
        }
      } else {
        LocalStorageService.setItem(LOCAL_STORAGE_KEY, [modalId]);
      }
    }
  );
};
