export const IconGoogle = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_24361_4166)">
        <path
          d="M14.4 8.14667C14.4 7.59854 14.3555 7.1985 14.2592 6.78369H8V9.25774H11.6741C11.6 9.8726 11.2 10.7985 10.3111 11.4207L10.2986 11.5035L12.2777 13.0367L12.4148 13.0504C13.6741 11.8874 14.4 10.1763 14.4 8.14667Z"
          fill="#4285F4"
        />
        <path
          d="M8.00248 14.6655C9.80248 14.6655 11.3135 14.0729 12.4173 13.0507L10.3136 11.421C9.75063 11.8136 8.99507 12.0877 8.00248 12.0877C6.23952 12.0877 4.74323 10.9248 4.20988 9.31738L4.13169 9.32402L2.07379 10.9166L2.04688 10.9915C3.14318 13.1692 5.39502 14.6655 8.00248 14.6655Z"
          fill="#34A853"
        />
        <path
          d="M4.20997 9.3172C4.06922 8.90238 3.98777 8.45792 3.98777 7.99869C3.98777 7.53941 4.06922 7.095 4.20253 6.68019L4.19879 6.59183L2.1152 4.97363L2.04702 5.00606C1.59517 5.90976 1.33594 6.9246 1.33594 7.99869C1.33594 9.07278 1.59517 10.0875 2.04702 10.9913L4.20997 9.3172Z"
          fill="#FBBC05"
        />
        <path
          d="M8.00249 3.9098C9.25431 3.9098 10.0988 4.45053 10.5803 4.90243L12.4617 3.0654C11.3062 1.99131 9.80243 1.33203 8.00249 1.33203C5.39502 1.33203 3.14318 2.82832 2.04688 5.0061L4.20244 6.68017C4.74323 5.07278 6.23952 3.9098 8.00249 3.9098Z"
          fill="#EB4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_24361_4166">
          <rect
            width="13.0667"
            height="13.3333"
            fill="white"
            transform="translate(1.33594 1.33252)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconApple = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 496.255 608.728"
    >
      <path
        d="M273.81 52.973C313.806.257 369.41 0 369.41 0s8.271 49.562-31.463 97.306c-42.426 50.98-90.649 42.638-90.649 42.638s-9.055-40.094 26.512-86.971zM252.385 174.662c20.576 0 58.764-28.284 108.471-28.284 85.562 0 119.222 60.883 119.222 60.883s-65.833 33.659-65.833 115.331c0 92.133 82.01 123.885 82.01 123.885s-57.328 161.357-134.762 161.357c-35.565 0-63.215-23.967-100.688-23.967-38.188 0-76.084 24.861-100.766 24.861C89.33 608.73 0 455.666 0 332.628c0-121.052 75.612-184.554 146.533-184.554 46.105 0 81.883 26.588 105.852 26.588z"
        fill="white"
      />
    </svg>
  );
};
