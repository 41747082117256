import { Dispatch, FC, SetStateAction } from "react";
import { createPortal } from "react-dom";
import { Icon } from "../Icon";
import { useBodyScroll } from "../../hooks/useBodyScroll";
import { useKeyPress } from "../../hooks/useKeyPress";
import { useIsMounted } from "../../hooks/useIsMounted";
import { GalleryImage } from "../Gallery";
import styles from "./FullScreenImage.module.css";

interface FullScreenImageProps {
  isActive: boolean;
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number | null>>;
  images: GalleryImage[];
  imagesCount: number;
  onClose: () => void;
  allowScroll?: boolean;
}

const FullScreenImageContainer: FC<FullScreenImageProps> = ({
  activeIndex,
  setActiveIndex,
  images,
  imagesCount,
  onClose,
  allowScroll,
}) => {
  const viewedImage = images[activeIndex];
  const { isMounted } = useIsMounted();
  useBodyScroll({ allowScroll });
  useKeyPress("Escape", onClose);

  const handleLeftClick = () => {
    setActiveIndex((prev) =>
      prev !== null ? (prev > 0 ? prev - 1 : prev) : null
    );
  };

  const handleRightClick = () => {
    setActiveIndex((prev) =>
      prev !== null ? (prev < images.length - 1 ? prev + 1 : prev) : null
    );
  };

  const rootClassName = [
    styles.root,
    isMounted ? styles.opened : styles.init,
  ].join(" ");

  return (
    <div className={rootClassName}>
      <button className={styles.close} onClick={onClose}>
        <Icon icon="close" color="white" />
      </button>

      <div className={styles.imageWrapper}>
        <img
          className={styles.image}
          src={viewedImage.url}
          alt={viewedImage.caption || ""}
        />
        <div className={styles.spClickableArea}>
          <div className={styles.leftArea} onClick={handleLeftClick}></div>
          <div className={styles.rightArea} onClick={handleRightClick}></div>
        </div>
      </div>
      <div className="tabletWithUp">
        {activeIndex > 0 && (
          <button className={styles.leftButton} onClick={handleLeftClick}>
            <Icon icon="chevronLeft" color="gray-50" />
          </button>
        )}
        {activeIndex < images.length - 1 && (
          <button className={styles.rightButton} onClick={handleRightClick}>
            <Icon icon="chevronRight" color="gray-50" />
          </button>
        )}
      </div>
      <footer className={styles.footer}>
        <div className={styles.captionWrapper}>
          <div className={styles.caption}>{viewedImage.caption}</div>
        </div>
        <div className={styles.countWrapper}>
          <span className={styles.count}>
            {activeIndex + 1}/{imagesCount}
          </span>
        </div>
      </footer>
    </div>
  );
};

export const FullScreenImage: FC<FullScreenImageProps> = (props) => {
  if (typeof document === "undefined") return null;

  return createPortal(
    props.isActive ? <FullScreenImageContainer {...props} /> : null,
    document.body
  );
};
