import { ReactNode, FC } from "react";
import { ResponsiveProp, useBreakPoints, useResponsiveProp } from "../..";
import styles from "./Layout.module.css";

interface MainProps {
  children: ReactNode;
}

const Main: FC<MainProps> = ({ children }) => {
  return <div className={[styles.main, styles.item].join(" ")}>{children}</div>;
};

interface SidebarProps {
  children: ReactNode;
}

const Sidebar: FC<SidebarProps> = ({ children }) => {
  const SIDEBAR_WIDTH = 380;

  const breakPoints = useBreakPoints();

  return (
    <div
      className={[styles.sidebar, styles.item].join(" ")}
      style={{ maxWidth: breakPoints.tabletWithDown ? "100%" : SIDEBAR_WIDTH }}
    >
      {children}
    </div>
  );
};

interface LayoutProps {
  children: ReactNode;
  direction?: ResponsiveProp<
    "row" | "row-reverse" | "column" | "column-reverse"
  >;
}

interface LayoutComponents {
  Main: typeof Main;
  Sidebar: typeof Sidebar;
}

export const Layout: FC<LayoutProps> & LayoutComponents = ({
  children,
  direction = { mobile: "column", laptop: "row" },
}) => {
  const responsiveDirection = useResponsiveProp(direction, "column");

  const className = [
    styles.root,
    styles[`direction--${responsiveDirection}`],
  ].join(" ");
  return <div className={className}>{children}</div>;
};

Layout.Main = Main;
Layout.Sidebar = Sidebar;
