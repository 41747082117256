import { forwardRef } from "react";
import styles from "./Radio.module.css";
import { FontSizes, ResponsiveProp } from "../../types/component.type";
import { Text } from "../Text";

export interface RadioProps {
  label?: string;
  labelSize?: ResponsiveProp<FontSizes>;
  checked?: boolean;
  onChange?: () => void;
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const { label, labelSize = "sm", ...rest } = props;

  return (
    <label className={styles.root}>
      <input className={styles.radio} type="radio" ref={ref} {...rest} />
      {label && <Text size={labelSize}>{label}</Text>}
    </label>
  );
});

Radio.displayName = "Radio";
