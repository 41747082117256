import { FC } from "react";
import { gql } from "graphql-request";
import { Divider, Spacer, Stack, Text } from "@newt/ui";
import {
  BookingPriceSummaryType,
  ReservationPriceCardModalSummary_BookingPriceSummaryFragment,
} from "@graphql/types";
import styles from "./ReservationPriceCardModalSummary.module.css";

const _RESERVATION_PRICE_CARD_MODAL_SUMMARY = gql`
  fragment ReservationPriceCardModalSummary_BookingPriceSummary on BookingPriceSummary {
    label
    totalPrice
    type
  }
`;

type ReservationPriceCardModalSummaryProps = {
  summary: ReservationPriceCardModalSummary_BookingPriceSummaryFragment[];
  totalPrice: string;
};

export const ReservationPriceCardModalSummary: FC<
  ReservationPriceCardModalSummaryProps
> = ({ summary, totalPrice }) => {
  return (
    <>
      <Text color="black" size="lg" weight="bold">
        お支払い金額
      </Text>
      <Spacer />
      <Divider />
      <Spacer />
      {summary.map((item, index) => {
        const color =
          item.type === BookingPriceSummaryType.COUPON ||
          item.type === BookingPriceSummaryType.POINT
            ? "positiveRed"
            : "default";

        return (
          <div key={`items-${index}`}>
            <div className={styles.flex}>
              <Text size="lg" color={color}>
                {item.label}
              </Text>
              <Text size="lg" color={color}>
                {item.totalPrice}
              </Text>
            </div>
            <Divider y="4x" />
          </div>
        );
      })}
      <Stack justify="between" align="center">
        <Text color="black" weight="bold" size="lg">
          合計
        </Text>
        <Text color="black" weight="bold" size="lg">
          {totalPrice}
        </Text>
      </Stack>
      <Spacer />
      <Divider />
    </>
  );
};
