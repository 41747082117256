import { markdown } from "@utils/markdown";
import styles from "./markdown.module.css";

interface MarkdownProps {
  content: string;
}

export const Markdown = ({ content }: MarkdownProps) => {
  const html = markdown(content);
  return (
    <div className={styles.root} dangerouslySetInnerHTML={{ __html: html }} />
  );
};
