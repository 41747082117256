import styles from "./SearchField.module.css";
import { Icon, IconType, Skeleton, useBreakPoints } from "@newt/ui";
import { FC, MouseEventHandler, ReactNode } from "react";
import {
  DropdownContainer,
  DropdownContainerPosition,
  DropdownContainerSize,
} from "@newt/ui/src/components/Dropdown/components/DropdownContainer";
import { Modal } from "@newt/ui/src/components/Modal";

export type SearchFieldTriggerHandler = (rect: DOMRect) => void;

interface SearchFieldProps {
  children: ReactNode;
  icon?: IconType;
  label?: string;
  selected?: string;
  isLoading?: boolean;
  isOpen: boolean;
  dropdownPosition?: DropdownContainerPosition;
  dropdownSize?: DropdownContainerSize;
  modalAction?: ReactNode;
  isModalOnly?: boolean;
  onOpen(): void;
  onClose(): void;
}

export const SearchField: FC<SearchFieldProps> = ({
  icon,
  label = "",
  selected,
  isOpen,
  dropdownPosition,
  dropdownSize,
  modalAction,
  onOpen,
  onClose,
  children,
  isLoading = false,
  isModalOnly = false,
}) => {
  const breakPoints = useBreakPoints();

  const handleClick: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  };

  return (
    <>
      <button className={styles.root} onClick={handleClick}>
        {icon && (
          <div className={styles.icon}>
            <Icon icon={icon} />
          </div>
        )}
        <dl className={styles.definition}>
          <dt>{label}</dt>
          <dd>
            {isLoading ? <Skeleton width="100%" height={20} /> : selected}
          </dd>
        </dl>
      </button>

      {isOpen &&
        (breakPoints.laptopWithUp && !isModalOnly ? (
          <DropdownContainer
            rect={{ height: 80 } as DOMRect}
            noPadding={true}
            size={dropdownSize}
            position={dropdownPosition}
            closeDropdown={onClose}
          >
            {children}
          </DropdownContainer>
        ) : (
          <Modal
            isActive={isOpen}
            title={label}
            action={modalAction}
            onClose={onClose}
          >
            {children}
          </Modal>
        ))}
    </>
  );
};
