import { FC } from "react";
import { ShareButton, Spacer, Text, useBreakPoints } from "@newt/ui";
import { MarketingService } from "@utils/marketing";
import {
  handleInstagramStoriesShare,
  handleShareClick,
} from "./utils/handleShareClick";
import styles from "./ShareButtons.module.css";

interface ShareButtonsProps {
  shareUrl: string | null;
  isWebview: boolean;
  itemId?: number;
  itemName?: string;
  instagramStoriesArgs: {
    imageUrl?: string;
    title?: string;
  };
}

export const ShareButtons: FC<ShareButtonsProps> = (props) => {
  const { shareUrl, itemId, itemName, instagramStoriesArgs, isWebview } = props;
  const { imageUrl, title } = instagramStoriesArgs;
  const breakPoints = useBreakPoints();

  const handleLogEvent = (
    variant: "x_share" | "line_share" | "instagram_share"
  ) => {
    if (!shareUrl) {
      return;
    }
    const variables = {
      link_type: variant,
      link_url: shareUrl,
      click_sns_share_count: 1,
      item_id: itemId?.toString() || undefined,
      item_name: itemName,
    };
    MarketingService.logEvent.clickLink(variables);
  };

  return (
    <div>
      <Spacer y="4x" />
      <Text align="center" weight="bold" size="lg">
        シェアする
      </Text>
      <Spacer y="4x" />
      <div className={styles.buttons}>
        <div>
          <ShareButton
            disabled={!shareUrl}
            variant="X"
            size={"lg"}
            onClick={() => {
              handleLogEvent("x_share");
              handleShareClick({ variant: "x", shareUrl });
            }}
          />
          <Text weight="bold" align="center" size="sm">
            X
          </Text>
        </div>
        {isWebview && (
          <div>
            <ShareButton
              disabled={!shareUrl}
              variant="instagramStories"
              size={"lg"}
              onClick={() => {
                handleLogEvent("instagram_share");
                handleInstagramStoriesShare({ title, imageUrl });
              }}
            />
            <Text weight="bold" align="center" size="sm">
              Stories
            </Text>
          </div>
        )}
        {breakPoints.mobileOnly && (
          <div>
            <ShareButton
              disabled={!shareUrl}
              variant="line"
              size={"lg"}
              onClick={() => {
                handleLogEvent("line_share");
                handleShareClick({ variant: "line", shareUrl });
              }}
            />
            <Text weight="bold" align="center" size="sm">
              LINE
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};
