import { IS_NOT_PRODUCTION_ENV } from "@constants";
import { useAuth } from "@utils/auth";
import { CreateForm, FieldErrors } from "@utils/form";
import { addCountryCodeToPhoneNumber, phoneValidation } from "@utils/phone";
import { ConfirmationResult } from "firebase/auth";
import * as yup from "yup";
import { ERROR_CODES, ERROR_MESSAGES } from "@utils/firebase/errorCodes";
export interface PhoneFormValues {
  phoneNumber: string;
}

interface FormOptions {
  setConfirmationResult: (data: ConfirmationResult) => void;
  setPhoneNumber: (value: string) => void;
  phoneNumber: string | undefined;
}

export const usePhoneForm = ({
  setConfirmationResult,
  setPhoneNumber,
  phoneNumber,
}: FormOptions) => {
  const auth = useAuth();

  const onSubmit = (data: PhoneFormValues) => {
    const parsedPhoneNumber = addCountryCodeToPhoneNumber(data.phoneNumber);

    return auth.firebase
      ?.signInWithPhoneNumber(parsedPhoneNumber)
      .then((res) => {
        setPhoneNumber(data.phoneNumber);
        setConfirmationResult(res);
      })
      .catch((e) => {
        if (typeof e === "object" && typeof e.code === "string") {
          if (
            e.code === ERROR_CODES.CAPTCHA_CHECK_FAILED &&
            IS_NOT_PRODUCTION_ENV
          ) {
            const message = ERROR_MESSAGES[ERROR_CODES.CAPTCHA_CHECK_FAILED];
            throw new FieldErrors({ phoneNumber: message });
          }

          const message = ERROR_MESSAGES[e.code] || ERROR_MESSAGES.GENERAL;
          throw new FieldErrors({ phoneNumber: message });
        }

        throw new FieldErrors({ phoneNumber: ERROR_MESSAGES.GENERAL });
      });
  };

  return CreateForm<PhoneFormValues>({
    defaultValues: {
      phoneNumber,
    },
    rules: {
      phoneNumber: yup
        .string()
        .min(9)
        .trim()
        .required()
        .test("phone-validation", (value?: string) => {
          return value ? phoneValidation(value) : false;
        })
        .label("電話番号"),
    },
    validateMode: "onTouched",
    onSubmit,
  });
};
