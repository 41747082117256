import { gtm } from "../gtm";

export interface PageViewEventVariables {
  // NEWTのuserId
  user_id?: string;
  // 会員ステータス
  user_status?: "guest" | "member";
  // 遷移前のURL
  previous_page?: string;
  // 認証の手法
  auth_method?: string;
}

export const pageViewEvent = (variables?: PageViewEventVariables) => {
  gtm.pushEvent("page_view", variables);
};
