import { ChangeEventHandler } from "react";
import styles from "./SegmentButton.module.css";

type SegmentButtonValue = string | number;

export type SegmentButtonOption<T extends SegmentButtonValue = string> = {
  label: string;
  value: T;
};

type SegmentButtonProps<T extends SegmentButtonValue = string> = {
  className?: string;
  options: SegmentButtonOption<T>[];
  value: T;
  onChange: ChangeEventHandler<HTMLInputElement>;
  size?: "sm" | "md";
};

export const SegmentButton = <T extends SegmentButtonValue = string>({
  className,
  options,
  value,
  size = "md",
  onChange,
}: SegmentButtonProps<T>) => {
  const rootClasses = [styles.root, className].join(" ");
  const bodyClasses = [styles.body, styles[`size--${size}`]].join(" ");

  return (
    <div className={rootClasses}>
      {options.map((option) => (
        <label
          key={option.value}
          className={`${bodyClasses} ${option.value === value ? styles.checked : ""}`}
        >
          <input
            type="radio"
            className={styles.input}
            checked={option.value === value}
            value={option.value}
            onChange={onChange}
          />
          <span className={styles.labelText}>{option.label}</span>
        </label>
      ))}
    </div>
  );
};
