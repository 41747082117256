import { FC } from "react";
import Link from "next/link";
import { ROUTES } from "../../../routes";
import { Icon, Text, Spacer, Alert, AlertVariant, Divider } from "@newt/ui";
import { CoreUserPoint_UserPointsFragment, NoticeLevel } from "@graphql/types";
import { gql } from "graphql-request";
import styles from "./UserPoint.module.css";

export const USER_POINT_FRAGMENT = gql`
  fragment CoreUserPoint_UserPoints on UserPoints {
    amount
    notice {
      content
      noticeLevel
    }
  }
`;

export const VARIANT = {
  [NoticeLevel.WARNING]: AlertVariant.NORMAL,
  [NoticeLevel.ERROR]: AlertVariant.WARNING,
  [NoticeLevel.INFO]: AlertVariant.POSITIVE,
};

type UserPointProps = {
  points: CoreUserPoint_UserPointsFragment;
};

export const UserPoint: FC<UserPointProps> = ({ points }) => {
  return (
    <>
      <Link className={styles.pointRoot} href={ROUTES.account.point()}>
        <div className={styles.pointContent}>
          <Icon size="base" icon="point" color="green-40" />
          <span className={styles.point}>{points.amount.toLocaleString()}</span>
          <Text weight="bold" size="sm">
            ポイント
          </Text>
        </div>
        <div className={styles.chevronRight}>
          <Icon size="md" icon={"chevronRight"} />
        </div>
      </Link>
      {points.amount === 0 && (
        <>
          <Spacer y="3x" />
          <Divider className={styles.divider} />
        </>
      )}

      {points.notice && (
        <>
          <Alert
            className={styles.alert}
            title={points.notice.content}
            variant={VARIANT[points.notice.noticeLevel]}
          />
          <Spacer y="2x" />
        </>
      )}
    </>
  );
};
