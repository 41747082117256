import { gtm } from "../gtm";

export interface SetMailNotificationEventVariables {
  is_mail_notification_subscribed: boolean;
  module_type: string;
}

export const setMailNotificationEvent = (
  variables?: SetMailNotificationEventVariables
) => {
  gtm.pushEvent("set_mail_notification", variables);
};
