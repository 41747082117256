import { UserInfo } from "firebase/auth";

export const mapProviderDataToAuthMethod = (
  providerData: UserInfo[]
): string => {
  const mapProviderIdToAuthMethod = (providerId: string): string => {
    switch (providerId) {
      case "apple.com":
        return "apple";
      case "google.com":
        return "google";
      case "phone":
        return "phone";
      default:
        return "";
    }
  };

  const mappedAuthMethods = providerData
    .map((data) => mapProviderIdToAuthMethod(data.providerId))
    .filter((method) => method !== "");

  return mappedAuthMethods.sort().join(",");
};
