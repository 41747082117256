import { FC, useState } from "react";
import { createRoot } from "react-dom/client";
import { ButtonProps } from "../components/Button";
import { Dialog } from "../components/Dialog";

export interface ConfirmProps {
  title?: string;
  message?: string;
  acceptLabel?: string;
  rejectLabel?: string;
  acceptColor?: ButtonProps["color"];
  rejectColor?: ButtonProps["color"];
  acceptVariant?: ButtonProps["variant"];
  rejectVariant?: ButtonProps["variant"];
  onAccept?(): void;
  onReject?(): void;
}

const Confirm: FC<ConfirmProps> = ({
  title,
  message,
  acceptLabel,
  rejectLabel,
  acceptColor,
  rejectColor,
  acceptVariant,
  rejectVariant,
  onReject = () => {},
  onAccept = () => {},
}) => {
  const [isActive, setIsActive] = useState(true);
  const handleAccept = () => {
    onAccept();
    setIsActive(false);
  };
  const handleReject = () => {
    onReject();
    setIsActive(false);
  };

  return (
    <Dialog
      type="confirm"
      isActive={isActive}
      acceptLabel={acceptLabel}
      rejectLabel={rejectLabel}
      acceptColor={acceptColor || "danger"}
      rejectColor={rejectColor}
      acceptVariant={acceptVariant || "solid"}
      rejectVariant={rejectVariant}
      title={title}
      message={message}
      onAccept={handleAccept}
      onReject={handleReject}
    />
  );
};

export interface ConfirmOptions {
  title?: string;
  acceptLabel?: string;
  rejectLabel?: string;
  acceptColor?: ButtonProps["color"];
  rejectColor?: ButtonProps["color"];
  acceptVariant?: ButtonProps["variant"];
  rejectVariant?: ButtonProps["variant"];
  onAccept?(): void;
  onReject?(): void;
}

export const confirm = async (
  message: string,
  options: Partial<ConfirmOptions> = {}
) => {
  const { onAccept, onReject, ...restOptions } = options;
  const wrapper = document.body.appendChild(document.createElement("div"));

  const confirmed = new Promise<boolean>((resolve) => {
    const root = createRoot(wrapper);
    root.render(
      <Confirm
        onAccept={() => {
          onAccept?.();
          resolve(true);
        }}
        onReject={() => {
          onReject?.();
          resolve(false);
        }}
        message={message}
        {...restOptions}
      />
    );
  });

  const dispose = () => {
    setTimeout(() => {
      if (document.body.contains(wrapper)) {
        document.body.removeChild(wrapper);
      }
    }, 300);
  };

  return confirmed.then((result) => {
    dispose();
    return result;
  });
};
