import { useCallback, useEffect, useState } from "react";

export const useFullScreenImage = (onParentHistoryBack: () => void) => {
  const [activeImageIndex, setActiveImageIndex] = useState<number | null>(null);

  const onImageClick = (index: number) => {
    setActiveImageIndex(index);
  };

  const onClose = useCallback(() => {
    setActiveImageIndex(null);
  }, []);

  useEffect(() => {
    const handleSwipeBack = () => {
      if (activeImageIndex !== null) {
        history.pushState(null, ""); // absorb browser back
        onClose();
      } else {
        onParentHistoryBack();
      }
    };
    window.addEventListener("popstate", handleSwipeBack, false);
    return () => {
      window.removeEventListener("popstate", handleSwipeBack, false);
    };
  }, [activeImageIndex, onClose, onParentHistoryBack]);

  return {
    activeImageIndex,
    setActiveImageIndex,
    onImageClick,
    onClose,
  };
};
