import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconEdit: FC<IconTypeProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.06588 18.9284H6.49688L15.8654 9.55988L14.4464 8.13488L5.06588 17.5154V18.9284ZM2.79688 21.2034V16.5709L15.9849 3.40188C16.1929 3.20654 16.4237 3.05687 16.6774 2.95287C16.9309 2.84887 17.197 2.79688 17.4756 2.79688C17.7503 2.79688 18.0186 2.84887 18.2806 2.95287C18.5425 3.05687 18.773 3.21288 18.9724 3.42088L20.6044 5.05988C20.8124 5.25521 20.9652 5.48488 21.0629 5.74888C21.1605 6.01271 21.2094 6.27762 21.2094 6.54362C21.2094 6.82229 21.1605 7.08937 21.0629 7.34487C20.9652 7.60054 20.8124 7.83238 20.6044 8.04037L7.44138 21.2034H2.79688ZM15.1464 8.85388L14.4464 8.13488L15.8654 9.55988L15.1464 8.85388Z"
        fill="none"
      />
    </svg>
  );
};
