import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconPool: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        fill="#1F1F1F"
        d="M3.07 28.269v-2.3c.845-.067 1.511-.3 2-.7.49-.4 1.245-.6 2.267-.6 1.022 0 1.861.239 2.517.717a3.67 3.67 0 002.216.716c.822 0 1.511-.239 2.067-.716.556-.478 1.344-.717 2.367-.717 1.022 0 1.86.239 2.516.717a3.67 3.67 0 002.217.716c.822 0 1.517-.239 2.083-.716.567-.478 1.361-.717 2.384-.717 1.022 0 1.772.2 2.25.6.477.4 1.139.633 1.983.7v2.3c-.822-.067-1.528-.317-2.117-.75s-1.294-.65-2.116-.65c-.823 0-1.54.233-2.15.7-.611.467-1.384.7-2.317.7-.933 0-1.75-.233-2.45-.7-.7-.467-1.461-.7-2.283-.7-.823 0-1.556.233-2.2.7-.645.467-1.434.7-2.367.7s-1.728-.233-2.383-.7a3.73 3.73 0 00-2.217-.7c-.822 0-1.533.217-2.133.65-.6.433-1.311.683-2.134.75zm0-6.333v-2.2c.845-.067 1.511-.295 2-.684.49-.389 1.245-.583 2.267-.583 1.022 0 1.839.211 2.45.633.611.423 1.328.634 2.15.634.822 0 1.533-.211 2.133-.634.6-.422 1.411-.633 2.434-.633 1.022 0 1.838.211 2.45.633.61.423 1.327.634 2.15.634.822 0 1.538-.211 2.15-.634.61-.422 1.427-.633 2.45-.633 1.022 0 1.772.194 2.25.583.477.39 1.139.617 1.983.684v2.2c-.822-.067-1.528-.317-2.117-.75-.589-.434-1.294-.65-2.116-.65-.823 0-1.545.233-2.167.7-.622.466-1.389.7-2.3.7-.933 0-1.75-.234-2.45-.7-.7-.467-1.461-.7-2.283-.7-.823 0-1.534.233-2.134.7-.6.466-1.366.7-2.3.7-.933 0-1.75-.234-2.45-.7-.7-.467-1.46-.7-2.283-.7-.822 0-1.533.216-2.133.65-.6.433-1.311.683-2.134.75zm6.867-7.134l4.533-4.5-1.733-1.733c-.733-.711-1.489-1.2-2.267-1.467-.777-.266-1.789-.4-3.033-.4v-2.8c1.644 0 3.011.211 4.1.634 1.089.422 2.122 1.122 3.1 2.1l8.467 8.433a2.676 2.676 0 01-.95.5 3.62 3.62 0 01-1.05.167c-.845 0-1.578-.234-2.2-.7-.623-.467-1.423-.7-2.4-.7-.978 0-1.773.233-2.384.7-.61.466-1.339.7-2.183.7-.4 0-.783-.09-1.15-.267-.367-.178-.65-.4-.85-.667zm12.933-10.9c.911 0 1.678.317 2.3.95.622.634.934 1.406.934 2.317 0 .911-.311 1.683-.934 2.317-.622.633-1.389.95-2.3.95-.933 0-1.71-.317-2.333-.95-.622-.634-.933-1.406-.933-2.317 0-.911.31-1.683.933-2.317.622-.633 1.4-.95 2.333-.95z"
      ></path>
    </svg>
  );
};
