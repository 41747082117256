import { gtm } from "../../gtm";

export const BRAZE_CHANGE_USER_EVENT = "braze_change_user";
export interface ChangeUserEventVariables {
  userId: number;
}

export const changeUserBrazeEvent = (variables: ChangeUserEventVariables) => {
  gtm.pushEvent(BRAZE_CHANGE_USER_EVENT, variables);
};
