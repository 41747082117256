import { FC, ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { useBreakPoints } from "../../hooks/useBreakPoints";
import { useIsMounted } from "../../hooks/useIsMounted";
import { useKeyPress } from "../../hooks/useKeyPress";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { Icon } from "../Icon";
import { Overlay } from "../Overlay";
import { Text } from "../Text";
import styles from "./Modal.module.css";

interface ModalProps {
  rootClassName?: string;
  bodyClassName?: string;
  children: ReactNode;
  isActive: boolean;
  title?: string;
  fullScreen?: boolean;
  size?: "md" | "lg";
  action?: ReactNode;
  onClose?: () => void;
  showHeader?: boolean;
}

const ModalContainer: FC<ModalProps> = ({
  rootClassName,
  bodyClassName,
  title,
  action,
  onClose = () => false,
  children,
  fullScreen = true,
  size = "md",
  showHeader = true,
}) => {
  const breakPoints = useBreakPoints();
  const root = useRef<HTMLDivElement>(null);
  const { isMounted } = useIsMounted();

  useOutsideClick<HTMLDivElement>(root, onClose);
  useKeyPress("Escape", onClose);

  const className = [
    rootClassName,
    styles.container,
    isMounted ? styles.opened : styles.init,
    breakPoints.tabletWithUp
      ? size === "md"
        ? styles["container--desktop"]
        : styles["container--desktop-lg"]
      : styles["container--mobile"],
    fullScreen ? styles["height--fullScreen"] : styles["height--auto"],
  ].join(" ");

  const bodyStyles = [styles.body, bodyClassName].join(" ");

  const headerClassName = [
    styles.header,
    breakPoints.laptopWithUp
      ? styles["header--desktop"]
      : styles["header--mobile"],
  ].join(" ");

  useEffect(() => {
    // avoid position:fixed as it causes modal hide error on Android webview.  see NEWT-2175
    if (breakPoints.mobileOnly) {
      document.body.style.position = `static`;
    }
    return () => {
      document.body.style.position = ``;
    };
  }, [breakPoints]);

  return (
    <section ref={root} className={className} data-testid="newt-ui-modal">
      {showHeader && (
        <header className={headerClassName}>
          {title && (
            <Text weight="bold" size="lg">
              {title}
            </Text>
          )}
          <button className={styles.close} onClick={onClose}>
            <Icon icon="close" color="black" />
          </button>
        </header>
      )}
      <div className={bodyStyles}>{children}</div>
      {action && <footer className={styles.action}>{action}</footer>}
    </section>
  );
};

export const Modal: FC<ModalProps> = (props) => {
  if (typeof document === "undefined") return null;

  return createPortal(
    props.isActive ? (
      <Overlay>
        <ModalContainer {...props} />
      </Overlay>
    ) : null,
    document.body
  );
};
