let GTM_userId: string | undefined;

const setUserId = (id: string | undefined) => {
  GTM_userId = id;
};

const clearUserId = () => {
  GTM_userId = undefined;
};

const push = (variables: Record<string, any> = {}) => {
  if (GTM_userId) {
    variables["user_id"] = GTM_userId;
    variables["user_status"] = "member";
  } else if (variables["user_id"]) {
    variables["user_status"] = "member";
  } else {
    variables["user_status"] = "guest";
  }
  // https://docs.google.com/spreadsheets/d/1exk03AgyTUvoCpRF4cuZf2tx_tUEM9A3ZnV19Olyb5A/edit?pli=1#gid=0&range=M16:S16
  variables["is_spa"] = true;

  // @ts-expect-error: dataLayer might be undefined
  window.dataLayer?.push({ ecommerce: null }); // clear the previous e-commerce object.

  // clear the previous destinationCodes array: it seems dataLayer won't update array correctly (NEWT-7630)
  if (variables["destinationCodes"]) {
    // @ts-expect-error: dataLayer might be undefined
    window.dataLayer?.push({ destinationCodes: undefined });
  }

  window.dataLayer?.push({
    ...variables,
  });
};

const pushEvent = (eventName: string, variables: Record<string, any> = {}) => {
  push({ event: eventName, ...variables });
};

export const gtm = {
  setUserId,
  clearUserId,
  pushEvent,
  push,
};
