import { FC } from "react";
import { Text } from "../Text";
import NoImage from "./assets/NoIcon.png";
import styles from "./Profile.module.css";

export interface ProfileProps {
  className?: string;
  icon: {
    url: string;
  };
  title?: string | null;
  name: string;
  description?: string | null;
  showDescription?: boolean;
}

export const Profile: FC<ProfileProps> = (props) => {
  const {
    className,
    icon,
    title,
    name,
    description,
    showDescription = true,
  } = props;

  const rootClassName = [
    className,
    styles.root,
    !description ? styles.alignCenter : "",
  ].join(" ");

  return (
    <div className={rootClassName}>
      <div className={styles.imageWrapper}>
        <img
          className={styles.image}
          src={icon?.url || NoImage.src}
          alt={name}
          loading="lazy"
        />
      </div>
      <div className={styles.content}>
        {title && (
          <Text weight="bold" size={{ mobile: "sm", tablet: "base" }}>
            {title}
          </Text>
        )}
        <Text weight="bold" size={{ mobile: "lg", tablet: "2xl" }}>
          {name}
        </Text>
        {description && showDescription && (
          <div className={styles.description}>
            <Text size={{ mobile: "sm", tablet: "base" }} color={"black"}>
              {description}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};
