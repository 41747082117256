import { BrowserOptions } from "@sentry/nextjs";

const IGNORE_PATH_KEYWORD = ["/webflow"] as string[];

export const beforeSendTransaction: BrowserOptions["beforeSendTransaction"] = (
  event
) => {
  // event.transaction
  for (const path of IGNORE_PATH_KEYWORD) {
    if (!!event?.transaction?.match(path)) {
      return null;
    }
  }

  return event;
};
