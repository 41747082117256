import { useRef } from "react";

export function useDebounce(callback: (input: string) => void, delay: number) {
  const timeoutId = useRef<number | undefined>(undefined);

  const debounce = (input: string) => {
    clearTimeout(timeoutId.current);
    timeoutId.current = window.setTimeout(() => callback(input), delay);
  };
  return debounce;
}
