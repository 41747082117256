export const YouTubeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_1118_32645)">
        <path
          fill="#1F1F1F"
          d="M18.371 16.114c-.73.586-3.308 1.046-6.317 1.046-3.01 0-5.587-.46-6.318-1.046-.671-.537-.816-2.968-.816-4.347 0-1.38.145-3.81.816-4.347.731-.586 3.31-1.046 6.318-1.046 3.008 0 5.586.46 6.317 1.046.672.537.817 2.967.817 4.347 0 1.38-.145 3.81-.817 4.347zM14.194 11.7l-3.31-1.961a.078.078 0 00-.119.066v3.924a.078.078 0 00.08.078.08.08 0 00.038-.011l3.312-1.962a.076.076 0 00.027-.105.078.078 0 00-.028-.029z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1118_32645">
          <path
            fill="#fff"
            d="M0 0H14.268V10.786H0z"
            transform="matrix(1 0 0 -1 4.92 17.16)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};
