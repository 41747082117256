import { FC } from "react"
import { IconTypeProps } from '../Icon'

export const IconBusStation: FC<IconTypeProps> = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.12791 17.584C4.93902 17.584 4.78068 17.5202 4.6529 17.3924C4.52513 17.2646 4.46124 17.1063 4.46124 16.9174V15.1635C4.18318 14.9527 3.92563 14.6544 3.68859 14.2686C3.45153 13.8829 3.33301 13.4496 3.33301 12.9686V4.69509C3.33301 3.61918 3.86771 2.83168 4.93713 2.33261C6.00653 1.83353 7.69395 1.58398 9.99941 1.58398C12.3882 1.58398 14.0966 1.8238 15.1245 2.30343C16.1524 2.78307 16.6664 3.58029 16.6664 4.69509V12.9686C16.6664 13.4496 16.5479 13.8829 16.3108 14.2686C16.0738 14.6544 15.8162 14.9527 15.5382 15.1635V16.9174C15.5382 17.1063 15.4743 17.2646 15.3465 17.3924C15.2187 17.5202 15.0604 17.584 14.8715 17.584H14.3245C14.1356 17.584 13.9773 17.5202 13.8495 17.3924C13.7217 17.2646 13.6579 17.1063 13.6579 16.9174V15.8063H6.34154V16.9174C6.34154 17.1063 6.27765 17.2646 6.14987 17.3924C6.02211 17.5202 5.86378 17.584 5.67489 17.584H5.12791ZM4.66633 8.69514H15.3331V5.37885H4.66633V8.69514ZM6.89059 13.4131C7.21403 13.4131 7.48828 13.2999 7.71335 13.0735C7.93841 12.8471 8.05095 12.5721 8.05095 12.2487C8.05095 11.9252 7.93774 11.651 7.71132 11.4259C7.48492 11.2008 7.21 11.0883 6.88654 11.0883C6.5631 11.0883 6.28885 11.2015 6.06378 11.4279C5.83871 11.6543 5.72618 11.9293 5.72618 12.2527C5.72618 12.5762 5.83939 12.8504 6.0658 13.0755C6.2922 13.3005 6.56713 13.4131 6.89059 13.4131ZM13.1128 13.4131C13.4363 13.4131 13.7105 13.2999 13.9356 13.0735C14.1607 12.8471 14.2732 12.5721 14.2732 12.2487C14.2732 11.9252 14.16 11.651 13.9336 11.4259C13.7072 11.2008 13.4323 11.0883 13.1088 11.0883C12.7854 11.0883 12.5111 11.2015 12.286 11.4279C12.061 11.6543 11.9484 11.9293 11.9484 12.2527C11.9484 12.5762 12.0616 12.8504 12.2881 13.0755C12.5145 13.3005 12.7894 13.4131 13.1128 13.4131Z" fill="#1F1F1F" />
    </svg>
  )
}
