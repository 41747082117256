import { FC, ReactNode } from "react";
import styles from "./TagColored.module.css";

export interface TagColoredProps {
  name: string;
  color: string;
  textColor?: string;
  borderColor?: string;
  icon?: { url: string } | ReactNode | null;
  size?: "sm" | "base";
}

export const TagColored: FC<TagColoredProps> = (props) => {
  const { name, color, textColor, borderColor, icon, size = "sm" } = props;

  const rootClassName = [styles.root, styles[`root--${size}`]].join(" ");
  const hasIconUrl = typeof icon === "object" && icon !== null && "url" in icon;

  return (
    <div
      className={rootClassName}
      style={{
        background: color,
        color: textColor,
        border: borderColor && `1px solid ${borderColor}`,
      }}
    >
      {icon &&
        (hasIconUrl ? (
          <img className={styles.themeIcon} src={icon.url} alt={name} />
        ) : (
          <span className={styles.themeIcon}>{icon}</span>
        ))}
      <span className={styles.label}>{name}</span>
    </div>
  );
};
