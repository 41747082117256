/**
 * Returns a new object with all undefined, null, and empty arrays removed
 * @param object
 */
export const trimObject = <T>(obj: Record<string, any>): T => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (
      value === undefined ||
      value === null ||
      (Array.isArray(value) && value.length === 0)
    ) {
      return acc;
    }
    return { ...acc, [key]: value };
  }, {} as T);
};
