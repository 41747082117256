import { FC } from "react";
import { DEFAULT_IMAGE } from "../../constants";
import { useResponsiveProp } from "../../hooks/useResponsiveProp";
import { ResponsiveProp } from "../../types/component.type";
import { showNoImageOnError } from "../../utils/showNoImageOnError";
import styles from "./Image.module.css";

interface ImageProps {
  src?: string;
  alt?: string;
  width?: ResponsiveProp<string | number>;
  height?: ResponsiveProp<string | number>;
}
export const Image: FC<ImageProps> = ({ src, width, height, alt }) => {
  const responsiveWidth = useResponsiveProp(width, "auto");
  const responsiveHeight = useResponsiveProp(height, "auto");
  return (
    <figure
      className={styles.root}
      style={{ width: responsiveWidth, height: responsiveHeight }}
    >
      <img
        className={styles.img}
        src={src || DEFAULT_IMAGE}
        alt={alt}
        onError={showNoImageOnError}
      />
    </figure>
  );
};
