import { FC, ReactNode } from "react";
import { Radio, Spacer, Text } from "@newt/ui";
import styles from "./PaymentMethodOption.module.css";

interface PaymentMethodOptionProps {
  label: string;
  help?: string;
  checked: boolean;
  children?: ReactNode;
  onClick?: () => void;
  error?: string | null;
}

export const PaymentMethodOption: FC<PaymentMethodOptionProps> = ({
  label,
  help,
  checked,
  children,
  onClick,
  error,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.contentRoot}>
        <div
          className={[styles.row, styles.clickeable].join(" ")}
          onClick={() => onClick && onClick()}
        >
          <div className={styles.spacer}>
            <Radio checked={checked} />
          </div>
          <div className={styles.label}>
            <Text weight="bold" size="lg">
              {label}
            </Text>
            {help && (
              <Text size="sm" weight="bold" color="gray">
                {help}
              </Text>
            )}
          </div>
        </div>
        {children && (
          <div className={styles.row}>
            <div className={styles.spacer}>&nbsp;</div>
            {children}
          </div>
        )}
      </div>
      {error && (
        <>
          <Spacer y="2x" />
          <Text size="sm" color="danger">
            {error}
          </Text>
        </>
      )}
    </div>
  );
};
