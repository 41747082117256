import {
  BookingOptionInsuranceTravelerInput,
  CreateBookingOptionInsuranceQuotationInput,
} from "@graphql/types";

export const SESSION_INSURANCE_FORM_KEY = "insurance-form";
export const SESSION_INSURANCE_GMO_PAYLOAD_KEY = "insurance-gmo-payload";
export const SESSION_INSURANCE_LOG_OPTIONS_KEY = "insurance-log-options";

export type SessionInsuranceFormData = Omit<
  CreateBookingOptionInsuranceQuotationInput,
  "travelers"
> & {
  travelers: (BookingOptionInsuranceTravelerInput & {
    isValid: boolean;
  })[];
  firstName: string;
  lastName: string;
  agreementCheckedIndexList: number[];
};

export type SessionInsuranceFormDataSetValue = {
  (
    key: keyof SessionInsuranceFormData,
    value: SessionInsuranceFormData[keyof SessionInsuranceFormData]
  ): Promise<void>;
  (
    updates: Array<{
      key: keyof SessionInsuranceFormData;
      value: SessionInsuranceFormData[keyof SessionInsuranceFormData];
    }>
  ): Promise<void>;
};
