import {
  HotelCard_ExpediaHotelFragment,
  HotelCard_ExpediaRateFragment,
  HotelCard_HotelWithPriceFragment,
} from "@graphql/types";
import {
  Button,
  Icon,
  Stack,
  Tag,
  Text,
  WishlistButton,
  showNoImageOnError,
} from "@newt/ui";
import { Rating } from "@newt/ui/src/components/Rating";
import { formatNumber } from "@utils/formatNumber";
import { gql } from "graphql-request";
import Link from "next/link";
import { FC } from "react";
import styles from "./HotelCard.module.css";
import { HotelCardPrice } from "./HotelCardPrice";

const _HOTEL_CARD_FRAGMENT = gql`
  fragment HotelCard_ExpediaHotel on ExpediaHotel {
    id
    name
    grade
    rating {
      totalCount
      overall
    }
    mainImage {
      url(size: LG)
    }
    canSpeakJapanese
    inWishlist
    richHotel {
      area {
        areas {
          areaId
          type
        }
      }
    }
  }

  fragment HotelCard_ExpediaRate on ExpediaRate {
    isRefundable
    availableRooms
    ...HotelCardPrice_ExpediaRate
  }

  fragment HotelCard_HotelWithPrice on ExpediaHotelWithPrice {
    hotel {
      ...HotelCard_ExpediaHotel
    }
    rate {
      ...HotelCard_ExpediaRate
    }
  }
`;

interface HotelCardProps {
  fragment: {
    hotel: HotelCard_ExpediaHotelFragment;
    rate?: HotelCard_ExpediaRateFragment | null;
  };
  isLoggedIn: boolean;
  inWishlist?: boolean;
  href: string;
  onWishlistClick?: (
    fragment: HotelCard_HotelWithPriceFragment["hotel"]
  ) => void;
}

export const HotelCard: FC<HotelCardProps> = ({
  fragment,
  isLoggedIn,
  inWishlist: inWishlistProp,
  href,
  onWishlistClick,
}) => {
  const { hotel, rate } = fragment;
  const inWishlist =
    inWishlistProp === undefined ? hotel.inWishlist : inWishlistProp;

  return (
    <Link href={href} className={styles.root} target="_blank" rel="noreferrer">
      <div className={styles.inner}>
        <figure className={styles.thumbnail}>
          {hotel.canSpeakJapanese && (
            <div className={styles.upperLeft}>
              <Tag variant="ghost" color="default">
                日本語OK！
              </Tag>
            </div>
          )}
          {onWishlistClick && (
            <div className={styles.wishlistButtonWrapper}>
              <WishlistButton
                variant={inWishlist ? "pressed" : "default"}
                stroke="white"
                size="lg"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onWishlistClick(hotel);
                }}
              />
            </div>
          )}
          {rate?.availableRooms && (
            <div className={styles.lowerRight}>
              <Tag variant="solid" color="danger">
                {`あと${rate.availableRooms}室`}
              </Tag>
            </div>
          )}
          <div className={styles.thumbnailInner}>
            {fragment.hotel && (
              <img
                src={fragment.hotel.mainImage.url}
                alt={fragment.hotel.name}
                onError={showNoImageOnError}
              />
            )}
          </div>
        </figure>

        <div className={styles.detail}>
          <header className={styles.header}>
            <h2 className={styles.title}>{fragment.hotel.name}</h2>
          </header>
          <div className={styles.main}>
            <div className={styles.leftBody}>
              <ul className={styles.items}>
                <li>
                  <div className={styles.item}>
                    <div className={styles.itemIcon}>
                      <Icon icon="hotel" size="sm" />
                    </div>
                    <div className={styles.itemBody}>
                      <div className={styles.hotelRating}>
                        <Rating value={fragment.hotel.grade} />
                      </div>
                    </div>
                  </div>
                </li>
                {hotel.rating.totalCount > 0 && (
                  <li>
                    <div className={styles.item}>
                      <div className={styles.itemIcon}>
                        <Icon icon="review" size="sm" />
                      </div>
                      <div className={styles.itemBody}>
                        <div className={styles.rating}>
                          <div className={styles.overall}>
                            {`${fragment.hotel.rating.overall} / 5`}
                          </div>
                          <div className={styles.totalCount}>
                            {`(${formatNumber(
                              fragment.hotel.rating.totalCount
                            )}件)`}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
              {rate?.isRefundable && (
                <div className={styles.refundable}>
                  <div className={styles.refundableIcon}>
                    <Icon icon="check" size="sm" color="primary" />
                  </div>
                  <div className={styles.refundableBody}>
                    <div>キャンセル無料</div>
                  </div>
                </div>
              )}
            </div>
            {rate ? (
              <Stack as="div" justify="between" align="end">
                <HotelCardPrice
                  rate={rate}
                  isLoggedIn={isLoggedIn}
                  justify={{ mobile: "end", tablet: "start" }}
                />
                <div className={styles.action}>
                  <Button>ホテルをみる</Button>
                </div>
              </Stack>
            ) : (
              <Stack as="div" justify="end" align="end">
                <Text size="lg" weight="bold" underlineHover>
                  価格をみる
                </Text>
              </Stack>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

HotelCard.displayName = "HotelCard";
