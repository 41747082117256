import { ImageLoader } from "next/image";

export const imgixLoader: ImageLoader = ({ src, width, quality }) => {
  const url = new URL(src);
  const params = url.searchParams;
  params.set("auto", params.getAll("auto").join(",") || "format");
  params.set("fit", params.get("fit") || "max");
  params.set("w", params.get("w") || width.toString());
  params.set("q", (quality || 50).toString());
  return url.href;
};

export const getThumbnailUrl = (src?: string) => {
  if (!src || !src.match(/imgix\.net/)) {
    return src;
  }

  const WIDTH = 600;
  const QUALITY = 90;

  return imgixLoader({
    src,
    width: WIDTH,
    quality: QUALITY,
  });
};
