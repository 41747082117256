import { FC, ReactNode } from "react";
import styles from "./DropdownGroup.module.css";

interface DropdownGroupProps {
  children: ReactNode;
}

export const DropdownGroup: FC<DropdownGroupProps> = ({ children }) => {
  return <div className={styles.root}>{children}</div>;
};
