import { FC, ReactNode } from "react";
import { createPortal } from "react-dom";
import { Text } from "../Text";
import { Icon } from "../Icon";
import { useBodyScroll } from "../../hooks/useBodyScroll";
import { useKeyPress } from "../../hooks/useKeyPress";
import styles from "./FullScreen.module.css";

interface FullScreenProps {
  children: ReactNode;
  isActive: boolean;
  title: string;
  action?: ReactNode;
  onClose?: () => void;
  showHeader?: boolean;
  allowScroll?: boolean;
}

const FullScreenContainer: FC<FullScreenProps> = ({
  title,
  onClose = () => false,
  showHeader = true,
  allowScroll = false,
  children,
}) => {
  useBodyScroll({ allowScroll });
  useKeyPress("Escape", onClose);

  return (
    <div className={styles.root}>
      {showHeader && (
        <header className={styles.header}>
          <div className={styles.close} onClick={onClose}>
            <Icon icon="chevronLeft" size="md" />
            <Text size="lg" weight="bold">
              {title}
            </Text>
          </div>
        </header>
      )}
      <div className={styles.body}>{children}</div>
    </div>
  );
};

export const FullScreen: FC<FullScreenProps> = (props) => {
  if (typeof document === "undefined") return null;

  return createPortal(
    props.isActive ? <FullScreenContainer {...props} /> : null,
    document.body
  );
};
