import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconTourAndHotel: FC<IconTypeProps> = () => {
  return (
    <svg
      width="38"
      height="37"
      viewBox="0 0 38 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1281 5.97873C14.3085 5.93641 14.4688 5.83345 14.5823 5.68706C14.6905 5.54281 14.749 5.36737 14.749 5.18706C14.7514 5.05901 14.7243 4.93211 14.6698 4.81623C14.611 4.70282 14.5269 4.60445 14.424 4.52873C14.3262 4.44644 14.2083 4.39177 14.0823 4.37039C13.9592 4.33918 13.8303 4.33918 13.7073 4.37039L11.049 5.06623L7.46146 1.86206L6.50729 2.13706L8.65729 5.71623L6.04062 6.41206L5.15729 5.64123L4.39062 5.79123L5.81562 8.19539L14.1281 5.97873Z"
        fill="#00CD68"
      />
      <path
        d="M16.4284 15.7458V8.41244C16.4296 8.31085 16.4098 8.21011 16.3701 8.1166C16.3329 8.02237 16.2761 7.93714 16.2034 7.8666C16.1333 7.7953 16.0497 7.73865 15.9576 7.69993C15.8633 7.66266 15.7631 7.6429 15.6617 7.6416H12.2492C12.1479 7.6429 12.0476 7.66266 11.9534 7.69993C11.86 7.73865 11.775 7.79527 11.7034 7.8666C11.6321 7.93826 11.5754 8.02319 11.5367 8.1166C11.4994 8.21084 11.4797 8.3111 11.4784 8.41244V15.7458H10.6951V11.0041C10.6938 10.9028 10.674 10.8025 10.6367 10.7083C10.598 10.6161 10.5414 10.5325 10.4701 10.4624C10.3992 10.3901 10.3141 10.3334 10.2201 10.2958C10.1275 10.2577 10.0285 10.2379 9.92839 10.2374H3.95339C3.8518 10.2362 3.75106 10.256 3.65755 10.2958C3.56434 10.333 3.47938 10.3882 3.40755 10.4583C3.33731 10.5308 3.28084 10.6155 3.24089 10.7083C3.20362 10.8025 3.18385 10.9028 3.18255 11.0041V15.7458H2.38672V17.2666H17.6117V15.7458H16.4284ZM7.53672 11.7458H8.94089V13.1499H7.53672V11.7458ZM4.94089 11.7458H6.33255V13.1499H4.92839L4.94089 11.7458ZM6.25339 15.7458V14.3416H7.65755V15.7458H6.25339ZM13.2367 9.1666H14.6409V10.5708H13.2367V9.1666ZM13.2367 11.7583H14.6409V13.1624H13.2367V11.7583ZM13.2367 15.7583V14.3416H14.6409V15.7458L13.2367 15.7583Z"
        fill="#00CD68"
      />
      <path
        d="M34.4352 32.696V24.8752H32.8643V20.9585C32.867 20.8531 32.8486 20.7483 32.8102 20.6502C32.7716 20.5567 32.715 20.4717 32.6435 20.4002C32.5719 20.3287 32.487 20.272 32.3935 20.2335C32.2975 20.192 32.1939 20.1707 32.0893 20.171H22.3768C22.2701 20.1679 22.1638 20.1863 22.0643 20.2252C21.9663 20.2622 21.8767 20.3185 21.8008 20.3908C21.725 20.4631 21.6644 20.5499 21.6227 20.646C21.5838 20.7455 21.5654 20.8517 21.5685 20.9585V32.696H20.7852V34.2668H35.2185V32.696H34.4352ZM25.7727 31.1668H24.206V29.5835L25.7727 29.5627V31.1668ZM25.7727 28.0293H24.206V26.446L25.7727 26.4252V28.0293ZM25.7727 24.8918H24.206V23.3085L25.7727 23.2877V24.8918ZM32.856 32.7127H31.4018V27.9918H29.8352V32.696H28.3685V26.446H32.8643L32.856 32.7127Z"
        fill="#00CD68"
      />
      <path d="M6.49219 31.2583L33.0087 4.7418" stroke="#BDEDCA" />
    </svg>
  );
};
