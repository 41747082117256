import { SITE_URL } from "./constants";
import { ItemListId } from "./utils/marketing/events/viewItemListEvent";

type RouterQueryInput = Record<
  string,
  string | number | boolean | string[] | number[] | boolean[] | null | undefined
>;

const withQuery = (path: string, query?: RouterQueryInput): string => {
  const url = new URLSearchParams("");

  if (query) {
    Object.keys(query).forEach((key) => {
      const value = query[key];

      if (value && query[key] != undefined) {
        if (Array.isArray(value)) {
          url.delete(key);
          value.forEach((v) => url.append(key, String(v)));
        } else {
          url.set(key, String(query[key]));
        }
      }
    });
  }

  return query ? `${path}?${url.toString()}` : path;
};

export interface SignInPageQuery extends RouterQueryInput {
  redirectTo?: string;
}

export interface HotelDetailPageQuery extends RouterQueryInput {
  hotelId?: number;
  regionId?: number;
  checkInDate?: string;
  checkOutDate?: string;
  adults?: string;
  children?: string;
  itemListId?: ItemListId;
}

const withDomain = (path: string) => {
  return `${SITE_URL}${path}`;
};

export const ROUTES = {
  tour: () => withDomain("/"),
  hotel: () => withDomain("/hotel"),
  mag: () => withDomain("/mag"),
  help: () => withDomain("/help"),
  contact: () => withDomain("/contact"),
  about: () => withDomain("/about"),
  signin: {
    index: (query?: SignInPageQuery) => withDomain(withQuery("/signin", query)),
    register: () => withDomain("/signin/register"),
  },
  search: () => withDomain("/tour/search"),
  account: {
    index: () => withDomain("/account"),
    profile: {
      index: () => withDomain("/account/profile"),
    },
    paymentMethod: {
      index: () => withDomain("/account/payment-method"),
    },
    departureAirport: () => withDomain("/account/departure-airport"),
    wishlist: () => withDomain("/account/wishlist"),
    coupons: () => withDomain("/account/coupons"),
    point: () => withDomain("/account/point"),
  },
  reservations: () => withDomain("/reservations"),
  point: () => withDomain("/point"),
  friendCode: () => withDomain("/friend-code"),
  notFound: () => withDomain("/404"),
  seoCountryHotel: (
    id: string | number,
    countryCode: string,
    query?: HotelDetailPageQuery
  ) => withDomain(withQuery(`/${countryCode}/hotel-${id}`, query)),
  seoCityHotel: (
    id: string | number,
    countryCode: string,
    cityCode: string,
    query?: HotelDetailPageQuery
  ) => withDomain(withQuery(`/${countryCode}/${cityCode}/hotel-${id}`, query)),
};
