import { FC } from "react";
import { Button, Stack, Text } from "@newt/ui";
import { AppLogo } from "./AppLogo";
import styles from "./OpenInApp.module.css";
import { useAppLink } from "@utils/useAppLink";
import { MarketingService } from "@utils/marketing";
import { UtmCampaign } from "@utils/dynamicLink";

interface OpenInAppProps {
  customPath?: string;
  utmCampaign?: UtmCampaign;
}

export const OpenInApp: FC<OpenInAppProps> = ({ customPath, utmCampaign }) => {
  const url = useAppLink({
    path: customPath,
    utmCampaign,
    utmContent: "header_banner",
  });

  return (
    <div className={`${styles.root}`}>
      <Stack
        align="center"
        direction="row"
        justify="between"
        spacing="2x"
        as="div"
      >
        <Stack align="center" spacing="2x" as="div">
          <AppLogo />
          <div>
            <Text color="white" weight="bold" size="sm">
              NEWT
            </Text>
            <Text color="white" weight="bold" size="sm">
              スマートに海外旅行
            </Text>
          </div>
        </Stack>
        <Button
          color="primary"
          variant="outline"
          size="sm"
          as="a"
          href={url}
          onClick={() => {
            MarketingService.logEvent.clickLink({
              link_type: "fdl",
              link_url: url || "",
            });
          }}
        >
          無料アプリでひらく
        </Button>
      </Stack>
    </div>
  );
};
