import { useEffect } from "react";

export const usePageEvent = ({
  onPageShow = undefined,
  onLoad = undefined,
  onPopState = undefined,
}: {
  readonly onPageShow?: () => void;
  readonly onLoad?: () => void;
  readonly onPopState?: () => void;
}) => {
  useEffect(() => {
    if (typeof window === "undefined") return;

    const onPageShowEvent: (event: PageTransitionEvent) => void = (event) => {
      if (!event.persisted) return;
      onPageShow && onPageShow();
    };

    if (onPageShow !== undefined) {
      window.addEventListener("pageshow", onPageShowEvent);
    }

    if (onLoad !== undefined) {
      window.addEventListener("load", onLoad);
    }

    if (onPopState !== undefined) {
      window.addEventListener("popstate", onPopState);
    }

    return () => {
      if (onPageShow !== undefined) {
        window.removeEventListener("pageshow", onPageShowEvent);
      }

      if (onLoad !== undefined) {
        window.removeEventListener("load", onLoad);
      }

      if (onPopState !== undefined) {
        window.removeEventListener("popstate", onPopState);
      }
    };
  }, [onPageShow, onLoad, onPopState]);
};
