import Cookies from "universal-cookie";

interface CookieProps {
  get(key: string): string;
  set(key: string, value?: string | null): void;
  remove(key: string): void;
}

export const cookies = (cookie?: string): CookieProps => {
  const cookies = new Cookies(cookie);

  return {
    get: (key) => cookies.get(key),
    set: (key, value) => cookies.set(key, value, { path: "/" }),
    remove: (key) => cookies.remove(key, { path: "/" }),
  };
};
