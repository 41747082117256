import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconSave: FC<IconTypeProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9936 11.1676C16.3027 11.1676 16.5635 11.0618 16.7762 10.8502C16.9889 10.6386 17.0953 10.3772 17.0953 10.0659C17.0953 9.75471 16.9887 9.4933 16.7756 9.2817C16.5624 9.0701 16.301 8.9643 15.9913 8.9643C15.6816 8.9643 15.42 9.0701 15.2064 9.2817C14.9928 9.4933 14.886 9.75471 14.886 10.0659C14.886 10.3772 14.9929 10.6386 15.2068 10.8502C15.4206 11.0618 15.6829 11.1676 15.9936 11.1676ZM7.80234 8.72138H12.9936V7.0779H7.80234V8.72138ZM4.26048 21.1496C3.68583 19.2257 3.11953 17.3059 2.56156 15.3902C2.00359 13.4745 1.72461 11.5112 1.72461 9.50018C1.72461 7.92699 2.27271 6.59113 3.36891 5.49258C4.46511 4.39401 5.80035 3.84473 7.37464 3.84473H12.5235C13.0199 3.21139 13.6208 2.71973 14.3263 2.36973C15.0318 2.01973 15.7881 1.84473 16.5953 1.84473C17.0505 1.84473 17.4392 2.00484 17.7614 2.32508C18.0836 2.64531 18.2447 3.03303 18.2447 3.48823C18.2447 3.59619 18.2322 3.70217 18.2072 3.80615C18.1822 3.91014 18.1531 4.00778 18.1197 4.0991C18.0571 4.27047 17.9985 4.44564 17.9442 4.6246C17.8898 4.80359 17.846 4.98783 17.8127 5.17735L19.9801 7.34473H22.2746V14.5888L19.3898 15.5198L17.7089 21.1496H11.9518V19.1496H10.0175V21.1496H4.26048ZM5.54226 19.4464H8.34416V17.4464H13.6192V19.4464H16.4224L17.9951 14.2032L20.5654 13.3393V9.05398H19.2654L16.0654 5.85398C16.082 5.44129 16.1352 5.03912 16.2249 4.64745C16.3146 4.25579 16.4114 3.85995 16.5154 3.45995C15.886 3.6306 15.293 3.879 14.7363 4.20515C14.1796 4.5313 13.7473 4.98091 13.4393 5.55398H7.37406C6.28029 5.55398 5.34915 5.93824 4.58063 6.70675C3.81212 7.47527 3.42786 8.40641 3.42786 9.50018C3.42786 11.2116 3.66753 12.8835 4.14688 14.5158C4.62623 16.1481 5.09136 17.7917 5.54226 19.4464Z"
        fill="black"
      />
    </svg>
  );
};
