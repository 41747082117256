import styles from "./Chip.module.css";
import { Icon, IconType } from "../Icon";
import { ChangeEventHandler } from "react";

export type ChipValue = string | number;

export interface ChipProps<T extends ChipValue = string> {
  label: string;
  value: T;
  checked: T[];
  onChange: (val: T[]) => void;
  iconLeft?: IconType;
  iconRight?: IconType;
  size?: "sm" | "md";
}

export const Chip = <T extends ChipValue = string>({
  label,
  value,
  checked,
  iconLeft,
  iconRight,
  size = "md",
  onChange,
}: ChipProps<T>) => {
  const isChecked: boolean = checked?.some((v) => v === value) ?? false;

  const classes = [styles.root, isChecked ? styles.checked : ""].join(" ");
  const bodyClasses = [styles.body, styles[`size--${size}`]].join(" ");

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.stopPropagation();

    if (isChecked) {
      onChange(checked.filter((v) => v !== value));
    } else {
      onChange(checked.concat([value]));
    }
  };
  return (
    <label className={classes}>
      <input
        type="checkbox"
        className={styles.input}
        value={value}
        checked={isChecked}
        onChange={handleChange}
      />
      <div className={bodyClasses}>
        {iconLeft && (
          <span className={styles.icon}>
            <Icon icon={iconLeft} size="sm" color="gray-80" />
          </span>
        )}
        <span className={styles.label}>{label}</span>
        {iconRight && (
          <span className={styles.icon}>
            <Icon icon={iconRight} size="sm" color="gray-80" />
          </span>
        )}
      </div>
    </label>
  );
};
