import { LocaleObject } from "yup";

export const ja: LocaleObject = {
  mixed: {
    default: "${label}に誤りがあります",
    required: "${label}は必須です",
    oneOf: "${label}に誤りがあります",
    notOneOf: "${labelに誤りがあります",
    defined: "${label}は必須です",
    notType: "${label}に誤りがあります",
  },
  string: {
    length: "${label}は${length}文字である必要があります",
    min: "${label}は${min}文字以上のみ入力できます",
    max: "${label}は${max}文字まで入力できます",
    matches: "${label}に誤りがあります",
    email: "${label}は正しいメールアドレスではありません",
    url: "${label}が正しいURLではありません",
    uuid: "${label}が正しいUUIDではありません",
    trim: "${label}の前後に空白は含められません",
    lowercase: "${label}には小文字のみ入力できます",
    uppercase: "${label}には大文字のみ入力できます",
  },
  number: {
    min: "${label}には${min}以上の数が入力できます",
    max: "${label}には${max}以下の数が入力できます",
    lessThan: "${label}には${less}未満の数が入力できます",
    moreThan: "${label}には${more}より大きい数が入力できます",
    positive: "${label}には正の数が入力できます",
    negative: "${label}には負の数が入力できます",
    integer: "${label}には整数が入力できます",
  },
  date: {
    min: "${label}には${min}以降の日付が入力できます",
    max: "${label}には${max}以前の日付が入力できます",
  },
  object: {
    noUnknown: "${label}に誤りがあります",
  },
  boolean: {
    isValue: "${label}は${value}である必要があります",
  },
  array: {
    length: "${label}は${min}個以上必要です",
    max: "${label}は${max}個まで入力できます",
    min: "${label}は${length}個である必要があります",
  },
};
