import { createElement, FC, ReactNode } from "react";
import { useResponsiveProp } from "../../hooks/useResponsiveProp";
import {
  FontSizes,
  LineHeights,
  ResponsiveProp,
} from "../../types/component.type";
import styles from "./Text.module.css";

interface TextProps {
  children?: ReactNode;
  size?: ResponsiveProp<FontSizes>;
  color?: ResponsiveProp<
    | "default"
    | "primary"
    | "danger"
    | "link"
    | "white"
    | "black"
    | "gray"
    | "disabled"
    | "inherit"
    | "positiveRed"
  >;
  weight?: ResponsiveProp<"normal" | "bold">;
  spacing?: ResponsiveProp<"normal" | "widest">;
  as?: "p" | "span" | "h1" | "h2" | "h3" | "h4" | "h5";
  align?: ResponsiveProp<"left" | "center" | "right">;
  lineHeight?: ResponsiveProp<LineHeights>;
  shadowed?: boolean;
  truncate?: boolean;
  lineThrough?: boolean;
  underlineHover?: boolean;
}

const defaultProps: Required<
  Omit<
    TextProps,
    | "children"
    | "as"
    | "shadowed"
    | "truncate"
    | "lineThrough"
    | "underlineHover"
  >
> = {
  size: "base",
  color: "default",
  weight: "normal",
  spacing: "normal",
  align: "left",
  lineHeight: "base",
};

export const Text: FC<TextProps> = ({
  children,
  size = defaultProps.size,
  color = defaultProps.color,
  weight = defaultProps.weight,
  spacing = defaultProps.spacing,
  as = "span",
  align = defaultProps.align,
  lineHeight,
  shadowed = false,
  truncate = false,
  lineThrough = false,
  underlineHover = false,
  ...props
}) => {
  const responsiveSize = useResponsiveProp(size, defaultProps.size);
  const responsiveColor = useResponsiveProp(color, defaultProps.color);
  const responsiveWeight = useResponsiveProp(weight, defaultProps.weight);
  const responsiveSpacing = useResponsiveProp(spacing, defaultProps.spacing);
  const responsiveAlign = useResponsiveProp(align, defaultProps.align);
  const responsiveLineHeight = useResponsiveProp(
    lineHeight,
    defaultProps.lineHeight
  );

  const classes = [
    styles.root,
    styles[`as--${as}`],
    styles[`color--${responsiveColor}`],
    styles[`size--${responsiveSize}`],
    styles[`weight--${responsiveWeight}`],
    styles[`spacing--${responsiveSpacing}`],
    styles[`align--${responsiveAlign}`],
    lineHeight ? styles[`lineHeight--${responsiveLineHeight}`] : "",
    shadowed ? styles.shadowed : "",
    truncate ? styles.truncate : "",
    lineThrough ? styles.lineThrough : "",
    underlineHover ? styles.underlineHover : "",
  ].join(" ");

  return createElement(as, { className: classes, ...props }, children);
};
