/**
 * compare two arrays. Returns true if they are the same, false otherwise
 * @param a array of any type
 * @param b array of any type
 */
export const isSameArray = (a?: any[] | null, b?: any[] | null) => {
  if (!a || !b) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  }
  return a.every((item, index) => item === b[index]);
};
