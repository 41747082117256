import { FC, useMemo } from "react";
import { alert } from "@newt/ui";
import { AreaDestination } from "./types";
import { DestinationChipSelectAreaToggle } from "./DestinationChipSelectAreaToggle";
import styles from "./DestinationChipSelect.module.css";

const MAX_SELECTED_NUM = 20;
const ERROR_MESSAGE = `同時にさがせるエリアは${MAX_SELECTED_NUM}件までです。数を減らしてもう一度お試しください🔍`;

export interface DestinationChipSelectProps {
  selectedIds: number[];
  onSelected: (ids?: number[]) => void;
  areaDestinations: AreaDestination[];
}

export const DestinationChipSelect: FC<DestinationChipSelectProps> = ({
  selectedIds,
  onSelected,
  areaDestinations,
}) => {
  // map the number of cities to the country id in advance to quickly display the alert.
  const mappedCityLength = useMemo(() => {
    const mapped = new Map<number, number>();
    areaDestinations.forEach((area) => {
      area.countries.forEach((country) => {
        mapped.set(country.id, country.cities.length);
      });
    });
    return mapped;
  }, [areaDestinations]);

  const handleSelected = (ids?: number[]) => {
    if (!ids) {
      onSelected(ids);
      return;
    }

    // If the number of selected cities is more than 20, alert should be displayed, but the selected ids includes country id.
    const numOfCities = ids.reduce((acc, id) => {
      if (mappedCityLength.has(id)) {
        // if the id is country, add number of cities
        return acc + (mappedCityLength.get(id) || 0);
      }
      // otherwise, just add 1
      return acc + 1;
    }, 0);

    if (numOfCities <= MAX_SELECTED_NUM) {
      onSelected(ids);
    } else {
      alert(ERROR_MESSAGE, { acceptLabel: "とじる" });
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.rootLabel}>📍 エリアからさがす</div>
      <ul>
        {areaDestinations.map((area, index) => (
          <li key={index} className={styles.areaRoot}>
            <DestinationChipSelectAreaToggle
              selectedIds={selectedIds}
              onSelected={handleSelected}
              area={area}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
