import { ChangeEvent, FC, useMemo } from "react";
import { ChipBasic } from "@newt/ui";
import { CountryDestination } from "./types";
import styles from "./DestinationChipSelectCountry.module.css";

interface DestinationChipSelectCountryProps {
  selectedIds?: number[];
  onSelected: (ids?: number[]) => void;
  country: CountryDestination;
}

export const DestinationChipSelectCountry: FC<
  DestinationChipSelectCountryProps
> = (props) => {
  const { selectedIds = [], onSelected, country } = props;

  const isCountrySelected = selectedIds.includes(country.id);

  const filteredSelectedIds = useMemo(() => {
    const cityOptionValues = country.cities.map((city) => city.id);
    const mappedCityValues = new Set(cityOptionValues);
    return selectedIds.filter(
      (value) => !(value === country.id || mappedCityValues.has(value))
    );
  }, [selectedIds, country]);

  const onClickSelectAllChip = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      onSelected([...filteredSelectedIds, country.id]);
    } else {
      onSelected(filteredSelectedIds);
    }
  };

  const onClickCityChip = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    const newSelectedCityId = parseInt(value, 10);
    if (checked) {
      // confirm all children are selected
      const newSelectedIds = [...selectedIds, newSelectedCityId];
      const newMappedSelectedIds = new Set(newSelectedIds);
      const isAllSelected = country.cities.every((city) =>
        newMappedSelectedIds.has(city.id)
      );
      if (country.cities.length > 1 && isAllSelected) {
        // if there are some cities and the all cities are selected, remove all cities and add country
        onSelected([...filteredSelectedIds, country.id]);
        return;
      } else {
        // if all children are not selected, just add new selected city
        onSelected(newSelectedIds);
        return;
      }
    } else {
      // confirm country is selected
      if (isCountrySelected) {
        // if country is selected, remove country and add remain children
        const remainCityIds = country.cities
          .map((city) => city.id)
          .filter((cityId) => cityId !== newSelectedCityId);
        onSelected([...filteredSelectedIds, ...remainCityIds]);
        return;
      } else {
        // if country is not selected, just remove selected city
        const filteredIds = selectedIds.filter(
          (id) => id !== newSelectedCityId
        );
        onSelected(filteredIds);
        return;
      }
    }
  };

  return (
    <>
      <p className={styles.countryLabel}>{country.name}</p>
      <ul className={styles.citiesWrapper}>
        {country.cities.length > 1 && (
          <li>
            <ChipBasic
              label="すべて"
              value={"all"}
              isChecked={isCountrySelected}
              onChange={onClickSelectAllChip}
            />
          </li>
        )}
        {country.cities.map((city) => (
          <li key={city.id}>
            <ChipBasic
              label={city.name}
              value={city.id}
              isChecked={isCountrySelected || selectedIds?.includes(city.id)}
              onChange={onClickCityChip}
            />
          </li>
        ))}
      </ul>
    </>
  );
};
