import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconBlock: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      fill="none"
      viewBox="0 0 17 16"
    >
      <g clipPath="url(#clip0_5969_53148)">
        <path
          fill="#fff"
          d="M8.499 1.332a6.67 6.67 0 00-6.667 6.667 6.67 6.67 0 006.667 6.666A6.67 6.67 0 0015.165 8 6.67 6.67 0 008.5 1.332zM3.165 7.999A5.332 5.332 0 018.5 2.665c1.233 0 2.366.42 3.266 1.127l-7.473 7.473A5.268 5.268 0 013.165 8zM8.5 13.332a5.269 5.269 0 01-3.267-1.127l7.473-7.473a5.269 5.269 0 011.127 3.267 5.332 5.332 0 01-5.333 5.333z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_5969_53148">
          <path fill="#fff" d="M0 0H16V16H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
