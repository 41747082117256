import { ReactNode, FC } from "react";
import { Paragraph } from "../Paragraph";
import { Stack } from "../Stack";
import { Tag } from "../Tag";
import { Text } from "../Text";
import styles from "./FormGroup.module.css";

interface FormGroupProps {
  title: ReactNode;
  help?: string | ReactNode;
  children: ReactNode;
  required?: boolean;
}

export const FormGroup: FC<FormGroupProps> = ({
  title,
  help,
  required,
  children,
}) => {
  return (
    <div className={styles.root}>
      <Stack spacing="4x">
        <Text as="h3" size="2xl" weight="bold">
          {title}
        </Text>
        {required === true && <Tag color="danger">必須</Tag>}
        {required === false && <Tag color="disabled">任意</Tag>}
      </Stack>

      {help && (
        <div className={styles.help}>
          {typeof help === "string" ? (
            <Paragraph color="gray" size="sm">
              {help}
            </Paragraph>
          ) : (
            help
          )}
        </div>
      )}
      <div className={styles.body}>{children}</div>
    </div>
  );
};
