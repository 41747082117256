import { Logo } from "@newt/ui";
import styles from "./LoadingLayout.module.css";

export const LoadingLayout = () => {
  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.logo}>
          <Logo variant="horizontal" />
        </div>
      </div>
    </div>
  );
};
