import { FC } from "react";
import Image from "next/image";
import BackgroundImg from "./assets/BackgroundImgForPc.webp";
import NativeApp from "./assets/NativeApp.webp";
import AppBannerTextForPc from "./assets/AppBannerTextForPc.webp";
import QrCodeForDownloadApp from "./assets/QrCodeForDownloadApp.png";
import { Container } from "../../Container";
import { DownloadAppButton } from "../../DownloadAppButton";
import { Icon } from "../../Icon";
import { DOWNLOAD_APP_BANNER_LINK } from "../constants";
import styles from "./FixedAppBannerForPc.module.css";

interface FixedAppBannerForPcProps {
  onClose: () => void;
}

export const FixedAppBannerForPc: FC<FixedAppBannerForPcProps> = (props) => {
  const { onClose } = props;
  return (
    <div className={styles.root}>
      <Image
        className={styles.img}
        src={BackgroundImg.src}
        alt=""
        fill={true}
        quality={95}
      />
      <Container>
        <div className={styles.flex}>
          <div className={styles.nativeAppWrapper}>
            <Image
              className={styles.nativeApp}
              src={NativeApp.src}
              alt="アプリ画面イメージ"
              width={171}
              height={157}
            />
          </div>
          <div className={styles.text}>
            <Image
              src={AppBannerTextForPc.src}
              alt="はじめての予約が7%OFF アプリ限定でクーポンもらえる"
              width={317}
              height={90}
            />
          </div>

          <div className={styles.action}>
            <DownloadAppButton
              className={styles.actionImg}
              variant="ios"
              link={DOWNLOAD_APP_BANNER_LINK}
            />
            <DownloadAppButton
              className={styles.actionImg}
              variant="android"
              link={DOWNLOAD_APP_BANNER_LINK}
            />
            <img
              src={QrCodeForDownloadApp.src}
              alt="QRコード"
              width={80}
              height={80}
            />
            <div className={styles.icon} onClick={onClose}>
              <Icon icon="close" color="white" />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
