import { FC } from "react";
import { BreakPoints } from "../../hooks/useBreakPoints";

import styles from "./SalesBanner.module.css";

interface BannerProps {}

const SALE = {
  mobileImageSrc: "https://newt.imgix.net/product/megasale_banner_sp.png",
  pcImageStc: "https://newt.imgix.net/product/megasale_banner_pc.png",
  link: "https://newt.net/sale-fes2306-htl",
  startDateTime: new Date("2023-06-05T00:00:00Z"),
  endDateTime: new Date("2023-07-31T14:59:00Z"),
} as const;

export const SalesBanner: FC<BannerProps> = () => {
  const sale = SALE; // WILL: fetch here
  const currentDateTime = new Date();
  const isSalePeriod =
    sale.startDateTime <= currentDateTime && currentDateTime < sale.endDateTime;

  if (!isSalePeriod) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.imageWrapper}>
        <a href={sale.link} target="_blank" rel="noopener noreferrer">
          <picture>
            <source
              className={styles.image}
              media={`(max-width:${BreakPoints.MOBILE_MAX})`}
              srcSet={`${sale.mobileImageSrc} 750w`}
            />
            <img
              className={styles.image}
              src={sale.pcImageStc}
              alt={"セールスバナー"}
            />
          </picture>
        </a>
      </div>
    </div>
  );
};
