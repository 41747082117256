import { ReactNode, FC } from "react";
import styles from "./Description.module.css";
import { Text } from "../Text";
import { Stack } from "../Stack";
import { Divider } from "../Divider";
import { Spacer } from "../Spacer";
import { ResponsiveProp } from "../../types/component.type";
import { useResponsiveProp } from "../../hooks/useResponsiveProp";

interface DescriptionItemProps {
  label?: string;
  children: ReactNode;
  divider?: boolean;
  variant?: ResponsiveProp<"vertical" | "horizontal">;
}

const DescriptionItem: FC<DescriptionItemProps> = ({
  label,
  children,
  divider = true,
  variant,
}) => {
  const responsiveVariant = useResponsiveProp(variant, "vertical");

  return (
    <div className={styles.item} data-testid="description-item">
      <div className={styles[`item--${responsiveVariant}`]}>
        {label && (
          <div>
            <Text
              weight="bold"
              size={responsiveVariant === "vertical" ? "sm" : "base"}
            >
              {label}
            </Text>
          </div>
        )}
        <div className={styles["item-inner"]}>
          <Text>{children}</Text>
        </div>
      </div>

      {divider && (
        <>
          <Spacer y="3x" />
          <Divider />
        </>
      )}
    </div>
  );
};

interface DescriptionProps {
  title?: string;
  children: ReactNode;
}

export const Description: FC<DescriptionProps> & {
  Item: typeof DescriptionItem;
} = ({ title, children }) => {
  return (
    <div className={styles.root}>
      {title && (
        <div className={styles.title}>
          <Text weight="bold" align="center">
            {title}
          </Text>
        </div>
      )}
      <div className={styles.inner}>
        <Stack direction="column" spacing="3x" align="stretch">
          {children}
        </Stack>
      </div>
    </div>
  );
};

Description.Item = DescriptionItem;
