import { CSSProperties, ReactNode, FC } from "react";
import styles from "./Skeleton.module.css";
import { useResponsiveProp } from "../../hooks/useResponsiveProp";
import { ResponsiveProp } from "../../types/component.type";

export interface SkeletonProps {
  width: number | "100%";
  height?: ResponsiveProp<number>;
  aspectRatio?: CSSProperties["aspectRatio"];
  padding?: CSSProperties["padding"];
  margin?: CSSProperties["margin"];
  rounded?: boolean;
  show?: boolean;
  children?: ReactNode;
}

export const Skeleton: FC<SkeletonProps> = ({
  width,
  height,
  aspectRatio,
  rounded,
  show,
  padding,
  margin,
  children,
}) => {
  const defaultHeight = 24;
  const responsiveHeight = useResponsiveProp(height, defaultHeight);
  const roundedStyle = rounded ? styles.rounded : "";
  const aspectRatioStyle = aspectRatio ?? "auto";

  if (show || (show && children !== null)) {
    return <div>{children}</div>;
  }

  return (
    <div style={{ padding }}>
      <div
        style={{
          minHeight: responsiveHeight,
          aspectRatio: aspectRatioStyle,
          width,
          margin,
        }}
        className={`${styles.root} ${roundedStyle}`}
      />
    </div>
  );
};
