import { FC } from "react"
import { IconTypeProps } from '../Icon'

export const IconPersonAdultOutline: FC<IconTypeProps> = () => {
  return (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.665 10.667a6.667 6.667 0 1 1-13.333 0 6.667 6.667 0 0 1 13.333 0Zm-2 0a4.667 4.667 0 1 1-9.333 0 4.667 4.667 0 0 1 9.333 0Z"
        fill="#757575"
      />
      <path
        d="M10.332 28.667v-5.09a.638.638 0 0 1 .058-.172 2.44 2.44 0 0 1 .301-.47c.294-.374.74-.796 1.302-1.194 1.135-.803 2.604-1.407 4.006-1.407v-2c-1.932 0-3.797.81-5.161 1.774-.688.487-1.284 1.037-1.72 1.591-.406.517-.786 1.182-.786 1.877v5.091h2ZM21.668 28.667v-5.09a.639.639 0 0 0-.058-.172 2.44 2.44 0 0 0-.301-.47c-.294-.374-.74-.796-1.302-1.194-1.135-.803-2.604-1.407-4.006-1.407v-2c1.932 0 3.797.81 5.161 1.774.688.487 1.284 1.037 1.72 1.591.406.517.786 1.182.786 1.877v5.091h-2Z"
        fill="#757575"
      />
    </svg>
  )
}
