import { useMediaQuery } from "./useMediaQuery";
import { ResponsiveKeys } from "../types/component.type";

export const BreakPoints = {
  MOBILE_MAX: "767px",
  TABLET_MIN: "768px",
  TABLET_MAX: "1024px",
  LAPTOP_MIN: "1025px",
  LAPTOP_MAX: "1400px",
  DESKTOP_MIN: "1401px",
} as const;

export const useBreakPoints = () => {
  const mobileOnly = useMediaQuery(`(max-width: ${BreakPoints.MOBILE_MAX})`);
  const tabletWithDown = useMediaQuery(
    `(max-width: ${BreakPoints.TABLET_MAX})`
  );
  const tabletWithUp = useMediaQuery(`(min-width: ${BreakPoints.TABLET_MIN})`);
  const laptopWithDown = useMediaQuery(
    `(max-width: ${BreakPoints.LAPTOP_MAX})`
  );
  const laptopWithUp = useMediaQuery(`(min-width: ${BreakPoints.LAPTOP_MIN})`);
  const desktopOnly = useMediaQuery(`(min-width: ${BreakPoints.DESKTOP_MIN})`);

  const tabletOnly = tabletWithDown && tabletWithUp;
  const laptopOnly = laptopWithDown && laptopWithUp;

  const getActiveKey = (): ResponsiveKeys => {
    if (mobileOnly) return "mobile";
    if (tabletOnly) return "tablet";
    if (laptopOnly) return "laptop";
    return "desktop";
  };

  return {
    mobileOnly,
    tabletWithDown,
    tabletWithUp,
    tabletOnly,
    laptopWithDown,
    laptopWithUp,
    laptopOnly,
    desktopOnly,
    activeKey: getActiveKey(),
  };
};
