import { FC } from "react";

export const LogoVertical: FC = () => {
  return (
    <svg
      width="153"
      height="121"
      viewBox="0 0 153 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33740_13872)">
        <path
          d="M31.7067 120.141H35.4074C36.3763 120.141 37.1585 119.359 37.1585 118.391V89.123C37.1585 88.1547 36.3763 87.3732 35.4074 87.3732H30.1657C29.1968 87.3732 28.4146 88.1547 28.4146 89.123V105.454L11.6974 88.1781C11.2071 87.6648 10.5183 87.3732 9.80619 87.3732H6.10552C5.13658 87.3732 4.35442 88.1547 4.35442 89.123V118.391C4.35442 119.359 5.13658 120.141 6.10552 120.141H11.3472C12.3161 120.141 13.0983 119.359 13.0983 118.391V102.06L29.8155 119.336C30.3058 119.849 30.9946 120.141 31.7067 120.141Z"
          fill="#00CD68"
        />
        <path
          d="M44.4314 120.141H67.581C68.55 120.141 69.3321 119.359 69.3321 118.391V114.133C69.3321 113.165 68.55 112.384 67.581 112.384H51.2491V107.507H63.4718C64.4407 107.507 65.2229 106.726 65.2229 105.758V101.5C65.2229 100.532 64.4407 99.75 63.4718 99.75H51.2491V95.1189H67.1141C68.083 95.1189 68.8652 94.3373 68.8652 93.3691V89.1112C68.8652 88.143 68.083 87.3615 67.1141 87.3615H44.4314C43.3691 87.3615 42.5052 88.2247 42.5052 89.2862V118.205C42.5052 119.266 43.3691 120.129 44.4314 120.129V120.141Z"
          fill="#00CD68"
        />
        <path
          d="M130.317 120.141H135.559C136.528 120.141 137.31 119.359 137.31 118.391V95.1306H146.871C147.84 95.1306 148.622 94.349 148.622 93.3808V89.123C148.622 88.1547 147.84 87.3732 146.871 87.3732H119.052C118.083 87.3732 117.301 88.1547 117.301 89.123V93.3808C117.301 94.349 118.083 95.1306 119.052 95.1306H128.566V118.391C128.566 119.359 129.348 120.141 130.317 120.141Z"
          fill="#00CD68"
        />
        <path
          d="M99.0076 120.141H104.144C105.125 120.141 105.977 119.464 106.187 118.508L112.853 89.2979C113.075 88.3181 112.328 87.3732 111.324 87.3732H106.117C105.125 87.3732 104.273 88.0731 104.062 89.0413L100.84 104.358L97.2331 88.9946C97.0113 88.0498 96.1591 87.3732 95.1901 87.3732H91.1743C90.2053 87.3732 89.3531 88.0498 89.1313 88.9946L85.5357 104.358L82.302 89.0413C82.0919 88.0731 81.2397 87.3732 80.2474 87.3732H75.0407C74.0251 87.3732 73.278 88.3064 73.5114 89.2979L80.2007 118.508C80.4225 119.464 81.263 120.141 82.2436 120.141H87.3569C88.3258 120.141 89.1663 119.476 89.3998 118.531L93.1822 102.818L96.9646 118.531C97.1864 119.476 98.0386 120.141 99.0076 120.141Z"
          fill="#00CD68"
        />
        <path
          d="M121.819 12.8085C119.543 14.5117 117.278 16.1098 115.001 17.603C114.78 17.7546 114.476 17.7079 114.301 17.498C102.323 2.93967 85.6878 -0.653242 75.1462 0.0933378C53.8177 1.5865 31.4152 13.4618 17.7216 33.5611C17.5815 33.7594 17.3363 33.8411 17.1145 33.7711C15.5502 33.2578 14.0092 32.7095 12.5383 32.1379C11.0674 31.5663 9.67814 33.3511 10.6938 34.6226C13.0986 37.6206 15.9471 40.4203 19.1925 42.87C19.5194 43.115 19.461 43.6049 19.0991 43.7799C14.4178 45.9963 8.46404 47.8394 1.50631 48.2127C-0.1981 48.306 -0.595018 50.6391 0.980978 51.304C12.4916 56.0868 25.4147 56.1218 36.9254 51.514C37.2289 51.3857 37.5674 51.3623 37.8943 51.444C46.2996 53.5554 56.8996 55.2702 68.9122 55.2702C69.1807 55.2702 69.2858 55.6202 69.0523 55.7602C63.0285 59.4114 55.7556 61.9894 47.3152 62.4677C45.6926 62.561 45.2139 64.7308 46.6615 65.489C67.3829 76.3727 91.0229 70.1435 104.331 55.1536C107.787 51.269 113.472 47.1278 118.737 44.6548C123.815 42.3917 128.952 39.7087 134.1 36.5241C145.085 29.6416 150.397 20.0993 152.627 14.8499C153.572 12.6452 152.51 10.0905 150.28 9.20394C144.665 6.97587 133.458 4.45616 121.796 12.8085H121.819ZM139.762 16.6814C138.279 16.7047 137.065 15.5265 137.042 14.0567C137.019 12.5752 138.198 11.362 139.669 11.3387C141.151 11.3154 142.365 12.4936 142.389 13.9634C142.412 15.4449 141.233 16.6581 139.762 16.6814Z"
          fill="#00CD68"
        />
        <path
          d="M140.415 69.8984H142.248C142.587 69.8984 142.855 70.1667 142.855 70.505V80.7005C142.855 81.0388 142.587 81.3071 142.248 81.3071H140.415C140.077 81.3071 139.808 81.0388 139.808 80.7005V70.505C139.808 70.1667 140.077 69.8984 140.415 69.8984Z"
          fill="#00CD68"
        />
        <path
          d="M111.464 78.0292H101.261C100.925 78.0292 100.654 78.3008 100.654 78.6358V80.1289C100.654 80.4639 100.925 80.7355 101.261 80.7355H111.464C111.799 80.7355 112.071 80.4639 112.071 80.1289V78.6358C112.071 78.3008 111.799 78.0292 111.464 78.0292Z"
          fill="#00CD68"
        />
        <path
          d="M136.294 74.2496H126.091C125.756 74.2496 125.484 74.5212 125.484 74.8562V76.3494C125.484 76.6844 125.756 76.956 126.091 76.956H136.294C136.63 76.956 136.901 76.6844 136.901 76.3494V74.8562C136.901 74.5212 136.63 74.2496 136.294 74.2496Z"
          fill="#00CD68"
        />
        <path
          d="M122.449 78.5775H114.697C114.427 78.5775 114.207 78.7969 114.207 79.0675V80.2457C114.207 80.5162 114.427 80.7356 114.697 80.7356H122.449C122.72 80.7356 122.939 80.5162 122.939 80.2457V79.0675C122.939 78.7969 122.72 78.5775 122.449 78.5775Z"
          fill="#00CD68"
        />
        <path
          d="M121.795 73.6548L121.13 79.9424H118.632L119.355 73.1182H121.305C121.597 73.1182 121.819 73.3631 121.795 73.6548Z"
          fill="#00CD68"
        />
        <path
          d="M115.666 73.1182H121.118C121.387 73.1182 121.609 73.3398 121.609 73.6081V74.798C121.609 75.0663 121.387 75.2879 121.118 75.2879H115.666C115.398 75.2879 115.176 75.0663 115.176 74.798V73.6081C115.176 73.3398 115.398 73.1182 115.666 73.1182Z"
          fill="#00CD68"
        />
        <path
          d="M110.063 70.4701H102.673C102.338 70.4701 102.066 70.7417 102.066 71.0767V72.5698C102.066 72.9049 102.338 73.1764 102.673 73.1764H110.063C110.398 73.1764 110.67 72.9049 110.67 72.5698V71.0767C110.67 70.7417 110.398 70.4701 110.063 70.4701Z"
          fill="#00CD68"
        />
        <path
          d="M141.291 75.9061C143.731 76.5127 145.739 77.1659 147.338 77.8192C147.665 77.9475 148.027 77.7725 148.144 77.4459C148.284 77.0143 148.47 76.4427 148.599 76.0227C148.692 75.7194 148.552 75.4045 148.26 75.2762C146.638 74.5996 144.606 73.958 142.12 73.3397C142.12 73.3397 141.454 75.3928 141.279 75.9061H141.291Z"
          fill="#00CD68"
        />
      </g>
      <defs>
        <clipPath id="clip0_33740_13872">
          <rect width="153" height="120.141" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
