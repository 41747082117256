import Script from "next/script";

interface CreditCard {
  readonly cardno: number;
  readonly expire: number; // YYYYMM or YYMM
  readonly holdername?: string;
  readonly securitycode: number;
}

type ResultCode =
  | "000"
  | 100
  | 101
  | 102
  | 110
  | 111
  | 112
  | 113
  | 121
  | 122
  | 131
  | 132
  | 141
  | 142
  | 150
  | 160
  | 161
  | 162
  | 170
  | 180
  | 190
  | 191
  | 200
  | 201
  | 501
  | 502
  | 511
  | 512
  | 521
  | 522
  | 531
  | 541
  | 551
  | 552
  | 701
  | 901
  | 902;

export type GmoToken =
  | {
      readonly resultCode: "000";
      readonly tokenObject: {
        readonly isSecurityCodeSet: boolean | string;
        readonly maskedCardNo: string;
        readonly toBeExpiredAt: string;
        readonly token: string;
      };
    }
  | {
      readonly resultCode: Exclude<ResultCode, "000">;
    };

export const gmoTokenErrorMessages: Partial<
  Record<`${Exclude<ResultCode, "000">}`, string>
> = {
  "100": "カード番号は必須です",
  "101": "カード番号に数字以外の文字が含まれています",
  "102": "カード番号の桁数が不正です",
  "110": "有効期限は必須です",
  "111": "有効期限に数字以外の文字が含まれています",
  "112": "有効期限の桁数が不正です",
  "113": "有効期限の月が不正です",
  "121": "セキュリティコードに数字以外の文字が含まれています",
  "122": "セキュリティコードの桁数が不正です",
  "902": "現在処理が混み合っています、時間を空けてリトライしてください",
};

declare global {
  interface Window {
    readonly Multipayment: {
      init(key: string): void;
      getToken(
        creditCard: CreditCard,
        callback: (token: GmoToken) => void
      ): GmoToken;
    };
  }
}

export const GMOMultiPaymentScript = () => (
  <Script
    src={process.env.NEXT_PUBLIC_GMO_TOKEN_URL}
    strategy={"afterInteractive"}
    onLoad={() => {
      const apiKey = process.env.NEXT_PUBLIC_GMO_TOKEN_API_KEY;
      if (apiKey === undefined) throw new Error("GMO_TOKEN_API_KEY is not set");
      window.Multipayment.init(apiKey);
    }}
  />
);

export const useGMOMultiPayment = () => {
  const getToken = (creditCard: CreditCard) => {
    return new Promise<GmoToken>((resolve) => {
      window.Multipayment.getToken(creditCard, resolve);
    });
  };

  return {
    getToken,
  };
};
