import { marked } from "marked";

interface TData {
  [key: string]: string | number;
}

const replaceAll = (source: string, data?: TData) => {
  let content = source;

  if (data) {
    Object.keys(data).map((key) => {
      const re = new RegExp(`{${key}}`, "g");
      content = content.replace(re, String(data[key]));
    });
  }

  return content;
};

export const markdown = (content: string, data?: TData) => {
  const markdown = marked(content, { breaks: true, async: false }) as string;
  return replaceAll(markdown, data);
};
