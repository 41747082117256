import { FC, ReactNode } from "react";
import { Paragraph } from "../Paragraph";
import styles from "./LoadingLogo.module.css";
import LoadingLogoImage from "./assets/loading-logo.gif";

interface LoadingLogoProps {
  size?: "base";
  withText?: ReactNode | string;
}

export const LoadingLogo: FC<LoadingLogoProps> = ({
  size = "base",
  withText = "読みこみ中です…",
}) => {
  const className = [styles.root, styles[`size--${size}`]].join(" ");

  return (
    <div className={className}>
      <img src={LoadingLogoImage.src} alt="NEWT" />
      {withText && (
        <div className={styles.loadingText}>
          <Paragraph weight="bold" align="center">
            {withText}
          </Paragraph>
        </div>
      )}
    </div>
  );
};
