import { FC } from "react";
import { Tooltip } from "@newt/ui";
import styles from "./MiddleNameTooltip.module.css";

export const MiddleNameTooltip: FC = () => {
  return (
    <div className={styles.note}>
      <span className={styles.text}>ミドルネームがある方はこちら</span>
      <Tooltip>
        <div className={styles.content}>
          名の入力欄にスペースを入れて入力してください。
          <br />
          例 : YAMADA / SAKURA MID <br />
          .や（）などの記号が入る場合はヘルプをご確認ください。
          <a
            href="https://newt.zendesk.com/hc/ja/articles/29671518355737/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            ヘルプ
          </a>
        </div>
      </Tooltip>
    </div>
  );
};
