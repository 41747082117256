import { FC, useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { LocalStorageService } from "@utils/webStorage";
import { NewsletterModal } from "../NewsletterModal";
import { ReservationShareModal } from "../ReservationShare";

export const LOCAL_STORAGE_KEY = "modalIdentifier";
export enum RegisteredModalId {
  Newsletter = "newsletter",
  BookingThanksTour = "tourBookingThanks",
}

/**
 * if there is flag on local storage, show specific modal on page load.
 */
export const ModalOnLoadHandler: FC = () => {
  const router = useRouter();
  const [modalIds, setModalIds] = useState<RegisteredModalId[]>([]);
  const firstModalId = modalIds[0];
  const isBooking = router.asPath.includes("/book");

  /** update modalIds */
  const restoreModalIds = useCallback(
    () =>
      LocalStorageService.getItem<RegisteredModalId[]>(LOCAL_STORAGE_KEY).then(
        (modalIds) => {
          if (modalIds) {
            setModalIds(modalIds);
          }
        }
      ),
    []
  );

  /** first load */
  useEffect(() => {
    restoreModalIds();
  }, []);

  /** once url has been changed */
  useEffect(() => {
    const onHistoryChange = (_url: string, options: { shallow: boolean }) => {
      // dismiss shallow change (e.g. date change on tour detail page)
      if (!options?.shallow) {
        restoreModalIds();
      }
    };
    router.events.on("routeChangeComplete", onHistoryChange);
    return () => {
      router.events.off("routeChangeComplete", onHistoryChange);
    };
  }, [router.events]);

  /** if opening modal has been closed, remove identifier from local storage and open next modal */
  const handleUpdateStoredModalIds = () => {
    const [_first, ...rest] = modalIds || [];
    setModalIds(rest);
    if (rest.length === 0) {
      LocalStorageService.removeItem(LOCAL_STORAGE_KEY);
    } else {
      LocalStorageService.setItem(LOCAL_STORAGE_KEY, rest);
    }
  };

  return (
    <>
      {/** メール許諾モーダル （会員登録後 */}
      {firstModalId === RegisteredModalId.Newsletter && (
        <NewsletterModal
          onClose={handleUpdateStoredModalIds}
          moduleType="via_signin"
        />
      )}
      {/** 予約したツアーのSNSシェア導線 （ツアー予約後） */}
      {firstModalId === RegisteredModalId.BookingThanksTour && !isBooking && (
        <ReservationShareModal
          onUpdateStoreModalIds={handleUpdateStoredModalIds}
        />
      )}
    </>
  );
};
