import { createElement, FC } from "react";
import XIcon from "./assets/icon_X.webp";
import LineIcon from "./assets/icon_line.webp";
import StoriesIcon from "./assets/icon_stories.webp";
import styles from "./ShareButton.module.css";

type Variant = "X" | "line" | "instagramStories";

interface ShareButtonProps {
  variant: Variant;
  size?: "md" | "lg";
  onClick?: () => void;
  as?: "button" | "a";
  href?: string;
  target?: HTMLAnchorElement["target"];
  disabled?: boolean;
}

const IMG_SRC = {
  X: XIcon.src,
  line: LineIcon.src,
  instagramStories: StoriesIcon.src,
};

export const ShareButton: FC<ShareButtonProps> = (props) => {
  const {
    variant,
    size = "md",
    onClick,
    href,
    as = props.href !== undefined ? "a" : "button",
    target,
    disabled = false,
  } = props;
  const className = [
    styles.root,
    styles[`variant--${variant}`],
    styles[`size--${size}`],
    disabled ? styles.disabled : "",
  ].join(" ");
  const isAnchor = as === "a";
  const imgSrc = IMG_SRC[variant];

  return createElement(
    as,
    {
      className: className,
      onClick,
      href: isAnchor ? href : undefined,
      target,
      disabled: disabled ? true : undefined,
    },
    <div className={styles.icon}>
      <img src={imgSrc} alt={`${variant}でシェア`} />
    </div>
  );
};
