import { TourHistoryCardFragment } from "@graphql/types";
import { TourHistoryCard as UiTourHistoryCard } from "@newt/ui";
import { ItemListId } from "@utils/marketing/events/viewItemListEvent";
import { useTourWishlistToggle } from "@utils/useTourWishlistToggle";
import { ComponentPropsWithoutRef, FC, useMemo } from "react";
import { toTourHistoryCardModel } from "./utils";

interface TourHistoryCardProps
  extends Pick<ComponentPropsWithoutRef<typeof UiTourHistoryCard>, "width"> {
  readonly fragment: TourHistoryCardFragment;
  readonly itemListId: ItemListId;
}

export const TourHistoryCard: FC<TourHistoryCardProps> = ({
  fragment,
  width,
  itemListId,
}) => {
  const { inWishlist, toggleInWishlist } = useTourWishlistToggle({
    tourId: fragment.id,
    tourUrl: fragment.url,
    defaultValue: fragment.inWishlist,
    logEventOptions: { tour: fragment },
  });
  const model = useMemo(
    () => toTourHistoryCardModel(fragment, itemListId),
    [fragment, itemListId]
  );

  return (
    <UiTourHistoryCard
      model={model}
      width={width}
      inWishlist={inWishlist}
      onClickWishlistIcon={toggleInWishlist}
    />
  );
};
