import { FC } from "react";
import styles from "./Loading.module.css";

interface LoadingProps {
  readonly text?: string;
  readonly centered?: boolean;
}

const Loader = () => {
  return (
    <svg
      className={styles.loader}
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="secondHalf">
          <stop offset="70%" stopOpacity="0" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient id="firstHalf">
          <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="1" stopColor="currentColor" />
        </linearGradient>
      </defs>

      <g strokeWidth="28">
        <path stroke="url(#secondHalf)" d="M 14 100 A 86 86 0 0 1 186 100" />
        <path stroke="url(#firstHalf)" d="M 186 100 A 86 86 0 0 1 14 100" />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          d="M 14 100 A 86 86 0 0 1 14 86"
        />
        <animateTransform
          from="0 100 100"
          to="360 100 100"
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1400ms"
        />
      </g>
    </svg>
  );
};

export const Loading: FC<LoadingProps> = ({
  centered = false,
  text = "Loading...",
}) => {
  const className = [styles.root, centered ? styles.centered : ""].join(" ");

  return (
    <>
      <div className={className}>
        <Loader />
        {text && <span className={styles.text}>{text}</span>}
      </div>
    </>
  );
};
