import { FC, ReactNode } from "react";
import { ResponsiveProp, Spacings } from "../../types/component.type";
import { useResponsiveProp } from "../../hooks/useResponsiveProp";
import styles from "./Container.module.css";

interface ContainerProps {
  size?: "base" | "1/2" | "2/3" | "1/3";
  align?: "left" | "center" | "right";
  spacing?: ResponsiveProp<Spacings>;
  children: ReactNode;
}

export const Container: FC<ContainerProps> = ({
  children,
  size = "base",
  align = "centered",
  spacing = "4x",
}) => {
  const responsiveSpacing = useResponsiveProp(spacing, "4x");

  const className = [
    styles.root,
    styles[`size--${size}`],
    styles[`spacing--${responsiveSpacing}`],
    styles[`align--${align}`],
  ].join(" ");
  return <div className={className}>{children}</div>;
};
