import { Icon } from "../Icon";
import {
  createElement,
  FC,
  forwardRef,
  MouseEventHandler,
  ReactNode,
} from "react";
import styles from "./ButtonLink.module.css";

export interface ButtonLinkProps {
  as?: "button" | "a";
  href?: string;
  target?: HTMLAnchorElement["target"];
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  children: ReactNode;
}

export const ButtonLink: FC<ButtonLinkProps> = forwardRef<
  HTMLButtonElement,
  ButtonLinkProps
>((props, ref) => {
  const {
    as = props.href !== undefined ? "a" : "button",
    href,
    target,
    children,
    onClick,
  } = props;

  const isAnchor = as === "a";

  return createElement(
    as,
    {
      type: isAnchor ? undefined : "button",
      href: isAnchor ? href : undefined,
      target,
      className: styles.root,
      onClick,
      ref,
    },
    <>
      <span className={styles.text}>{children}</span>
      <Icon icon="chevronRight" color="gray-80" size="md" />
    </>
  );
});

ButtonLink.displayName = "ButtonLink";
