import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconSmartPhone: FC<IconTypeProps> = () => {
  return (
    <svg
      width="14"
      height="22"
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.87803 21.5C1.44439 21.5 1.06837 21.3408 0.749969 21.0223C0.431549 20.7039 0.272339 20.3279 0.272339 19.8942V2.10576C0.272339 1.6721 0.431549 1.29607 0.749969 0.97765C1.06837 0.659217 1.44439 0.5 1.87803 0.5H12.124C12.5576 0.5 12.9337 0.659217 13.2521 0.97765C13.5705 1.29607 13.7297 1.6721 13.7297 2.10576V19.8942C13.7297 20.3279 13.5705 20.7039 13.2521 21.0223C12.9337 21.3408 12.5576 21.5 12.124 21.5H1.87803ZM1.87803 17.773V19.8942H12.124V17.773H1.87803ZM7.00514 19.5407C7.20664 19.5407 7.37417 19.4725 7.50773 19.3362C7.64128 19.1999 7.70806 19.031 7.70806 18.8295C7.70806 18.628 7.6399 18.4605 7.5036 18.3269C7.36728 18.1933 7.19838 18.1266 6.99689 18.1266C6.79538 18.1266 6.62785 18.1947 6.4943 18.331C6.36075 18.4673 6.29397 18.6362 6.29397 18.8378C6.29397 19.0393 6.36212 19.2068 6.49843 19.3404C6.63474 19.4739 6.80365 19.5407 7.00514 19.5407ZM1.87803 16.3589H12.124V4.22697H1.87803V16.3589ZM1.87803 2.81283H12.124V2.10576H1.87803V2.81283Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};
