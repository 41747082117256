import { Stack } from "../Stack";
import { Text } from "../Text";
import { Alert } from "../Alert";
import { forwardRef, ReactNode } from "react";
import { Icon, IconType } from "../Icon";
import styles from "./List.module.css";
import { ResponsiveProp } from "../../types/component.type";

interface ListAlert {
  title: string;
  body: string;
}

export interface ListProps {
  variant?: "default" | "card";
  status?: "success" | "failure";
  label?: string;
  placeholder?: string | null;
  href?: string;
  target?: "_blank";
  disabled?: boolean;
  icon?: IconType | ReactNode;
  iconSize?: ResponsiveProp<"xs" | "sm" | "md" | "lg" | "xl">;
  iconColor?:
    | "gray-80"
    | "gray-50"
    | "gray-30"
    | "green-40"
    | "white"
    | "black"
    | "primary"
    | "warning"
    | "danger";
  action?: ReactNode;
  subText?: string;
  alert?: ListAlert;
  onClick?: () => void;
  children?: ReactNode;
}

export const List = forwardRef<HTMLAnchorElement, ListProps>((props, ref) => {
  const {
    label,
    placeholder,
    icon,
    iconSize = "lg",
    iconColor = "gray-50",
    action,
    subText,
    href,
    children,
    onClick,
    status,
    disabled = false,
    variant = "default",
    target,
    alert,
  } = props;

  const className = [
    styles.root,
    styles[`variant--${variant}`],
    disabled ? styles.disabled : "",
  ].join(" ");

  return (
    <>
      <a
        href={href}
        className={className}
        onClick={onClick}
        ref={ref}
        target={target}
        rel={target === "_blank" ? "noreferrer" : undefined}
        style={{ cursor: "pointer" }}
      >
        <div>
          <Stack direction="row" spacing="1" justify="between" align="center">
            <Stack align="center" spacing="4x">
              {typeof icon === "string" ? (
                <Icon
                  icon={icon as IconType}
                  size={iconSize}
                  color={iconColor}
                />
              ) : (
                icon
              )}

              <Stack key={label} direction="column" spacing="base">
                {label && (
                  <Text weight="bold" size="lg">
                    {label}
                  </Text>
                )}
                {children}
                {placeholder && <Text>{placeholder}</Text>}
              </Stack>
            </Stack>
            <Stack>
              {status && (
                <Icon
                  icon={status === "success" ? "checkCircle" : "alertCircle"}
                  color={status === "success" ? "primary" : "danger"}
                />
              )}
              {action || <Icon icon="chevronRight" />}
            </Stack>
          </Stack>
          {subText && <p className={styles.subText}>{subText}</p>}
          {alert && (
            <div className={styles[`variant--${variant}--alert`]}>
              <Alert title={alert.title} body={alert.body} />
            </div>
          )}
        </div>
      </a>
    </>
  );
});

List.displayName = "List";
