import { Header, OpenInApp } from "@components/common";
import { UtmCampaign } from "@utils/dynamicLink";
import { BasicFooter, Container } from "@newt/ui";
import { FC, ReactNode } from "react";
import styles from "./AccountLayout.module.css";

interface AccountLayoutProps {
  children: ReactNode;
  utmCampaign?: UtmCampaign;
}

export const AccountLayout: FC<AccountLayoutProps> = ({
  utmCampaign,
  children,
}) => {
  return (
    <div className={styles.root}>
      <OpenInApp utmCampaign={utmCampaign} />
      <Header />
      <div className={styles.body}>
        <Container spacing={{ mobile: "5x", tablet: "8x" }}>
          {children}
        </Container>
      </div>
      <BasicFooter />
    </div>
  );
};
