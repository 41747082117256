import { Tooltip } from "../Tooltip";

export const BedTypeTooltip = () => {
  return (
    <Tooltip position="bottomLeft">
      <div style={{ width: "250px", whiteSpace: "pre-wrap" }}>
        ベッドタイプはチェックイン時の利用状況によってご希望に添えない場合がございます。
      </div>
    </Tooltip>
  );
};
