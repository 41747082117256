import { FC, useRef } from "react";
import Image from "next/image";
import { useFetchReservation } from "./useFetchReservation";
import { ShareButtons, ShareTicket, TourTicket } from "./components";
import { getHighlightedTlUrl } from "./utils";
import SmartTravelTicket from "./assets/SmartTravelTicket.webp";
import styles from "./ReservationShare.module.css";
import { Loading } from "@newt/ui";

export interface ReservationShareProps {
  reservationId?: number;
  isWebview: boolean;
}

export const ReservationShare: FC<ReservationShareProps> = (props) => {
  const { reservationId, isWebview } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { data, isPending } = useFetchReservation(reservationId);
  const highlightedUrl = getHighlightedTlUrl(data?.tour);

  return (
    <div className={styles.root}>
      <div className={styles.ticketWrapper} ref={wrapperRef}>
        {/** LOADING */}
        {isPending && (
          <div className={styles.loading}>
            <Loading />
          </div>
        )}
        {/** SHARE TICKET */}
        {!!data?.tour && (
          <ShareTicket
            wrapperRef={wrapperRef}
            cardFront={<TourTicket tour={data?.tour} />}
            cardBack={
              <Image
                src={SmartTravelTicket}
                alt="スマートトラベルチケット画像"
                width={180}
                height={255}
                quality={100}
              />
            }
          />
        )}
      </div>
      <ShareButtons
        shareUrl={highlightedUrl}
        itemId={data?.tour?.id}
        itemName={data?.tour?.title}
        isWebview={isWebview}
        instagramStoriesArgs={{
          imageUrl: data?.tour?.mainImage.url,
          title: data?.tour?.title,
        }}
      />
    </div>
  );
};
