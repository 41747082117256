import { FC } from "react"
import { IconTypeProps } from '../Icon'

export const IconSale: FC<IconTypeProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23 12L20.56 9.21L20.9 5.52L17.29 4.7L15.4 1.5L12 2.96L8.6 1.5L6.71 4.69L3.1 5.5L3.44 9.2L1 12L3.44 14.79L3.1 18.49L6.71 19.31L8.6 22.5L12 21.03L15.4 22.49L17.29 19.3L20.9 18.48L20.56 14.79L23 12ZM10.09 16.72L6.29 12.91L7.77 11.43L10.09 13.76L15.94 7.89L17.42 9.37L10.09 16.72Z" fill="#757575"/>
      <path d="M6 7H18V17H6V7Z" fill="#757575"/>
      <path d="M8.77246 10.2578C8.77246 9.82161 8.91406 9.46842 9.19727 9.19824C9.48047 8.9248 9.85156 8.78809 10.3105 8.78809C10.776 8.78809 11.1504 8.92318 11.4336 9.19336C11.7168 9.46029 11.8584 9.82324 11.8584 10.2822V10.6338C11.8584 11.0732 11.7168 11.4264 11.4336 11.6934C11.1504 11.9603 10.7793 12.0938 10.3203 12.0938C9.85807 12.0938 9.48372 11.9603 9.19727 11.6934C8.91406 11.4232 8.77246 11.0602 8.77246 10.6045V10.2578ZM9.70996 10.6338C9.70996 10.8291 9.7653 10.987 9.87598 11.1074C9.98991 11.2246 10.138 11.2832 10.3203 11.2832C10.5026 11.2832 10.6475 11.223 10.7549 11.1025C10.8623 10.9821 10.916 10.821 10.916 10.6191V10.2578C10.916 10.0625 10.8623 9.90462 10.7549 9.78418C10.6475 9.66374 10.4993 9.60352 10.3105 9.60352C10.1315 9.60352 9.98665 9.66374 9.87598 9.78418C9.7653 9.90137 9.70996 10.0658 9.70996 10.2773V10.6338ZM12.1709 14.2568C12.1709 13.8174 12.3141 13.4642 12.6006 13.1973C12.887 12.9271 13.2581 12.792 13.7139 12.792C14.1761 12.792 14.5488 12.9255 14.832 13.1924C15.1185 13.4561 15.2617 13.8206 15.2617 14.2861V14.6377C15.2617 15.0739 15.1217 15.4271 14.8418 15.6973C14.5618 15.9642 14.1891 16.0977 13.7236 16.0977C13.2549 16.0977 12.8789 15.9626 12.5957 15.6924C12.3125 15.4222 12.1709 15.0641 12.1709 14.6182V14.2568ZM13.1084 14.6377C13.1084 14.8167 13.167 14.9697 13.2842 15.0967C13.4014 15.2236 13.5479 15.2871 13.7236 15.2871C14.1208 15.2871 14.3193 15.0674 14.3193 14.6279V14.2568C14.3193 14.0615 14.264 13.9053 14.1533 13.7881C14.0426 13.6676 13.8962 13.6074 13.7139 13.6074C13.5316 13.6074 13.3851 13.6676 13.2744 13.7881C13.1637 13.9053 13.1084 14.0664 13.1084 14.2715V14.6377ZM10.5986 15.4678L9.91016 15.0967L13.3818 9.54004L14.0703 9.91113L10.5986 15.4678Z" fill="white"/>
    </svg>
  )
}
