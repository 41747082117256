import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconBathtub: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        fill="#1F1F1F"
        d="M9.521 11.18c-.66 0-1.225-.235-1.693-.703a2.308 2.308 0 01-.703-1.693c0-.66.234-1.23.703-1.71a2.282 2.282 0 011.693-.718c.66 0 1.224.24 1.693.719a2.36 2.36 0 01.703 1.709c0 .66-.234 1.224-.703 1.693a2.308 2.308 0 01-1.693.703zM6.87 28.462a1.61 1.61 0 01-1.055-.4c-.319-.266-.479-.59-.479-.974a2.091 2.091 0 01-1.533-.639 2.091 2.091 0 01-.639-1.533v-8.561h3.961v-.863c0-.788.277-1.459.83-2.013a2.74 2.74 0 012.013-.83c.426 0 .836.08 1.23.24.394.16.74.41 1.038.75l1.661 1.885c.15.17.304.325.464.463.16.139.324.261.495.368h9.008V5.845c0-.405-.133-.75-.4-1.038a1.313 1.313 0 00-1.005-.432 2.79 2.79 0 00-.607.064 1.029 1.029 0 00-.512.288l-1.565 1.565c.128.405.133.81.016 1.214a4.318 4.318 0 01-.527 1.15l-3.482-3.322a3.847 3.847 0 011.23-.543 2.078 2.078 0 011.294.095l1.66-1.629c.32-.34.698-.601 1.135-.782.436-.181.889-.272 1.358-.272 1 0 1.847.357 2.54 1.07a3.561 3.561 0 011.037 2.572v10.51h3.195v8.56c0 .597-.213 1.108-.64 1.534a2.091 2.091 0 01-1.533.639c0 .383-.16.708-.479.974-.32.266-.692.4-1.118.4H6.87zm-1.534-3.546H27.06v-6.39H5.336v6.39zm21.723 0H5.336h21.722z"
      ></path>
    </svg>
  );
};
