import { FC, useEffect, CSSProperties, useRef } from "react";
import styles from "./DateRangePicker.module.css";
import {
  DayPicker,
  Matcher,
  ModifiersClassNames,
  ModifiersStyles,
  ClassNames,
  Formatters,
  DayPickerProps,
  DateRange,
  DayButtonProps,
  OnSelectHandler,
  NextMonthButtonProps,
  PreviousMonthButtonProps,
} from "react-day-picker";
import { DateTime } from "luxon";
import { Icon } from "../Icon";
import { holidays } from "@newt/config/holidays";
import { ja } from "react-day-picker/locale";

interface DateColor {
  base: string;
  hover: string;
}

export const getDateColorFromLevel = (level?: number): DateColor => {
  switch (level) {
    case 1:
      return { base: "#E3F2FD", hover: "#BBDEFB" };
    case 2:
      return { base: "#EAF4D4", hover: "#D2ED85" };
    case 3:
      return { base: "#FFF9C4", hover: "#FFF176" };
    case 4:
      return { base: "#FFE9EE", hover: "#FFC8D1" };
    default:
      return { base: "#fff", hover: "#fff" };
  }
};

export interface DateRangePickerDateColor {
  level: number;
  date: Date;
  price?: string;
}

export interface DateRangePickerProps {
  from?: Date;
  to?: Date;
  initialMonth?: Date;
  minDate?: Date;
  maxDate?: Date;
  minSelectableDate?: Date;
  maxSelectableDate?: Date;
  isDisabledDay?: (date: Date) => boolean;
  onChange?: (from?: Date, to?: Date) => void;
  onMonthChange?: (month: Date) => void;
  dateColors?: DateRangePickerDateColor[];
  isScrollable: boolean;
}

const DAY_CUSTOM_MODIFIER_NAME = {
  PAST: "past",
  HIDDEN: "hidden",
  SELECTABLE: "selectable",
  CUSTOM_COLOR: "custom_color",
  RECOMMENDED_RANGE_TO: "recommended_range_to",
  SELECTED_RANGE_FROM: "selected_range_from",
  SELECTED_RANGE_TO: "selected_range_to",
  SELECTED_RANGE: "selected_range",
  HOLIDAY: "holiday",
  NONE: "none",
};

const MODIFIERS_CLASS_NAMES: ModifiersClassNames = {
  [DAY_CUSTOM_MODIFIER_NAME.PAST]: styles.dayPast,
  [DAY_CUSTOM_MODIFIER_NAME.HIDDEN]: styles.dayHidden,
  [DAY_CUSTOM_MODIFIER_NAME.SELECTABLE]: styles.daySelectable,
  [DAY_CUSTOM_MODIFIER_NAME.CUSTOM_COLOR]: styles.dayCustomColor,
  [DAY_CUSTOM_MODIFIER_NAME.RECOMMENDED_RANGE_TO]: styles.dayRecommendedRangeTo,
  [DAY_CUSTOM_MODIFIER_NAME.SELECTED_RANGE]: styles.daySelectedRange,
  [DAY_CUSTOM_MODIFIER_NAME.SELECTED_RANGE_FROM]: styles.daySelectedRangeFrom,
  [DAY_CUSTOM_MODIFIER_NAME.SELECTED_RANGE_TO]: styles.daySelectedRangeTo,
  [DAY_CUSTOM_MODIFIER_NAME.HOLIDAY]: styles.holiday,
  [DAY_CUSTOM_MODIFIER_NAME.NONE]: styles.dayNone,
};

const DayContent: FC<{
  buttonProps: DayButtonProps;
  date: Date;
  activeModifiers: Record<string, boolean>;
  customColor?: DateColor;
  price?: string;
}> = (props) => {
  const additionalStyles: CSSProperties = props.customColor
    ? ({
        ["--day-custom-color"]: props.customColor.base,
        ["--day-custom-color-hover"]: props.customColor.hover,
      } as CSSProperties)
    : {};

  return (
    <button
      {...props.buttonProps}
      className={styles.dayInner}
      style={additionalStyles}
    >
      <span className={styles.dayText}>{props.date.getDate()}</span>
      {!props.activeModifiers.disabled &&
        !props.activeModifiers.recommended_range_to && (
          <span className={styles.dayPrice}>{props.price}</span>
        )}
    </button>
  );
};

const isEqualYmd = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const DateRangePicker: FC<DateRangePickerProps> = ({
  from,
  to,
  minDate = DateTime.now().startOf("month").toJSDate(),
  maxDate = DateTime.now().plus({ year: 1 }).endOf("month").toJSDate(),
  minSelectableDate,
  maxSelectableDate,
  initialMonth,
  isDisabledDay,
  onChange,
  onMonthChange,
  dateColors = [],
  isScrollable,
}) => {
  if (minSelectableDate === undefined) minSelectableDate = minDate;
  if (maxSelectableDate === undefined) maxSelectableDate = maxDate;

  const ref = useRef<HTMLDivElement>(null);

  const components = {
    DayButton: (props: DayButtonProps) => {
      const foundDate = dateColors.find((v) =>
        isEqualYmd(v.date, props.day.date)
      );
      return (
        <DayContent
          buttonProps={props}
          date={props.day.date}
          activeModifiers={props.modifiers}
          customColor={getDateColorFromLevel(foundDate?.level)}
          price={foundDate?.price}
        />
      );
    },
    NextMonthButton: (props: NextMonthButtonProps) => (
      <button {...props}>
        <Icon icon="chevronRight" />
      </button>
    ),
    PreviousMonthButton: (props: PreviousMonthButtonProps) => (
      <button {...props}>
        <Icon icon="chevronLeft" />
      </button>
    ),
  };
  const classNames: ClassNames = {
    root: styles.root,
    months: isScrollable ? styles.monthsScrollable : styles.months,
    month: styles.month,
    month_caption: styles.caption,
    dropdowns: styles.captionDropdowns,
    weekday: styles.headCell,
    week: styles.week,
    day_button: styles.dayButton,
    disabled: styles.dayDisabled,
    hidden: styles.dayHidden,
    outside: styles.dayOutside,
    range_start: styles.dayRangeStart,
    range_middle: styles.dayRangeMiddle,
    range_end: styles.dayRangeEnd,
    selected: styles.daySelected,
    today: styles.dayToday,
    chevron: styles.chevron,
    months_dropdown: styles.monthsDropdown,
    years_dropdown: styles.yearsDropdown,
    footer: styles.footer,
    week_number: styles.weekNumber,
    button_previous: styles.navBtnPrev,
    button_next: styles.navBtnNext,
    month_grid: styles.monthGrid,
    nav: styles.nav,
    weeks: styles.weeks,
    weekdays: styles.weekdays,
    caption_label: styles.captionLabel,
    day: styles.day,
    dropdown: styles.dropdown,
    dropdown_root: styles.dropdownRoot,
    week_number_header: styles.weekNumberHeader,
    focused: styles.focused,
  };

  const formatters: Partial<Formatters> = {
    formatCaption: (date) => DateTime.fromJSDate(date).toFormat("yyyy年M月"),
  };

  const getDisabledDays = (): Matcher[] | undefined => {
    const modifiers: Matcher[] = [];

    if (isDisabledDay) modifiers.push((date) => isDisabledDay(date));
    if (minSelectableDate) {
      modifiers.push({ before: minSelectableDate });
    }
    if (maxSelectableDate) {
      modifiers.push({ after: maxSelectableDate });
    }

    return modifiers;
  };

  const adjustScrollTop = () => {
    const { current } = ref;
    if (current === null) return;

    const elRangeStart = current.querySelector(
      `.${styles.daySelectedRangeFrom}`
    );
    if (!elRangeStart) return;

    const elRangeStartMonth = elRangeStart.closest(`.${styles.month}`);
    if (!elRangeStartMonth) return;

    const wrapperRect = current.getBoundingClientRect();
    const targetRect = elRangeStartMonth.getBoundingClientRect();

    current.scrollTo({
      left: 0,
      top: targetRect.top - wrapperRect.top,
      behavior: "smooth",
    });
  };

  const getDayModifiers = (): Record<string, Matcher | Matcher[]> => {
    const modifiers: Record<string, Matcher | Matcher[]> = {};

    modifiers[DAY_CUSTOM_MODIFIER_NAME.HOLIDAY] = holidays;

    modifiers[DAY_CUSTOM_MODIFIER_NAME.SELECTABLE] = [
      {
        from: minDate,
        to: maxDate,
      },
      (date) => (isDisabledDay ? !isDisabledDay(date) : true),
    ];

    if (from) {
      modifiers[DAY_CUSTOM_MODIFIER_NAME.SELECTED_RANGE_FROM] = from;

      if (to) {
        modifiers[DAY_CUSTOM_MODIFIER_NAME.SELECTED_RANGE_TO] = to;
        modifiers[DAY_CUSTOM_MODIFIER_NAME.SELECTED_RANGE] = {
          from: from,
          to: to,
        };
      } else {
        modifiers[DAY_CUSTOM_MODIFIER_NAME.RECOMMENDED_RANGE_TO] = {
          after: from,
          before: maxDate,
        };
      }
    }

    if (dateColors?.length) {
      modifiers[DAY_CUSTOM_MODIFIER_NAME.CUSTOM_COLOR] = dateColors.map(
        (v) => v.date
      );
    }

    if (isScrollable) {
      modifiers[DAY_CUSTOM_MODIFIER_NAME.NONE] = {
        after: DateTime.fromJSDate(maxDate)
          .endOf("week")
          .plus({ day: -1 })
          .toJSDate(),
        before: DateTime.fromJSDate(minDate)
          .startOf("week")
          .plus({ day: -1 })
          .toJSDate(),
      };
    }

    modifiers[DAY_CUSTOM_MODIFIER_NAME.PAST] = {
      before: minDate,
    };
    return modifiers;
  };

  const getModifiersStyles = () => {
    const modifiers: ModifiersStyles = {
      [DAY_CUSTOM_MODIFIER_NAME.CUSTOM_COLOR]: {},
    };
    return modifiers;
  };

  const onSelect: OnSelectHandler<DateRange | undefined> = (
    selected: DateRange | undefined
  ) => {
    if (onChange) {
      // rangeにもかかわらず、未選択状態でdayをクリックすると from だけ選択されるはずが、from と to の両方に同じ日付がセットされる
      // そのため、from と to が未選択状態、かつ、クリック時に from と to の日付が同じの場合はfromだけ選択したとみなす
      if (!from && !to && selected?.from === selected?.to) {
        onChange(selected?.from);
      } else {
        onChange(selected?.from, selected?.to);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (isScrollable) {
        adjustScrollTop();
      }
    }, 200);
  }, [isScrollable]);

  const dayPickerProps: DayPickerProps = {
    locale: ja,
    mode: "range",
    className: styles.root,
    classNames,
    formatters,
    disabled: getDisabledDays(),
    modifiers: getDayModifiers(),
    modifiersStyles: getModifiersStyles(),
    modifiersClassNames: MODIFIERS_CLASS_NAMES,
    startMonth: minDate,
    endMonth: maxDate,
    selected: { from, to },
    components,
    onMonthChange,
    onSelect,
  };
  if (isScrollable) {
    dayPickerProps.numberOfMonths = 14;
    // numberOfMonths の最大値は startMonth と endMonth の差分値となる
    // そのため、endMonthの次の月まで表示させる場合は、endMonth も一緒に延ばさなければならない
    dayPickerProps.endMonth = DateTime.fromJSDate(maxDate)
      .endOf("week")
      .plus({ day: 1 })
      .toJSDate();
    // scrollableの時はUI上でmonthの変更は無いため、minDateでmonthを固定する。
    // monthを指定しないと、dayPicker側で `selected.from` の値になり、`selected.from` より前の日付が表示されなくなる
    dayPickerProps.month = minDate;
    dayPickerProps.hideNavigation = true;
  } else {
    dayPickerProps.defaultMonth = from || initialMonth || minDate;
    dayPickerProps.numberOfMonths = 2;
    // maxDateが月末でない場合、表示が奇数になってしまう（最後のページが単月表示になる）
    // （例）
    // ・現在日が9/30の場合、maxDateが翌年9/29で、13ヶ月分の表示になる
    // ・現在日が10/1の場合、maxDateが翌年9/30で、12ヶ月分の表示になる
    // ・現在日が10/2の場合、maxDateが翌年10/1で、13ヶ月分の表示になる
    // そのため、月末でない場合は、endMonth に maxDate + 1ヶ月 したものを設定する
    const nextDayOfMaxDate = DateTime.fromJSDate(maxDate)
      .plus({ day: 1 })
      .toJSDate()
      .getDate();
    dayPickerProps.endMonth =
      nextDayOfMaxDate !== 1
        ? DateTime.fromJSDate(maxDate).plus({ month: 1 }).toJSDate()
        : maxDate;
    dayPickerProps.pagedNavigation = true;
  }

  return (
    <div
      ref={ref}
      onClick={(e) => e.stopPropagation()}
      className={[styles.wrapper, isScrollable ? styles.scrollable : ""].join(
        " "
      )}
    >
      <DayPicker {...dayPickerProps} />
    </div>
  );
};
