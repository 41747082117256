import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconFamilyRoom: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        fill="#1F1F1F"
        d="M24.497 7.765a2.342 2.342 0 01-1.716-.717 2.342 2.342 0 01-.717-1.716c0-.667.239-1.24.717-1.717a2.342 2.342 0 011.716-.717c.667 0 1.24.24 1.717.717.478.478.717 1.05.717 1.717 0 .666-.24 1.239-.717 1.716a2.342 2.342 0 01-1.717.717zm-2 21.567v-11.2c0-.667-.166-1.267-.5-1.8-.333-.534-.822-.923-1.466-1.167l1.366-4c.178-.555.506-1 .984-1.333a2.759 2.759 0 011.616-.5c.6 0 1.14.166 1.617.5.478.333.806.777.983 1.333l3.4 9.833h-3.666v8.334h-4.334zm-5.5-14a1.929 1.929 0 01-1.416-.584 1.929 1.929 0 01-.584-1.416c0-.556.195-1.028.584-1.417a1.929 1.929 0 011.416-.583c.556 0 1.028.194 1.417.583.389.389.583.861.583 1.417 0 .555-.194 1.027-.583 1.416a1.929 1.929 0 01-1.417.584zM7.831 7.765a2.342 2.342 0 01-1.717-.717 2.342 2.342 0 01-.717-1.716c0-.667.24-1.24.717-1.717a2.342 2.342 0 011.717-.717c.666 0 1.239.24 1.716.717.478.478.717 1.05.717 1.717 0 .666-.239 1.239-.717 1.716a2.342 2.342 0 01-1.716.717zM5.497 29.332v-9.667H3.164v-8.333c0-.556.195-1.028.583-1.417a1.929 1.929 0 011.417-.583h5.333c.556 0 1.028.194 1.417.583.389.389.583.861.583 1.417v8.333h-2.333v9.667H5.497zm9.667 0v-5.667h-1.667v-5.533c0-.4.145-.745.434-1.034a1.41 1.41 0 011.033-.433h4.067c.4 0 .744.145 1.033.433.289.29.433.634.433 1.034v5.533h-1.666v5.667h-3.667z"
      ></path>
    </svg>
  );
};
