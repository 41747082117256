import { forwardRef, useState, ChangeEvent } from "react";
import styles from "./InputDate.module.css";
import { DateTime } from "luxon";
import { Select } from "../Select";

interface InputDateProps {
  name: string;
  minYear?: number;
  value?: string | null;
  disabled?: boolean | undefined;
  onChange?: (value: string) => void;
  error?: boolean;
}

interface DateFields {
  year?: number;
  month?: number;
  day?: number;
}

function getOptions(start: number, end: number) {
  const options = [];

  for (let i = start; i <= end; i++) {
    options.push(<Select.Option key={i}>{i}</Select.Option>);
  }

  return options;
}

export const InputDate = forwardRef<HTMLInputElement, InputDateProps>(
  (props, _ref) => {
    const {
      name,
      onChange: _onChange,
      disabled,
      value: defaultValue,
      minYear,
      error,
    } = props;
    const [value, setValue] = useState<DateFields>(() => {
      if (!defaultValue) {
        return {};
      }
      const date = DateTime.fromISO(defaultValue);
      return {
        year: date.year,
        month: date.month,
        day: date.day,
      };
    });

    const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
      const fieldKey = e.target.name as "year" | "month" | "day";
      const fieldValue = e.target.value;

      const newValue = { ...value };
      newValue[fieldKey] = Number(fieldValue);

      setValue(newValue);

      if (!newValue.day || !newValue.year || !newValue.month) {
        return;
      }

      const newDate = DateTime.fromObject({
        year: newValue.year,
        month: newValue.month,
        day: newValue.day,
      }).toFormat("yyyy-MM-dd");

      _onChange && _onChange(newDate);
    };

    const startYear = minYear ?? new Date().getFullYear() - 100;
    const thisYear = new Date().getFullYear();

    return (
      <div className={styles.root} data-testid={name}>
        <Select
          name="year"
          placeholder="YYYY"
          disabled={disabled}
          onChange={onChange}
          defaultValue={value.year}
          error={error}
        >
          {getOptions(startYear, thisYear + 30)}
        </Select>
        <div className={styles.label}>年</div>
        <Select
          name="month"
          placeholder="MM"
          disabled={disabled}
          onChange={onChange}
          defaultValue={value.month}
          error={error}
        >
          {getOptions(1, 12)}
        </Select>
        <div className={styles.label}>月</div>
        <Select
          name="day"
          placeholder="DD"
          disabled={disabled}
          onChange={onChange}
          defaultValue={value.day}
          error={error}
        >
          {getOptions(1, 31)}
        </Select>
        <div className={styles.label}>日</div>
      </div>
    );
  }
);

InputDate.displayName = "InputDate";
