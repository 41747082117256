import * as yup from "yup";

export const PROFILE_NAME_REGEXP = /^[A-Za-z 　]+$/;

export const lastName = () =>
  yup
    .string()
    .required()
    .matches(PROFILE_NAME_REGEXP, {
      message: "ローマ字で入力してください",
    })
    .label("姓");

export const firstName = () =>
  yup
    .string()
    .required()
    .matches(PROFILE_NAME_REGEXP, {
      message: "ローマ字で入力してください",
    })
    .label("名");

export const getValidation = {
  lastName,
  firstName,
};
