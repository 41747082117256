import { useEffect, useLayoutEffect } from "react";

export function canUseDOM(): boolean {
  return !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  );
}

export const isBrowser = canUseDOM();

export const useSafeLayoutEffect = isBrowser ? useLayoutEffect : useEffect;
