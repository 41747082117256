import { FC } from "react";
import { useBreakPoints } from "../../hooks/useBreakPoints";
import { Stack } from "../Stack";
import { Text } from "../Text";
import styles from "./Steps.module.css";

// Divider
const Divider = () => {
  const breakPoints = useBreakPoints();

  const className = [
    styles.divider,
    breakPoints.mobileOnly ? styles["divider--mobile"] : "",
  ].join(" ");

  return <div className={className} />;
};

export interface StepItem {
  id: string;
  label: string;
}

// StepItem
interface StepItemProps extends StepItem {
  position: number;
  current: boolean;
}

const StepItem: FC<StepItemProps> = ({ position, label, current }) => {
  const breakPoints = useBreakPoints();

  const iconClassName = [
    styles.circle,
    current ? styles["circle--active"] : "",
    breakPoints.mobileOnly ? styles["circle--mobile"] : "",
  ].join(" ");
  return (
    <Stack align="center" direction={{ mobile: "column", tablet: "row" }}>
      <div className={iconClassName}>
        <Text color="white" size={{ mobile: "sm", tablet: "base" }}>
          {position}
        </Text>
      </div>
      <Text
        weight="bold"
        size={{ mobile: "sm", tablet: "lg" }}
        color={current ? "black" : "gray"}
      >
        {label}
      </Text>
    </Stack>
  );
};

// Steps
interface StepsProps {
  items: StepItem[];
  current?: StepItem["id"];
}

export const Steps: FC<StepsProps> = ({ items, current }) => {
  const breakPoints = useBreakPoints();

  const className = [
    styles.root,
    breakPoints.mobileOnly ? styles.centered : "",
  ].join(" ");

  return (
    <div className={className}>
      <Stack
        align={{ mobile: "start", tablet: "center" }}
        spacing={{ mobile: "2x", tablet: "3x" }}
      >
        {items.map((item, index) => {
          const isCurrent = current ? item.id === current : index === 0;
          const position = index + 1;

          const Step = (
            <StepItem
              key={item.id}
              position={position}
              {...item}
              current={isCurrent}
            />
          );

          if (position != items.length)
            return [Step, <Divider key={`divider-${position}`} />];

          return Step;
        })}
      </Stack>
    </div>
  );
};
