import { FlightType } from "@graphql/types";

export const getFlightType = (type: FlightType) => {
  switch (type) {
    case FlightType.DIRECT:
      return "直行便";
    case FlightType.TRANSIT:
      return "乗継便";
    default:
      return "--";
  }
};
