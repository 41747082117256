import { FC, useState } from "react";
import { Icon } from "@newt/ui";
import { DestinationChipSelectCountry } from "./DestinationChipSelectCountry";
import { hasSelectedChip } from "./utils";
import { AreaDestination } from "./types";
import styles from "./DestinationChipSelectAreaToggle.module.css";

interface DestinationChipSelectAreaToggleProps {
  selectedIds?: number[];
  onSelected: (ids?: number[]) => void;
  area: AreaDestination;
}

export const DestinationChipSelectAreaToggle: FC<
  DestinationChipSelectAreaToggleProps
> = (props) => {
  const { area, selectedIds, onSelected } = props;
  const [isOpen, setIsOpen] = useState(
    hasSelectedChip(area.countries, selectedIds)
  );

  const onAreaToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className={styles.areaLabel} onClick={onAreaToggle}>
        {area.name}
        <Icon icon={isOpen ? "chevronUp" : "chevronDown"} color="gray-80" />
      </div>
      <ul className={isOpen ? styles.open : styles.close}>
        {area.countries.map((country) => (
          <li key={country.id} className={styles.countryRoot}>
            <DestinationChipSelectCountry
              selectedIds={selectedIds}
              onSelected={onSelected}
              country={country}
            />
          </li>
        ))}
      </ul>
    </>
  );
};
