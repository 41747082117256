import { CreateForm, FieldErrors, GraphQLErrorFormat } from "@utils/form";
import { gql } from "graphql-request";
import * as yup from "yup";
import { useToast } from "@newt/ui";
import { useRouter } from "next/router";
import { firebase } from "@utils/firebase";
import { useCreateSimpleUser } from "./useCreateSimpleUserMutation";
import { MarketingService } from "@utils/marketing";

export const USE_REGISTRATION_FORM_CREATE_USER_MUTATION = gql`
  mutation UseRegistrationForm_CreateSimpleUser(
    $input: CreateSimpleUserInput!
  ) {
    CreateSimpleUser(input: $input) {
      user {
        id
      }
    }
  }
`;

interface RegistrationForm {
  email: string;
  rejectNewsletter?: boolean;
}

export const useRegistrationForm = () => {
  const router = useRouter();
  const toast = useToast();

  const { createSimpleUserMutation } = useCreateSimpleUser();

  const onSubmit = async (input: RegistrationForm) => {
    try {
      await createSimpleUserMutation({
        email: input.email,
        newsletter: !input.rejectNewsletter,
      });
      MarketingService.logEvent.setMailNotification({
        is_mail_notification_subscribed: !input.rejectNewsletter,
        module_type: "via_signin",
      });
      toast.success("会員登録が完了しました！");
    } catch (e) {
      const typedError = e as GraphQLErrorFormat;
      const error = typedError?.response?.errors?.[0];
      if (error?.type === "logout_required") {
        firebase.auth.signOut();
        router.back(); // TODO: /signin/phone へ直接アクセスしてきた場合への対応
      } else if (error?.fields) {
        throw new FieldErrors(error?.fields);
      }
    }
  };

  return CreateForm<RegistrationForm>({
    rules: {
      email: yup
        .string()
        .email()
        .required()
        .lowercase()
        .label("メールアドレス"),
      rejectNewsletter: yup.boolean(),
    },
    onSubmit,
  });
};
