import { ReactNode } from "react";
import { Text } from "../Text";
import styles from "./TabNavigation.module.css";

export type TabItem<T = string> = {
  id: T;
  label: ReactNode;
};

interface TabNavigationProps<T = string> {
  currentTabId: T;
  tabItems: TabItem<T>[];
  onClick: (tabId: T) => void;
}

export function TabNavigation<T>({
  currentTabId,
  tabItems,
  onClick,
}: TabNavigationProps<T>) {
  return (
    <section className={styles.root}>
      <ul className={styles.list}>
        {tabItems.map((tabItem) => {
          const isActive = tabItem.id === currentTabId;
          return (
            <li
              key={tabItem.id as string}
              onClick={() => onClick(tabItem.id)}
              className={[styles.listItem, isActive ? styles.active : ""].join(
                " "
              )}
            >
              <Text
                weight="bold"
                align="center"
                size="base"
                color={isActive ? "black" : "gray"}
              >
                {tabItem.label}
              </Text>
            </li>
          );
        })}
      </ul>
    </section>
  );
}
