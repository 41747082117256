import { SearchFormValues } from "@components/common/SearchBox/hooks/useSearchForm";
import {
  AirportCardFragment,
  DestinationStatus,
  SearchBoxArea_DestinationFragment,
  TravelDateFlexibility,
  SearchBoxDisplayDestination_DestinationFragment,
  destinationsQuery,
} from "@graphql/types";
import { DropdownSelectOptionGroup } from "@newt/ui";
import { DateTime } from "luxon";
import { SearchDataset } from "./hooks/useSearchData";
import { AreaDestination } from "./components/DestinationChipSelect/types";

export const toDisplayPeople = (values: SearchFormValues, short = false) => {
  const { rooms, adults, children_without_bed, children, infants } = values;

  const roomsText = `${rooms}室`;

  if (short) {
    const totalPeople = adults + children_without_bed + children + infants;
    const peoplesText = `${totalPeople}名`;
    return [roomsText, peoplesText].filter((v) => v !== "").join(", ");
  }

  const peoplesText = [
    { label: "おとな", count: adults },
    { label: "子ども", count: children + children_without_bed + infants },
  ]
    .filter((v) => v.count > 0)
    .map((v) => `${v.label} ${v.count}名`)
    .join(" + ");

  return [roomsText, peoplesText].filter((v) => v !== "").join(" ｜ ");
};

type ToDisplayCalendar = {
  from: string;
  to: string;
  withDuration?: boolean;
  travelDateFlexibility?: TravelDateFlexibility;
};

const TRAVEL_DATE_FLEXIBILITY_LABELS = {
  [TravelDateFlexibility.FIXED]: "",
  [TravelDateFlexibility.PLUS_MINUS_1DAY]: "±1日",
  [TravelDateFlexibility.PLUS_MINUS_2DAY]: "±2日",
  [TravelDateFlexibility.PLUS_MINUS_3DAY]: "±3日",
  [TravelDateFlexibility.PLUS_MINUS_7DAY]: "±7日",
} as { [key in TravelDateFlexibility]: string };

export const toDisplayCalendar = ({
  from,
  to,
  withDuration,
  travelDateFlexibility,
}: ToDisplayCalendar) => {
  if (!from && !to) return "日付をえらぶ";

  const now = DateTime.now();
  const fromDate = DateTime.fromISO(from).setLocale("ja");
  const toDate = DateTime.fromISO(to).setLocale("ja");

  const format = (dateTime: DateTime) => {
    return now.year !== dateTime.year
      ? dateTime.toFormat("yyyy/M/d")
      : dateTime.toFormat("M/d (EEE)");
  };

  let fromTo = [fromDate, toDate]
    .filter((v) => v.isValid)
    .map((v) => format(v))
    .join(" - ");
  const diff = toDate.diff(fromDate, "days");

  if (
    travelDateFlexibility &&
    travelDateFlexibility !== TravelDateFlexibility.FIXED
  ) {
    fromTo = `${fromTo} ${TRAVEL_DATE_FLEXIBILITY_LABELS[travelDateFlexibility]}`;
  }

  return withDuration && diff.isValid
    ? `${fromTo} ${diff.get("days") + 1}日間`
    : fromTo;
};

export const toDisplayCalendarBottom = ({
  from,
  to,
  ...restArgs
}: ToDisplayCalendar) => {
  if (from && !to) {
    return "日本に到着する日をえらんでください🛬";
  }
  if (!from && !to) return "日本を出発する日をえらんでください🛫";

  return toDisplayCalendar({ from, to, ...restArgs });
};

export const toDestinationSelectOptionGroups = (
  destinations: SearchDataset<SearchBoxArea_DestinationFragment>
): AreaDestination[] => {
  const areaOptions = destinations.destinations?.map((v) => {
    const countries = v.children
      ?.filter(
        (country) =>
          country.status === DestinationStatus.PUBLIC &&
          country.children?.length > 0 &&
          country.children.some(
            (city) => city.status === DestinationStatus.PUBLIC
          )
      )
      .map((country) => {
        const cities = country.children
          ?.filter((city) => city.status === DestinationStatus.PUBLIC)
          .map((city) => ({
            name: city.name,
            id: city.id,
          }));

        return {
          name: country.name,
          id: country.id,
          cities,
        };
      });

    return {
      id: v.id,
      name: v.name,
      countries,
      disabled: false,
    };
  });

  return areaOptions || [];
};

export const toAirportSelectOptionGroups = (
  airports: SearchDataset<AirportCardFragment>
): DropdownSelectOptionGroup[] => {
  const options = airports.public?.map((v) => ({
    label: v.name,
    value: v.id.toString(),
  }));

  const comingSoonOptions = airports.comingSoon?.map((v) => ({
    label: v.name,
    value: v.id.toString(),
    disabled: true,
  }));

  const result: DropdownSelectOptionGroup[] = [
    {
      label: "出発地",
      options: options || [],
    },
  ];

  if (comingSoonOptions && comingSoonOptions.length > 0) {
    result.push({
      label: "Coming soon",
      options: comingSoonOptions,
    });
  }

  return result;
};

export const getDisplayDestinations = (
  destinations?: SearchBoxDisplayDestination_DestinationFragment[]
) => {
  if (!destinations || destinations.length === 0) return "目的地をえらぶ";
  return destinations.map((v) => v.name).join("、 ");
};

export const getListTitle = (
  destinations?: SearchBoxDisplayDestination_DestinationFragment[]
) => {
  if (!destinations || destinations.length === 0) return "検索結果";

  if (destinations.length === 1) {
    return `${destinations[0].displayName}の海外旅行・ツアー`;
  }

  return `${destinations[0].name}(他${destinations.length - 1}エリア)の海外旅行・ツアー`;
};

export const getStaticTLPathFromDestinations = (
  destinations:
    | destinationsQuery["destinations"]
    | SearchDataset<SearchBoxArea_DestinationFragment>,
  watchDestinationId: number
): string => {
  const edges =
    "edges" in destinations ? destinations.edges : destinations.destinations;

  if (edges) {
    for (const edge of edges) {
      const countries = "node" in edge ? edge.node.countries : edge.children;
      for (const country of countries) {
        if (country.id === watchDestinationId) {
          return `/tour/${country.code.toLowerCase()}`;
        }
        const cities = "cities" in country ? country.cities : country.children;
        for (const city of cities) {
          if (city.id === watchDestinationId) {
            return `/tour/${country.code.toLowerCase()}/${city.code.toLowerCase()}`;
          }
        }
      }
    }
  }
  return "";
};

export const canNavigateToStl = (watched: SearchFormValues) => {
  const isDefault =
    watched.airportId === "9" &&
    !watched.from &&
    !watched.to &&
    watched.rooms === 1 &&
    watched.adults === 2 &&
    watched.children === 0 &&
    watched.children_without_bed === 0 &&
    watched.infants === 0;

  return watched.destinationIds.length === 1 && isDefault;
};
