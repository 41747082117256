import { FC } from "react";
import Image from "next/image";
import { Paragraph, Spacer } from "@newt/ui";
import Beach from "./assets/beach.webp";
import styles from "./TravelConcierge.module.css";
import { OpenSupportChatButton } from "../OpenSupportChatButton";
import { SupportChatOpenFromValue } from "@utils/chat/context/SupportChatContext";

interface Props {
  openFrom: SupportChatOpenFromValue;
}

export const TravelConcierge: FC<Props> = ({ openFrom }) => {
  return (
    <>
      <div className={styles.flex}>
        <div>
          <Paragraph size={{ tablet: "lg" }}>
            海外旅行のことなら、どんなことでも！NEWTトラベルコンシェルジュにご相談ください🤝
          </Paragraph>
          <ul className={styles.list}>
            <li>
              <span>自分に合ったホテルを提案してほしい</span>
            </li>
            <li>
              <span>さまざまなエリアを周遊したい</span>
            </li>
            <li>
              <span>ハネムーンプランがあるか確認したい&nbsp;など</span>
            </li>
          </ul>
        </div>
        <div className={styles.imageWrapper}>
          <Image
            className={styles.image}
            src={Beach.src}
            alt="NEWTトラベルコンシェルジュに相談する"
            loading="lazy"
            fill={true}
            style={{ objectFit: "cover" }}
          />
        </div>
      </div>
      <Spacer y={{ mobile: "6x", tablet: "10x" }} />
      <div className={styles.buttonWrapper}>
        <OpenSupportChatButton openFrom={openFrom} />
      </div>
    </>
  );
};
