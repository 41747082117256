import Image from "next/image";
import styles from "./FollowUs.module.css";
import IconLine from "./assets/icon_line.webp";
import IconTiktok from "./assets/icon_tiktok.png";
import IconX from "./assets/icon_X.webp";
import IconYoutube from "./assets/icon_youtube.png";
import IconInstagram from "./assets/icon_instagram.png";
import {
  Container,
  INSTAGRAM_LINK,
  LINE_LINK,
  Text,
  TIKTOK_LINK,
  TWITTER_LINK,
  YOUTUBE_LINK,
} from "@newt/ui";
import { Stack } from "@newt/ui/src/components/Stack";

const LINKS = [
  { name: "LINE", href: LINE_LINK, imageSrc: IconLine.src },
  { name: "X", href: TWITTER_LINK, imageSrc: IconX.src },
  { name: "Instagram", href: INSTAGRAM_LINK, imageSrc: IconInstagram.src },
  { name: "TikTok", href: TIKTOK_LINK, imageSrc: IconTiktok.src },
  { name: "YouTube", href: YOUTUBE_LINK, imageSrc: IconYoutube.src },
];

export const FollowUs = () => {
  return (
    <section className={styles.root}>
      <Container>
        <div className={styles.inner}>
          <header className={styles.header}>
            <Text
              as="h2"
              weight={"bold"}
              size={{ mobile: "2xl", tablet: "3xl" }}
              align={{ mobile: "center", tablet: "left" }}
            >
              Follow Us
            </Text>
            <div className={styles.description}>
              <Text size={{ mobile: "base", tablet: "lg" }} spacing="widest">
                最新の渡航ニュースやおすすめスポットなど、
                <br />
                海外旅行の情報をお届けします✈️
              </Text>
            </div>
          </header>
          <div className={styles.main}>
            <Stack direction="row" spacing={{ mobile: "6x", tablet: "8x" }}>
              {LINKS.map((link) => (
                <a
                  href={link.href}
                  className={styles.link}
                  target="_blank"
                  rel="noreferrer"
                  key={link.name}
                >
                  <Image
                    src={link.imageSrc}
                    alt={link.name}
                    height={40}
                    width={40}
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                </a>
              ))}
            </Stack>
          </div>
        </div>
      </Container>
    </section>
  );
};
