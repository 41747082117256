import { gtm } from "../gtm";

interface Item {
  // ツアーID
  item_id: string;
  // ツアー名
  item_name: string;
  // 通貨価格(大人一人あたり旅行代金)
  price?: number;
  // リスト内の商品アイテムのインデックスまたは位置
  index?: number;
  // 定常/セール
  item_brand: "regular" | "sale";
  // 商品カテゴリー
  item_category: "tour";
  // 目的地(目的地名)
  item_category2: string;
  // 出発地(出発空港名)
  item_category3: string;
  // カスタマーが入力した人数(大人子供乳幼児含めた合計数)
  item_category4: string;
  // カスタマーが入力した開始日(出発日) ※ Unix Timestamp
  item_category5?: string;
  // カスタマーが入力した日数(旅行日数)
  item_variant?: string;
  item_list_id?: string;
  item_list_name?: string;
}

export interface BeginCheckoutEventVariables {
  // ツアーID
  item_id: string;
  // ツアー名
  item_name: string;
  // 商品の表示金額
  price: string;
  // 商品セールタイプ
  sales_type: "regular" | "sale";
  // 商品カテゴリー
  item_category: "tour";
  // 出発空港
  departure: string;
  // 目的地
  destination: string;
  // 出発日(Unix Timestamp)
  departure_date: string;
  // 旅行日数
  travel_days: string;
  // 部屋数
  rooms: string;
  // 大人人数
  adults: string;
  // 子ども（ベッドあり）人数
  children_with_beds: string;
  // 子ども（ベッドなし）人数
  children_without_beds: string;
  // 乳幼児人数
  infants: string;

  // Eコマース
  ecommerce: {
    // イベントに関連づけられた商品アイテムの通貨（ISO 4217）
    currency?: string;
    // 合計金額（諸税や比嘉料金を含めた合計額）
    value?: number;

    items: Item[];
  };
}

export const beginCheckoutEvent = (variables?: BeginCheckoutEventVariables) => {
  gtm.pushEvent("begin_checkout", variables);
};
