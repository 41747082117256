import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconCopy: FC<IconTypeProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.53409 14.1467C3.21699 14.1467 2.94454 14.0328 2.71672 13.805C2.48891 13.5772 2.375 13.3047 2.375 12.9877V4.30189H3.53409V12.9877H10.3464V14.1467H3.53409ZM5.55663 12.1161C5.24179 12.1161 4.97127 12.003 4.74507 11.7768C4.51886 11.5506 4.40576 11.2801 4.40576 10.9653V2.97804C4.40576 2.66097 4.51886 2.38853 4.74507 2.16073C4.97127 1.93293 5.24179 1.81903 5.55663 1.81903H11.8011C12.1182 1.81903 12.3906 1.93293 12.6184 2.16073C12.8462 2.38853 12.9602 2.66097 12.9602 2.97804V10.9653C12.9602 11.2801 12.8462 11.5506 12.6184 11.7768C12.3906 12.003 12.1182 12.1161 11.8011 12.1161H5.55663ZM5.55663 10.9653H11.8011V2.97804H5.55663V10.9653Z"
        fill="black"
      />
    </svg>
  );
};
