import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconAirport: FC<IconTypeProps> = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5414_289972)">
        <path
          d="M28.9754 35.0694L31.3324 32.7124L27.2076 16.8025L33.6894 10.3206C34.6676 9.34248 34.6676 7.76328 33.6894 6.78511C32.7112 5.80695 31.132 5.80695 30.1539 6.78511L23.6721 13.2669L7.76216 9.14214L5.40514 11.4992L18.3688 18.5702L11.887 25.052L7.17291 23.8735L5.40514 25.6413L11.2977 29.1768L14.8332 35.0694L16.601 33.3016L15.4225 28.5876L21.9043 22.1058L28.9754 35.0694Z"
          fill="#1F1F1F"
        />
      </g>
      <defs>
        <clipPath id="clip0_5414_289972">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
