import { useEffect } from "react";
import Router from "next/router";
import { disableBodyScroll, enableBodyScroll } from "../utils/element";

interface UseBodyScrollArgs {
  allowScroll?: boolean;
}

export const useBodyScroll = (args?: UseBodyScrollArgs) => {
  const { allowScroll = false } = args || {};

  useEffect(() => {
    if (allowScroll) return;
    disableBodyScroll();

    const handleRouteChangeStart = () => {
      enableBodyScroll();
    };

    Router.events.on("routeChangeStart", handleRouteChangeStart);
    window.addEventListener("beforeunload", handleRouteChangeStart);

    return () => {
      enableBodyScroll();

      Router.events.off("routeChangeStart", handleRouteChangeStart);
      window.removeEventListener("beforeunload", handleRouteChangeStart);
    };
  }, []);
};
