import { Icon } from "../Icon";
import { Text } from "../Text";
import { FC } from "react";
import { DEFAULT_IMAGE } from "../../constants";
import { Tag } from "../Tag";
import styles from "./ThemeCard.module.css";

interface DestinationCardProps {
  href: string;
  variant?: "default" | "disabled";
  color: string;
  title: string;
  imageUrl: string;
}

export const ThemeCard: FC<DestinationCardProps> = ({
  href,
  variant = "default",
  color,
  title,
  imageUrl,
}) => {
  const rootClasses = [styles.root, styles[`variant--${variant}`]].join(" ");
  const isDisabled = variant === "disabled";

  return (
    <a
      href={isDisabled ? undefined : href}
      className={rootClasses}
      style={{ color, fill: color }}
    >
      <div className={styles.title}>
        <Text
          size={{ mobile: "base", laptop: "3xl" }}
          color="inherit"
          weight="bold"
          as="h3"
        >
          {title}
        </Text>
        {!isDisabled && (
          <div className={styles.titleIcon}>
            <Icon icon="chevronRight" />
          </div>
        )}
      </div>
      <figure className={styles.figure}>
        <img
          className={styles.img}
          src={imageUrl || DEFAULT_IMAGE}
          alt={`${title}の風景`}
        />
        {isDisabled && (
          <div className={styles.tag}>
            <Tag color="disabled">Coming Soon</Tag>
          </div>
        )}
      </figure>
    </a>
  );
};
