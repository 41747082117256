import { BannerCardFragment } from "@graphql/types";
import {
  CarouselBanner as CarouselBannerUi,
  CarouselBannerItem,
} from "@newt/ui";
import { FC } from "react";

interface CarouselBannerProps {
  items?: BannerCardFragment[];
  onClick?: (link?: string) => void;
}

export const CarouselBanner: FC<CarouselBannerProps> = ({ items, onClick }) => {
  if (!items || items.length == 0) {
    return null;
  }

  const banners: CarouselBannerItem[] = items.map((item, index) => {
    return {
      thumbnail: item.image?.url,
      caption: item.name,
      href: item.url,
      loading: index < 3 ? "eager" : "lazy",
    };
  });

  return <CarouselBannerUi items={banners} onClick={onClick} />;
};
