import { Breadcrumb, BreadcrumbItem, Footer, Header } from "@components/common";
import { Container, Spacer } from "@newt/ui";
import { FC, ReactNode } from "react";
import { WebviewLayout } from "../WebviewLayout";
import styles from "./BasicLayout.module.css";
import { destinationsQuery } from "@graphql/types";

interface BasicLayoutProps {
  withHero?: boolean;
  children?: ReactNode;
  headerNoFollow?: boolean;
  isWebview?: boolean;
  breadcrumbItems?: BreadcrumbItem[];
  destinations?: destinationsQuery["destinations"];
  hasFooter?: boolean;
}

export const BasicLayout: FC<BasicLayoutProps> = ({
  children,
  headerNoFollow = false,
  isWebview = false,
  breadcrumbItems,
  destinations,
  hasFooter = true,
}) => {
  const rootClasses = [styles.root].join(" ");

  if (isWebview) {
    return (
      <WebviewLayout>
        <Spacer />
        {children}
      </WebviewLayout>
    );
  }

  const BreadcrumbForNotWebview = () => (
    <>
      {breadcrumbItems && !isWebview && (
        <Container>
          <Breadcrumb items={breadcrumbItems} />
        </Container>
      )}
    </>
  );

  return (
    <div className={rootClasses}>
      {isWebview === false && <Header noFollow={headerNoFollow} />}
      <div className={"laptopWithUp"}>
        <BreadcrumbForNotWebview />
      </div>
      <Spacer y="8x" />
      <div className={styles.inner}>{children}</div>
      <Spacer y={"10x"} />
      <div className={"tabletWithDown"}>
        <BreadcrumbForNotWebview />
      </div>
      {hasFooter && <Footer destinations={destinations} />}
    </div>
  );
};
