import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconWarning: FC<IconTypeProps> = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33325 27.9998H30.6666L15.9999 2.6665L1.33325 27.9998ZM17.3333 23.9998H14.6666V21.3332H17.3333V23.9998ZM17.3333 18.6665H14.6666V13.3332H17.3333V18.6665Z"
        fill="#F53649"
      />
    </svg>
  );
};
