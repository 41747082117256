import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "@newt/ui";
import { getLocale } from "@utils/yup";
import { useState } from "react";
import {
  DefaultValues,
  FieldValues,
  Mode,
  Path,
  useForm,
  Resolver,
} from "react-hook-form";
import * as yup from "yup";
import { stripNulls } from ".";
import { setFieldErrors } from "./setFieldErrors";

export interface CreateFormOptions<T> {
  rules: Record<keyof T, yup.AnySchema>;
  defaultValues?: DefaultValues<T>;
  onSubmit: (data: T) => Promise<void> | unknown;
  validateMode?: Mode;
}

yup.setLocale(getLocale("ja"));

export const CreateForm = <T extends FieldValues>({
  rules,
  defaultValues,
  onSubmit,
  validateMode = "onChange",
}: CreateFormOptions<T>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<
    boolean | undefined
  >(undefined);
  const [errorCount, setErrorCount] = useState(0);
  const schema = yup.object().shape(rules);
  const toast = useToast();

  const {
    register,
    handleSubmit,
    setError,
    watch,
    control,
    getValues,
    setValue,
    formState: { errors, isValid, isDirty },
    reset,
    resetField,
    trigger,
  } = useForm({
    defaultValues: stripNulls(defaultValues) as DefaultValues<T>,
    // https://github.com/react-hook-form/resolvers/issues/648#issuecomment-2050487345
    resolver: yupResolver(schema) as unknown as Resolver<T>,
    mode: validateMode,
  });

  const fieldRegister = (
    name: Path<T>,
    options?: Partial<{ onChange: () => void }>
  ) => {
    return {
      ...register(name, options),
      error: name in errors,
      disabled: isSubmitting,
    };
  };

  const onFormSubmit = handleSubmit((data) => {
    setIsSubmitting(true);

    Promise.resolve(onSubmit(data as T))
      .then(() => setIsSubmitSuccessful(true))
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        setErrorCount(errorCount + 1);
        const errorMessage = setFieldErrors<T>(e, setError);
        if (errorMessage) {
          toast.error(errorMessage);
        }
      })
      .finally(() => setIsSubmitting(false));
  });

  return {
    register: fieldRegister,
    errors,
    watch,
    errorCount,
    isSubmitting,
    onFormSubmit,
    onSubmit,
    control,
    setValue,
    getValues,
    isSubmitSuccessful,
    formState: { isDirty, isValid },
    isValid: !isDirty || !isValid, // MEMO: latent bug, should be !isDirty || isValid
    reset,
    resetField,
    trigger,
    setError,
  };
};
