import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconLightning: FC<IconTypeProps> = () => {
  return (
    <svg
      width="8"
      height="15"
      viewBox="0 0 8 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.664062 0.333374V8.03337H2.86406V14.3334L7.9974 5.93337H5.06406L7.26406 0.333374H0.664062Z"
        fill="#FFEB3B"
      />
    </svg>
  );
};
