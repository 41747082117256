import { FC, forwardRef, ImgHTMLAttributes, ReactNode } from "react";
import { useBreakPoints } from "../../hooks/useBreakPoints";
import { Button } from "../Button";
import { Icon, IconType } from "../Icon";
import { Rating } from "../Rating";
import { Stack } from "../Stack";
import { Tag } from "../Tag";
import { Text } from "../Text";
import { WishlistButton } from "../WishlistButton";
import { TagColored } from "../TagColored";
import styles from "./TourCard.module.css";
import { TourCardPoint } from "./TourCardPoint";
import { useTooltip } from "./useTooltip";

interface TourCardProps {
  model: TourCardModel;
  inWishlist: boolean;
  isVisiblePointTooltip?: boolean;
  onClickWishlistIcon?: () => void;
  imgLoading?: ImgHTMLAttributes<HTMLImageElement>["loading"];
  isFlexibleTravelDates?: boolean;
}

interface Theme {
  name: string;
  color: string;
  textColor?: string;
  borderColor?: string;
  icon?: { url: string } | null;
}

export interface TourCardModel {
  imageUrl: string | null;
  title: string;
  priceCaption: string;
  price: string;
  point: string | null;
  pointRate?: number;
  themes: Theme[];
  location: string;
  hotel: string;
  hotelCount: number;
  hotelRating: number;
  flightType: string;
  airOriginTime: string;
  airDestinationTime: string;
  href: string;
  onSale: boolean;
  isHighlighted?: boolean;
  isMultiDestination: boolean;
  flexibleTravelDates?: string | undefined;
  isFreeCancel?: boolean;
}

interface TourCardItemProps {
  children: ReactNode;
  icon: IconType;
}

export const TourCard = forwardRef<HTMLAnchorElement, TourCardProps>(
  (
    {
      model,
      inWishlist,
      isVisiblePointTooltip,
      onClickWishlistIcon,
      imgLoading,
      isFlexibleTravelDates,
    },
    ref
  ) => {
    const breakPoints = useBreakPoints();
    const { tooltipStatus, closeTooltip } = useTooltip();
    const [destination, duration] = model.location.split("/");

    const Item: FC<TourCardItemProps> = ({ icon, children, ...props }) => (
      <div className={styles.item} {...props}>
        <div className={styles.itemIcon}>
          <Icon icon={icon} size="sm" />
        </div>
        <div className={styles.itemBody}>{children}</div>
      </div>
    );

    return (
      <a
        className={[
          styles.root,
          model.isHighlighted ? styles.highlight : "",
        ].join(" ")}
        href={model.href}
        target="_blank"
        rel="noopener"
        ref={ref}
        data-testid="tourCard-container"
      >
        {model.isHighlighted && (
          <div className={styles.highlightHeader}>
            <Icon icon="doneOutline" color="green-40" size="sm" />
            <Text size="base" weight="bold">
              あなたが気になったツアー👀
            </Text>
          </div>
        )}
        <div className={styles.inner}>
          <figure className={styles.thumbnail}>
            <div className={styles.labels}>
              {model.onSale && (
                <TagColored
                  name="SALE"
                  color="var(--color-red-30)"
                  icon={<Icon icon="lightning" color="yellow-50" size="sm" />}
                />
              )}
              {model.themes.map((theme) => (
                <TagColored key={theme.name} {...theme} />
              ))}
            </div>
            {onClickWishlistIcon && (
              <div className={styles.wishlistButtonWrapper}>
                <WishlistButton
                  variant={inWishlist ? "pressed" : "default"}
                  stroke="white"
                  size="lg"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClickWishlistIcon();
                  }}
                />
              </div>
            )}
            <div className={styles.thumbnailInner}>
              {model.imageUrl && (
                <img
                  className={styles.mainImage}
                  src={model.imageUrl}
                  alt={model.title}
                  loading={imgLoading}
                  data-testid="tourCard-mainImage"
                />
              )}
              <div className={styles.imageCover}></div>
            </div>
            <div className={styles.stayInfo}>
              <Icon icon="location" size="sm" color="white" />
              <p>{destination}</p>
              <p>
                <span>/</span>
                {duration}
              </p>
            </div>
          </figure>
          <div className={styles.detail}>
            <div className={styles.main}>
              <header className={styles.header}>
                <p className={styles.title} data-testid="tourCard-tourTitle">
                  {model.title}
                </p>
              </header>
              <ul className={styles.items}>
                <li>
                  <Item icon="hotel">
                    <div className={styles.hotel}>
                      <div
                        className={styles.hotelRating}
                        data-testid="tourCard-hotelGrade"
                      >
                        <Rating value={model.hotelRating} />
                      </div>
                      <div className={styles.hotelNameWrap}>
                        <div
                          className={styles.hotelName}
                          data-testid="tourCard-hotelName"
                        >
                          <Text size="sm">{model.hotel}</Text>
                        </div>
                        {model.hotelCount > 1 && <Text size="sm">/ 他</Text>}
                      </div>
                    </div>
                  </Item>
                </li>
                <li>
                  <Item icon="airport">
                    <Stack align="center" spacing="2x">
                      <Tag
                        color="disabled"
                        variant="outline"
                        size="sm"
                        data-testid="tourCard-flightType"
                      >
                        {model.isMultiDestination
                          ? "周遊プラン"
                          : model.flightType}
                      </Tag>
                      <Stack align="center" spacing="4x">
                        <Stack align="center" spacing="2x">
                          <Tag color="disabled" size="sm">
                            行き
                          </Tag>
                          <Text
                            size="sm"
                            data-testid="tourCard-flightOriginTime"
                          >
                            {model.airOriginTime}
                          </Text>
                        </Stack>
                        <Stack align="center" spacing="2x">
                          <Tag color="disabled" size="sm">
                            帰り
                          </Tag>
                          <Text
                            size="sm"
                            data-testid="tourCard-flightDestinationTime"
                          >
                            {model.airDestinationTime}
                          </Text>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Item>
                </li>
                {model.isFreeCancel && (
                  <li
                    className={`${styles.freeCancel} ${styles.freeCancelForPC}`}
                  >
                    <Icon icon="check" color="primary" size="sm" />
                    キャンセル無料
                  </li>
                )}
              </ul>
            </div>
            <div className={styles.footer}>
              {isFlexibleTravelDates ? (
                <div
                  className={styles.flexibleSearchInfo}
                  data-testid="tourCard-priceCaption"
                >
                  <p>{model.priceCaption}</p>
                  {model.flexibleTravelDates && (
                    <p className={styles.flexibleTravelDates}>
                      {model.flexibleTravelDates}
                    </p>
                  )}
                </div>
              ) : (
                <p
                  className={`${styles.searchInfo} tabletWithUp`}
                  data-testid="tourCard-priceCaption"
                >
                  {model.priceCaption}
                </p>
              )}
              <div className={styles.footerBottom}>
                <div className={styles.priceInfo}>
                  <div className={styles.priceLabel}>
                    {!isFlexibleTravelDates && (
                      <p
                        className={`${styles.searchInfo} mobileOnly`}
                        data-testid="tourCard-priceCaption"
                      >
                        {model.priceCaption}
                      </p>
                    )}
                    <p className={styles.price} data-testid="tourCard-price">
                      {model.price}
                    </p>
                  </div>
                  <div
                    className={`${styles.bottomSection} ${model.isFreeCancel ? "" : styles.isNotFreeCancel}`}
                  >
                    {model.isFreeCancel && (
                      <p
                        className={`${styles.freeCancel} ${styles.freeCancelForSP}`}
                      >
                        <Icon icon="check" color="primary" size="sm" />
                        キャンセル無料
                      </p>
                    )}
                    {model.point && (
                      <TourCardPoint
                        point={model.point}
                        pointRate={model.pointRate}
                        isVisible={isVisiblePointTooltip}
                        tooltipStatus={tooltipStatus}
                        onClose={closeTooltip}
                      />
                    )}
                  </div>
                </div>
                {!breakPoints.mobileOnly && (
                  <div className={styles.action}>
                    <Button
                      data-testid="tourCard-button"
                      size="lg"
                      width="full"
                    >
                      ツアーをみる
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </a>
    );
  }
);

TourCard.displayName = "TourCard";
