import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconCar: FC<IconTypeProps> = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5415_293771)">
        <path
          d="M28.3337 8.33331H5.00033C3.16699 8.33331 1.66699 9.81665 1.66699 11.6666V26.6666H5.00033C5.00033 29.4166 7.23366 31.6666 10.0003 31.6666C12.767 31.6666 15.0003 29.4166 15.0003 26.6666H24.167C24.167 29.4166 26.4003 31.6666 29.167 31.6666C31.9337 31.6666 34.167 29.4166 34.167 26.6666H38.3337V18.3333L28.3337 8.33331ZM5.00033 18.3333V11.6666H11.667V18.3333H5.00033ZM10.0003 29.1666C8.61699 29.1666 7.50033 28.05 7.50033 26.6666C7.50033 25.2833 8.61699 24.1666 10.0003 24.1666C11.3837 24.1666 12.5003 25.2833 12.5003 26.6666C12.5003 28.05 11.3837 29.1666 10.0003 29.1666ZM21.667 18.3333H15.0003V11.6666H21.667V18.3333ZM29.167 29.1666C27.7837 29.1666 26.667 28.05 26.667 26.6666C26.667 25.2833 27.7837 24.1666 29.167 24.1666C30.5503 24.1666 31.667 25.2833 31.667 26.6666C31.667 28.05 30.5503 29.1666 29.167 29.1666ZM25.0003 18.3333V11.6666H26.667L33.3337 18.3333H25.0003Z"
          fill="#1F1F1F"
        />
      </g>
      <defs>
        <clipPath id="clip0_5415_293771">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
