import { FC } from "react"
import { IconTypeProps } from '../Icon'

export const IconOptions: FC<IconTypeProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.3327 7.00065C14.6423 7.00065 14.0827 7.5603 14.0827 8.25065C14.0827 8.94101 14.6423 9.50065 15.3327 9.50065C16.023 9.50065 16.5827 8.94101 16.5827 8.25065C16.5827 7.5603 16.023 7.00065 15.3327 7.00065ZM12.416 8.25065C12.416 6.63982 13.7219 5.33398 15.3327 5.33398C16.9435 5.33398 18.2493 6.63982 18.2493 8.25065C18.2493 9.86148 16.9435 11.1673 15.3327 11.1673C13.7219 11.1673 12.416 9.86148 12.416 8.25065Z" fill="#757575"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2 8.24935C2 7.78911 2.3731 7.41602 2.83333 7.41602H10.3333C10.7936 7.41602 11.1667 7.78911 11.1667 8.24935C11.1667 8.70959 10.7936 9.08268 10.3333 9.08268H2.83333C2.3731 9.08268 2 8.70959 2 8.24935Z" fill="#757575"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.791 8.24935C16.791 7.78911 17.1641 7.41602 17.6243 7.41602H21.166C21.6263 7.41602 21.9993 7.78911 21.9993 8.24935C21.9993 8.70959 21.6263 9.08268 21.166 9.08268H17.6243C17.1641 9.08268 16.791 8.70959 16.791 8.24935Z" fill="#757575"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2 15.7493C2 15.2891 2.3731 14.916 2.83333 14.916H6.58333C7.04357 14.916 7.41667 15.2891 7.41667 15.7493C7.41667 16.2096 7.04357 16.5827 6.58333 16.5827H2.83333C2.3731 16.5827 2 16.2096 2 15.7493Z" fill="#757575"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.66667 14.5007C7.97631 14.5007 7.41667 15.0603 7.41667 15.7507C7.41667 16.441 7.97631 17.0006 8.66667 17.0006C9.35702 17.0006 9.91667 16.441 9.91667 15.7507C9.91667 15.0603 9.35702 14.5007 8.66667 14.5007ZM5.75 15.7507C5.75 14.1398 7.05584 12.834 8.66667 12.834C10.2775 12.834 11.5833 14.1398 11.5833 15.7507C11.5833 17.3615 10.2775 18.6673 8.66667 18.6673C7.05584 18.6673 5.75 17.3615 5.75 15.7507Z" fill="#757575"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.416 15.7493C12.416 15.2891 12.7891 14.916 13.2493 14.916H21.166C21.6263 14.916 21.9993 15.2891 21.9993 15.7493C21.9993 16.2096 21.6263 16.5827 21.166 16.5827H13.2493C12.7891 16.5827 12.416 16.2096 12.416 15.7493Z" fill="#757575"/>
    </svg>
  )
}
