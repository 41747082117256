import { BookingPriceSummaryType } from "@graphql/types";

interface PriceSummary {
  type: BookingPriceSummaryType;
}

interface PriceItems<T = any> {
  basePrice?: T;
  taxPrice?: T;
  extraPrice?: T;
  coupon?: T;
  pointsForPayment?: T;
}

export const findPrices: <T extends PriceSummary>(
  summaries: T[]
) => PriceItems<T> = (summaries) => {
  const priceItems: PriceItems = {};

  summaries.forEach((summary) => {
    switch (summary.type) {
      case BookingPriceSummaryType.BASE:
        priceItems.basePrice = summary;
        break;
      case BookingPriceSummaryType.TAX:
        priceItems.taxPrice = summary;
        break;
      case BookingPriceSummaryType.EXTRA:
        priceItems.extraPrice = summary;
        break;
      case BookingPriceSummaryType.COUPON:
        priceItems.coupon = summary;
        break;
      case BookingPriceSummaryType.POINT:
        priceItems.pointsForPayment = summary;
        break;
    }
  });

  return priceItems;
};
