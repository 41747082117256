import { FC } from "react";
import { Icon, IconType } from "../Icon";
import styles from "./GlobalNavList.module.css";

interface GlobalNavListItem {
  className?: string;
  icon?: string;
  label: string;
  isActive: boolean;
  href: string;
}

const GlobalNavListItem: FC<GlobalNavListItem> = (props) => {
  const { className, icon, label, isActive, href } = props;
  return (
    <li className={[className || "", isActive ? styles.active : ""].join(" ")}>
      <a className={styles.link} href={href}>
        {icon && <Icon icon={icon as IconType} size="base" color="primary" />}
        {label}
      </a>
    </li>
  );
};

interface GlobalNavListProps {
  className?: string;
  itemClassName?: string;
  items: GlobalNavListItem[];
}

export const GlobalNavList: FC<GlobalNavListProps> = (props) => {
  const { className, itemClassName, items } = props;
  return (
    <nav>
      <ul className={[styles.navList, className || ""].join(" ")}>
        {items.map((item, index) => (
          <GlobalNavListItem key={index} className={itemClassName} {...item} />
        ))}
      </ul>
    </nav>
  );
};
