import { FC, ReactNode, useCallback, useState } from "react";
import { Icon } from "../Icon";
import styles from "./CarouselDisplay.module.css";

interface CCarouselDisplayProps {
  readonly items: ReactNode[];
  readonly itemWidth: number;
  readonly show: number; // num of display items
  readonly slide?: number;
}

export const CarouselDisplay: FC<CCarouselDisplayProps> = ({
  items,
  itemWidth,
  show,
  slide = 1,
}) => {
  const [cursor, setCursor] = useState(0);
  const onClickLeftButton = useCallback(() => {
    setCursor((state) => Math.max(state - slide, 0));
  }, [setCursor, slide]);
  const onClickRightButton = useCallback(() => {
    setCursor((state) => Math.min(state + slide, items.length - show));
  }, [items.length, setCursor, show, slide]);

  return (
    <div className={styles.root}>
      <div className={styles.carousel}>
        <ul
          className={styles.items}
          style={{
            left: itemWidth * cursor * -1,
          }}
        >
          {items.map((item, index) => (
            <li className={styles.item} key={`carousel-display-${index}`}>
              {item}
            </li>
          ))}
        </ul>
      </div>
      {cursor > 0 && (
        <button className={styles.leftButton} onClick={onClickLeftButton}>
          <Icon icon="chevronLeft" color="gray-50" />
        </button>
      )}
      {cursor < items.length - show && (
        <button className={styles.rightButton} onClick={onClickRightButton}>
          <Icon icon="chevronRight" color="gray-50" />
        </button>
      )}
    </div>
  );
};
