import { Container } from "../../Container";
import { Stack } from "../../Stack";
import { Text } from "../../Text";
import styles from "./FormFooter.module.css";

export const FormFooter = () => {
  return (
    <div className={styles.root}>
      <Container>
        <Stack justify="center">
          <Text>© 2021 Reiwa Travel, Inc.</Text>
        </Stack>
      </Container>
    </div>
  );
};
