export const DEFAULT_PER_PAGE = 20;

// Elasticsearchの検索条件（from + size）の閾値を定義。この閾値を超える場合はESがエラーを返す。
// https://www.elastic.co/guide/en/elasticsearch/reference/current/index-modules.html
// see) index.max_inner_result_window
const MAX_SEACH_RESULT_COUNT = 10_000;

const MAX_PAGE = MAX_SEACH_RESULT_COUNT / DEFAULT_PER_PAGE;

export const isValidPage = (value?: unknown): value is number | string =>
  (typeof value === "string" || typeof value === "number") &&
  !Number.isNaN(+value) &&
  +value > 0 &&
  +value <= MAX_PAGE;

export const getPage = (value?: number | string) => {
  return isValidPage(value) ? +value : 1;
};

export const createDisplayPageNumber = (page: number) =>
  page > 1 ? `（${page}ページ目）` : "";

export const getLastPage = (page: number) => Math.min(page, MAX_PAGE);

export const addPageQuery = (
  path: string,
  currentPage: number | string,
  returnPath?: string
) =>
  getPage(currentPage) > 1
    ? `${path}?page=${currentPage}`
    : returnPath
      ? returnPath
      : path;

export const addPageNumberInLabel = (label: string, currentPage: number) =>
  `${label}${currentPage > 1 ? createDisplayPageNumber(currentPage) : ""}`;

export const createTextForCountsByPage = (
  isLastPage: boolean,
  perPage: number,
  currentPage: number,
  totalCount: number
) => {
  return `${totalCount}件 (${
    isLastPage
      ? `${perPage * (currentPage - 1) + 1}〜${totalCount}`
      : `${perPage * (currentPage - 1) + 1}〜${perPage * currentPage}`
  }件を表示)`;
};
