import {
  Button,
  FieldGroup,
  Input,
  Form,
  Checkbox,
  PageTitle,
  Paragraph,
  FormGroup,
} from "@newt/ui";
import { FC, useEffect } from "react";
import { useRegistrationForm } from "./useRegistrationForm";
import styles from "./RegistrationForm.module.css";
import { MarketingService } from "@utils/marketing";

export const RegistrationForm: FC = () => {
  const { register, onFormSubmit, errors, isSubmitting, isValid } =
    useRegistrationForm();

  useEffect(() => {
    MarketingService.logEvent.viewModule({
      module_id: "signup_email_form",
    });
  }, []);

  return (
    <>
      <PageTitle align="center" size={{ mobile: "2xl", tablet: "4xl" }}>
        メールアドレス登録
      </PageTitle>
      <Form onSubmit={onFormSubmit}>
        <FormGroup
          title="メールアドレスを入力してください🚀"
          help={
            <Paragraph color="gray">
              予約に関する重要なお知らせなどをご連絡します📨
            </Paragraph>
          }
        >
          <FieldGroup label="メールアドレス" error={errors.email}>
            <Input
              type="email"
              placeholder="メールアドレスを入力"
              {...register("email")}
            />
          </FieldGroup>
          <FieldGroup help="おトクな割引やセール情報、おすすめツアーなどをメールでお送りします✉️ 配信はいつでも停止できます。">
            <Checkbox
              className={styles.checkbox}
              label="NEWTからのおトク情報を希望しません"
              {...register("rejectNewsletter")}
            />
          </FieldGroup>
          <Button isLoading={isSubmitting} disabled={isValid}>
            登録する
          </Button>
        </FormGroup>
      </Form>
    </>
  );
};
