import { FC } from "react";

export const LogoHorizontal: FC = () => {
  return (
    <svg
      width="144"
      height="35"
      viewBox="0 0 144 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.3469 29.3291H80.4247C80.9688 29.3291 81.4079 28.8655 81.4079 28.2913V10.9388C81.4079 10.3646 80.9688 9.901 80.4247 9.901H77.4817C76.9376 9.901 76.4985 10.3646 76.4985 10.9388V20.6252L67.1122 10.3784C66.8369 10.074 66.4501 9.901 66.0503 9.901H63.9725C63.4284 9.901 62.9893 10.3646 62.9893 10.9388V28.2982C62.9893 28.8725 63.4284 29.336 63.9725 29.336H66.9155C67.4596 29.336 67.8987 28.8725 67.8987 28.2982V18.6118L77.285 28.8586C77.5603 29.163 77.9471 29.336 78.3469 29.336V29.3291Z"
        fill="#00CD68"
      />
      <path
        d="M85.4927 29.3274H98.4906C99.0346 29.3274 99.4738 28.8639 99.4738 28.2896V25.7642C99.4738 25.19 99.0346 24.7264 98.4906 24.7264H89.3206V21.8343H96.1834C96.7274 21.8343 97.1666 21.3708 97.1666 20.7965V18.2711C97.1666 17.6969 96.7274 17.2333 96.1834 17.2333H89.3206V14.4865H98.2284C98.7725 14.4865 99.2116 14.023 99.2116 13.4487V10.9233C99.2116 10.3491 98.7725 9.8855 98.2284 9.8855H85.4927C84.8962 9.8855 84.4111 10.3975 84.4111 11.0271V28.1789C84.4111 28.8085 84.8962 29.3205 85.4927 29.3205V29.3274Z"
        fill="#00CD68"
      />
      <path
        d="M133.715 29.3294H136.658C137.202 29.3294 137.641 28.8659 137.641 28.2916V14.4954H143.009C143.553 14.4954 143.992 14.0319 143.992 13.4576V10.9322C143.992 10.358 143.553 9.89441 143.009 9.89441H127.389C126.845 9.89441 126.406 10.358 126.406 10.9322V13.4576C126.406 14.0319 126.845 14.4954 127.389 14.4954H132.732V28.2916C132.732 28.8659 133.171 29.3294 133.715 29.3294Z"
        fill="#00CD68"
      />
      <path
        d="M116.135 29.3294H119.02C119.57 29.3294 120.049 28.9281 120.167 28.3608L123.909 11.036C124.034 10.4548 123.614 9.89441 123.051 9.89441H120.127C119.57 9.89441 119.092 10.3095 118.974 10.8838L117.165 19.9682L115.139 10.8561C115.015 10.2957 114.536 9.89441 113.992 9.89441H111.737C111.187 9.89441 110.715 10.2957 110.59 10.8561L108.571 19.9682L106.756 10.8838C106.638 10.3095 106.159 9.89441 105.602 9.89441H102.679C102.108 9.89441 101.689 10.4479 101.82 11.036L105.576 28.3608C105.7 28.9281 106.172 29.3294 106.723 29.3294H109.594C110.138 29.3294 110.61 28.9351 110.741 28.3746L112.865 19.055L114.988 28.3746C115.113 28.9351 115.591 29.3294 116.135 29.3294Z"
        fill="#00CD68"
      />
      <path
        d="M44.2574 10.9255C43.4315 11.5828 42.6057 12.1916 41.7798 12.7659C41.6946 12.8213 41.5897 12.8074 41.5241 12.7244C37.1718 7.14089 31.1284 5.76404 27.3005 6.04771C19.5528 6.62198 11.4119 11.1815 6.44344 18.8891C6.39755 18.9652 6.30579 18.9998 6.22058 18.9721C5.65032 18.7784 5.09317 18.5639 4.55569 18.3425C4.0182 18.1211 3.51349 18.8061 3.88711 19.2973C4.75888 20.4458 5.79452 21.5182 6.97437 22.4661C7.09235 22.563 7.07269 22.7498 6.94159 22.819C5.23737 23.67 3.08088 24.3757 0.550771 24.521C-0.0719249 24.5556 -0.209573 25.4551 0.360685 25.7041C4.54258 27.5376 9.23573 27.5584 13.4176 25.7872C13.5291 25.7387 13.6536 25.7318 13.7716 25.7595C16.8261 26.569 20.6737 27.2263 25.0391 27.2263C25.1374 27.2263 25.1767 27.3647 25.0915 27.4131C22.9023 28.8176 20.2607 29.807 17.1997 29.9869C16.6098 30.0215 16.4393 30.8587 16.9637 31.1493C24.4885 35.3282 33.0817 32.9343 37.9125 27.1848C39.1645 25.6903 41.2357 24.1059 43.1497 23.158C44.9981 22.2862 46.8597 21.2553 48.7277 20.0376C52.7195 17.3946 54.6466 13.7345 55.4594 11.7212C55.8003 10.8771 55.4201 9.89459 54.6073 9.55557C52.5688 8.70455 48.4983 7.73591 44.2574 10.9393V10.9255ZM50.7728 12.4061C50.2353 12.413 49.7962 11.9633 49.783 11.396C49.7765 10.8286 50.2025 10.3651 50.74 10.3512C51.2775 10.3443 51.7167 10.794 51.7298 11.3614C51.7363 11.9287 51.3103 12.3923 50.7728 12.4061Z"
        fill="#00CD68"
      />
      <path
        d="M140.297 0H139.235C139.039 0 138.881 0.167274 138.881 0.373617V6.62133C138.881 6.82767 139.039 6.99495 139.235 6.99495H140.297C140.492 6.99495 140.651 6.82767 140.651 6.62133V0.373617C140.651 0.167274 140.492 0 140.297 0Z"
        fill="#00CD68"
      />
      <path
        d="M122.435 4.98926H116.516C116.321 4.98926 116.162 5.15653 116.162 5.36288V6.26924C116.162 6.47559 116.321 6.64286 116.516 6.64286H122.435C122.63 6.64286 122.789 6.47559 122.789 6.26924V5.36288C122.789 5.15653 122.63 4.98926 122.435 4.98926Z"
        fill="#00CD68"
      />
      <path
        d="M136.842 2.67188H130.923C130.728 2.67188 130.569 2.83915 130.569 3.04549V3.95186C130.569 4.1582 130.728 4.32548 130.923 4.32548H136.842C137.038 4.32548 137.196 4.1582 137.196 3.95186V3.04549C137.196 2.83915 137.038 2.67188 136.842 2.67188Z"
        fill="#00CD68"
      />
      <path
        d="M128.807 5.31934H124.304C124.149 5.31934 124.022 5.45254 124.022 5.61685V6.34332C124.022 6.50763 124.149 6.64083 124.304 6.64083H128.807C128.963 6.64083 129.089 6.50763 129.089 6.34332V5.61685C129.089 5.45254 128.963 5.31934 128.807 5.31934Z"
        fill="#00CD68"
      />
      <path
        d="M128.427 2.30391L128.04 6.1577H126.592L127.011 1.9718H128.145C128.316 1.9718 128.447 2.12402 128.427 2.30391Z"
        fill="#00CD68"
      />
      <path
        d="M124.873 1.97839H128.032C128.189 1.97839 128.314 2.10985 128.314 2.2759V3.00238C128.314 3.16843 128.189 3.29989 128.032 3.29989H124.873C124.715 3.29989 124.591 3.16843 124.591 3.00238V2.2759C124.591 2.10985 124.715 1.97839 124.873 1.97839Z"
        fill="#00CD68"
      />
      <path
        d="M121.624 0.352295H117.337C117.142 0.352295 116.983 0.519569 116.983 0.725912V1.63228C116.983 1.83862 117.142 2.0059 117.337 2.0059H121.624C121.82 2.0059 121.978 1.83862 121.978 1.63228V0.725912C121.978 0.519569 121.82 0.352295 121.624 0.352295Z"
        fill="#00CD68"
      />
      <path
        d="M139.739 3.68882C141.155 4.06244 142.322 4.46373 143.253 4.85811C143.443 4.94113 143.652 4.83043 143.718 4.62979C143.797 4.35995 143.908 4.01401 143.987 3.75801C144.046 3.57812 143.96 3.37748 143.79 3.30137C142.846 2.88624 141.673 2.49186 140.231 2.11133C140.231 2.11133 139.844 3.37056 139.746 3.68882H139.739Z"
        fill="#00CD68"
      />
    </svg>
  );
};
