import { forwardRef, ChangeEvent, ReactNode } from "react";
import styles from "./Checkbox.module.css";
import { Text } from "../Text";
import { FontSizes, ResponsiveProp } from "../../types/component.type";

export interface CheckboxProps {
  className?: string;
  disabled?: boolean;
  label?: ReactNode;
  labelSize?: ResponsiveProp<FontSizes>;
  error?: boolean;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const {
      className,
      label,
      labelSize = "sm",
      disabled,
      error,
      ...rest
    } = props;

    const rootClasses = [
      styles.root,
      className || "",
      disabled ? styles[`root--disabled`] : "",
    ].join(" ");

    const checkboxClasses = [
      styles.checkbox,
      !disabled && !error ? styles.default : "",
      disabled ? styles.disabled : "",
      error && !disabled ? styles.error : "",
    ].join(" ");

    return (
      <label className={rootClasses}>
        <input
          disabled={disabled}
          className={checkboxClasses}
          type="checkbox"
          ref={ref}
          defaultValue={1}
          {...rest}
        />
        {label && (
          <Text size={labelSize} color={disabled ? "gray" : undefined}>
            {label}
          </Text>
        )}
      </label>
    );
  }
);

Checkbox.displayName = "Checkbox";
