import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconRoomView: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#1F1F1F"
        d="M2.5 17.5V5.937h3.438V2.5h8.125v6.875H17.5V17.5h-6.396v-3.438H8.896V17.5H2.5zm1.25-1.25h2.188v-2.188H3.75v2.188zm0-3.438h2.188v-2.187H3.75v2.188zm0-3.437h2.188V7.187H3.75v2.188zm3.438 3.438h2.187v-2.188H7.187v2.188zm0-3.438h2.187V7.187H7.187v2.188zm0-3.438h2.187V3.75H7.187v2.188zm3.437 6.875h2.188v-2.187h-2.188v2.188zm0-3.437h2.188V7.187h-2.188v2.188zm0-3.438h2.188V3.75h-2.188v2.188zm3.438 10.313h2.187v-2.188h-2.188v2.188zm0-3.438h2.187v-2.187h-2.188v2.188z"
      ></path>
    </svg>
  );
};
