import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconCircle: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="#EEE"
      viewBox="0 0 28 28"
      strokeWidth={0}
    >
      <circle cx="14.001" cy="14.001" r="13.333" fill="#EEE"></circle>
    </svg>
  );
};
