const defaults = {
  code: "JPY",
  locale: "ja",
};

const getCurrencySymbol = ({ code, locale } = defaults) => {
  const parts = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: code,
  }).formatToParts(0);

  return parts.find((item) => item.type == "currency")?.value || "";
};

const formatDefaults = {
  ...defaults,
  symbol: true,
};

const getCurrencyFormat = (
  amount: number,
  { locale, code, symbol } = formatDefaults
) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: code,
    minimumFractionDigits: 0,
  });

  const currency = getCurrencySymbol({ code, locale });
  const value = formatter.format(amount).replace(currency, "");

  // we are not using the standard format
  if (symbol) {
    return `￥${value}`;
  }
};

export const currency = {
  symbol: getCurrencySymbol,
  format: getCurrencyFormat,
};
