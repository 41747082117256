import { FC } from "react";
import styles from "./PaymentIcon.module.css";
import AmexIcon from "./icons/amex.png";
import DinerIcon from "./icons/dinners.png";
import JCBIcon from "./icons/jcb.png";
import MasterIcon from "./icons/master.png";
import VisaIcon from "./icons/visa.png";
import CardIcon from "./icons/default.png";
import UnionPayIcon from "./icons/unionpay.png";
import DiscoverIcon from "./icons/discover.png";
import MaestroIcon from "./icons/maestro.png";

// GMO: AMEX, DINERS, JCB, MASTER, VISA
const icons = {
  VISA: VisaIcon,
  MASTER: MasterIcon,
  DINERS: DinerIcon,
  JCB: JCBIcon,
  AMEX: AmexIcon,
  UNIONPAY: UnionPayIcon,
  DISCOVER: DiscoverIcon,
  MAESTRO: MaestroIcon,
  UNKNOWN: CardIcon,
};

export type PaymentIconType = keyof typeof icons;

interface PaymentIconProps {
  icon: PaymentIconType;
  size?: "md" | "lg" | "xl";
}

export const PaymentIcon: FC<PaymentIconProps> = ({ icon, size = "md" }) => {
  const Icon = icons[icon] || icons.UNKNOWN;
  return (
    <i className={styles.root}>
      <img src={Icon.src} alt={icon} className={styles[`size--${size}`]} />
    </i>
  );
};
