import { FC } from "react"
import { IconTypeProps } from '../Icon'

export const IconCamera: FC<IconTypeProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0008 15.2008C13.7681 15.2008 15.2008 13.7681 15.2008 12.0008C15.2008 10.2335 13.7681 8.80078 12.0008 8.80078C10.2335 8.80078 8.80078 10.2335 8.80078 12.0008C8.80078 13.7681 10.2335 15.2008 12.0008 15.2008Z" fill="#757575"/>
      <path d="M9 2L7.17 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4H16.83L15 2H9ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17Z" fill="#757575"/>
    </svg>
  )
}
