import { gtm } from "../gtm";

export interface ViewModuleEventVariables {
  module_id: string;
  module_name: string;
}

export const viewModuleEventToApp = (variables?: ViewModuleEventVariables) => {
  gtm.pushEvent("view_module", { ...variables, is_newt_app: true });
};
