import { FC } from "react"
import { IconTypeProps } from '../Icon'

export const IconArrowDropLeft: FC<IconTypeProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 7L9 12L14 17V7Z" fill="#757575"/>
    </svg>
  )
}
