import { createElement, forwardRef, MouseEventHandler, ReactNode } from "react";
import { useResponsiveProp } from "../../hooks/useResponsiveProp";
import { ResponsiveProp } from "../../types/component.type";
import { Loading } from "../Loading";
import styles from "./Button.module.css";
import { Icon, IconType } from "../Icon";

export interface ButtonProps {
  color?: "primary" | "default" | "danger" | "disabled";
  variant?: "solid" | "outline" | "ghost";
  disabled?: boolean;
  isLoading?: boolean;
  size?: ResponsiveProp<"xxs" | "xs" | "sm" | "md" | "lg">;
  as?: "button" | "a";
  href?: string;
  type?: "button" | "submit";
  id?: string;
  icon?: IconType | ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  children: ReactNode;
  target?: HTMLAnchorElement["target"];
  width?: ResponsiveProp<"full" | "content" | "md">;
  autoFocus?: HTMLButtonElement["autofocus"];
  truncate?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      children,
      color = "primary",
      variant = "solid",
      size = "md",
      disabled,
      isLoading,
      as = props.href !== undefined ? "a" : "button",
      type = "submit",
      icon,
      href,
      width = "full",
      onClick,
      target,
      truncate = false,
      ...rest
    } = props;

    const defaultWidth =
      typeof width == "string" ? width : { mobile: "full", ...width };
    const responsiveWidth = useResponsiveProp(defaultWidth, "full");
    const responsiveSize = useResponsiveProp(size, "md");

    const styleColor = disabled ? "disabled" : color;
    const isAnchor = as === "a";
    const rootClasses = [
      styles.root,
      styles[`size--${responsiveSize}`],
      isLoading ? styles.loading : styles[`${styleColor}--${variant}`],
      disabled ? styles.disabled : null,
      styles[`width--${responsiveWidth}`],
    ].join(" ");

    return createElement(
      as,
      {
        type: isAnchor ? undefined : type,
        className: rootClasses,
        onClick,
        href: isAnchor ? href : undefined,
        disabled: isLoading || disabled,
        target,
        rel: target === "_blank" ? "noreferrer" : undefined,
        ...rest,
        ref,
      },
      <>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {icon && (
              <span className={styles.icon}>
                {typeof icon === "string" ? (
                  <Icon icon={icon as IconType} />
                ) : (
                  icon
                )}
              </span>
            )}
            <span
              className={`${styles.body} ${truncate ? styles.truncate : ""}`}
            >
              {children}
            </span>
          </>
        )}
      </>
    );
  }
);

Button.displayName = "Button";
