import { Breadcrumb as UiBreadCrumb } from "@newt/ui";
import Link from "next/link";
import styles from "./Breadcrumb.module.css";

export type BreadcrumbItem = {
  label: string;
  href: string;
};

interface BreadcrumbProps {
  items: BreadcrumbItem[];
  lastChildElemType?: "span" | "h1";
}

export const Breadcrumb = ({
  items,
  lastChildElemType = "span",
}: BreadcrumbProps) => {
  if (!items.length) {
    return null;
  }

  return (
    <div className={styles.root}>
      <UiBreadCrumb>
        {items.map((item, index) => {
          const isLastChild = index === items.length - 1;

          if (isLastChild) {
            return (
              <UiBreadCrumb.Item
                key={`bc-${index}`}
                textType={lastChildElemType}
              >
                <span className={styles.lastChild}>{item.label}</span>
              </UiBreadCrumb.Item>
            );
          }

          return (
            <Link
              key={`bc-${index}`}
              href={item.href}
              passHref
              legacyBehavior
              prefetch={false}
            >
              <UiBreadCrumb.Item>{item.label}</UiBreadCrumb.Item>
            </Link>
          );
        })}
      </UiBreadCrumb>
    </div>
  );
};
