import { MouseEventHandler } from "react";
import { useResponsiveProp } from "../../hooks/useResponsiveProp";
import { ResponsiveProp } from "../../types/component.type";
import styles from "./WishlistButton.module.css";

interface WishlistButtonProps {
  readonly variant: "default" | "filled" | "pressed";
  readonly stroke: "black" | "white";
  readonly size: ResponsiveProp<"sm" | "md" | "lg" | "xl">;
  readonly onClick?: MouseEventHandler<HTMLElement>;
}

export const WishlistButton = ({
  variant,
  stroke,
  size,
  onClick,
}: WishlistButtonProps) => {
  const responsiveSize = useResponsiveProp(size, "md");
  const rootClasses = [
    styles.root,
    styles[`variant--${variant}`],
    styles[`stroke--${stroke}`],
    styles[`size--${responsiveSize}`],
  ].join(" ");

  return (
    <i className={rootClasses} onClick={onClick}>
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        {(variant === "pressed" || stroke === "white") && (
          <path d="M19.9386 9C18.1609 9 17.1331 10.1372 15.9958 11.5271C14.8669 10.1288 13.8391 9 12.0614 9C11.5182 9.00987 10.9822 9.12664 10.4841 9.34366C9.98601 9.56068 9.53555 9.87369 9.15845 10.2648C8.78136 10.6559 8.48503 11.1175 8.28638 11.6231C8.08773 12.1288 7.99066 12.6686 8.00071 13.2118C8.00071 16.0758 11.2021 18.8051 15.9958 23C20.7979 18.7882 23.9993 16.0758 23.9993 13.2118C24.0093 12.6686 23.9123 12.1288 23.7136 11.6231C23.515 11.1175 23.2186 10.6559 22.8416 10.2648C22.4645 9.87369 22.014 9.56068 21.5159 9.34366C21.0178 9.12664 20.4818 9.00987 19.9386 9Z" />
        )}
        <path d="M15.7918 24L15.1744 23.5022C10.2701 19.1878 7.00049 16.3406 7.00049 13.1092C6.98189 11.7756 7.49038 10.489 8.41456 9.53133C9.33874 8.57365 10.6032 8.02298 11.9309 8C13.6701 8 14.844 8.87336 16.0005 10.2183C17.1918 8.8821 18.3048 8 20.1048 8C21.4325 8.02298 22.697 8.57365 23.6212 9.53133C24.5454 10.489 25.0539 11.7756 25.0353 13.1092C25.0353 16.3406 21.7918 19.2227 16.8875 23.5022L16.3135 24H15.7918ZM11.9657 9.48472C11.5002 9.49267 11.0408 9.5928 10.6138 9.77937C10.1869 9.96595 9.80083 10.2353 9.47771 10.572C9.1546 10.9087 8.90081 11.3061 8.73088 11.7415C8.56096 12.1769 8.47823 12.6416 8.48745 13.1092C8.48745 15.6594 11.4788 18.2969 16.0092 22.2795C20.5657 18.2969 23.5222 15.6594 23.5222 13.1092C23.5314 12.6416 23.4487 12.1769 23.2788 11.7415C23.1089 11.3061 22.8551 10.9087 22.532 10.572C22.2088 10.2353 21.8228 9.96595 21.3958 9.77937C20.9689 9.5928 20.5095 9.49267 20.044 9.48472C18.5483 9.48472 17.4353 10.7598 16.2527 12.1834H15.7657C14.6614 10.821 13.4614 9.48472 11.9657 9.48472Z" />
      </svg>
    </i>
  );
};
