import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconTrain: FC<IconTypeProps> = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.336 12.916V4.999c0-.57.132-1.066.396-1.49.264-.423.67-.77 1.219-1.04.548-.272 1.243-.473 2.083-.605.84-.132 1.83-.198 2.969-.198 1.194 0 2.211.063 3.052.188.84.125 1.527.322 2.062.593.535.271.927.615 1.177 1.032.25.416.375.923.375 1.52v7.917c0 .82-.281 1.51-.844 2.073-.562.562-1.253.844-2.072.844l1.25 1.25v.416h-1.459l-1.666-1.666h-3.75L6.46 17.499H5.003v-.416l1.25-1.25c-.82 0-1.51-.282-2.073-.844-.563-.563-.844-1.254-.844-2.073Zm1.25-4.27H9.46v-3.23H4.586v3.23Zm6.125 0h4.708v-3.23h-4.708v3.23Zm-3.73 4.79c.32 0 .591-.11.813-.332.222-.223.334-.493.334-.813 0-.32-.112-.59-.334-.813a1.106 1.106 0 0 0-.812-.333c-.32 0-.59.111-.813.334a1.106 1.106 0 0 0-.333.812c0 .32.111.59.333.813.222.222.493.333.813.333Zm6.042 0c.32 0 .59-.11.813-.332.222-.223.333-.493.333-.813 0-.32-.11-.59-.333-.813a1.106 1.106 0 0 0-.813-.333c-.319 0-.59.111-.812.334a1.106 1.106 0 0 0-.333.812c0 .32.11.59.333.813.222.222.493.333.812.333Z"
        fill="#757575"
      />
    </svg>
  );
};
