import { SITE_URL_NEW } from "@constants";
import { UtmCampaign, UtmContent } from "./utmParams";

// FIREBASE
export const FIREBASE_DYNAMIC_LINK_URL =
  process.env.NEXT_PUBLIC_FIREBASE_DYNAMIC_LINK_URL || "https://links.newt.net";
export const ANDROID_APN = process.env.NEXT_PUBLIC_ANDROID_APN || "net.newt";
export const IOS_BUNDLE_ID =
  process.env.NEXT_PUBLIC_IOS_BUNDLE_ID || "net.newt";
export const IOS_APP_ID = process.env.NEXT_PUBLIC_IOS_APP_ID || "1585901351"; // only production

interface GetAppLinkArgs {
  path: string;
  utmCampaign?: UtmCampaign;
  utmContent?: UtmContent;
}

export const getAppLink = ({
  path,
  utmCampaign,
  utmContent,
}: GetAppLinkArgs) => {
  const params = new URLSearchParams();

  params.set("link", `${SITE_URL_NEW}${path}`);

  // Android
  params.set("apn", ANDROID_APN); // package name

  // iOS
  params.set("ibi", IOS_BUNDLE_ID); // bundle ID
  params.set("isi", IOS_APP_ID); // App Store ID
  params.set("efr", "1"); // skip the app preview page

  // UTM params
  if (utmCampaign) {
    params.set("utm_medium", "newt");
    params.set("utm_source", "newt");
    params.set("utm_campaign", utmCampaign);
    params.set("utm_content", utmContent || "");
  }

  return `${FIREBASE_DYNAMIC_LINK_URL}?${params.toString()}`;
};

export const getAppLinkForLP = (url: string) => {
  const utmCampaign: UtmCampaign = "area";
  const utmContent: UtmContent = "tour_card";

  const params = new URLSearchParams();
  params.set("link", `${window.location.origin}${url}`);

  // Android
  params.set("apn", ANDROID_APN); // package name
  params.set("afl", `${window.location.origin}${url}`); // 	The link to open when the app isn't installed for Android.

  // iOS
  params.set("ibi", IOS_BUNDLE_ID); // bundle ID
  params.set("ifl", `${window.location.origin}${url}`); // 	The link to open when the app isn't installed for iOS.
  params.set("efr", "1"); // skip the app preview page

  // UTM params
  params.set("utm_medium", "newt");
  params.set("utm_source", "newt");
  params.set("utm_campaign", utmCampaign);
  params.set("utm_content", utmContent || "");

  return `${FIREBASE_DYNAMIC_LINK_URL}?${params.toString()}`;
};
