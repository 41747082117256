import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconTour: FC<IconTypeProps> = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.0273 9.98681C23.3158 9.91911 23.5723 9.75437 23.7539 9.52015C23.927 9.28935 24.0206 9.00864 24.0206 8.72015C24.0245 8.51526 23.9812 8.31223 23.8939 8.12681C23.7998 7.94536 23.6652 7.78797 23.5006 7.66681C23.3442 7.53515 23.1555 7.44768 22.9539 7.41348C22.7571 7.36353 22.5508 7.36353 22.3539 7.41348L18.1006 8.52681L12.3606 3.40015L10.8339 3.84015L14.2739 9.56681L10.0873 10.6801L8.67393 9.44681L7.44727 9.68681L9.72726 13.5335L23.0273 9.98681Z"
        fill="#00CD68"
      />
      <path
        d="M26.7089 25.6142V13.8809C26.7109 13.7184 26.6791 13.5572 26.6155 13.4076C26.5561 13.2568 26.4652 13.1204 26.3489 13.0076C26.2367 12.8935 26.103 12.8029 25.9555 12.7409C25.8047 12.6813 25.6443 12.6497 25.4822 12.6476H20.0222C19.8601 12.6497 19.6996 12.6813 19.5489 12.7409C19.3994 12.8029 19.2635 12.8935 19.1489 13.0076C19.0347 13.1222 18.9441 13.2581 18.8822 13.4076C18.8226 13.5584 18.7909 13.7188 18.7889 13.8809V25.6142H17.5355V18.0276C17.5335 17.8654 17.5018 17.705 17.4422 17.5542C17.3803 17.4068 17.2896 17.2731 17.1755 17.1609C17.0621 17.0452 16.9259 16.9544 16.7755 16.8942C16.6275 16.8333 16.469 16.8016 16.3089 16.8009H6.74886C6.58632 16.7989 6.42513 16.8307 6.27552 16.8942C6.12638 16.9537 5.99045 17.0421 5.87552 17.1542C5.76313 17.2703 5.67278 17.4059 5.60886 17.5542C5.54922 17.705 5.51759 17.8654 5.51552 18.0276V25.6142H4.24219V28.0476H28.6022V25.6142H26.7089ZM12.4822 19.2142H14.7289V21.4609H12.4822V19.2142ZM8.32886 19.2142H10.5555V21.4609H8.30886L8.32886 19.2142ZM10.4289 25.6142V23.3676H12.6755V25.6142H10.4289ZM21.6022 15.0876H23.8489V17.3342H21.6022V15.0876ZM21.6022 19.2342H23.8489V21.4809H21.6022V19.2342ZM21.6022 25.6342V23.3676H23.8489V25.6142L21.6022 25.6342Z"
        fill="#00CD68"
      />
    </svg>
  );
};
