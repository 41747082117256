import { FC } from "react"
import { IconTypeProps } from '../Icon'

export const IconDoneOutline: FC<IconTypeProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.77 4.83L21.17 6.23L8.43 18.97L2.83 13.37L4.23 11.97L8.43 16.17L19.77 4.83ZM19.77 2L8.43 13.34L4.23 9.14L0 13.37L8.43 21.8L24 6.23L19.77 2Z" fill="#757575"/>
    </svg>
  )
}
