import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useRouter } from "next/router";
import { firebase } from "@utils/firebase";
import { FirebaseError } from "firebase/app";
import { alert } from "@newt/ui";
import { MarketingService } from "@utils/marketing";
import { ROUTES } from "@routes";

export const useGoogleAuth = () => {
  const signInWithGoogle = async () => {
    MarketingService.logEvent.clickModule({
      module_id: "google_auth_button",
      module_name: "Google認証ボタン",
    });
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(firebase.auth, provider);
    } catch (error) {
      const typedError = error as FirebaseError;
      const errorCode = typedError.code;

      switch (errorCode) {
        case "auth/cancelled-popup-request":
        case "auth/popup-closed-by-user":
          return;
        default:
          await alert(
            `認証できませんでした。お手数ですが、もう一度お試しください。何度も起こる場合は、トラベルコンシェルジュまでご連絡お願いいたします。その際、次の（ ）内に記載の内容をご共有ください。(${errorCode})`
          );
      }
    }
  };

  return { signInWithGoogle };
};

export const useAppleAuth = () => {
  const signInWithApple = async () => {
    MarketingService.logEvent.clickModule({
      module_id: "apple_auth_button",
      module_name: "Apple認証ボタン",
    });
    try {
      const provider = new OAuthProvider("apple.com");
      provider.addScope("email");
      await signInWithPopup(firebase.auth, provider);
    } catch (error) {
      const typedError = error as FirebaseError;
      const errorCode = typedError.code;

      switch (errorCode) {
        case "auth/cancelled-popup-request":
        case "auth/popup-closed-by-user":
          return;
        default:
          await alert(
            `認証できませんでした。お手数ですが、もう一度お試しください。何度も起こる場合は、トラベルコンシェルジュまでご連絡お願いいたします。その際、次の（ ）内に記載の内容をご共有ください。(${errorCode})`
          );
      }
    }
  };

  return { signInWithApple };
};

export const usePhoneAuth = () => {
  const router = useRouter();
  const tourId = Number(router.query.tourId);

  const signInWithPhone = (isInsideBookingPage?: boolean) => {
    MarketingService.logEvent.clickModule({
      module_id: "phone_auth_button",
      module_name: "電話番号認証ボタン",
    });
    router.push({
      pathname: isInsideBookingPage
        ? ROUTES.booking.tour(tourId).signin.phone()
        : ROUTES.signin.phone(),
      query: isInsideBookingPage ? undefined : router.query,
    });
  };

  return { signInWithPhone };
};
