import { FC } from "react"
import { IconTypeProps } from '../Icon'

export const IconHistory: FC<IconTypeProps> = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.00002 17.9998C6.78399 18.0056 4.64404 17.1919 2.99183 15.7151C1.33962 14.2383 0.291833 12.2027 0.0500001 9.99998H2.10001C2.30148 11.6733 3.12717 13.209 4.412 14.2999C5.3737 15.1401 6.54649 15.702 7.80398 15.9248C9.06146 16.1477 10.3559 16.0231 11.5478 15.5645C12.7397 15.1059 13.7838 14.3307 14.5676 13.3224C15.3514 12.3142 15.8451 11.1112 15.9955 9.843C16.1459 8.57484 15.9474 7.28966 15.4212 6.12604C14.895 4.96242 14.0612 3.96448 13.0097 3.2398C11.9581 2.51513 10.7287 2.09121 9.45401 2.01373C8.17928 1.93625 6.90757 2.20816 5.77599 2.80014C4.7777 3.33192 3.91383 4.0843 3.25001 5.00009H6.00002V7.00005H0V1.00018H2.00001V3.35013C2.83402 2.29656 3.89958 1.44933 5.114 0.874185C6.32716 0.294408 7.65545 -0.00432825 9.00002 0.000205566C10.208 -0.00808265 11.4045 0.234342 12.514 0.712177C14.6624 1.62789 16.3732 3.33933 17.288 5.48807C17.7579 6.5993 18 7.79352 18 9C18 10.2065 17.7579 11.4007 17.288 12.5119C16.3732 14.6606 14.6624 16.3721 12.514 17.2878C11.4045 17.7656 10.208 18.0081 9.00002 17.9998ZM11.8 13.1999L8.00002 9.39999V4.00011H10V8.60001L13.2 11.7999L11.8 13.1999Z" fill="black"/>
    </svg>
  )
}
