import { FC, ReactNode } from "react";
import styles from "./Badge.module.css";

export interface BadgeProps {
  color?: "primary" | "default" | "danger" | "disabled";
  variant?: "solid" | "outline" | "ghost";
  disabled?: boolean;
  children: ReactNode;
}

export const Badge: FC<BadgeProps> = ({
  children,
  color = "primary",
  variant = "solid",
  disabled,
}) => {
  const styleColor = disabled ? "disabled" : color;

  return (
    <div
      className={`${styles.root} ${styles[`${styleColor}--${variant}`]} ${
        disabled ? styles.disabled : ``
      } `}
    >
      {children}
    </div>
  );
};
