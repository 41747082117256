import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconIndent: FC<IconTypeProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 9V15H15" stroke="black" style={{ fill: "none" }} />
    </svg>
  );
};
