import Head from "next/head";
import type { BreadcrumbList, WithContext } from "schema-dts";
import { SITE_URL } from "@constants";
import { BreadcrumbItem } from "../Breadcrumb";

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

export const BreadcrumbJsonLdHead = ({ items }: BreadcrumbProps) => {
  const itemListElement: BreadcrumbList["itemListElement"] = items.map(
    (item, index) => ({
      "@type": "ListItem",
      position: index + 1,
      name: item.label,
      item: item.href === "/" ? SITE_URL : SITE_URL + item.href, // use newt.net instead of newt.net/
    })
  );

  const jsonLd: WithContext<BreadcrumbList> = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement,
  };

  return (
    <Head>
      <script
        key="breadcrumbs-jsonld"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      />
    </Head>
  );
};
