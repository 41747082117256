import { HotelDetail_DescriptionFragment } from "@graphql/types";
import { ReadMore, useSafeLayoutEffect } from "@newt/ui";
import { gql } from "graphql-request";
import {
  FC,
  ReactNode,
  useCallback,
  useRef,
  useState,
  MouseEvent,
} from "react";
import styles from "./HotelDetailDescription.module.css";

const _HOTEL_DETAIL_DESCRIPTION_FRAGMENT = gql`
  fragment HotelDetail_Description on ExpediaHotelDescription {
    title
    content
  }
`;

interface HotelDetailDescriptionProps {
  title: string;
  fragment?: HotelDetail_DescriptionFragment[];
  children?: ReactNode;
}

export const HotelDetailDescription: FC<HotelDetailDescriptionProps> = ({
  title,
  fragment,
  children,
}) => {
  const [hasExpandButton, setHasExpandButton] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const onClick = useCallback((e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsExpanded((isExpanded) => !isExpanded);
  }, []);

  useSafeLayoutEffect(() => {
    // calculate height before re-rendering
    if (ref.current && fragment) {
      setHasExpandButton(ref.current.scrollHeight > 208);
    }
  }, []);

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>{title}</h2>
      <div
        ref={ref}
        className={`${
          hasExpandButton && !isExpanded && styles.descriptionsCompact
        }`}
      >
        {fragment?.map((item, index) => (
          <div key={index} className={styles.description}>
            <div className={styles.descriptionTitle}>{item.title}</div>
            <div
              dangerouslySetInnerHTML={{ __html: item.content }}
              className={styles.descriptionContent}
            />
          </div>
        ))}
        {children}
        {hasExpandButton && !isExpanded && <div className={styles.blur}></div>}
      </div>
      {hasExpandButton && (
        <div className={styles.footer}>
          <ReadMore isExpanded={isExpanded} onClick={onClick}>
            {isExpanded ? "とじる" : "もっとみる"}
          </ReadMore>
        </div>
      )}
    </div>
  );
};
