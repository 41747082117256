export const ERROR_CODES = {
  TOO_MANY_REQUESTS: "auth/too-many-requests",
  CAPTCHA_CHECK_FAILED: "auth/captcha-check-failed",
  INVALID_PHONE_NUMBER: "auth/invalid-phone-number",
};

export const ERROR_MESSAGES = {
  [ERROR_CODES.TOO_MANY_REQUESTS]:
    "SMSの送信数が上限に達しました。しばらくしてからもう一度お試しください。お急ぎの場合は、トラベルコンシェルジュまでご連絡ください。",
  [ERROR_CODES.CAPTCHA_CHECK_FAILED]:
    "一致するホストネームが見つかりません。Firebaseで承認済みドメインに追加してください。",
  [ERROR_CODES.INVALID_PHONE_NUMBER]: "電話番号を正しく入力してください。",
  GENERAL:
    "認証できませんでした。お手数ですが、しばらくしてからもう一度お試しください。何度も起こる場合は、トラベルコンシェルジュまでご連絡お願いいたします。",
} as const;
