import {
  SearchBoxCalendar,
  SearchBoxCalendarModalAction,
  SearchBoxPeople,
  SearchField,
  SearchBoxDestination,
  SearchBoxDestinationSpAction,
} from "./components";
import { useSearchForm } from "@components/common/SearchBox/hooks/useSearchForm";
import {
  getDisplayDestinations,
  toAirportSelectOptionGroups,
  toDestinationSelectOptionGroups,
  toDisplayCalendar,
  toDisplayCalendarBottom,
  toDisplayPeople,
} from "@components/common/SearchBox/utils";
import { useFetchSearchHistory } from "@components/pages/home";
import { Button, DropdownSelect, Icon, alert, toDate } from "@newt/ui";
import { useQuery } from "@tanstack/react-query";
import { graphql } from "@utils/graphql";
import { gql } from "graphql-request";
import { useRouter } from "next/router";
import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { DateTime } from "luxon";
import styles from "./SearchBox.module.css";
import { useDestinations } from "./hooks/useDestinations";
import { SearchInitialData, useSearchData } from "./hooks/useSearchData";
import { DEFAULT_SEARCH_FORM_VALUES } from "./hooks/useSearchForm";
import {
  getStaticTLPathFromDestinations,
  canNavigateToStl,
} from "@components/common/SearchBox/utils";
import { IS_DATA_SAVER_MODE } from "@constants";

interface SearchBoxProps {
  rootClassName?: string;
  onClickSubmit?: () => void;
  searchForm: ReturnType<typeof useSearchForm>;
  initialData?: SearchInitialData;
  isLoading?: boolean;
  showSearchHistory?: boolean;
  catchphrase?: string;
}

type FieldTypes = "airport" | "destination" | "calendar" | "people";

export const SEARCH_BOX_DESTINATION_FRAGMENT = gql`
  fragment SearchBoxArea_Destination on Destination {
    id
    name
    children(
      filters: { DESTINATION_STATUS: [PUBLIC, COMING_SOON], ORDER_BY: STATUS }
    ) {
      ...SearchBoxCountry_Destination
    }
  }

  fragment SearchBoxCountry_Destination on Destination {
    id
    type
    name
    displayName
    status
    code
    hasSingletonCityWithSameName
    mainImage {
      url
    }
    ...SearchBoxDisplayDestination_Destination
    children(
      filters: { DESTINATION_STATUS: [PUBLIC, COMING_SOON], ORDER_BY: STATUS }
    ) {
      ...SearchBoxCity_Destination
    }
  }

  fragment SearchBoxCity_Destination on Destination {
    id
    type
    name
    displayName
    status
    code
    mainImage {
      url
    }
    ...SearchBoxDisplayDestination_Destination
  }

  fragment SearchBoxDisplayDestination_Destination on Destination {
    id
    name
    displayName
  }
`;

export const SearchBox: FC<SearchBoxProps> = ({
  rootClassName,
  onClickSubmit,
  searchForm,
  initialData,
  isLoading = false,
  showSearchHistory = false,
}) => {
  const router = useRouter();
  const {
    destinations,
    airports,
    isLoading: isSearchBoxDataLoading,
  } = useSearchData(initialData);
  const showSkeletonLoading = isSearchBoxDataLoading || isLoading;
  const { onFormSubmit, watch, control, setValue, getValues, resetField } =
    searchForm;
  const [openedField, setOpenedField] = useState<FieldTypes | null>(null);
  const [initialMonth, setInitialMonth] = useState<Date>();
  const { histories } = useFetchSearchHistory({ enabled: showSearchHistory });

  useEffect(() => {
    if (router.query.from) {
      setInitialMonth(toDate(String(router.query.from)));
    }
  }, [router.query]);

  const areaDestinations = toDestinationSelectOptionGroups(destinations);
  const airportOptionGroups = toAirportSelectOptionGroups(airports);

  const watched = watch();
  const { findDestinationsByIds } = useDestinations(destinations);
  const selectedDestinations = findDestinationsByIds(watched.destinationIds);
  const displayDestinations = getDisplayDestinations(selectedDestinations);

  const displayAirport = airports.public?.find(
    (v) => String(v.id) === watched.airportId
  );

  const displayCalendarWithDuration = toDisplayCalendarBottom({
    from: watched.from,
    to: watched.to,
    withDuration: true,
    travelDateFlexibility: watched.travelDateFlexibility,
  });
  const displayCalendar = toDisplayCalendar({
    from: watched.from,
    to: watched.to,
    travelDateFlexibility: watched.travelDateFlexibility,
  });
  const displayPeople = toDisplayPeople(watched);

  const unsetOpenedField = () => {
    setOpenedField(null);
  };

  const clearCalendar = () => {
    resetField("from", { defaultValue: DEFAULT_SEARCH_FORM_VALUES.from });
    resetField("to", { defaultValue: DEFAULT_SEARCH_FORM_VALUES.to });
    setValue(
      "travelDateFlexibility",
      DEFAULT_SEARCH_FORM_VALUES.travelDateFlexibility
    );
  };

  const handleClickSubmit = async () => {
    const from = getValues("from");
    const to = getValues("to");
    if (from && !to) {
      alert("日本に到着する日をえらんでください。");
      return;
    }
    if (from && to) {
      const fromDateTime = DateTime.fromISO(from);
      const toDateTime = DateTime.fromISO(to);
      const diff = toDateTime.diff(fromDateTime, "days").get("days");
      if (diff >= 30) {
        alert("えらべる旅行日数は30日までです🙇");
        return;
      }
    }

    const destinationIds = getValues("destinationIds");
    if (!destinationIds || destinationIds.length === 0) {
      setOpenedField("destination");
      return;
    }

    if (onClickSubmit) onClickSubmit();

    if (IS_DATA_SAVER_MODE && canNavigateToStl(watched)) {
      const path = getStaticTLPathFromDestinations(
        destinations,
        destinationIds[0]
      );

      if (path) {
        return router.push(path);
      }
    }

    await onFormSubmit();
  };

  const handleCloseCalendar = () => {
    unsetOpenedField();
  };

  const { data: priceCalendar, isFetching: isPriceFetching } = useQuery({
    queryKey: ["priceCalendar", watched.destinationIds],
    enabled: watched.destinationIds?.length === 1,
    queryFn: async () => {
      const res = await graphql.priceCalendar({
        destinationId: Number(watched.destinationIds[0]),
      });

      return res.destinationById?.priceCalendar;
    },
  });

  const handleCloseDestination = () => {
    if (!watched.from || !watched.to) {
      setOpenedField("calendar");
    } else {
      unsetOpenedField();
    }
  };

  const clearDestinationIds = () => {
    resetField("destinationIds", {
      defaultValue: DEFAULT_SEARCH_FORM_VALUES.destinationIds,
    });
  };

  return (
    <header className={[styles.root, rootClassName || ""].join(" ")}>
      <div className={styles.main}>
        <ul className={styles.fields}>
          <li>
            <SearchField
              isOpen={openedField === "airport"}
              icon="flightTakeoff"
              label="出発地"
              isLoading={showSkeletonLoading}
              selected={displayAirport?.name || "指定なし"}
              onOpen={() => setOpenedField("airport")}
              onClose={unsetOpenedField}
            >
              {airports.public && (
                <Controller
                  name="airportId"
                  control={control}
                  render={({ field }) => (
                    <div className={styles.fieldWrapper}>
                      <DropdownSelect
                        name="airportId"
                        checked={field.value}
                        optionGroups={airportOptionGroups}
                        onChange={(v) => {
                          setValue("airportId", v);
                          unsetOpenedField();
                        }}
                        withBorder
                      />
                    </div>
                  )}
                />
              )}
            </SearchField>
          </li>
          <li>
            <SearchField
              isOpen={openedField === "destination"}
              icon="location"
              label="目的地"
              isLoading={showSkeletonLoading}
              selected={displayDestinations}
              onOpen={() => setOpenedField("destination")}
              onClose={unsetOpenedField}
              modalAction={
                <SearchBoxDestinationSpAction
                  hasValue={!!watched.destinationIds?.[0]}
                  onSubmit={handleCloseDestination}
                  onClear={clearDestinationIds}
                />
              }
            >
              {destinations.destinations && (
                <Controller
                  name="destinationIds"
                  control={control}
                  render={({ field }) => (
                    <SearchBoxDestination
                      showSearchHistory={showSearchHistory}
                      histories={histories}
                      selectedIds={field.value}
                      areaDestinations={areaDestinations}
                      onSelected={(destinationIds?: number[]) => {
                        if (destinationIds) {
                          setValue("destinationIds", destinationIds);
                        } else {
                          resetField("destinationIds", { defaultValue: [] });
                        }
                      }}
                      onSubmit={handleCloseDestination}
                      onClear={clearDestinationIds}
                    />
                  )}
                />
              )}
            </SearchField>
          </li>
          <li>
            <SearchField
              isOpen={openedField === "calendar"}
              icon="event"
              label="旅行日"
              selected={displayCalendar}
              dropdownSize="lg"
              dropdownPosition="bottom-center"
              isLoading={showSkeletonLoading}
              modalAction={
                <SearchBoxCalendarModalAction
                  hasValue={!!watched.from || !!watched.to}
                  display={displayCalendarWithDuration}
                  value={watched.travelDateFlexibility}
                  onChange={(value) => setValue("travelDateFlexibility", value)}
                  onClick={handleCloseCalendar}
                  onClear={clearCalendar}
                />
              }
              onOpen={() => setOpenedField("calendar")}
              onClose={handleCloseCalendar}
            >
              <SearchBoxCalendar
                values={watched}
                control={control}
                setValue={setValue}
                onClear={clearCalendar}
                priceCalendar={priceCalendar || null}
                initialMonth={initialMonth}
                onMonthChange={(month) => setInitialMonth(month)}
                isFetching={isPriceFetching}
              />
            </SearchField>
          </li>
          <li>
            <SearchField
              isOpen={openedField === "people"}
              icon="person"
              label="部屋数と人数"
              selected={displayPeople}
              isLoading={showSkeletonLoading}
              modalAction={
                <Button size="lg" onClick={unsetOpenedField}>
                  決定する
                </Button>
              }
              onOpen={() => setOpenedField("people")}
              onClose={unsetOpenedField}
            >
              <SearchBoxPeople
                control={control}
                setValue={setValue}
                getValues={getValues}
                maxTotalPeoples={6}
              />
            </SearchField>
          </li>
        </ul>
      </div>
      <div className={styles.action}>
        <Button
          color="primary"
          onClick={handleClickSubmit}
          data-testid="searchBox-search-button"
        >
          <div className={styles.actionIcon}>
            <Icon icon="search" color="white" />
          </div>
          <span className={styles.actionBody}>さがす</span>
        </Button>
      </div>
    </header>
  );
};
