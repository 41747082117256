import {
  useCallback,
  useEffect,
  useRef,
  useState,
  FC,
  ReactNode,
  CSSProperties,
} from "react";
import { useBreakPoints } from "../../hooks/useBreakPoints";
import { debounce } from "../../utils/debounce";
import { Icon } from "../Icon";
import { LazyImage } from "../LazyImage";
import styles from "./CarouselBanner.module.css";
import {
  DEFAULT_ASPECT_RATIO,
  DEFAULT_SHOW,
  ITEM_GAP,
  MOBILE_ITEM_WIDTH,
} from "./constants";

export interface CarouselBannerItem {
  readonly thumbnail: HTMLImageElement["src"];
  readonly caption?: HTMLImageElement["alt"] | null;
  readonly href?: HTMLAnchorElement["href"];
  readonly title?: string;
  readonly loading?: "eager" | "lazy" | undefined;
}

interface ItemContainerProps extends Pick<CarouselBannerItem, "href"> {
  readonly children: ReactNode;
  onClick?: (link?: string) => void;
}

const ItemContainer = ({ href, children, onClick }: ItemContainerProps) =>
  onClick !== undefined ? (
    <button className={styles.thumbnail} onClick={() => onClick(href)}>
      {children}
    </button>
  ) : href !== undefined ? (
    <a className={styles.thumbnail} href={href} target="_blank" rel="noopener">
      {children}
    </a>
  ) : (
    <div className={styles.thumbnail}>{children}</div>
  );

interface CarouselBannerProps {
  readonly items: CarouselBannerItem[];
  readonly aspectRatio?: CSSProperties["aspectRatio"];
  readonly show?: number;
  onClick?: (link?: string) => void;
}

export const CarouselBanner: FC<CarouselBannerProps> = ({
  items,
  aspectRatio = DEFAULT_ASPECT_RATIO,
  show = DEFAULT_SHOW,
  onClick,
}) => {
  const [cursor, setCursor] = useState(0);
  const onClickLeftButton = useCallback(() => {
    setCursor((state) => Math.max(state - 1, 0));
  }, [setCursor]);
  const onClickRightButton = useCallback(() => {
    setCursor((state) => Math.min(state + 1, items.length - show));
  }, [items.length, setCursor, show]);

  const { laptopWithUp } = useBreakPoints();

  const [containerWidth, setContainerWidth] = useState<number>(0);
  const ulRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const resizeObserverCallback = debounce(([element]) => {
      setContainerWidth(element.contentRect.width);
    }, 50);
    const resizeObserver = new ResizeObserver(resizeObserverCallback);

    if (ulRef.current !== null) {
      resizeObserver.observe(ulRef.current);
      setContainerWidth(ulRef.current.clientWidth);
    }

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (laptopWithUp && ulRef.current?.parentElement) {
      ulRef.current.parentElement.scrollLeft = 0;
    }
  }, [laptopWithUp]);

  const imgWidth = laptopWithUp
    ? (containerWidth - ITEM_GAP * (show - 1)) / show
    : MOBILE_ITEM_WIDTH;

  return (
    <div className={styles.root}>
      <div className={styles.carousel} ref={ulRef}>
        <ul
          className={styles.items}
          style={{
            left: laptopWithUp ? (imgWidth + ITEM_GAP) * cursor * -1 : "0px",
          }}
        >
          {items.map((v, index) => (
            <li className={styles.item} key={`carousel-banner-${index}`}>
              <ItemContainer href={v.href} onClick={onClick}>
                <LazyImage
                  src={v.thumbnail}
                  alt={v.caption || ""}
                  title={v.title}
                  loading={v.loading}
                  width={imgWidth}
                  aspectRatio={aspectRatio}
                />
              </ItemContainer>
            </li>
          ))}
        </ul>
      </div>
      {cursor > 0 && (
        <button className={styles.leftButton} onClick={onClickLeftButton}>
          <Icon icon="chevronLeft" color="gray-50" />
        </button>
      )}
      {cursor < items.length - show && (
        <button className={styles.rightButton} onClick={onClickRightButton}>
          <Icon icon="chevronRight" color="gray-50" />
        </button>
      )}
    </div>
  );
};
