import { FC, useEffect, useMemo, useState } from "react";
import { GallerySection, ImageCategory } from "./components";
import { Container } from "../Container";
import { TabNavigation } from "../TabNavigation";
import { FullScreenImage } from "../FullScreenImage";
import { useIsMounted } from "../../hooks/useIsMounted";
import { useFullScreenImage } from "./useFullScreenImage";
import { disableBodyScroll, enableBodyScroll } from "../../utils/element";
import styles from "./Gallery.module.css";

export type Category = {
  id: string;
  label: string;
  imagesCount: number;
  imageCategories: ImageCategory[];
};

interface GalleryProps {
  categories: Category[];
  onBack: () => void;
  isWebview?: boolean;
}

export const Gallery: FC<GalleryProps> = (props) => {
  const { categories, onBack, isWebview } = props;
  const { isMounted } = useIsMounted();
  const [categoryId, setCategoryId] = useState("all");
  const currentCategory = categories.find((c) => c.id === categoryId);
  const currentCategoryImages = useMemo(() => {
    return currentCategory?.imageCategories.flatMap((c) => c.images) || [];
  }, [currentCategory]);

  const { activeImageIndex, setActiveImageIndex, onImageClick, onClose } =
    useFullScreenImage(onBack);

  const rootClassName = [
    styles.root,
    isMounted ? styles.opened : styles.init,
  ].join(" ");

  useEffect(() => {
    // handle here instead of "routeChangeStart" in useBodyScroll.ts
    disableBodyScroll();
    return () => enableBodyScroll();
  }, []);

  return (
    <>
      <section className={rootClassName}>
        <header
          className={[styles.header, isWebview ? styles.webview : ""].join(" ")}
        >
          <Container spacing={{ mobile: "0", tablet: "4x" }}>
            <TabNavigation
              currentTabId={categoryId || ""}
              tabItems={categories}
              onClick={(categoryId: string) => setCategoryId(categoryId)}
            />
          </Container>
        </header>
        <Container>
          {currentCategory?.imageCategories.map((c) => {
            return (
              <GallerySection
                key={c.id}
                imageCategory={c}
                onClick={onImageClick}
              />
            );
          })}
        </Container>
      </section>
      <FullScreenImage
        isActive={activeImageIndex !== null}
        activeIndex={activeImageIndex || 0}
        setActiveIndex={setActiveImageIndex}
        images={currentCategoryImages}
        imagesCount={currentCategory?.imagesCount || 0}
        onClose={onClose}
        allowScroll={true}
      />
    </>
  );
};
