import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconSauna: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        fill="#000"
        d="M21.2 26.498H10.635a2.156 2.156 0 01-1.6-.683 2.237 2.237 0 01-.667-1.617V8.932h2.167v15.366H21.2v2.2zM15.268 8.065a2.7 2.7 0 01-1.983-.817 2.7 2.7 0 01-.817-1.983 2.7 2.7 0 01.817-1.983 2.7 2.7 0 011.983-.817c.778 0 1.434.272 1.967.817.533.544.8 1.205.8 1.983s-.267 1.439-.8 1.983c-.534.545-1.189.817-1.967.817zm7.267 21.267v-6.367h-8.167a2.436 2.436 0 01-1.767-.717 2.354 2.354 0 01-.733-1.75v-7.5c0-.933.328-1.722.983-2.366.656-.645 1.462-.967 2.417-.967.934 0 1.728.322 2.384.967.655.644.983 1.433.983 2.366v6.534h3.5c.689 0 1.278.244 1.767.733.488.489.733 1.066.733 1.733v7.333h-2.1z"
      ></path>
      <path
        fill="#1F1F1F"
        d="M29.886 14.174l-.033.491h1.907l.032-.393c.108-.764.016-1.588-.274-2.472a7.894 7.894 0 00-1.245-2.374c-.495-.655-.818-1.223-.969-1.703-.15-.48-.205-1.103-.162-1.867l.065-.524H27.3l-.064.36a6.133 6.133 0 00.21 2.358 7.678 7.678 0 001.115 2.325c.452.655.802 1.234 1.05 1.736s.34 1.19.275 2.063zM24.521 14.174l-.032.491h1.939l.032-.393c.087-.764-.01-1.588-.29-2.472a7.622 7.622 0 00-1.229-2.374c-.495-.655-.818-1.223-.969-1.703-.15-.48-.205-1.103-.162-1.867l.065-.524h-1.939l-.064.36a6.133 6.133 0 00.21 2.358 7.68 7.68 0 001.114 2.325c.453.655.803 1.234 1.05 1.736.248.502.34 1.19.275 2.063z"
      ></path>
    </svg>
  );
};
