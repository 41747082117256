import { LoadingLayout } from "@components/layout";
import { useToast } from "@newt/ui";
import { NextPageWithLayout } from "@root/types";
import { ROUTES } from "@routes";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useAuth } from ".";
import { Head } from "@components/common";

interface UserConditions {
  isActive?: boolean;
}

export const withAuth = (
  Component: NextPageWithLayout,
  conditions: UserConditions = { isActive: true }
) => {
  const WithAuthComponent: NextPageWithLayout = (props) => {
    const { user, isLoading } = useAuth();
    const router = useRouter();
    const toast = useToast();

    const isNotAuth = !isLoading && !user;
    const isActiveCondition =
      !isNotAuth && user && user.isActive !== conditions.isActive;

    const isAllowed = !(isNotAuth || isActiveCondition);

    useEffect(() => {
      if (!isAllowed) {
        toast.error("このページの閲覧権限がありません");
        router.push(ROUTES.signin.index({ redirectTo: router.asPath }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, isLoading]);

    if (isLoading || !user || !isAllowed) {
      return (
        <>
          <Head noindex />
          <LoadingLayout />;
        </>
      );
    }

    const getLayout = Component.getLayout ?? ((page) => page);

    return getLayout(<Component {...props} />);
  };

  WithAuthComponent.getLayout = (page) => <>{page}</>;

  return WithAuthComponent;
};
