import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconTv: FC<IconTypeProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.17461 21.1496V19.0996H3.54961C3.09961 19.0996 2.70378 18.9288 2.36211 18.5871C2.02044 18.2454 1.84961 17.8413 1.84961 17.3746V4.54961C1.84961 4.08294 2.02044 3.68294 2.36211 3.34961C2.70378 3.01628 3.09961 2.84961 3.54961 2.84961H20.4496C20.9163 2.84961 21.3163 3.01628 21.6496 3.34961C21.9829 3.68294 22.1496 4.08294 22.1496 4.54961V17.3746C22.1496 17.8413 21.9829 18.2454 21.6496 18.5871C21.3163 18.9288 20.9163 19.0996 20.4496 19.0996H15.8246V21.1496H8.17461ZM3.54961 17.3746H20.4496V4.54961H3.54961V17.3746Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};
