import { gtm } from "@utils/marketing/gtm";

export interface RegisterTourWishListEventVariables {
  tourId: number;
}

export const registerTourWishListBrazeEvent = (
  variables: RegisterTourWishListEventVariables
) => {
  gtm.pushEvent("braze_register_tour_wish_list", variables);
};
