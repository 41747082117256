import styles from "./Search.module.css";
import { Icon, Text } from "@newt/ui";

export const Search = () => (
  <button className={styles.search}>
    <div className={styles.searchTitle}>
      <Text weight="bold">ツアーを探す</Text>
    </div>
    <div className={styles.searchIcon}>
      <Icon icon="search" color="white" />
    </div>
  </button>
);
