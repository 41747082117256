import { FC } from "react";

export const IconPossessions: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.631 15.1592C15.444 15.1592 14.3524 15.4268 13.4997 15.8727C12.5605 16.3632 11.4349 16.3632 10.4957 15.8727C9.64296 15.4268 8.55143 15.1592 7.36439 15.1592C4.62874 15.1592 2.41157 16.5794 2.41157 18.3296C2.41157 20.0798 4.63102 21.5 7.36666 21.5C8.5537 21.5 9.64523 21.2325 10.498 20.7866C11.4372 20.2961 12.5628 20.2961 13.502 20.7866C14.3547 21.2325 15.4463 21.5 16.6333 21.5C19.3689 21.5 21.5861 20.0798 21.5861 18.3296C21.5861 16.5794 19.3667 15.1592 16.631 15.1592ZM7.36666 19.6941C6.07047 19.6941 5.0176 19.0832 5.0176 18.3296C5.0176 17.576 6.0682 16.9652 7.36666 16.9652C8.66513 16.9652 9.71572 17.576 9.71572 18.3296C9.71572 19.0832 8.66285 19.6941 7.36666 19.6941ZM16.631 19.6941C15.3348 19.6941 14.282 19.0832 14.282 18.3296C14.282 17.576 15.3326 16.9652 16.631 16.9652C17.9295 16.9652 18.9801 17.576 18.9801 18.3296C18.9801 19.0832 17.9272 19.6941 16.631 19.6941Z"
        fill="url(#paint0_linear_23825_54194)"
      />
      <path
        d="M20.549 10.883V11.0859H3.44842V10.883C3.44842 6.27235 7.29606 2.5 11.9987 2.5C14.3501 2.5 16.4877 3.44309 18.0363 4.96362C19.5849 6.48416 20.549 8.57768 20.549 10.883Z"
        fill="url(#paint1_linear_23825_54194)"
      />
      <path
        d="M22 12.2586C22 12.5819 21.8658 12.8717 21.6475 13.0858C21.4315 13.2976 21.1313 13.4291 20.8016 13.4291H3.19613C2.53894 13.4291 2 12.9029 2 12.2586C2 11.9353 2.13417 11.6455 2.35247 11.4315C2.5685 11.2197 2.86868 11.0881 3.19841 11.0881H20.8061C21.4611 11.0859 22 11.6121 22 12.2586Z"
        fill="url(#paint2_linear_23825_54194)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_23825_54194"
          x1="13.6567"
          y1="22.1009"
          x2="13.3401"
          y2="2.50511"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0520833" stopColor="#0085FF" />
          <stop offset="0.645" stopColor="#00CD6B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_23825_54194"
          x1="13.6567"
          y1="22.1009"
          x2="13.3401"
          y2="2.50511"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0520833" stopColor="#0085FF" />
          <stop offset="0.645" stopColor="#00CD6B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_23825_54194"
          x1="13.6567"
          y1="22.1009"
          x2="13.3401"
          y2="2.50511"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0520833" stopColor="#0085FF" />
          <stop offset="0.645" stopColor="#00CD6B" />
        </linearGradient>
      </defs>
    </svg>
  );
};
