export const TwitterIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1416 11.0602L17.6089 5.55566H16.5503L12.6714 10.3353L9.5733 5.55566H6L10.6849 12.7832L6 18.5557H7.0587L11.155 13.5083L14.4268 18.5557H18.0001L13.1415 11.0602H13.1418H13.1416ZM11.6916 12.8468L11.2169 12.1272L7.4401 6.40041H9.0661L12.1141 11.0221L12.5888 11.7417L16.5508 17.7492H14.9248L11.6917 12.847V12.8467L11.6916 12.8468Z"
        fill="black"
      />
    </svg>
  );
};
