import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconParking: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        fill="#1F1F1F"
        d="M7.164 28.134V3.867h9.967c2.266 0 4.166.761 5.7 2.284 1.533 1.522 2.3 3.394 2.3 5.616 0 2.245-.767 4.128-2.3 5.65-1.534 1.522-3.434 2.284-5.7 2.284h-5.6v8.433H7.164zM11.531 15.5h5.466c1.067 0 1.956-.356 2.667-1.067.711-.711 1.067-1.6 1.067-2.667 0-1.066-.356-1.955-1.067-2.666-.711-.712-1.6-1.067-2.667-1.067h-5.466V15.5z"
      ></path>
    </svg>
  );
};
