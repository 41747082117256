import Link from "next/link";
import { Icon } from "@newt/ui";
import styles from "./SignInButton.module.css";
import { FC, MouseEventHandler } from "react";
import { IconApple, IconGoogle } from "./Icons";

type ProviderType = "phone" | "google" | "apple";

type SignInButtonProps = {
  provider: ProviderType;
  as?: "button" | "a";
  href?: string;
  query?: string | Record<string, any>;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
};

const providerIcons = {
  google: <IconGoogle />,
  apple: <IconApple />,
  phone: <Icon icon="call" color="primary" size="sm" />,
};

const providerTexts = {
  google: "Googleで続ける",
  apple: "Appleで続ける",
  phone: "電話番号で続ける",
};

export const SignInButton: FC<SignInButtonProps> = ({
  provider,
  href,
  query,
  onClick,
}) => {
  const classNames = `${styles.root} ${provider === "apple" && styles.apple}`;

  const content = (
    <>
      <span className={styles.icon}>{providerIcons[provider]}</span>
      <span>{providerTexts[provider]}</span>
    </>
  );

  if (href) {
    return (
      <Link href={{ pathname: href, query: query }} className={classNames}>
        {content}
      </Link>
    );
  }

  return (
    <button className={classNames} onClick={onClick}>
      {content}
    </button>
  );
};
