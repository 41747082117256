import { TourPageQuery } from "@components/pages/tours/[tourId]/query";
import { TourPriceType } from "@graphql/types";
import { AddToWishlistEventVariables } from "@utils/marketing/events/addToWishlistEvent";

const UNKNOWN = "unknown";

export interface FormatForLogEventVariables {
  tour: {
    id: number;
    price?: { type: TourPriceType; price: number; travelDays: number } | null;
    title: string;
    onSale: boolean;
    destination: { name: string };
    plans: {
      airPlan: { departureFlights: { departureAirport: { code: string } }[] };
    }[];
  };
  query?: TourPageQuery;
}

export const formatForAddToWishlistEvent = ({
  tour,
  query,
}: FormatForLogEventVariables): AddToWishlistEventVariables => {
  const isPriceFrom = tour.price?.type === "FROM";
  const departure =
    tour.plans[0]?.airPlan?.departureFlights?.[0]?.departureAirport?.code;

  return {
    // from tour
    item_id: tour.id.toString(),
    item_name: tour.title,
    price: isPriceFrom ? "" : tour.price?.price.toString() || UNKNOWN, // 売り切れのときは price = null
    min_price: isPriceFrom ? tour.price?.toString() : "",
    max_price: "",
    sales_type: tour.onSale ? "sale" : "regular",
    item_category: "tour",
    destination: tour.destination.name,
    travel_days: tour.price?.travelDays?.toString() || UNKNOWN,
    departure: departure || UNKNOWN,

    // query
    departure_date: query?.from ? new Date(query.from).toISOString() : "",
    rooms: query?.rooms?.toString() || "",
    adults: query?.adults?.toString() || "",
    children_with_beds: query?.children?.toString() || "",
    children_without_beds: query?.children_without_bed?.toString() || "",
    infants: query?.infants?.toString() || "",

    // fixed value
    add_to_wishlist_count: 1,

    ecommerce: {
      currency: "JPY",
      value: tour.price?.price,
    },
  };
};
