import { FC } from "react";
import { gql } from "graphql-request";
import { ReservationPriceCardModalBlock_BookingPriceSectionFragment } from "@graphql/types";
import { Description, Divider, Spacer, Stack, Text } from "@newt/ui";

const _RESERVATION_PRICE_CARD_MODAL_BLOCK = gql`
  fragment ReservationPriceCardModalBlock_BookingPriceSection on BookingPriceSection {
    items {
      description
      label
      totalPrice
    }
    title
    totalPrice
  }
`;

type ReservationPriceCardModalBlockProps = {
  section: ReservationPriceCardModalBlock_BookingPriceSectionFragment;
};

export const ReservationPriceCardModalBlock: FC<
  ReservationPriceCardModalBlockProps
> = ({ section }) => {
  const { items, title, totalPrice } = section;

  return (
    <>
      <Description title={title}>
        {items.map((item, index) => (
          <div key={`items-${index}`}>
            <Stack justify="between" align="center">
              <div>
                <Text>{item.label}</Text>
                <Text color="gray" size="sm">
                  {item.description}
                </Text>
              </div>
              <Text size="lg">{item.totalPrice}</Text>
            </Stack>
            <Spacer />
            <Divider />
          </div>
        ))}
      </Description>
      <Spacer />
      <Stack justify="between" align="center">
        <Text color="black" weight="bold" size="lg">
          合計
        </Text>
        <Text color="black" weight="bold" size="lg">
          {totalPrice}
        </Text>
      </Stack>
      <Spacer />
      <Divider />
      <Spacer y="10x" />
    </>
  );
};
