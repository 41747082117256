import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconAccessibility: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        fill="#1F1F1F"
        d="M16.598 7.434a2.42 2.42 0 01-1.8-.75 2.463 2.463 0 01-.733-1.783c0-.69.244-1.284.733-1.784a2.42 2.42 0 011.8-.75c.689 0 1.278.25 1.767.75.489.5.733 1.095.733 1.784 0 .688-.244 1.283-.733 1.783-.49.5-1.078.75-1.767.75zm7.067 21.9v-6.967h-6.867a2.568 2.568 0 01-1.883-.783 2.568 2.568 0 01-.784-1.883v-8c0-.69.24-1.273.717-1.75a2.38 2.38 0 011.75-.717c.467 0 .878.089 1.233.267.356.177.778.555 1.267 1.133a16.079 16.079 0 003.117 2.65 7.138 7.138 0 003.716 1.183v2.1c-1.11 0-2.266-.267-3.466-.8a15.22 15.22 0 01-3.467-2.166v6h4.267c.71 0 1.316.25 1.816.75s.75 1.105.75 1.816v7.167h-2.166zm-10.034.1c-1.866 0-3.433-.639-4.7-1.917-1.266-1.278-1.9-2.85-1.9-4.716 0-1.534.561-2.95 1.684-4.25 1.122-1.3 2.594-2.05 4.416-2.25v2.233c-1.178.111-2.122.594-2.833 1.45-.711.855-1.067 1.794-1.067 2.816 0 1.267.422 2.323 1.267 3.167.844.845 1.9 1.267 3.167 1.267 1 0 1.933-.35 2.8-1.05.866-.7 1.344-1.639 1.433-2.817h2.233c-.155 1.8-.894 3.261-2.216 4.383-1.323 1.123-2.75 1.684-4.284 1.684z"
      ></path>
    </svg>
  );
};
