import {
  forwardRef,
  HTMLProps,
  ReactNode,
  SelectHTMLAttributes,
  ForwardRefExoticComponent,
  RefAttributes,
  FC,
} from "react";
import { Icon } from "../Icon";
import styles from "./Select.module.css";

const Option: FC<HTMLProps<HTMLOptionElement>> = ({
  children,
  selected,
  disabled,
  hidden,
  ...props
}) => (
  <option
    role="option"
    selected={selected}
    aria-selected={selected}
    disabled={disabled}
    aria-disabled={disabled}
    hidden={hidden}
    aria-hidden={hidden}
    {...props}
  >
    {children}
  </option>
);

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  disabled?: boolean;
  error?: boolean;
  name: string;
  children?: ReactNode;
  placeholder?: string;
}

interface SelectComponent
  extends ForwardRefExoticComponent<
    SelectProps & RefAttributes<HTMLSelectElement>
  > {
  Option: typeof Option;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => {
    const { disabled, error, children, placeholder, ...rest } = props;

    const selectClass = [
      styles.select,
      !disabled && !error ? styles.default : "",
      disabled ? styles.disabled : "",
      error && !disabled ? styles.error : "",
    ].join(" ");

    return (
      <div className={styles.root}>
        <select className={selectClass} disabled={disabled} ref={ref} {...rest}>
          {placeholder && (
            <Option value="" hidden>
              {placeholder}
            </Option>
          )}
          {children}
        </select>
        <div className={styles.icon}>
          <Icon icon="arrowDropdown" />
        </div>
      </div>
    );
  }
) as SelectComponent;

Select.displayName = "Select";
Select.Option = Option;
