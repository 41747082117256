import { gtm } from "../../gtm";

export interface TourBookingViewEventVariables {
  tourId: number;
}

export const tourBookingViewBrazeEvent = (
  variables: TourBookingViewEventVariables
) => {
  gtm.pushEvent("braze_tour_booking_view", variables);
};
