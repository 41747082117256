import { FC, MouseEventHandler, ReactNode } from "react";
import styles from "./ReadMore.module.css";
import { Icon } from "../Icon";

interface ReadMoreProps {
  isExpanded: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  children: ReactNode;
}

export const ReadMore: FC<ReadMoreProps> = (props) => {
  const { isExpanded, children, onClick } = props;

  return (
    <button
      className={styles.root}
      onClick={onClick}
      aria-expanded={isExpanded}
    >
      <span className={styles.text}>{children}</span>
      <Icon
        icon={isExpanded ? "chevronUp" : "chevronDown"}
        color="gray-80"
        size="md"
      />
    </button>
  );
};
