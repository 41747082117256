import { createElement, FC, Children, ReactNode } from "react";
import styles from "./Grid.module.css";
import { ResponsiveProp, Spacings } from "../../types/component.type";
import { useResponsiveProp } from "../../hooks/useResponsiveProp";

export type GridNum =
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12";

interface GridProps {
  children?: ReactNode;
  columns?: ResponsiveProp<GridNum>;
  spacing?: ResponsiveProp<Spacings>;
  spacingX?: ResponsiveProp<Spacings>;
  spacingY?: ResponsiveProp<Spacings>;
  as?: "ul" | "ol" | "div";
  listItemHeight?: "fitContent" | "full" | "auto";
}

const defaultProps: Required<
  Omit<GridProps, "children" | "listItemHeight" | "spacingX" | "spacingY">
> = {
  columns: "1",
  spacing: "base",
  as: "ul",
};

export const Grid: FC<GridProps> = ({
  children,
  columns = defaultProps.columns,
  spacing = defaultProps.spacing,
  as = defaultProps.as,
  spacingX,
  spacingY,
  listItemHeight = "auto",
}) => {
  const childAs = as === "div" ? "div" : "li";

  const responsiveColumns = useResponsiveProp(columns, defaultProps.columns);
  const responsiveSpacing = useResponsiveProp(spacing, defaultProps.spacing);
  const responsiveSpacingX = useResponsiveProp(spacingX, "1");
  const responsiveSpacingY = useResponsiveProp(spacingY, "1");

  const listClasses = [
    styles.root,
    styles[`columns--${responsiveColumns}`],
    !spacingX && !spacingY && styles[`spacing--${responsiveSpacing}`],
    spacingX && styles[`spacing--row--${responsiveSpacingX}`],
    spacingY && styles[`spacing--column--${responsiveSpacingY}`],
  ].join(" ");

  const listItemClasses = [
    styles.item,
    styles[`height--${listItemHeight}`],
  ].join(" ");

  return children
    ? createElement(
        as,
        { className: listClasses },
        Children.map(children, (child, index) => {
          if (!child) {
            return null;
          }
          return createElement(
            childAs,
            { className: listItemClasses, key: index },
            child
          );
        })
      )
    : null;
};
