import {
  AirportCardFragment,
  airportsQuery,
  destinationsQuery,
  SearchBoxArea_DestinationFragment,
} from "@graphql/types";
import { graphql } from "@utils/graphql";
import { useQuery } from "@tanstack/react-query";

export interface SearchDataset<T> {
  public?: T[];
  comingSoon?: T[];
  destinations?: T[];
}

export interface SearchInitialData {
  airports?: airportsQuery;
  destinations?: destinationsQuery;
}

export const useSearchData = (initialData?: SearchInitialData) => {
  const { data, isLoading } = useQuery({
    queryKey: ["search-data"],
    queryFn: async () => {
      const [airports, destinations] = await Promise.all([
        initialData?.airports || graphql.airports(),
        initialData?.destinations || graphql.destinations(),
      ]);

      return { airports, destinations };
    },
  });

  const airports: SearchDataset<AirportCardFragment> = {
    public: data?.airports.public.edges.map((v) => v.node),
    comingSoon: data?.airports.comingSoon.edges.map((v) => v.node),
  };

  const destinations: SearchDataset<SearchBoxArea_DestinationFragment> = {
    destinations: data?.destinations.destinations.edges.map((v) => v.node),
  };

  return {
    airports,
    destinations,
    isLoading,
  };
};
