import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconSort: FC<IconTypeProps> = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 14.1667C2.5 14.6269 2.8731 15 3.33333 15H6.66667C7.1269 15 7.5 14.6269 7.5 14.1667C7.5 13.7064 7.1269 13.3333 6.66667 13.3333H3.33333C2.8731 13.3333 2.5 13.7064 2.5 14.1667ZM3.33333 5C2.8731 5 2.5 5.3731 2.5 5.83333C2.5 6.29357 2.8731 6.66667 3.33333 6.66667H16.6667C17.1269 6.66667 17.5 6.29357 17.5 5.83333C17.5 5.3731 17.1269 5 16.6667 5H3.33333ZM2.5 10C2.5 10.4602 2.8731 10.8333 3.33333 10.8333H11.6667C12.1269 10.8333 12.5 10.4602 12.5 10C12.5 9.53976 12.1269 9.16667 11.6667 9.16667H3.33333C2.8731 9.16667 2.5 9.53976 2.5 10Z"
        fill="#757575"
      />
    </svg>
  );
};
