import { useEffect, useState } from "react";

type ValueOf<T> = T[keyof T];

const SESSION_STORAGE_KEY = "pointTooltip";
export const STATUS = {
  CLOSED: "closed",
  OPEN: "open",
} as const;
export type STATUS = ValueOf<typeof STATUS>;

export const useTooltip = () => {
  const [tooltipStatus, setTooltipStatus] = useState<STATUS>(STATUS.CLOSED);

  useEffect(() => {
    setTooltipStatus(
      sessionStorage.getItem(SESSION_STORAGE_KEY) === STATUS.CLOSED
        ? STATUS.CLOSED
        : STATUS.OPEN
    );
  }, []);

  const closeTooltip = () => {
    sessionStorage.setItem(SESSION_STORAGE_KEY, STATUS.CLOSED);
    setTooltipStatus(STATUS.CLOSED);
  };

  return { tooltipStatus, closeTooltip };
};
