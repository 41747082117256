import { ClickModuleId } from "../events/clickModule";

export const findClickModuleNameById = (clickModuleId: ClickModuleId) => {
  switch (clickModuleId) {
    case "mypage_contact_cell":
      return "マイページ-サポートチャットセル";

    case "mypage_contact_button":
      return "マイページ-チャットで相談するボタン";

    case "tour_detail_contact_button":
      return "ツアー詳細-チャットで相談するボタン";

    case "tour_list_contact_button":
      return "ツアー一覧-チャットで相談するボタン";

    case "area_lp_contact_button":
      return "エリアLP-チャットで相談するボタン";

    case "seo_tour_list_contact_button":
      return "SEOツアー一覧-チャットで相談するボタン";

    case "spot_list_contact_button":
      return "スポット一覧-チャットで相談するボタン";

    default:
      return "";
  }
};
