import { FC } from "react"
import { IconTypeProps } from '../Icon'

export const IconPersonChildOutline: FC<IconTypeProps> = () => {
  return (
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.665 10.667a4.667 4.667 0 1 1-9.333 0 4.667 4.667 0 0 1 9.333 0Zm2 0a6.667 6.667 0 1 1-13.333 0 6.667 6.667 0 0 1 13.333 0Zm-9.547 11.038c-.102.178-.116.282-.118.295 0 0 0 .001 0 0v3.334h-2v-3.333c0-.85.565-1.773 1.36-2.435.847-.706 2.074-1.232 3.64-1.232 1.566 0 2.793.526 3.64 1.232C20.435 20.228 21 21.15 21 22v3.333h-2V22c-.002-.013-.016-.117-.118-.295a2.283 2.283 0 0 0-.522-.603c-.486-.405-1.26-.768-2.36-.768-1.1 0-1.874.363-2.36.768-.247.206-.418.421-.522.603Z"
        fill="#757575"
      />
    </svg>
  )
}
