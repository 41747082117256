import {
  forwardRef,
  useState,
  useEffect,
  ChangeEvent,
  MouseEvent,
  useCallback,
  TouchEventHandler,
} from "react";
import styles from "./InputSlider.module.css";

interface InputSliderProps {
  disable?: boolean;
  min?: number;
  max?: number;
  step?: number;
  values?: [number, number];
  onChange?: (values: [number, number]) => void;
  onMouseUp?: (values: [number, number]) => void;
}

export const InputSlider = forwardRef<HTMLInputElement, InputSliderProps>(
  (props, _ref) => {
    const {
      min = 0,
      max = 10,
      step = 1,
      values = [min, max],
      disable,
      onChange,
      onMouseUp,
      ...extraProps
    } = props;

    const [leftValue, rightValue] = values;
    const calcPercent = useCallback(
      (value: number) => ((value - min) / (max - min)) * 100,
      [min, max]
    );

    const [inputLeftValue, setInputLeftValue] = useState(leftValue);
    const [inputRightValue, setInputRightValue] = useState(rightValue);
    const [leftPercent, setLeftPercent] = useState(calcPercent(leftValue));
    const [rightPercent, setRightPercent] = useState(calcPercent(rightValue));

    const isDefault = inputLeftValue === min && inputRightValue === max;

    const calculatePosition = (value: number) =>
      (100 / (max - min)) * (value - min);

    const setLeftValue = (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = Math.min(
        parseInt(e.target.value),
        inputRightValue - step
      );
      const percent = calculatePosition(newValue);

      setInputLeftValue(newValue);
      setLeftPercent(percent);

      onChange && onChange([newValue, inputRightValue]);
    };

    const setRightValue = (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = Math.max(
        parseInt(e.target.value),
        inputLeftValue + step
      );
      const percent = calculatePosition(newValue);

      setInputRightValue(newValue);
      setRightPercent(percent);

      onChange && onChange([inputLeftValue, newValue]);
    };

    const handleMouseUp = (e: MouseEvent<HTMLInputElement> | TouchEvent) => {
      onMouseUp && onMouseUp([inputLeftValue, inputRightValue]);
    };

    const handleTouchend: TouchEventHandler = () => {
      onMouseUp && onMouseUp([inputLeftValue, inputRightValue]);
    };

    useEffect(() => {
      setInputLeftValue(leftValue);
      setInputRightValue(rightValue);
      setLeftPercent(calcPercent(leftValue));
      setRightPercent(calcPercent(rightValue));
    }, [leftValue, rightValue, calcPercent]);

    return (
      <div className={styles.root} {...extraProps}>
        <input
          disabled={disable}
          onChange={setLeftValue}
          onMouseUp={handleMouseUp}
          onTouchEnd={handleTouchend}
          type="range"
          min={min}
          max={max}
          step={step}
          value={inputLeftValue}
        />
        <input
          disabled={disable}
          onChange={setRightValue}
          onMouseUp={handleMouseUp}
          onTouchEnd={handleTouchend}
          type="range"
          min={min}
          max={max}
          step={step}
          value={inputRightValue}
        />

        <div className={styles.slider}>
          <div className={styles.track} />
          <div
            className={`${styles.range} ${
              isDefault ? styles.gray : styles.green
            }`}
            style={{
              left: `${leftPercent}%`,
              right: `${100 - rightPercent}%`,
            }}
          />
          <div
            className={`${styles.thumb} ${styles.left} ${
              isDefault ? styles.gray : styles.green
            }`}
            style={{
              left: `${leftPercent}%`,
            }}
          />
          <div
            className={`${styles.thumb} ${styles.right} ${
              isDefault ? styles.gray : styles.green
            }`}
            style={{ right: `${100 - rightPercent}%` }}
          />
        </div>
      </div>
    );
  }
);

InputSlider.displayName = "InputSlider";
