import { ReactNode, FC } from "react";
import { Tag } from "../Tag";
import { Text } from "../Text";
import styles from "./FieldGroup.module.css";
import { Spacer } from "../Spacer";

interface FieldError {
  type: unknown;
  message?: string;
}

export interface FieldGroupProps {
  bodyClassName?: string;
  label?: string;
  required?: boolean;
  help?: ReactNode;
  error?: string | FieldError;
  children: ReactNode;
}

export const FieldGroup: FC<FieldGroupProps> = ({
  bodyClassName,
  label,
  required,
  help,
  error,
  children,
}) => {
  const errorMessage = typeof error === "object" ? error.message : error;
  const bodyStyles = [styles.body, bodyClassName || ""].join(" ");

  return (
    <div className={`${styles.root} ${error ? "error-field" : ""}`}>
      {label && (
        <div className={styles.label}>
          <Text weight="bold">{label}</Text>
          {required && <Tag color="danger">必須</Tag>}
        </div>
      )}
      <div className={bodyStyles}>{children}</div>
      {error && (
        <>
          <Text color="danger" size="sm">
            {errorMessage}
          </Text>
          <Spacer y="2x" />
        </>
      )}
      {help && (
        <Text color="default" size="sm">
          {help}
        </Text>
      )}
    </div>
  );
};
