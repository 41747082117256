import {
  Button,
  FieldGroup,
  FormGroup,
  Input,
  Paragraph,
  Form,
  PageTitle,
  useEffectOnce,
} from "@newt/ui";
import { useAuth } from "@utils/auth";
import { MarketingService } from "@utils/marketing";
import { ConfirmationResult } from "firebase/auth";
import { FC } from "react";
import { usePhoneForm } from "./usePhoneForm";

interface PhoneFormProps {
  setConfirmationResult: (value: ConfirmationResult) => void;
  setPhoneNumber: (value: string) => void;
  phoneNumber: string | undefined;
}

export const PhoneForm: FC<PhoneFormProps> = ({
  setConfirmationResult,
  setPhoneNumber,
  phoneNumber,
}) => {
  const auth = useAuth();

  const { register, onFormSubmit, errors, isSubmitting, errorCount } =
    usePhoneForm({
      setConfirmationResult,
      setPhoneNumber,
      phoneNumber,
    });

  const buttonId = auth.firebase?.useCaptcha(
    `signInWithPhoneNumber-${errorCount}`
  );

  useEffectOnce(() => {
    MarketingService.logEvent.viewSmsVerification();
  });

  return (
    <>
      <PageTitle align="center" size={{ mobile: "2xl", tablet: "4xl" }}>
        ログイン / 新規登録
      </PageTitle>
      <Form onSubmit={onFormSubmit}>
        <FormGroup
          title="電話番号を入力してください🤳"
          help={
            <Paragraph color="gray">
              SMSで認証コードを送ります✉️ <br />
              日本国内で受信できる番号を入力してください。
            </Paragraph>
          }
        >
          <FieldGroup label="電話番号" error={errors["phoneNumber"]}>
            <Input
              type="tel"
              placeholder="電話番号を入力"
              data-testid="login-phoneNumber-textField"
              {...register("phoneNumber")}
            />
          </FieldGroup>
          <Button
            id={buttonId}
            key={buttonId}
            isLoading={isSubmitting}
            data-testid="login-phoneNumber-submit"
          >
            次へ
          </Button>
        </FormGroup>
      </Form>
    </>
  );
};
