import { DateTime } from "luxon";

export const getGADates = (checkInDate: string, checkOutDate: string) => {
  const fromDate = DateTime.fromISO(checkInDate).setLocale("ja");
  const toDate = DateTime.fromISO(checkOutDate).setLocale("ja");
  const diffDate =
    checkInDate && checkOutDate
      ? (toDate.diff(fromDate, "days").get("days") + 1).toString()
      : "";

  return { diffDate, fromDate: fromDate.toSeconds().toString() };
};
