import { gql } from "graphql-request";
import { Text } from "@newt/ui";
import styles from "./HistoryContent.module.css";
import { HistoryContent_UserTourSearchHistoryFragment } from "@graphql/types";
import Link from "next/link";

export const HISTORY_CONTENT_FRAGMENT = gql`
  fragment HistoryContent_UserTourSearchHistory on UserTourSearchHistory {
    id
    destinations {
      name
      displayName
      mainImage {
        caption
        url
      }
    }
    destinationsDisplayName
    travelDates
    travelers
    filters
    url
  }
`;

interface HistoryContentProps {
  fragment: HistoryContent_UserTourSearchHistoryFragment;
}

export function HistoryContent(props: HistoryContentProps) {
  const { fragment } = props;

  return (
    <Link href={fragment.url} className={styles.root} passHref>
      {fragment.destinations[0].mainImage && (
        <figure className={styles.thumbnail}>
          <img
            src={fragment.destinations[0].mainImage.url}
            alt={fragment.destinations[0].mainImage.caption || ""}
          />
        </figure>
      )}
      <div className={styles.details}>
        <dl>
          <dt>
            <Text weight="bold" truncate>
              {fragment.destinationsDisplayName}
            </Text>
          </dt>
          <dd>
            <Text size={{ mobile: "sm", tablet: "base" }} truncate>
              {fragment.travelDates}
            </Text>
          </dd>
          <dd>
            <Text size={{ mobile: "sm", tablet: "base" }} truncate>
              {fragment.travelers}
            </Text>
          </dd>
          {fragment.filters && (
            <dd>
              <Text size={{ mobile: "sm", tablet: "base" }} truncate>
                {fragment.filters}
              </Text>
            </dd>
          )}
        </dl>
      </div>
    </Link>
  );
}
