import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconLeaf: FC<IconTypeProps> = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.53751 3.0375C-0.509995 5.085 -0.509995 8.4 1.52251 10.4475C2.62501 7.8975 4.59001 5.7675 7.04251 4.5C4.96501 6.255 3.51001 8.7075 3.00001 11.49C4.95001 12.4125 7.35001 12.075 8.96251 10.4625C11.5725 7.8525 12 0 12 0C12 0 4.14751 0.4275 1.53751 3.0375Z"
        fill="#00CD68"
      />
    </svg>
  );
};
