import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconOption: FC<IconTypeProps> = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 5.5V19.5H5V5.5H19ZM20.1 3.5H3.9C3.4 3.5 3 3.9 3 4.4V20.6C3 21 3.4 21.5 3.9 21.5H20.1C20.5 21.5 21 21 21 20.6V4.4C21 3.9 20.5 3.5 20.1 3.5ZM11 7.5H17V9.5H11V7.5ZM11 11.5H17V13.5H11V11.5ZM11 15.5H17V17.5H11V15.5ZM7 7.5H9V9.5H7V7.5ZM7 11.5H9V13.5H7V11.5ZM7 15.5H9V17.5H7V15.5Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};
