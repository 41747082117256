import { CSSProperties, FC } from "react";
import { LazyImage } from "../LazyImage";
import styles from "./LinkCard.module.css";

export interface LinkCardModel {
  alt?: string
  imageUrl: string | null;
  title: string;
  href: string;
}

interface LinkCardProps {
  readonly model: LinkCardModel;
  readonly width?: CSSProperties["width"];
}

export const LinkCard: FC<LinkCardProps> = ({ model, width }) => (
  <a className={styles.root} href={model.href} target="_blank" rel="noreferrer">
    <section className={styles.inner} style={{ width }}>
      <div className={styles.thumbnail}>
        {model.imageUrl && <LazyImage alt={model.alt} src={model.imageUrl} aspectRatio={1} />}
      </div>
      <div className={styles.main}>
        <header>
          <p className={styles.title}>{model.title}</p>
        </header>
      </div>
    </section>
  </a>
);
