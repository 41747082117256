import { FC, ReactNode, useRef } from "react";
import { Icon } from "../../Icon";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import styles from "./TooltipContainer.module.css";

export type Position =
  | "top"
  | "topLeft"
  | "topRight"
  | "bottom"
  | "bottomLeft"
  | "bottomRight";

export interface TooltipContainerProps {
  children: ReactNode;
  position?: Position;
  color?: "white" | "green";
  onClose: () => void;
}

export const TooltipContainer: FC<TooltipContainerProps> = ({
  children,
  position = "top",
  color = "white",
  onClose,
}) => {
  const root = useRef<HTMLDivElement>(null);

  useOutsideClick<HTMLDivElement>(root, onClose);

  return (
    <div
      ref={root}
      className={`${styles.tooltip} ${styles[`position-${position}`]} ${
        styles[`color-${color}`]
      }`}
    >
      <div className={styles.inner}>{children}</div>
      <span
        className={styles.close}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClose();
        }}
      >
        <Icon icon="close" color="gray-80" size="sm" />
      </span>
    </div>
  );
};
