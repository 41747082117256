import { ReactNode, FC } from "react";
import {
  FontSizes,
  LineHeights,
  ResponsiveProp,
  Spacings,
} from "../../types/component.type";
import { Spacer } from "../Spacer";
import { Text } from "../Text";

interface PageTitleProps {
  children: ReactNode;
  align?: ResponsiveProp<"left" | "center" | "right">;
  as?: "span" | "h1" | "h2" | "h3" | "h4" | "h5";
  spacing?: ResponsiveProp<Spacings>;
  size?: ResponsiveProp<FontSizes>;
  lineHeight?: ResponsiveProp<LineHeights>;
}

export const PageTitle: FC<PageTitleProps> = ({
  children,
  as = "h1",
  align = "left",
  size = { mobile: "xl", tablet: "4xl" },
  spacing = { mobile: "8x", laptop: "16x" },
  lineHeight,
}) => {
  return (
    <>
      <Text
        as={as}
        size={size}
        weight="bold"
        align={align}
        lineHeight={lineHeight}
        data-testid="pageTitle-text"
      >
        {children}
      </Text>
      <Spacer y={spacing} />
    </>
  );
};
