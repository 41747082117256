import { Container } from "../../Container";
import { Stack } from "../../Stack";
import { Text } from "../../Text";
import styles from "./BasicFooter.module.css";

export const BasicFooter = () => {
  return (
    <div className={styles.root}>
      <Container>
        <Stack
          align={{ mobile: "start", tablet: "center" }}
          justify={{ mobile: "start", tablet: "between" }}
          direction={{ mobile: "column-reverse", tablet: "row" }}
          spacing={{ mobile: "6x", tablet: "1" }}
        >
          <Text>© 2021 Reiwa Travel, Inc.</Text>
          <Stack
            direction={{ mobile: "column", tablet: "row" }}
            spacing={{ mobile: "3x", tablet: "6x" }}
          >
            <a
              href="/terms"
              target="_blank"
              rel="noreferrer"
              className={styles.navLink}
            >
              <Text size="sm">利用規約</Text>
            </a>
            <a
              href="/privacy"
              target="_blank"
              rel="noreferrer"
              className={styles.navLink}
            >
              <Text size="sm">プライバシーポリシー</Text>
            </a>
            <a
              href="/license"
              target="_blank"
              rel="noreferrer"
              className={styles.navLink}
            >
              <Text size="sm">旅行業登録票</Text>
            </a>
            <a
              href="/help"
              target="_blank"
              rel="noreferrer"
              className={styles.navLink}
            >
              <Text size="sm">ヘルプセンター</Text>
            </a>
          </Stack>
        </Stack>
      </Container>
    </div>
  );
};
