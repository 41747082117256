import {
  CSSProperties,
  FC,
  useEffect,
  useRef,
  useState,
  ReactNode,
} from "react";
import styles from "./DropdownContainer.module.css";
import { createPortal } from "react-dom";
import { useOutsideClick } from "../../../../hooks/useOutsideClick";
import { Property } from "csstype";

export type DropdownContainerPosition =
  | "bottom-left"
  | "bottom-center"
  | "bottom-right";

export type DropdownContainerZIndex = "dropdown" | "follow" | "float";

export type DropdownContainerSize = "sm" | "md" | "lg";

export type DropdownCalcSize = (
  rect: DOMRect,
  elements: {
    root: HTMLDivElement;
    inner: HTMLDivElement;
  }
) => {
  maxWidth?: Property.MaxWidth | number;
  maxHeight?: Property.MaxHeight | number;
  minHeight?: Property.MinHeight | number;
};

interface DropdownContainerProps {
  className?: string;
  children: ReactNode;
  rect: DOMRect | null;
  closeDropdown: () => void;
  position?: DropdownContainerPosition;
  zIndex?: DropdownContainerZIndex;
  size?: DropdownContainerSize;
  fixed?: boolean;
  noPadding?: boolean;
  resizable?: boolean;
  calcSize?: DropdownCalcSize;
}

export const DropdownContainer: FC<DropdownContainerProps> = ({
  className,
  children,
  rect,
  position = "bottom-left",
  zIndex = "dropdown",
  size = "md",
  closeDropdown,
  fixed = false,
  resizable = true,
  noPadding = false,
  calcSize,
}) => {
  const root = useRef<HTMLDivElement>(null);
  const inner = useRef<HTMLDivElement>(null);
  const [rootStyles, setRootStyles] = useState<CSSProperties>({});
  useOutsideClick<HTMLDivElement>(root, closeDropdown);

  const rootClasses = [
    className || "",
    styles.root,
    styles[`z-index--${zIndex}`],
    styles[`size--${size}`],
    fixed ? styles.fixed : "",
    noPadding ? styles.noPadding : "",
  ].join(" ");

  const { left = 0, width = 0, height = 0, top = 0 } = rect || {};

  const calcPositionStyles = (): CSSProperties => {
    switch (position) {
      case "bottom-center":
        return {
          left: fixed ? left + width / 2 : "50%",
          transform: `translateX(-50%)`,
        };
      case "bottom-left":
        return {
          left: fixed ? left : 0,
        };
      case "bottom-right":
        return {
          right: fixed ? window.innerWidth - left - width : 0,
        };
    }
  };

  const calcSizeStyles = () => {
    if (!resizable) return {};

    if (calcSize && rect && root.current && inner.current) {
      return calcSize(rect, { root: root.current, inner: inner.current });
    } else {
      return {
        maxHeight: window.innerHeight - (top + height) - 16,
      };
    }
  };

  useEffect(() => {
    setRootStyles({
      ...calcPositionStyles(),
      ...calcSizeStyles(),
      top: fixed ? top + height : height,
      opacity: 1,
    });
  }, [calcSize, resizable, size, position, fixed]);

  const Container = (
    <div
      ref={root}
      style={rootStyles}
      className={rootClasses}
      onScroll={(e) => e.stopPropagation()}
    >
      <div ref={inner} className={styles.inner}>
        {children}
      </div>
    </div>
  );

  return fixed ? createPortal(Container, document.body) : Container;
};
