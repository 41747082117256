import {
  TourPrice,
  TourPriceType,
  TourPointRange,
  BookingTourQuotationWithoutDateFragment,
} from "@graphql/types";

export const getTourPrice = (
  price?: Pick<TourPrice, "price" | "type"> | null
): string => {
  if (!price) return "売り切れ";
  const priceStr = price.price.toLocaleString();
  switch (price.type) {
    case TourPriceType.FROM:
      return `￥${priceStr}〜`;
    case TourPriceType.FIXED:
      return `￥${priceStr}`;
  }
};

export const getTourPriceWithoutDate = ({
  minPrice,
  maxPrice,
}: BookingTourQuotationWithoutDateFragment): string => {
  if (minPrice === 0 && maxPrice === 0) return "￥0";
  if (!minPrice && !maxPrice) return "売り切れ";
  if (minPrice === maxPrice) return `￥${minPrice.toLocaleString()}`;
  return [minPrice, maxPrice]
    .map((v) => `￥${v.toLocaleString()}`)
    .join(" 〜 ");
};

export const getTourPoint = (
  price?: Pick<TourPrice, "points" | "pointRange"> | null
): string | null => {
  if (!price || price.points === 0) return null;
  const pointStr = `${price.points.toLocaleString()}ポイント`;
  switch (price.pointRange) {
    case TourPointRange.FROM:
      return `${pointStr}〜`;
    case TourPointRange.FIXED:
      return pointStr;
  }
};
