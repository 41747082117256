export const DEFAULT_IMAGE =
  "https://placehold.jp/30/edfdf5/444/300x300.png?text=Image%20not%20found.";

// Social Network
export const TWITTER_LINK = "https://x.com/newt_travel?openExternalBrowser=1";
export const LINE_LINK = "https://lin.ee/ZKchfbF";
export const INSTAGRAM_LINK =
  "https://www.instagram.com/newt_guide/?hl=ja&openExternalBrowser=1";
export const TIKTOK_LINK =
  "https://www.tiktok.com/@newt_travel?openExternalBrowser=1";
export const YOUTUBE_LINK =
  "https://www.youtube.com/channel/UCdM6a4_8TQCR03ShAGU8CyQ?openExternalBrowser=1";

export const HEADER_IOS_DOWNLOAD =
  "https://app.adjust.com/yax7b7x?fallback=https%3A%2F%2Fapps.apple.com%2Fjp%2Fapp%2Fid1585901351";
export const HEADER_ANDROID_DOWNLOAD =
  "https://app.adjust.com/f1bxs70?redirect_macos=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dnet.newt&redirect_windows=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dnet.newt&redirect_ios=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dnet.newt";

export const LINE_CONCIERGE_LINK = "https://lin.ee/6wzhvP0f";
