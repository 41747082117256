import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconCoffeeMaker: FC<IconTypeProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H20V4H18V6C18 6.28333 17.9042 6.52083 17.7125 6.7125C17.5208 6.90417 17.2833 7 17 7H9C8.71667 7 8.47917 6.90417 8.2875 6.7125C8.09583 6.52083 8 6.28333 8 6V4H6V20H10.05C9.41667 19.55 8.91667 18.9792 8.55 18.2875C8.18333 17.5958 8 16.8333 8 16V11H18V16C18 16.8333 17.8167 17.5958 17.45 18.2875C17.0833 18.9792 16.5833 19.55 15.95 20H20V22H6ZM13 19C13.8333 19 14.5417 18.7083 15.125 18.125C15.7083 17.5417 16 16.8333 16 16V13H10V16C10 16.8333 10.2917 17.5417 10.875 18.125C11.4583 18.7083 12.1667 19 13 19ZM13 10C13.2833 10 13.5208 9.90417 13.7125 9.7125C13.9042 9.52083 14 9.28333 14 9C14 8.71667 13.9042 8.47917 13.7125 8.2875C13.5208 8.09583 13.2833 8 13 8C12.7167 8 12.4792 8.09583 12.2875 8.2875C12.0958 8.47917 12 8.71667 12 9C12 9.28333 12.0958 9.52083 12.2875 9.7125C12.4792 9.90417 12.7167 10 13 10Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};
