import { Children, FC, ReactNode, createElement, forwardRef } from "react";
import { Icon } from "../Icon";
import { Stack } from "../Stack";
import { Text } from "../Text";
import styles from "./Breadcrumb.module.css";

type TextType = "span" | "h1" | "h2" | "h3" | "h4" | "h5";

interface BreadcrumbItemProps {
  children: ReactNode;
  href?: string;
  onClick?: () => void;
  textType?: TextType;
}

export const BreadcrumbItem = forwardRef<
  HTMLAnchorElement,
  BreadcrumbItemProps
>((props, ref) => {
  const { children, onClick, href, textType } = props;

  return createElement(
    href ? "a" : "div",
    { href, onClick, ref },
    <Text size="sm" color={href ? "link" : "black"} as={textType}>
      {children}
    </Text>
  );
});

BreadcrumbItem.displayName = "BreadcrumbItem";

interface BreadcrumbProps {
  children: ReactNode[];
}

export const Breadcrumb: FC<BreadcrumbProps> & {
  Item: typeof BreadcrumbItem;
} = ({ children }) => {
  const items =
    children &&
    Children.map(children, (child, index) => {
      if (!child) return null;
      const isLastChild = index >= children.length - 1;

      if (isLastChild) {
        return child;
      }

      return [child, <Icon key={index} icon="chevronRight" color="gray-30" />];
    });

  return (
    <div className={styles.root}>
      <Stack direction="row" align="center">
        {items}
      </Stack>
    </div>
  );
};

Breadcrumb.Item = BreadcrumbItem;
