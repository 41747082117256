import { IS_PRODUCTION_ENV } from "@constants";

export const CHAT_FIELD_IDS = {
  NAME: IS_PRODUCTION_ENV ? "23191481717145" : "36356499754649",
  PHONE: IS_PRODUCTION_ENV ? "23191249919129" : "36356509792409",
  TOUR_CODE: IS_PRODUCTION_ENV ? "26128053408153" : "36356495495449",
  RESERVATION_ID: IS_PRODUCTION_ENV ? "6047081974681" : "36356507841305",
};

export const ZENDESK_KEY = process.env.NEXT_PUBLIC_ZENDESK_KEY || "";
