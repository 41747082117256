import { CountryDestination } from "./types";

export function hasSelectedChip(
  countries: CountryDestination[],
  selectedValues?: number[]
) {
  if (!selectedValues) return false;

  const mappedValues = new Set(selectedValues);

  return countries.some(
    (country) =>
      mappedValues.has(country.id) ||
      country.cities?.some((city) => mappedValues.has(city.id))
  );
}
