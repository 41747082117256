import { FC, SyntheticEvent } from "react";
import { LazyImage } from "../../../LazyImage";
import styles from "./GallerySection.module.css";

export type GalleryImage = {
  indexInAll?: number; // index in all category
  caption?: string | null;
  url: string;
};

export type ImageCategory = {
  id: string;
  images: GalleryImage[];
  imagesCount: number;
  name: string;
};

export interface GallerySectionProps {
  imageCategory: ImageCategory;
  onClick?: (index: number) => void;
}

export const GallerySection: FC<GallerySectionProps> = ({
  imageCategory,
  onClick,
}) => {
  return (
    <section className={styles.root}>
      <h2 className={styles.title}>
        {`${imageCategory.name} (${imageCategory.imagesCount})`}
      </h2>
      <div className={styles.grid}>
        {imageCategory.images.map((c, index) => (
          <div
            key={c.url}
            id={`GallerySection-${c.indexInAll || index}`} // for scrollIntoView
            onClick={() => onClick?.(c.indexInAll || index)}
          >
            <LazyImage
              imgClassName={styles.image}
              src={c.url}
              alt={c.caption || ""}
            />
          </div>
        ))}
      </div>
    </section>
  );
};
