import { FC, useEffect, useRef, ReactNode } from "react";
import style from "./ShareTicket.module.css";

interface ShareTicketProps {
  enableRotate?: boolean;
  enableShadow?: boolean;
  cardFront: ReactNode;
  cardBack: ReactNode;
  wrapperRef: React.RefObject<HTMLDivElement>;
}

export const ShareTicket: FC<ShareTicketProps> = (props) => {
  const {
    enableRotate = true,
    enableShadow,
    cardFront,
    cardBack,
    wrapperRef,
  } = props;
  const cardRef = useRef<HTMLDivElement>(null);
  const cardFrontRef = useRef<HTMLDivElement>(null);
  const cardBackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!enableRotate) {
      return;
    }
    const card = cardRef.current;

    let autoRotateMode = true;
    let isDragging = false;
    let startX = 0;
    let currentRotation = 0;
    let initialRotation = 0;
    let lastMouseX = 0;
    let velocity = 0;

    function handleDragStart(e: MouseEvent | TouchEvent) {
      if (!card) return;
      e.preventDefault();
      isDragging = true;
      autoRotateMode = false;
      const clientX = "touches" in e ? e.touches[0].clientX : e.clientX;
      startX = clientX;
      initialRotation = currentRotation;
      lastMouseX = clientX;
      velocity = 0;
      card.style.transition = "none";
      card.classList.add("is-dragging");
    }

    function handleDragMoving(e: MouseEvent | TouchEvent) {
      if (isDragging) {
        const clientX = "touches" in e ? e.touches[0].clientX : e.clientX;
        const deltaX = clientX - startX;
        currentRotation = initialRotation + deltaX;
        normalizeRotation();
        updateCardTransformAndDarkness(currentRotation);

        const mouseDelta = clientX - lastMouseX;
        velocity = mouseDelta / 5;
        lastMouseX = clientX;
      }
    }

    function handleDragEnd() {
      if (!card) return;
      if (isDragging) {
        isDragging = false;
        card.classList.remove("is-dragging");
        // dragが終わっても少しだけ回転を維持する
        card.style.transition = "transform 2s ease-out, filter 2s ease-out";
        currentRotation += velocity * 40;
        normalizeRotation();
        updateCardTransformAndDarkness(currentRotation);
        autoRotateMode = true;
      }
    }

    wrapperRef.current?.addEventListener("mousedown", handleDragStart);
    wrapperRef.current?.addEventListener("mousemove", handleDragMoving);
    wrapperRef.current?.addEventListener("mouseup", handleDragEnd);
    wrapperRef.current?.addEventListener("mouseleave", handleDragEnd);

    wrapperRef.current?.addEventListener("touchstart", handleDragStart);
    wrapperRef.current?.addEventListener("touchmove", handleDragMoving);
    wrapperRef.current?.addEventListener("touchend", handleDragEnd);
    wrapperRef.current?.addEventListener("touchcancel", handleDragEnd);

    function updateCardTransformAndDarkness(rotation: any) {
      /** カードを回転させる */
      if (card) {
        card.style.transform = `rotateY(${rotation}deg) rotateZ(15deg)`;
      }
      /** 回転角によって明るさを変える */
      if (enableShadow) {
        const normalizedRotation = ((rotation % 360) + 360) % 360;
        const frontBrightness =
          0.8 + 0.2 * Math.abs(Math.cos((normalizedRotation * Math.PI) / 180));
        const backBrightness =
          0.8 +
          0.2 *
            Math.abs(Math.cos(((normalizedRotation + 180) * Math.PI) / 180));
        if (cardFrontRef.current) {
          cardFrontRef.current.style.filter = `brightness(${frontBrightness})`;
        }
        if (cardBackRef.current) {
          cardBackRef.current.style.filter = `brightness(${backBrightness})`;
        }
      }
    }

    // currentRotation がオーバーフローしないように一定の範囲内に収める
    function normalizeRotation() {
      currentRotation = currentRotation % (360 * 3);
      if (currentRotation < 0) {
        currentRotation += 360;
      }
    }

    function autoRotateCard() {
      if (autoRotateMode) {
        currentRotation += 0.5;
        normalizeRotation();
        updateCardTransformAndDarkness(currentRotation);
      }
      requestAnimationFrame(autoRotateCard);
    }

    requestAnimationFrame(autoRotateCard);
  }, [enableRotate, wrapperRef]);

  return (
    <div className={style.scene}>
      <div className={style.card} ref={cardRef}>
        <div
          ref={cardFrontRef}
          className={`${style.card__face} ${style["card__front"]}`}
        >
          {cardFront}
        </div>
        <div
          ref={cardBackRef}
          className={`${style.card__face} ${style["card__back"]}`}
        >
          {cardBack}
        </div>
      </div>
    </div>
  );
};
