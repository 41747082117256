import { TravelDateFlexibility } from "@graphql/types";
import { ChangeEventHandler, FC } from "react";
import styles from "./TravelDateFlexibilitySegmentButton.module.css";
import { SegmentButton } from "@newt/ui";

const TRAVEL_DATE_FLEXIBILITY_OPTIONS = [
  {
    label: "指定日のみ",
    value: TravelDateFlexibility.FIXED,
  },
  {
    label: "±1日",
    value: TravelDateFlexibility.PLUS_MINUS_1DAY,
  },
  {
    label: "±2日",
    value: TravelDateFlexibility.PLUS_MINUS_2DAY,
  },
  {
    label: "±3日",
    value: TravelDateFlexibility.PLUS_MINUS_3DAY,
  },
  {
    label: "±7日",
    value: TravelDateFlexibility.PLUS_MINUS_7DAY,
  },
];

type Props = {
  value: TravelDateFlexibility;
  onChange: (value: TravelDateFlexibility) => void;
};

export const TravelDateFlexibilitySegmentButton: FC<Props> = ({
  value,
  onChange,
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(e.target.value as TravelDateFlexibility);
  };

  return (
    <SegmentButton
      className={styles.root}
      options={TRAVEL_DATE_FLEXIBILITY_OPTIONS}
      value={value}
      onChange={handleChange}
    />
  );
};
