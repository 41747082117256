import { gtm } from "../gtm";

export interface AddToWishlistEventVariables {
  // ツアーID
  item_id: string;
  // ツアー名
  item_name: string;
  // 商品の表示金額
  price: string;
  // 商品の表示金額（最安値）金額幅がある場合の最安値を詰めるが、単一金額の場合は空文字
  min_price?: string;
  // 商品の表示金額（最高値）金額幅がある場合の最大値を詰めるが、単一金額の場合は空文字
  max_price?: string;
  // 商品セールタイプ
  sales_type: "regular" | "sale";
  // 商品カテゴリー
  item_category: "tour";
  // 出発空港
  departure?: string;
  // 目的地
  destination: string;
  // 出発日(Unix Timestamp)
  departure_date?: string;
  // 旅行日数
  travel_days?: string;
  // 部屋数
  rooms?: string;
  // 大人人数
  adults?: string;
  // 子ども（ベッドあり）人数
  children_with_beds?: string;
  // 子ども（ベッドなし）人数
  children_without_beds?: string;
  // 乳幼児人数
  infants?: string;
  // お気に入り追加数（固定値で1を入れる）
  add_to_wishlist_count: 1;

  // Eコマース
  ecommerce: {
    // イベントに関連づけられた商品アイテムの通貨（ISO 4217）
    currency?: string;
    // 合計金額（諸税や比嘉料金を含めた合計額）
    value?: number;
  };
}

export const addToWishlistEvent = (variables?: AddToWishlistEventVariables) => {
  gtm.pushEvent("add_to_wishlist", variables);
};
