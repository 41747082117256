import { forwardRef, useRef } from "react";
import styles from "./InputStepper.module.css";
import { Icon } from "../Icon";

interface InputStepperProps {
  name: string;
  value: number;
  min?: number;
  max?: number;
  onChange: (v: number) => void;
}

export const InputStepper = forwardRef<HTMLInputElement, InputStepperProps>(
  ({ min = 0, max = 20, name, value, onChange }, ref) => {
    const root = useRef<HTMLDivElement>(null);

    const increment = () => {
      if (max <= value) return;
      onChange(value + 1);
    };
    const decrement = () => {
      if (min >= value) return;
      onChange(value - 1);
    };

    return (
      <div ref={root} className={styles.root}>
        <button
          className={styles.button}
          disabled={value === min}
          onClick={decrement}
        >
          <Icon icon="remove" size="sm" />
        </button>
        <input
          className={styles.input}
          type="text"
          name={name}
          readOnly={true}
          value={value}
          ref={ref}
        />
        <button
          className={styles.button}
          disabled={value === max}
          onClick={increment}
        >
          <Icon icon="add" size="sm" />
        </button>
      </div>
    );
  }
);

InputStepper.displayName = "InputStepper";
