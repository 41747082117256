import { FC, useEffect, useState, ReactNode } from "react";
import styles from "./Overlay.module.css";
import { useBodyScroll } from "../../hooks/useBodyScroll";
import { useIsMounted } from "../../hooks/useIsMounted";

interface OverlayProps {
  children: ReactNode;
  durationMs?: number;
}

export const DATA_OVERLAY_ATTR = "data-overlay";

export const getNumOfOverlays = () => {
  return document.querySelectorAll(`[${DATA_OVERLAY_ATTR}]`).length;
};

export const OVERLAY_DURATION = "0.20s";

export const Overlay: FC<OverlayProps> = ({ children }) => {
  const [numOfOverlay, setNumOfOverlay] = useState(0);
  const { isMounted } = useIsMounted();
  useBodyScroll();

  useEffect(() => {
    // TODO: avoid duplicate overlay with numOfOverlay in case modal on modal
    setNumOfOverlay(getNumOfOverlays());
  }, []);

  return (
    <div
      className={[isMounted ? styles.opened : styles.init, styles.root].join(
        " "
      )}
      data-overlay={numOfOverlay}
    >
      {children}
    </div>
  );
};
