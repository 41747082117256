import { FC } from "react";
import {
  confirm,
  Button,
  FieldGroup,
  Form,
  Icon,
  Input,
  PaymentIcon,
  PaymentIconType,
  Stack,
  Text,
  FieldGroupProps,
} from "../../";
import { Control, Controller } from "react-hook-form";
import styles from "./CreditCardFormEdit.module.css";

interface Card {
  readonly brand: string;
  readonly maskedCardNumber: string;
  readonly expMonth: string;
  readonly expYear: string;
}

interface CreditCardFormEditValues {
  readonly holderName: string;
}

interface CreditCardFormEditProps {
  readonly card: Card;
  readonly control: Control<CreditCardFormEditValues, object>;
  readonly isSubmitting: boolean;
  readonly errors: Partial<
    Record<keyof CreditCardFormEditValues, FieldGroupProps["error"]>
  >;
  onEdit: () => void;
  onDelete?: () => void;
}

export const CreditCardFormEdit: FC<CreditCardFormEditProps> = ({
  card,
  control,
  isSubmitting,
  errors,
  onEdit,
  onDelete,
}) => {
  const handleDelete = () => {
    confirm("このクレジットカードの情報を、本当に削除してもいいですか？", {
      acceptLabel: "削除する",
      onAccept: () => onDelete?.(),
    });
  };

  return (
    <>
      <Form onSubmit={onEdit}>
        <FieldGroup>
          <PaymentIcon icon={card.brand as PaymentIconType} />
        </FieldGroup>
        <FieldGroup
          label="カード名義人（ローマ字）"
          error={errors["holderName"]}
        >
          <Controller
            control={control}
            name="holderName"
            render={({ field }) => (
              <Input
                {...field}
                value={field.value}
                error={!!errors["holderName"]}
                placeholder="SAKURA YAMADA"
                inputMode="text"
              />
            )}
          />
        </FieldGroup>
        <FieldGroup label="カード番号">
          <Input
            name="cardNumber"
            value={card.maskedCardNumber}
            disabled={true}
          />
        </FieldGroup>
        <Stack spacing="6x">
          <div style={{ width: "150px" }}>
            <FieldGroup label="有効期限">
              <Input
                name="cardExpiry"
                value={`${card.expMonth} / ${card.expYear}`}
                disabled={true}
              />
            </FieldGroup>
          </div>
          <div style={{ width: "150px" }}>
            <FieldGroup label="セキュリティコード">
              <Input
                name="cardCvc"
                value={card.brand === "AMEX" ? "●●●●" : "●●●"}
                disabled={true}
              />
            </FieldGroup>
          </div>
        </Stack>
        <Button
          disabled={Object.keys(errors).length > 0}
          isLoading={isSubmitting}
          width={{ tablet: "md" }}
          size={{ mobile: "md", tablet: "lg" }}
        >
          編集を保存する
        </Button>
      </Form>
      {!!onDelete && (
        <div className={styles.deleteButton}>
          <Button
            variant={"ghost"}
            width={"content"}
            size={{ mobile: "md", tablet: "lg" }}
            onClick={handleDelete}
          >
            <Text color="danger" weight="bold">
              <Icon icon="removeCircle" color="danger" />
              このカードを削除する
            </Text>
          </Button>
        </div>
      )}
    </>
  );
};
