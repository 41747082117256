import { HistoryContent_UserTourSearchHistoryFragment } from "@graphql/types";
import { CarouselDisplay, Section } from "@newt/ui";
import { HistoryContent } from "./HistoryContent";
import styles from "./SectionSearchHistory.module.css";

interface SectionSearchHistoryProps {
  histories?: HistoryContent_UserTourSearchHistoryFragment[];
}

export const SectionSearchHistory = ({
  histories,
}: SectionSearchHistoryProps) => {
  if (!histories || histories?.length === 0) return null;
  return (
    <Section title="🔎 最近の検索" textAlign="left">
      <div className={styles.carousel}>
        <CarouselDisplay
          itemWidth={352}
          show={3}
          items={histories.map((fragment) => (
            <HistoryContent key={fragment.id} fragment={fragment} />
          ))}
        />
      </div>
    </Section>
  );
};
