import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconKitchen: FC<IconTypeProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.875 7.80156V4.85156H9.425V7.80156H7.875ZM7.875 16.7766V11.9266H9.425V16.7766H7.875ZM5.7 22.1516C5.23333 22.1516 4.83333 21.9849 4.5 21.6516C4.16667 21.3182 4 20.9182 4 20.4516V3.55156C4 3.0849 4.16667 2.6849 4.5 2.35156C4.83333 2.01823 5.23333 1.85156 5.7 1.85156H18.6C19.0667 1.85156 19.4667 2.01823 19.8 2.35156C20.1333 2.6849 20.3 3.0849 20.3 3.55156V20.4516C20.3 20.9182 20.1333 21.3182 19.8 21.6516C19.4667 21.9849 19.0667 22.1516 18.6 22.1516H5.7ZM5.7 20.4516H18.6V10.6016H5.7V20.4516ZM5.7 9.10156H18.6V3.55156H5.7V9.10156Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};
