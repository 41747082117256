import { ReactNode, FC } from "react";
import { Paragraph } from "../Paragraph";
import styles from "./Note.module.css";

interface NoteProps {
  children: ReactNode;
  color?: "primary" | "disabled"; // ToDo: default, danger
  variant?: "solid" | "outline"; // ToDo: ghost
  weight?: "normal" | "bold";
  centered?: boolean;
}

export const Note: FC<NoteProps> = ({
  children,
  color = "primary",
  variant = "solid",
  weight = "normal",
  centered,
}) => {
  const className = [
    styles.root,
    styles[`${color}--${variant}`],
    centered ? styles.centered : "",
    weight === "bold" ? styles.bold : "",
  ].join(" ");

  return (
    <div className={className}>
      <p>{children}</p>
    </div>
  );
};
