import { FC } from "react";
import { useResponsiveProp } from "../../hooks/useResponsiveProp";
import { ResponsiveProp, Spacings } from "../../types/component.type";
import styles from "./Spacer.module.css";

interface SpacerProps {
  x?: ResponsiveProp<Spacings>;
  y?: ResponsiveProp<Spacings>;
}

export const Spacer: FC<SpacerProps> = ({ x = "1", y = "4x" }) => {
  const responsiveSpacingX = useResponsiveProp(x, "1");
  const responsiveSpacingY = useResponsiveProp(y, "4x");
  return (
    <div
      className={`${styles.root} ${styles[`vertical--${responsiveSpacingY}`]} ${
        styles[`horizontal--${responsiveSpacingX}`]
      }`}
    />
  );
};
