import { SITE_URL_NEW } from "@constants";
import { GetHighlightedTlUrl_TourSnapshotFragment } from "@graphql/types";
import { ROUTES } from "@routes";
import { gql } from "graphql-request";

const _TOUR_TICKET_FRAGMENT = gql`
  fragment GetHighlightedTlUrl_TourSnapshot on TourSnapshot {
    id
    destinationAirport {
      destinationId
    }
  }
`;

export const getHighlightedTlUrl = (
  tour?: GetHighlightedTlUrl_TourSnapshotFragment | null
) => {
  if (!tour) {
    return null;
  }

  const destinationId = tour.destinationAirport?.destinationId;

  return (
    SITE_URL_NEW +
    ROUTES.search({
      highlightedTourId: tour.id,
      destinationIds: destinationId ? [destinationId] : [],
    })
  );
};
