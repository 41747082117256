import { FC } from "react"
import { IconTypeProps } from '../Icon'

export const IconWifiOff: FC<IconTypeProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.99 8.99906C19.15 5.15906 13.8 3.75906 8.84 4.77906L11.36 7.29906C14.83 7.12906 18.35 8.34906 20.99 10.9991L22.99 8.99906ZM18.99 12.9991C17.7 11.7091 16.15 10.8691 14.5 10.4391L18.03 13.9691L18.99 12.9991ZM2 3.04906L5.07 6.09906C3.6 6.81906 2.22 7.77906 1 8.99906L2.99 10.9991C4.23 9.75906 5.66 8.83906 7.19 8.22906L9.43 10.4691C7.81 10.8891 6.27 11.7291 5 12.9991V13.0091L6.99 14.9991C8.35 13.6391 10.13 12.9591 11.91 12.9391L18.98 19.9991L20.25 18.7391L3.29 1.78906L2 3.04906ZM9 16.9991L12 19.9991L15 16.9991C13.35 15.3391 10.66 15.3391 9 16.9991Z" fill="#757575"/>
    </svg>
  )
}
