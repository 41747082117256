import RouterQueryUtils, {
  RouterQueryInput,
  RouterQueryParams,
} from "@utils/router/routerQuery";

export interface SignInPageQuery extends RouterQueryInput {
  redirectTo?: string;
  previousModuleType?: string;
}

export const toSignInPageQuery = (
  query: RouterQueryParams
): SignInPageQuery => {
  return {
    redirectTo: RouterQueryUtils.getString(query.redirectTo),
    previousModuleType: RouterQueryUtils.getString(query.previousModuleType),
  };
};
