import { TimeOfDay } from "@graphql/types";
import { AirPlanFlightItemFragment, FlightTimeType } from "@graphql/types";

export interface FlightTimeConfig {
  type: AirPlanFlightItemFragment["departureTimeType"];
  time: AirPlanFlightItemFragment["departureTime"];
  from: AirPlanFlightItemFragment["departureTimeFrom"];
  to: AirPlanFlightItemFragment["departureTimeTo"];
}

export const getFlightTime = (config: FlightTimeConfig) => {
  const { type, time, from, to } = config;
  switch (type) {
    case FlightTimeType.FIXED:
      return time || "";
    case FlightTimeType.RANGE:
      return [from ?? "", "〜", to ?? ""].filter((v) => v !== "").join("");
    case FlightTimeType.NOT_YET_FIXED:
      return null;
  }
};

export const getFlightTimeOfDay = (time: TimeOfDay) => {
  switch (time) {
    case TimeOfDay.UNSPECIFIED:
      return "おまかせ";
    case TimeOfDay.EARLY_MORNING:
      return "早朝発";
    case TimeOfDay.MORNING:
      return "午前発";
    case TimeOfDay.AFTERNOON:
      return "午後発";
    case TimeOfDay.EARLY_EVENING:
      return "夕刻発";
    case TimeOfDay.NIGHT:
      return "夜発";
    case TimeOfDay.MIDNIGHT:
      return "深夜発";
    default:
      return "unknown";
  }
};
