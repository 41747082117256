import { FC } from "react";
import styles from "./Rating.module.css";

export type RatingSize = "base" | "lg";

interface RatingProps {
  value: number;
  size?: RatingSize;
}

export const Rating: FC<RatingProps> = ({ value, size = "base" }) => {
  const rootClasses = [styles.root, styles[`size--${size}`]].join(" ");
  return (
    <div className={rootClasses}>
      {[...Array(value)].map((_, index) => (
        <i key={index} className={styles.icon} />
      ))}
    </div>
  );
};
