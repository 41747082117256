import { useState, useEffect, useCallback } from "react";

type OnExceedLimit = () => void;

const useScrollVisibility = (
  limit: number,
  onExceedLimit: OnExceedLimit,
  isVisible: boolean
): void => {
  const [startScrollPosition, setStartScrollPosition] = useState(0);
  const [totalScrollDistance, setTotalScrollDistance] = useState(0);

  const handleScroll = useCallback(() => {
    const currentScrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    const scrollDistance = Math.abs(
      currentScrollPosition - startScrollPosition
    );
    setTotalScrollDistance((prevTotal) => prevTotal + scrollDistance);
    setStartScrollPosition(currentScrollPosition);

    if (totalScrollDistance > limit) {
      onExceedLimit();
    }
  }, [startScrollPosition, totalScrollDistance, limit, onExceedLimit]);

  useEffect(() => {
    setStartScrollPosition(
      window.pageYOffset || document.documentElement.scrollTop
    );

    // isVisibleがtrueのときだけスクロールイベントリスナーを追加
    if (isVisible) {
      window.addEventListener("scroll", handleScroll);
    }

    // isVisibleがfalseになったとき、totalScrollDistanceをリセットし、スクロールイベントリスナーを削除
    if (!isVisible) {
      setTotalScrollDistance(0);
      window.removeEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, isVisible]);
};

export default useScrollVisibility;
