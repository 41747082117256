export const TikTokIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#25F4EE"
        d="M11.136 10.725v-.468a3.446 3.446 0 00-.49-.042 3.637 3.637 0 00-2.086 6.623 3.634 3.634 0 012.576-6.113z"
      ></path>
      <path
        fill="#25F4EE"
        d="M11.224 16.023a1.664 1.664 0 001.66-1.59v-7.93h1.449A2.718 2.718 0 0114.29 6h-1.982v7.923a1.663 1.663 0 01-1.66 1.602 1.687 1.687 0 01-.766-.192 1.663 1.663 0 001.341.69zm5.815-6.83v-.441A2.706 2.706 0 0115.54 8.3c.39.452.916.766 1.499.893z"
      ></path>
      <path
        fill="#FE2C55"
        d="M15.54 8.301a2.73 2.73 0 01-.678-1.801h-.53a2.744 2.744 0 001.208 1.801zM10.645 12.2a1.663 1.663 0 00-.766 3.135 1.66 1.66 0 011.836-2.557v-2.016a3.473 3.473 0 00-.49-.038h-.089v1.533a1.703 1.703 0 00-.49-.057z"
      ></path>
      <path
        fill="#FE2C55"
        d="M17.039 9.191v1.534a4.704 4.704 0 01-2.748-.893v4.028a3.645 3.645 0 01-3.645 3.637 3.607 3.607 0 01-2.085-.659 3.637 3.637 0 006.3-2.476v-4.017a4.711 4.711 0 002.753.882V9.253c-.193 0-.386-.021-.575-.062z"
      ></path>
      <path
        fill="#000"
        d="M14.29 13.863V9.835a4.703 4.703 0 002.752.881V9.183a2.748 2.748 0 01-1.503-.882A2.744 2.744 0 0114.332 6.5h-1.449v7.934a1.664 1.664 0 01-3.008.909 1.664 1.664 0 01.766-3.136c.166.002.332.027.49.077V10.75a3.634 3.634 0 00-2.575 6.132 3.607 3.607 0 002.089.617c2.01 0 3.64-1.627 3.645-3.637z"
      ></path>
    </svg>
  );
};
