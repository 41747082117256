import Image from "next/image";
import { FC } from "react";
import styles from "./FesHero.module.css";

export interface FesHeroProps {
  bgImgPcSrc: string;
  bgImgSpSrc: string;
  titleImgPcSrc: string;
  titleImgSpSrc: string;
  alt: string;
  onClick: () => void;
}

export const FesHero: FC<FesHeroProps> = (props) => {
  const { bgImgPcSrc, bgImgSpSrc, titleImgPcSrc, titleImgSpSrc, alt, onClick } =
    props;

  return (
    <a onClick={onClick} className={styles.link}>
      <header className={styles.root}>
        <div className={styles.pc}>
          <Image
            src={bgImgPcSrc}
            alt={`${alt} メイン画像`}
            loading="eager"
            fill={true}
            style={{ objectFit: "cover" }}
            priority={true}
            quality={90}
          />
        </div>
        <div className={styles.sp}>
          <Image
            src={bgImgSpSrc}
            alt={`${alt} メイン画像`}
            loading="eager"
            fill={true}
            style={{ objectFit: "cover" }}
            quality={90}
          />
        </div>
        <div className={styles.inner}>
          <div className={styles.title}>
            <div className={styles.pc}>
              <Image
                src={titleImgPcSrc}
                alt={`海外ツアー・ホテルの予約はNEWT（ニュート）│ ${alt}`}
                fill={true}
                style={{ objectFit: "contain" }}
                loading="eager"
                quality={90}
              />
            </div>
            <div className={styles.sp}>
              <Image
                src={titleImgSpSrc}
                alt={`海外ツアー・ホテルの予約はNEWT（ニュート）│ ${alt}`}
                fill={true}
                style={{ objectFit: "contain" }}
                loading="eager"
                quality={90}
              />
            </div>
          </div>
        </div>
      </header>
    </a>
  );
};
