import {
  DestinationDetailLink_DestinationFragment,
  DestinationDetailLinks_DestinationFragment,
  destinationsQuery,
} from "@graphql/types";

export const getCurrentDestination = (
  destinationId: number,
  destinations: DestinationDetailLinks_DestinationFragment[]
) => {
  for (const destination of destinations) {
    const foundCountry = destination.countries.find(
      (country) => country.id === destinationId
    );
    if (foundCountry) {
      return foundCountry;
    }

    for (const country of destination.countries) {
      const foundCity = country.cities.find(
        (city) => city.id === destinationId
      );
      if (foundCity) {
        return foundCity;
      }
    }
  }
};

/**
 * 国の場合：国とその国の都市を表示
 * 都市の場合：その都市の国とその国の都市を表示
 * @param destinationId
 * @param destinations
 * @param includeSelfDestination 自分自身のDestinationを含めるかどうか
 * @returns
 */
export const getSelectedDestinations = (
  destinationId: number,
  destinations: DestinationDetailLinks_DestinationFragment[],
  includeSelfDestination: boolean = true
) => {
  let selectedDestinations: DestinationDetailLink_DestinationFragment[] = [];

  destinations.forEach((destination) => {
    destination.countries.forEach((country) => {
      if (country.id === destinationId) {
        // If the destinationId matches the country's id
        if (includeSelfDestination) {
          selectedDestinations.push(country);
        }
        selectedDestinations = selectedDestinations.concat(
          country.cities.filter((city) => city.name !== country.name)
        );
      } else {
        country.cities.forEach((city) => {
          if (city.id === destinationId) {
            // If the destinationId matches the city's id
            if (includeSelfDestination) {
              selectedDestinations.push(country);
              if (city.name !== country.name) {
                selectedDestinations.push(city);
              }
            } else {
              if (city.name !== country.name) {
                selectedDestinations.push(country);
                selectedDestinations.push(city);
              }
            }
            // Select the other cities in the same country
            const otherCities = country.cities.filter(
              (c) => c.id !== city.id && c.name !== country.name
            );
            selectedDestinations = selectedDestinations.concat(otherCities);
          }
        });
      }
    });
  });

  return selectedDestinations;
};

/**
 * 国の場合：自身を除く同じ大陸で国の並び順が前から6つ表示
 * 都市の場合：自身を除く同じ大陸で国の並び順で1番目の都市を前から6つ表示
 * @param destinationId
 * @param destinations
 * @returns
 */
export const getRelatedDestinations = (
  destinationId: number,
  destinations: DestinationDetailLinks_DestinationFragment[]
) => {
  let relatedDestinations: DestinationDetailLink_DestinationFragment[] = [];

  destinations.forEach((destination) => {
    const cities: DestinationDetailLink_DestinationFragment[] = [];
    let selectedCity: DestinationDetailLink_DestinationFragment | null = null;
    destination.countries.forEach((country) => {
      if (country.id === destinationId) {
        relatedDestinations = relatedDestinations.concat(
          destination.countries.filter((c) => c.id !== country.id).slice(0, 6)
        );
      } else {
        country.cities.forEach((city, index) => {
          if (city.id === destinationId) {
            selectedCity = city;
          } else if (index === 0) {
            cities.push(city);
          }
        });
      }
    });
    if (selectedCity) {
      relatedDestinations.push(...cities.slice(0, 6));
    }
  });

  return relatedDestinations;
};

/**
 * 指定したdestinationIdsの国と都市を表示
 * @param destinationIds
 * @param destinations
 * @returns
 */
export const getPopularDestinationsByIds = (
  destinationIds: number[],
  destinations: destinationsQuery["destinations"]["edges"]
) => {
  const popularDestinations: DestinationDetailLink_DestinationFragment[] = [];

  const countries = destinations.flatMap((area) => area.node.countries);
  destinationIds.forEach((destinationId) => {
    countries.forEach((country) => {
      if (country.id === destinationId) {
        popularDestinations.push(country);
      } else {
        country.cities.forEach((city) => {
          if (city.id === destinationId) {
            popularDestinations.push(city);
          }
        });
      }
    });
  });

  return popularDestinations;
};
