import { FC, ReactNode, ReactElement, useState } from "react";
import { Icon } from "../Icon";
import { Position, TooltipContainer } from "./TooltipContainer";
import styles from "./Tooltip.module.css";

export interface TooltipProps {
  children: ReactNode;
  isVisible?: boolean;
  color?: "white" | "green";
  triggerElement?: ReactElement;
  position?: Position;
  onClose?: () => void;
}

export const Tooltip: FC<TooltipProps> = ({
  children,
  isVisible = false,
  triggerElement = <Icon icon="alertCircleOutline" color="gray-80" size="sm" />,
  color,
  position,
  onClose,
}) => {
  const [isActive, setIsActive] = useState(isVisible);

  const handleClickTrigger = () => setIsActive(true);
  const handleClose = () => {
    setIsActive(false);
    onClose && onClose();
  };

  return (
    <div className={styles.root}>
      {isActive && (
        <TooltipContainer
          onClose={handleClose}
          position={position}
          color={color}
        >
          {children}
        </TooltipContainer>
      )}
      <span onClick={handleClickTrigger}>{triggerElement}</span>
    </div>
  );
};
