import { FC, ReactNode } from "react";
import styles from "./Scroller.module.css";

export interface ScrollerProps {
  children: ReactNode;
}

export const Scroller: FC<ScrollerProps> = ({ children }) => {
  return (
    <div className={styles.root}>
      <div className={styles.inner}>{children}</div>
    </div>
  );
};
