import { PaymentMethodCard_GmoCardFragment } from "@graphql/types";
import { Icon, PaymentIcon, PaymentIconType } from "@newt/ui";
import { useRouter } from "next/router";
import { FC } from "react";
import { CreditCard } from "../CreditCard";
import styles from "./PaymentMethodCard.module.css";
import { gql } from "graphql-request";

const _PAYMENT_METHOD_CARD = gql`
  fragment PaymentMethodCard_GmoCard on GmoCard {
    brand
    expMonth
    expYear
    maskedCardNumber
    requireRegisterHolderName
  }
`;

enum CardStatus {
  SELECTED = "selected",
  UNSELECTED = "unselected",
  NOT_ADDED = "notAdded",
}

interface PaymentMethodCardProps {
  card?: PaymentMethodCard_GmoCardFragment;
  cardAddLink: string;
  cardSelectLink: string;
  icon?: "success" | "error";
}

export const PaymentMethodCard: FC<PaymentMethodCardProps> = ({
  card,
  cardAddLink,
  cardSelectLink,
  icon,
}) => {
  const router = useRouter();

  const cardStatus = !!card
    ? CardStatus.SELECTED
    : card === null
      ? CardStatus.NOT_ADDED
      : CardStatus.UNSELECTED;

  const handleClick = () => {
    const actionUrl =
      cardStatus === CardStatus.NOT_ADDED ? cardAddLink : cardSelectLink;
    router.push(actionUrl);
  };

  return (
    <div
      className={styles.root}
      onClick={handleClick}
      data-testid="payment-method-card"
    >
      {cardStatus === CardStatus.SELECTED && card && (
        <CreditCard
          icon={<PaymentIcon icon={card.brand as PaymentIconType} />}
          label={card.maskedCardNumber}
          placeholder={`${card.expMonth}/${card.expYear}`}
        />
      )}
      {cardStatus === CardStatus.NOT_ADDED && (
        <CreditCard
          icon={<PaymentIcon icon="UNKNOWN" />}
          label="追加する"
          placeholder="クレジットカードを追加"
        />
      )}
      {cardStatus === CardStatus.UNSELECTED && (
        <CreditCard
          icon={<PaymentIcon icon="UNKNOWN" />}
          label="選択する"
          placeholder="クレジットカードを選択"
        />
      )}
      <div className={styles.actions}>
        {icon === "success" && <Icon icon="checkCircle" color="primary" />}
        {icon === "error" && <Icon icon="alertCircle" color="danger" />}
        <Icon icon="chevronRight" />
      </div>
    </div>
  );
};
