import styles from "./SearchHistory.module.css";
import { FC } from "react";
import { Text, Icon } from "@newt/ui";
import Link from "next/link";
import { HistoryContent_UserTourSearchHistoryFragment } from "@graphql/types";

interface SearchHistoryProps {
  history?: HistoryContent_UserTourSearchHistoryFragment;
}

export const SearchHistory: FC<SearchHistoryProps> = ({ history }) => {
  if (!history) return null;

  return (
    <div className={styles.root}>
      <Text weight="bold" size="lg">
        🔍 前回の条件でさがす
      </Text>
      <Link href={history.url} className={styles.history} passHref>
        <div className={styles.iconWrapper}>
          <Icon icon="history" size="sm" />
        </div>
        <dl className={styles.details}>
          <dt>
            <Text weight="bold" truncate>
              {history.destinationsDisplayName}
            </Text>
          </dt>
          <dd>
            <Text truncate>{history.travelDates}</Text>
          </dd>
          <dd>
            <Text truncate>{history.travelers}</Text>
          </dd>
          {history.filters && (
            <dd>
              <Text truncate>{history.filters}</Text>
            </dd>
          )}
        </dl>
      </Link>
    </div>
  );
};
