export const shareTwitterURL = (text: string) =>
  `https://x.com/intent/tweet?text=${encodeURIComponent(text)}`;

export const shareTwitter = (text: string) => {
  window.open(shareTwitterURL(text), "_blank");
};

export const shareLineURL = (text: string) =>
  `https://line.me/R/share?text=${encodeURIComponent(text)}`;

export const shareLine = (text: string) => {
  window.open(shareLineURL(text), "_blank");
};
