import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconPriorityHigh: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      fill="none"
      viewBox="0 0 17 16"
    >
      <path
        fill="#fff"
        d="M8.501 14.167c-.344 0-.642-.125-.892-.375a1.218 1.218 0 01-.375-.892c0-.344.125-.638.375-.883.25-.244.548-.367.892-.367s.642.123.892.367c.25.245.375.54.375.883 0 .345-.125.642-.375.892s-.548.375-.892.375zM7.284 9.984V1.867h2.434v8.117H7.284z"
      ></path>
    </svg>
  );
};
