import {
  Button,
  FieldGroup,
  Form,
  FormGroup,
  Input,
  PageTitle,
  Paragraph,
  Spacer,
  Text,
} from "@newt/ui";
import { formatPhoneNumber } from "@utils/phone";
import { ConfirmationResult } from "firebase/auth";
import { FC, useEffect, useState } from "react";
import { usePhoneVerificationForm } from "./usePhoneVerificationForm";

interface PhoneVerificationFormProps {
  confirmationResult: ConfirmationResult;
  phoneNumber: string | undefined;
  onRetry: () => void;
  setIsLoggingIn?: (value: boolean) => void;
}

export const PhoneVerificationForm: FC<PhoneVerificationFormProps> = ({
  confirmationResult,
  phoneNumber,
  onRetry,
  setIsLoggingIn,
}) => {
  const { register, onFormSubmit, isSubmitting, errors } =
    usePhoneVerificationForm({ confirmationResult, setIsLoggingIn });
  const [counter, setCounter] = useState(30);
  const canRetry = counter === 0;

  useEffect(() => {
    if (counter === 0) return;
    const timeoutId = setTimeout(() => setCounter(counter - 1), 1000);
    return () => clearTimeout(timeoutId);
  }, [counter]);

  return (
    <>
      <PageTitle align="center" size={{ mobile: "2xl", tablet: "4xl" }}>
        SMS認証
      </PageTitle>
      <Form onSubmit={onFormSubmit}>
        <FormGroup
          title="認証コードを入力してください💁"
          help={
            <Paragraph color="gray">
              {phoneNumber && formatPhoneNumber(phoneNumber)}
              に送りました✉️
            </Paragraph>
          }
        >
          <FieldGroup label="認証コード" error={errors["verificationCode"]}>
            <Input
              {...register("verificationCode")}
              placeholder="6桁の認証コードを入力"
              data-testid="login-verificationCode-textField"
            />
          </FieldGroup>
          <Button
            isLoading={isSubmitting}
            data-testid="login-verificationCode-submit"
          >
            SMS認証する
          </Button>
          <Spacer />
          <a
            onClick={() => canRetry && onRetry()}
            style={{ cursor: canRetry ? "pointer" : "not-allowed" }}
          >
            <Text color={counter === 0 ? "link" : "disabled"}>
              認証コードを再送する{!canRetry && `${counter}秒後`}
            </Text>
          </a>
        </FormGroup>
      </Form>
    </>
  );
};
