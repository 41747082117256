import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconCooking: FC<IconTypeProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 21V18H2V16H9C9.55 16 10.0208 16.1958 10.4125 16.5875C10.8042 16.9792 11 17.45 11 18V21H9ZM13 21V18C13 17.45 13.1958 16.9792 13.5875 16.5875C13.9792 16.1958 14.45 16 15 16H22V18H15V21H13ZM6 15C5.16667 15 4.45833 14.7083 3.875 14.125C3.29167 13.5417 3 12.8333 3 12V8H21V12C21 12.8333 20.7083 13.5417 20.125 14.125C19.5417 14.7083 18.8333 15 18 15H6ZM6 13H18C18.2833 13 18.5208 12.9042 18.7125 12.7125C18.9042 12.5208 19 12.2833 19 12V10H5V12C5 12.2833 5.09583 12.5208 5.2875 12.7125C5.47917 12.9042 5.71667 13 6 13ZM3 7V5H9V4C9 3.71667 9.09583 3.47917 9.2875 3.2875C9.47917 3.09583 9.71667 3 10 3H14C14.2833 3 14.5208 3.09583 14.7125 3.2875C14.9042 3.47917 15 3.71667 15 4V5H21V7H3Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};
