import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconMeters: FC<IconTypeProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 20H5.07198V11.9512C6.03987 11.0523 7.07088 10.6341 7.84939 10.6341C9.25914 10.6341 9.93245 11.3031 9.93245 13.0801V20H13.0465V11.9512C14.0144 11.0523 15.0033 10.6341 15.845 10.6341C17.1705 10.6341 17.9491 11.3031 17.9491 13.0801V20H21V12.7038C21 9.777 19.8427 8 17.0653 8C15.34 8 13.9513 8.91986 12.7099 10.0488C12.0576 8.75261 10.9003 8 9.09081 8C7.30233 8 5.97674 8.83624 4.84053 9.86063H4.73533L4.52492 8.25087H2V20Z"
        fill="#757575"
      />
      <path
        d="M24 7H20.113V6.19512L21.9474 4.15447C22.1993 3.86721 22.3849 3.61653 22.5044 3.40244C22.6264 3.18835 22.6874 2.98509 22.6874 2.79268C22.6874 2.52981 22.6238 2.32385 22.4966 2.1748C22.3694 2.02304 22.1876 1.94715 21.9513 1.94715C21.6969 1.94715 21.4956 2.0393 21.3476 2.22358C21.2022 2.40515 21.1295 2.64499 21.1295 2.94309H20C20 2.58266 20.0818 2.25339 20.2454 1.95528C20.4116 1.65718 20.6452 1.42412 20.9464 1.2561C21.2476 1.08537 21.5891 1 21.9708 1C22.555 1 23.0081 1.14634 23.3301 1.43902C23.6547 1.73171 23.8169 2.14499 23.8169 2.67886C23.8169 2.97154 23.7442 3.26965 23.5988 3.57317C23.4534 3.87669 23.2042 4.23035 22.851 4.63415L21.5618 6.05285H24V7Z"
        fill="#757575"
      />
    </svg>
  );
};
