import { FC } from "react";
import Link from "next/link";
import { Icon, ResponsiveProp, Stack, Text } from "@newt/ui";
import { formatNumber } from "@utils/formatNumber";
import { ROUTES } from "@newt/core";
import styles from "./PointsDisplay.module.css";

type PointsDisplayProps = {
  points: number;
  justify?: ResponsiveProp<"end" | "center" | "start" | "between">;
  hasInfo?: boolean;
};

export const PointsDisplay: FC<PointsDisplayProps> = ({
  points,
  justify,
  hasInfo,
}) => {
  return (
    <Stack as="div" align="center" justify={justify}>
      <div className={styles.pointIcon}>
        <Icon icon="point" size="sm" color="green-40" />
      </div>
      <Text size="sm" weight="bold">
        {`${formatNumber(points)}ポイント`}
      </Text>
      <Text size="sm">たまる</Text>
      {hasInfo && (
        <Link
          href={ROUTES.point()}
          target="_blank"
          className={styles.pointAlertIcon}
        >
          <Icon icon="alertCircleOutline" size="sm" />
        </Link>
      )}
    </Stack>
  );
};
