export const ArrowIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="24" height="24" rx="12" fill="#00CD68" />
      <g clipPath="url(#clip0_27161_16178)">
        <rect
          x="17.6562"
          y="13.9854"
          width="10"
          height="2"
          transform="rotate(-135 17.6562 13.9854)"
          fill="white"
        />
        <rect
          x="19.0703"
          y="12.5713"
          width="10"
          height="2"
          transform="rotate(135 19.0703 12.5713)"
          fill="white"
        />
        <rect
          x="17"
          y="13.5"
          width="12"
          height="2"
          transform="rotate(180 17 13.5)"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_27161_16178">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(5 5.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
