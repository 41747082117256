import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconRoomSize: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path fill="#1F1F1F" d="M3.335 3.335h13.333v1.25H3.335v-1.25z"></path>
      <path
        fill="#1F1F1F"
        d="M3.335 3.335h1.25v13.333h-1.25V3.335zM14.585 14.585h1.666v1.666h-1.666v-1.666zM14.585 10.835h1.666V12.5h-1.666v-1.666zM14.585 7.085h1.666V8.75h-1.666V7.085zM7.085 14.585H8.75v1.666H7.085v-1.666zM10.835 14.585H12.5v1.666h-1.666v-1.666zM17.918 3.96l-2.812 1.624V2.336l2.812 1.624z"
      ></path>
      <path
        fill="#1F1F1F"
        d="M3.96 17.918l-1.624-2.812h3.248L3.96 17.918z"
      ></path>
    </svg>
  );
};
