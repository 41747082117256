import { FC, ReactNode } from "react";
import { useResponsiveProp } from "../../hooks/useResponsiveProp";
import { FontSizes, ResponsiveProp } from "../../types/component.type";
import styles from "./Paragraph.module.css";

interface ParagraphProps {
  children?: ReactNode;
  size?: ResponsiveProp<FontSizes>;
  color?: ResponsiveProp<
    | "default"
    | "primary"
    | "danger"
    | "warning"
    | "link"
    | "white"
    | "black"
    | "gray"
    | "disabled"
    | "inherit"
    | "positiveRed"
  >;
  weight?: ResponsiveProp<"normal" | "bold">;
  lineHeight?: ResponsiveProp<"base" | "lg">;
  align?: ResponsiveProp<"left" | "center" | "right">;
}

const defaultProps: Required<Omit<ParagraphProps, "children">> = {
  size: "base",
  color: "default",
  weight: "normal",
  lineHeight: "base",
  align: "left",
};

export const Paragraph: FC<ParagraphProps> = ({
  children,
  size = defaultProps.size,
  color = defaultProps.color,
  weight = defaultProps.weight,
  lineHeight = defaultProps.lineHeight,
  align = defaultProps.align,
}) => {
  const responsiveSize = useResponsiveProp(size, defaultProps.size);
  const responsiveColor = useResponsiveProp(color, defaultProps.color);
  const responsiveWeight = useResponsiveProp(weight, defaultProps.weight);
  const responsiveAlign = useResponsiveProp(align, defaultProps.align);
  const responsiveLineHeight = useResponsiveProp(
    lineHeight,
    defaultProps.lineHeight
  );

  const classes = [
    styles.root,
    styles[`color--${responsiveColor}`],
    styles[`size--${responsiveSize}`],
    styles[`weight--${responsiveWeight}`],
    styles[`lineHeight--${responsiveLineHeight}`],
    styles[`align--${responsiveAlign}`],
    styles[`lineHeight--${responsiveLineHeight}`],
  ].join(" ");

  return <p className={classes}>{children}</p>;
};
