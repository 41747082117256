import { Icon } from "../Icon";
import { Text } from "../Text";
import { createElement, FC } from "react";
import { DEFAULT_IMAGE } from "../../constants";
import { Tag } from "../Tag";
import styles from "./DestinationCard.module.css";

interface DestinationCardProps {
  href?: string;
  variant?: "default" | "disabled";
  title: string;
  imageUrl: string;
  titleAs?: "h2" | "h3";
  as?: "a" | "div";
  onClick?: () => void;
}

export const DestinationCard: FC<DestinationCardProps> = ({
  as,
  href,
  variant = "default",
  title,
  imageUrl,
  titleAs = "h3",
  onClick,
}) => {
  const className = [styles.root, styles[`variant--${variant}`]].join(" ");
  const isDisabled = variant === "disabled";

  return createElement(
    as || isDisabled ? "div" : "a",
    { href: isDisabled ? undefined : href, className, onClick },
    <>
      <div className={styles.title}>
        <div className={styles.titleBody}>
          <Text
            size={{ mobile: "base", laptop: "2xl" }}
            color={isDisabled ? "disabled" : "default"}
            weight="bold"
            as={titleAs}
          >
            {title}
          </Text>
        </div>
        {!isDisabled && (
          <div className={styles.titleIcon}>
            <Icon icon="chevronRight" color="gray-80" />
          </div>
        )}
      </div>
      <figure className={styles.figure}>
        <img
          className={`${styles.img} ${isDisabled ? styles.disabledImg : ""}`}
          src={imageUrl || DEFAULT_IMAGE}
          alt={`${title}の風景`}
        />
        {isDisabled && (
          <div className={styles.tag}>
            <Tag color="disabled">Coming Soon</Tag>
          </div>
        )}
      </figure>
    </>
  );
};
