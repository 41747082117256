import { FC } from "react";
import Image from "next/image";
import BackgroundImg from "./assets/BackgroundImgForSp.webp";
import NativeApp from "./assets/NativeApp.webp";
import AppBannerTextForPc from "./assets/AppBannerTextForSp.webp";
import { Icon } from "../../Icon";
import { Button } from "../../Button";
import { Text } from "../../Text";
import styles from "./FixedAppBannerForSp.module.css";

interface FixedAppBannerForSpProps {
  downloadLink: string;
  onClose: () => void;
}

export const FixedAppBannerForSp: FC<FixedAppBannerForSpProps> = (props) => {
  const { downloadLink, onClose } = props;

  return (
    <div className={styles.root}>
      <Image
        className={styles.bgImg}
        src={BackgroundImg.src}
        alt=""
        fill={true}
      />
      <div className={styles.flexContent}>
        <div className={styles.leftContent}>
          <div className={styles.text}>
            <Image
              src={AppBannerTextForPc.src}
              alt="アプリ限定 はじめての予約が7%OFF"
              fill={true}
              quality={95}
            />
          </div>
          <div className={styles.action}>
            <Button
              type="button"
              variant="outline"
              color="primary"
              size="sm"
              width="content"
              href={downloadLink}
            >
              <Text color={"primary"} weight={"bold"}>
                アプリでひらく
              </Text>
            </Button>
          </div>
        </div>
        <div className={styles.rightContent}>
          <Image src={NativeApp.src} alt="アプリ画面イメージ" fill={true} />
        </div>
        <div className={styles.icon} onClick={onClose}>
          <Icon icon="close" color="white" />
        </div>
      </div>
    </div>
  );
};
