import { DestinationDetailLink_DestinationFragment } from "@graphql/types";
import { LinkCard } from "@newt/ui";
import { gql } from "graphql-request";
import { CSSProperties, FC } from "react";

export const DESTINATION_DETAIL_LINK_DESTINATION_FRAGMENT = gql`
  fragment DestinationDetailLink_Destination on Destination {
    id
    code
    name
    mainImage {
      url(size: XS)
    }
    links {
      areaLp {
        title
        url
      }
    }
    hasSingletonCityWithSameName
  }
`;

interface DestinationDetailLinkProps {
  fragment: DestinationDetailLink_DestinationFragment;
  width?: CSSProperties["width"];
}

export const DestinationDetailLink: FC<DestinationDetailLinkProps> = ({
  fragment,
  width,
}) => {
  return (
    <LinkCard
      model={{
        alt: fragment.name,
        title: `${fragment.name}旅行・ツアーの情報` || "",
        imageUrl: fragment.mainImage?.url || "",
        href: fragment.links?.areaLp?.url || "",
      }}
      width={width}
    />
  );
};
