import { Key, useCallback, useEffect, useState, FC, ReactNode } from "react";
import { useDebounce } from "../../hooks/useDebounce";
import { Loading } from "../Loading";
import styles from "./AutoComplete.module.css";

interface Listable<K extends Key = Key> {
  readonly id: K;
}

interface InputProps {
  readonly initialValue: string;
  readonly onChange: (input: string) => void;
  readonly onClear: () => void;
}

interface OptionProps<T extends Listable> {
  readonly option: T;
  readonly onSelect: () => void;
}

export interface AutoCompleteProps<T extends Listable> {
  readonly defaultOptions?: T[];
  readonly initialValue?: string;
  readonly onSearch: (input: string) => Promise<T[]>;
  readonly onSelect: (option: T) => void;
  readonly renderInput: FC<InputProps>;
  readonly renderOption: FC<OptionProps<T>>;
  readonly renderSkeleton?: ReactNode;
}

export const AutoComplete = <
  T extends Listable<K>,
  K extends Key = T extends Listable<infer K> ? K : never
>({
  defaultOptions = [],
  initialValue = "",
  onSearch,
  onSelect,
  renderInput: Input,
  renderOption: Option,
  renderSkeleton,
}: AutoCompleteProps<T>) => {
  const [options, setOptions] = useState<T[]>(defaultOptions);
  const [isLoading, setIsLoading] = useState(!!initialValue);

  const handleSearch = useCallback(
    async (input: string) => {
      if (!input) {
        setIsLoading(false);
        setOptions([]);
        return;
      }
      const options = await onSearch(input);
      setIsLoading(false);
      setOptions(options);
    },
    [onSearch]
  );

  const debounce = useDebounce(handleSearch, 700);

  const handleChange = (input: string) => {
    setIsLoading(true); // show loading when start inputting
    debounce(input);
  };

  useEffect(() => {
    handleSearch(initialValue);
  }, [handleSearch, initialValue]);

  return (
    <div className={styles.root}>
      <Input
        onChange={handleChange}
        onClear={() => handleSearch("")}
        initialValue={initialValue}
      />
      {isLoading ? (
        renderSkeleton || (
          <div className={styles.loading}>
            <Loading centered />
          </div>
        )
      ) : (
        <div className={styles.options}>
          {options.map((option) => (
            <Option
              key={option.id}
              option={option}
              onSelect={() => onSelect(option)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
