import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconMenu: FC<IconTypeProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.89844 18.1669V16.4637H21.1017V18.1669H2.89844ZM2.89844 12.8512V11.1479H21.1017V12.8512H2.89844ZM2.89844 7.53542V5.82617H21.1017V7.53542H2.89844Z"
        fill="black"
      />
    </svg>
  );
};
