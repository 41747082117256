export type RouterQueryParam = string | string[] | undefined;

export type RouterQueryParams = Record<string, RouterQueryParam>;

export type RouterQueryInput = Record<
  string,
  string | number | boolean | string[] | number[] | boolean[] | null | undefined
>;

class RouterQueryUtils {
  static getString(param: RouterQueryParam): string | undefined {
    return typeof param === "string" ? param : undefined;
  }

  static getNumber(param: RouterQueryParam): number | undefined {
    const num = Number(param);
    return !isNaN(num) ? num : undefined;
  }

  static getArrayString(param: RouterQueryParam): string[] | undefined {
    return Array.isArray(param) ? param : param ? [param] : undefined;
  }

  static getArrayNumber(param: RouterQueryParam): number[] | undefined {
    if (Array.isArray(param)) {
      return param
        .map((v) => this.getNumber(v))
        .filter((v) => v !== undefined) as number[];
    } else {
      const num = this.getNumber(param);
      return num ? [num] : undefined;
    }
  }

  static getBoolean(param: RouterQueryParam): boolean | undefined {
    if (typeof param !== "string") return undefined;
    if (param === "true") return true;
    if (param === "false") return false;
    return undefined;
  }
}

export default RouterQueryUtils;
