import { DestinationDetailLink } from "@components/common";
import { DestinationDetailLinks_DestinationFragment } from "@graphql/types";
import {
  FontSizes,
  LinkCard,
  ResponsiveProp,
  Spacer,
  Spacings,
  Text,
} from "@newt/ui";
import {
  getCurrentDestination,
  getRelatedDestinations,
  getSelectedDestinations,
} from "@utils/useDestinations";
import { gql } from "graphql-request";
import { FC } from "react";
import styles from "./DestinationDetailLinks.module.css";
import { airports } from "@components/common/DestinationDetailLinks/airports";

export const DESTINATION_DETAIL_LINKS_DESTINATION = gql`
  fragment DestinationDetailLinks_Destination on Destination {
    countries: children(
      filters: { DESTINATION_STATUS: [PUBLIC], ORDER_BY: POSITION_ASC }
    ) {
      ...DestinationDetailLink_Destination
      cities: children(
        filters: { DESTINATION_STATUS: [PUBLIC], ORDER_BY: POSITION_ASC }
      ) {
        ...DestinationDetailLink_Destination
      }
    }
  }
`;

interface DestinationDetailLinksProps {
  destinationId: number;
  parentDestinationId?: number;
  destinationName: string;
  destinations: DestinationDetailLinks_DestinationFragment[];
  includeSelfDestination?: boolean;
  titleSize?: ResponsiveProp<FontSizes>;
  gap?: ResponsiveProp<Spacings>;
  showRelatedDestinations?: boolean;
  showAirports?: boolean;
}

export const DestinationDetailLinks: FC<DestinationDetailLinksProps> = ({
  destinationId,
  parentDestinationId,
  destinationName,
  destinations,
  includeSelfDestination = false,
  titleSize = { mobile: "xl", tablet: "3xl", laptop: "4xl" },
  gap = { mobile: "8x", tablet: "16x" },
  showRelatedDestinations = true,
  showAirports = false,
}) => {
  const currentDestination = getCurrentDestination(destinationId, destinations);

  const selectedDestinations = getSelectedDestinations(
    destinationId,
    destinations,
    includeSelfDestination
  );

  const relatedDestinations = getRelatedDestinations(
    parentDestinationId || destinationId,
    destinations
  );

  return (
    <>
      {selectedDestinations.length > 0 && (
        <section>
          <Text size={titleSize} as="h2" weight="bold" align={"left"}>
            {`${destinationName}旅行・ツアー情報`}
          </Text>
          <Spacer y={{ mobile: "4x", tablet: "6x" }} />
          <ul className={styles.destinationDetailsItems}>
            {selectedDestinations.map((destination) => (
              <li key={destination.id}>
                <DestinationDetailLink fragment={destination} />
              </li>
            ))}
          </ul>
        </section>
      )}
      {showRelatedDestinations && relatedDestinations.length > 0 && (
        <>
          <Spacer y={gap} />
          <section>
            <Text size={titleSize} as="h2" weight="bold" align={"left"}>
              {"ほかエリアの旅行・ツアー情報"}
            </Text>
            <Spacer y={{ mobile: "4x", tablet: "6x" }} />
            <ul className={styles.destinationDetailsItems}>
              {relatedDestinations.map((destination) => (
                <li key={destination.id}>
                  <DestinationDetailLink fragment={destination} />
                </li>
              ))}
            </ul>
          </section>
        </>
      )}
      {showAirports && currentDestination?.links?.areaLp?.url && (
        <>
          <Spacer y={gap} />
          <section>
            <Text size={titleSize} as="h2" weight="bold" align={"left"}>
              {`ほか出発空港の${currentDestination.name}ツアー`}
            </Text>
            <Spacer y={{ mobile: "4x", tablet: "6x" }} />
            <ul className={styles.destinationDetailsItems}>
              {airports.map((airport) => (
                <li key={airport.id}>
                  <LinkCard
                    model={{
                      alt: `${airport.name}発の${currentDestination.name}ツアー`,
                      title: `${airport.name}発の${currentDestination.name}ツアー`,
                      imageUrl: airport.imageUrl,
                      href: `/tour${currentDestination.links?.areaLp?.url}/${airport.id}`,
                    }}
                  />
                </li>
              ))}
            </ul>
          </section>
        </>
      )}
    </>
  );
};
