/**
 * InstagramのStoriesのシェアに使う画像をImgixのrender APIを使って生成する
 * お試し：https://sandbox.imgix.com/view?url=https%3A%2F%2Fnewt.imgix.net%2Fhotels%2F3375%2F1723170451881-008.jpg%3Fblend-pad%3D30%26blend-h%3D300%26border-radius%3D20%26bg%3DF2FAF6%26w%3D720%26h%3D1032%26fit%3Dcrop%26mark64%3DaHR0cHM6Ly9hc3NldHMuaW1naXgubmV0L350ZXh0P2ZtPXBuZyZ0eHRzaXplPTMyJnc9NjAwJnR4dGZvbnQ9QXZlbmlyK05leHQrQ29uZGVuc2VkLEJvbGQmdHh0PeOCu-ODvOODq-S-oeagvPCfj5bnvr3nlLDnmbrjgIHmtbfjga7opovjgYjjgovpg6jlsYvjgavjgqLjg4Pjg5fjgrDjg6zjg7zjg4nvvIHjgqLjg6njg6LjgqLjg4rjgrvjg7Pjgr_jg7znm7TntZDjg5vjg4bjg6vjgavlrr_ms4omYmc9MDAwMDAwMDAmdHh0Y2xyPTFmMWYxZg%26mark-pad%3D850%26mark-align%3Dtop%252Ccenter%26border%3D0%252Cf2faf6%26border-bottom%3D200%26mark-h%3D170%26blend64%3DaHR0cHM6Ly9uZXd0LmltZ2l4Lm5ldC9wcm9kdWN0L05ld3RMb2dvLnBuZw%26blend-align%3Dtop%26blend-alpha%3D100%26blend-mode%3Dnormal%26mark-w%3D650%26fm%3Dpng
 */
const PARAMS = {
  w: "720",
  h: "840",
  fit: "crop",
  border: "0,f2faf6",
  "border-bottom": "200",
  "border-radius": "20",
  "mark-pad": "650",
  "mark-align": "top,center",
  "mark-w": "660",
  fm: "png",
};

const TXT_PARAMS = {
  fm: "png",
  txtsize: "32",
  w: "600",
  txtfont: "NotoSansJP-Bold",
  bg: "00000000",
  txtclr: "1f1f1f",
};

/**
 * @description
 * 意図した位置で改行するために、半角英数字記号を1バイト、それ以外を2バイトとしてカウントし、32バイトごとに半角スペースを挿入する。
 * また、3行目を超えそうな場合は"…"を末尾に追加する。 */
const adjustText = (text: string) => {
  const BYTE_IN_ONE_LINE = 33;
  const MAX_LINE = 3;

  // サロゲート文字を考慮して配列にする
  const textArr = [...text];

  let adjustedText = "";
  let byteCountInLine = 0;
  let numOfLine = 0;
  for (let i = 0; i < textArr.length; i++) {
    const charCode = textArr[i].charCodeAt(0);
    const byte = charCode >= 0x20 && charCode <= 0x7e ? 1 : 2;
    byteCountInLine += byte;

    if (byteCountInLine > BYTE_IN_ONE_LINE) {
      numOfLine++;
      if (numOfLine >= MAX_LINE) {
        adjustedText += "…";
        break;
      }
      adjustedText += textArr[i] + " ";
      byteCountInLine = 0;
    } else {
      adjustedText += textArr[i];
    }
  }
  return adjustedText;
};
export const getInstagramImgUrl = (imgUrl?: string, title?: string) => {
  if (!imgUrl || !title || typeof window === "undefined") {
    return "";
  }

  const params = new URLSearchParams(PARAMS);

  /**  ImgixのTypesetting Endpointをbase64に埋め込む
   * cf. https://docs.imgix.com/getting-started/tutorials/design-elements-and-composition/multiline-text-and-overlays-with-the-typesetting-endpoint   */
  const txtParams = new URLSearchParams(TXT_PARAMS);
  const adjustedTitle = adjustText(title);
  txtParams.set("txt", adjustedTitle);
  const txtUrl = `https://assets.imgix.net/~text?${txtParams.toString()}`;
  const mark64 = btoa(txtUrl)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");

  params.set("mark64", mark64);

  const originalImgUrl = new URL(imgUrl);
  return `${originalImgUrl.origin}${originalImgUrl.pathname}?${params}`;
};
