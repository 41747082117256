import { gtm } from "../gtm";

export interface ClickLinkEventVariables {
  link_type: string; // リンクタイプ e.g. sns_share, line_newt_official
  link_url: string; // 遷移先URL ※イベントパラメータの値が100文字を超えた場合は101文字以降を削除して送る。
  item_id?: string; //　ツアーコード（予約コード）
  item_name?: string; // ツアー名
  click_sns_share_count?: number; // SNSシェア導線押下数
  is_newt_app?: boolean; // アプリからGTMに送信する場合（GTMでハンドリングする）
}

export const clickLinkEvent = (variables?: ClickLinkEventVariables) => {
  gtm.pushEvent("click_link", variables);
};
