import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconFitness: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        fill="#1F1F1F"
        d="M18.736 29.3l-1.533-1.534 4.733-4.733L9.47 10.566l-4.734 4.733-1.533-1.533 1.867-1.933-1.867-1.867 2.833-2.833-1.4-1.4 1.6-1.6L7.67 5.499l2.8-2.8 1.866 1.867 1.934-1.867 1.533 1.534-4.733 4.733 12.466 12.467 4.734-4.734 1.533 1.534-1.867 1.933 1.867 1.867-2.867 2.866 1.4 1.4-1.6 1.6-1.4-1.4-2.8 2.8-1.866-1.866-1.934 1.866z"
      ></path>
    </svg>
  );
};
