import { ReactNode, FC } from "react";
import styles from "./Card.module.css";

interface CardProps {
  children: ReactNode;
}

export const Card: FC<CardProps> = ({ children, ...rest }) => {
  return (
    <div className={styles.root} {...rest}>
      {children}
    </div>
  );
};
