import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconSpa: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        fill="#1F1F1F"
        d="M16.502 29.532c-1.644-.178-3.278-.65-4.9-1.417a14.533 14.533 0 01-4.35-3.166c-1.278-1.345-2.311-3-3.1-4.967-.789-1.967-1.183-4.239-1.183-6.817V11.7h1.466c1.134 0 2.356.222 3.667.666 1.311.445 2.478 1 3.5 1.667.2-2 .761-4.067 1.683-6.2.923-2.133 1.995-3.967 3.217-5.5 1.222 1.533 2.295 3.367 3.217 5.5.922 2.133 1.483 4.2 1.683 6.2a18.031 18.031 0 013.517-1.65c1.3-.455 2.539-.683 3.716-.683h1.4v1.4c0 2.6-.394 4.889-1.183 6.866-.789 1.978-1.817 3.64-3.083 4.984a14.327 14.327 0 01-4.334 3.166c-1.622.767-3.266 1.24-4.933 1.417zm.167-2.333c-.245-4.067-1.428-7.15-3.55-9.25-2.123-2.1-4.74-3.406-7.85-3.917.289 4.2 1.528 7.345 3.716 9.433 2.19 2.09 4.75 3.334 7.684 3.734zm-.2-8.4c.31-.578.722-1.172 1.233-1.784a13.971 13.971 0 011.533-1.583c.09-1.489-.127-2.972-.65-4.45-.522-1.478-1.216-3.028-2.083-4.65a35.923 35.923 0 00-2.067 4.633c-.533 1.49-.755 2.978-.666 4.467.489.422.983.95 1.483 1.583.5.634.906 1.228 1.217 1.784zm2.5 7.766c1-.355 2-.844 3-1.466s1.905-1.428 2.716-2.417c.812-.989 1.49-2.194 2.034-3.617.544-1.422.883-3.1 1.016-5.033-2.288.378-4.31 1.194-6.066 2.45-1.756 1.256-3.056 2.806-3.9 4.65.266.845.505 1.689.716 2.533.212.845.373 1.812.484 2.9z"
      ></path>
    </svg>
  );
};
