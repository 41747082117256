export const disableBodyScroll = () => {
  const scrollY = window.scrollY;
  document.body.classList.add("disabled-scroll");
  document.body.style.top = `-${scrollY}px`;
};

export const enableBodyScroll = () => {
  if (!isDisabledBodyScroll()) return;
  document.body.classList.remove("disabled-scroll");

  const scrollY = Number(document.body.style.top.replace("px", "")) * -1;
  document.body.style.top = "";
  window.scrollTo(0, scrollY);
};

export const isDisabledBodyScroll = () => {
  return document.body.classList.contains("disabled-scroll");
};
