import { FC } from "react"
import { IconTypeProps } from '../Icon'

export const IconArrowDropRight: FC<IconTypeProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 17L15 12L10 7V17Z" fill="#757575"/>
    </svg>
  )
}
