import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconReview: FC<IconTypeProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.84961 22.1496V3.54961C1.84961 3.09961 2.02044 2.70378 2.36211 2.36211C2.70378 2.02044 3.09961 1.84961 3.54961 1.84961H20.4496C20.8996 1.84961 21.2954 2.02044 21.6371 2.36211C21.9788 2.70378 22.1496 3.09961 22.1496 3.54961V16.4496C22.1496 16.8829 21.9788 17.2746 21.6371 17.6246C21.2954 17.9746 20.8996 18.1496 20.4496 18.1496H5.84961L1.84961 22.1496ZM3.54961 18.1496L5.27461 16.4496H20.4496V3.54961H3.54961V18.1496Z"
        fill="#1F1F1F"
      />
      <rect x="7" y="6" width="10" height="1" fill="black" />
      <rect x="7" y="9" width="10" height="1" fill="black" />
      <rect x="7" y="12" width="10" height="1" fill="black" />
    </svg>
  );
};
