import { useCallback, useEffect, useState } from "react";
import { GoogleAuthProvider, signInWithCredential } from "firebase/auth";
import { useFirebaseAuth, firebase } from "@utils/firebase";
import { useRouter } from "next/router";
import { useAuth } from "@utils/auth";
import Script from "next/script";
import { UserStatus } from "@graphql/types";
import { NewsletterModal, useCreateSimpleUser } from "@components/common";
import { MarketingService } from "@utils/marketing";
import { mapProviderDataToAuthMethod } from "@utils/mapProviderDataToAuthMethod";
import { toSignInPageQuery } from "@components/pages/signIn/query";
import { LoadingLogoOverlay } from "@newt/ui";

export const GSIScript = () => {
  const router = useRouter();
  const { user, isLoading, userStatus } = useAuth();
  const { isLoading: isFirebaseLoading } = useFirebaseAuth();
  const [isLoggingByGSI, setIsLoggingByGSI] = useState(false);
  const currentUser = firebase.auth.currentUser;
  const { createSimpleUserMutation } = useCreateSimpleUser();
  const [isNewsletterOpen, setIsNewsletterOpen] = useState(false);

  const initializeGSI = useCallback(() => {
    // the authentication handler function
    const authHandler = async (response: any) => {
      const idToken = response.credential;
      const credential = GoogleAuthProvider.credential(idToken);
      try {
        setIsLoggingByGSI(true);
        await signInWithCredential(firebase.auth, credential);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    window?.google?.accounts.id.initialize({
      client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
      callback: authHandler,
      // https://developers.google.com/identity/gsi/web/guides/fedcm-migration?hl=ja
      use_fedcm_for_prompt: true,
    });
    window?.google?.accounts.id.prompt();
  }, []);

  /** ランディング時の処理 */
  useEffect(() => {
    if (currentUser || user || isLoading || isFirebaseLoading) return;
    // using a timer to avoid the window?.google being undefined
    const timer = setTimeout(() => {
      initializeGSI();
    }, 1000);
    return () => clearTimeout(timer);
  }, [currentUser, user, isLoading, isFirebaseLoading, initializeGSI]);

  /** ログイン状況をトレースする */
  useEffect(() => {
    if (!isLoggingByGSI || isLoading || !currentUser) {
      return;
    }

    // GSIによってログインされたカスタマーでかつ未登録だった場合、即座に会員登録する
    if (userStatus === UserStatus.INACTIVE) {
      createSimpleUserMutation(undefined)
        .then(() => {
          setIsNewsletterOpen(true);
        })
        .catch(() => {
          firebase.auth.signOut();
          setIsLoggingByGSI(false);
        });
    }

    // 会員登録済み/非会員にかかわらず、ログインした時点でログを送る
    if (userStatus === UserStatus.ACTIVE) {
      const query = toSignInPageQuery(router.query);
      const authMethod = firebase.auth.currentUser
        ? mapProviderDataToAuthMethod(firebase.auth.currentUser?.providerData)
        : "";

      MarketingService.logEvent.login({
        uid: user?.id.toString(),
        user_id: user?.id.toString(),
        user_status: "member",
        previous_module_type: query.previousModuleType,
        auth_method: authMethod,
      });
      setIsLoggingByGSI(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isLoading, isLoggingByGSI, userStatus]);

  return (
    <>
      {isLoggingByGSI && <LoadingLogoOverlay />}
      {isNewsletterOpen && userStatus === UserStatus.ACTIVE && (
        <NewsletterModal
          onClose={() => setIsNewsletterOpen(false)}
          moduleType="via_one_tap"
        />
      )}
      <Script src="https://accounts.google.com/gsi/client" />
    </>
  );
};
