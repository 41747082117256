import { useState } from "react";
import styles from "./HeaderMenu.module.css";
import { Button, Divider, Icon, IconType } from "@newt/ui";
import { useBackScrollLock } from "./useBackScrollLock";
import Link from "next/link";
import { ROUTES } from "@routes";
import { useRouter } from "next/router";

type NavItems = {
  icon: IconType;
  label: string;
  href: string;
  prefetch?: boolean;
};

interface MenuNavProps {
  label: string;
  href: string;
  icon: IconType;
  isCurrentPath: boolean;
  onClick: () => void;
  prefetch?: boolean;
}

const MenuNav = ({
  label,
  href,
  icon,
  isCurrentPath,
  onClick,
  prefetch = true,
}: MenuNavProps) => {
  // 現在のパスと同じメニューを選択したときに、遷移後にまたメニューが開いてしまう場合があるため、現在のパスと同じ時はメニューを閉じるのみにする。
  return !isCurrentPath ? (
    <li key={label}>
      <Link className={styles.menuNavLink} href={href} prefetch={prefetch}>
        <Icon icon={icon} />
        <span className={styles.menuNavLabel}>{label}</span>
        <Icon icon="chevronRight" />
      </Link>
    </li>
  ) : (
    <li key={label}>
      <button
        className={`${styles.menuNavButton} ${styles.menuNavLink}`}
        onClick={onClick}
      >
        <Icon icon={icon} />
        <span className={styles.menuNavLabel}>{label}</span>
        <Icon icon="chevronRight" />
      </button>
    </li>
  );
};

const checkIsCurrentPath = (
  routerPathname: string,
  itemHref: string,
  basePath: string
) => {
  // itemHrefにドメインが含まれているかを判定するために、ドメインを除いたパスを取得する。
  const itemHrefWithoutDomain = itemHref.replace(/^https?:\/\/[^/]+/, "");

  // basePathに空文字ではなく、routerPathnameがルートパスの場合は、basePathをそのまま返す。
  const pathname =
    basePath !== "" && routerPathname === "/"
      ? basePath
      : `${basePath}${routerPathname}`;

  return pathname === itemHrefWithoutDomain;
};

interface HeaderMenuProps {
  isLogin: boolean;
  navItems: {
    mainPage: NavItems[];
    userInfo: NavItems[];
    footer: {
      title: string;
      items: Omit<NavItems, "icon">[];
    }[];
  };
  signIn: (query?: { redirectTo?: string }) => string;
}

export const useHeaderMenu = () => {
  const router = useRouter();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { canScrollElementId } = useBackScrollLock({ isModalOpen: isMenuOpen });

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const MenuOpenButton = () => {
    return (
      <button
        className={`${styles.menuButton} ${styles.openButton}`}
        onClick={handleToggleMenu}
        aria-label="メニューを開く"
        data-testid="header-menu-button"
      >
        <Icon icon="menu" size="md" />
      </button>
    );
  };

  const HeaderMenu = ({ isLogin, navItems, signIn }: HeaderMenuProps) => {
    return isMenuOpen ? (
      <section
        className={`${styles.menuWrapper} ${isMenuOpen ? styles.visible : ""}`}
      >
        <div id={canScrollElementId} className={styles.menu}>
          <button className={styles.menuButton} onClick={handleToggleMenu}>
            <Icon icon="close" />
          </button>
          <ul className={styles.menuNav}>
            {navItems.mainPage.map((item) => (
              <MenuNav
                key={item.label}
                isCurrentPath={checkIsCurrentPath(
                  router.pathname,
                  item.href,
                  router.basePath
                )}
                onClick={handleToggleMenu}
                {...item}
              />
            ))}
          </ul>
          <Divider />
          <ul className={styles.menuNav}>
            {navItems.userInfo.map((item) => (
              <MenuNav
                key={item.label}
                href={isLogin ? item.href : signIn({ redirectTo: item.href })}
                icon={item.icon}
                label={item.label}
                isCurrentPath={checkIsCurrentPath(
                  router.pathname,
                  item.href,
                  router.basePath
                )}
                onClick={handleToggleMenu}
                prefetch={item.prefetch}
              />
            ))}
          </ul>
          {!isLogin && (
            <div className={styles.loginButtonArea}>
              <Button
                size="lg"
                href={ROUTES.signin.index()}
                as="a"
                variant="solid"
                color="primary"
              >
                ログイン・新規登録
              </Button>
            </div>
          )}
          <Divider />
          <ul className={styles.menuNav}>
            {navItems.footer.map((footerItem) => (
              <li key={footerItem.title}>
                <h3 className={styles.menuNavLabel}>{footerItem.title}</h3>
                <ul className={styles.footerNav}>
                  {footerItem.items.map((item) => (
                    <li key={item.label}>
                      <a
                        className={styles.footerNavLabel}
                        href={item.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </section>
    ) : null;
  };

  return {
    MenuOpenButton,
    HeaderMenu,
  };
};
