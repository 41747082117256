import { ReactNode, FC } from "react";
import { Icon, IconType } from "../Icon";
import { Paragraph } from "../Paragraph";
import { Spacer } from "../Spacer";
import styles from "./Toast.module.css";

export type ToastVariants = "success" | "error";
export interface ToastProps {
  id?: string;
  children: ReactNode;
  variant?: ToastVariants;
  onClose: () => void;
  onRevert?: () => void;
}

interface Variant {
  icon: IconType;
  iconColor: "primary" | "white";
  textColor: "black" | "white";
}

const variants: Record<ToastVariants, Variant> = {
  success: {
    icon: "checkCircle",
    iconColor: "primary",
    textColor: "black",
  },
  error: {
    icon: "alertCircle",
    iconColor: "white",
    textColor: "white",
  },
};

export const Toast: FC<ToastProps> = ({
  children,
  variant = "success",
  onClose,
  onRevert,
}) => {
  const config = variants[variant];

  const handleRevert = (e: React.MouseEvent) => {
    e.stopPropagation();
    onRevert?.();
  };

  return (
    <div className={styles.root} onClick={onClose}>
      <div className={[styles.inner, styles[`variant--${variant}`]].join(" ")}>
        <div className={styles.left}>
          <Icon icon={config.icon} color={config.iconColor} size="md" />
          <Spacer x="2x" />
          <Paragraph color={config.textColor} weight="bold">
            {children}
          </Paragraph>
        </div>
        {onRevert && (
          <div className={styles.right} onClick={handleRevert}>
            戻す
          </div>
        )}
      </div>
    </div>
  );
};
