import { forwardRef, InputHTMLAttributes } from "react";
import styles from "./Input.module.css";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  type?: HTMLInputElement["type"];
  error?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    name,
    label,
    disabled = false,
    error = false,
    type = "text",
    ...rest
  } = props;

  const className = [
    styles.root,
    disabled ? styles.disabled : "",
    error && !disabled ? styles.error : "",
    type === "number" ? styles.noappearance : "",
  ].join(" ");

  return (
    <input
      className={className}
      type={type}
      name={name}
      aria-label={label || name}
      disabled={disabled}
      {...rest}
      ref={ref}
    />
  );
});

Input.displayName = "Input";
