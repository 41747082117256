import { SearchButton } from "@components/common/SearchButton";
import { toSearchPageQuery } from "@components/pages/search/query";
import { useRouter } from "next/router";
import { FC, ReactNode, useState } from "react";

import { SearchBox } from "@components/common/SearchBox";
import { useSearchForm } from "@components/common/SearchBox/hooks/useSearchForm";
import styles from "./SearchBoxInHeader.module.css";
import useScrollVisibility from "./useScrollVisibility";
import { graphql } from "@utils/graphql";
import { useQuery } from "@tanstack/react-query";

interface SearchBoxInHeaderProps {
  globalNav: ReactNode;
}

export const SearchBoxInHeader: FC<SearchBoxInHeaderProps> = ({
  globalNav,
}: SearchBoxInHeaderProps) => {
  const router = useRouter();
  const query = toSearchPageQuery(router.query);

  const [isVisible, setIsVisible] = useState(false);

  const { data } = useQuery({
    queryKey: ["SearchBoxInHeader"],
    queryFn: async () => {
      const [airports, destinations] = await Promise.all([
        graphql.airports(),
        graphql.destinations(),
      ]);

      return { airports, destinations };
    },
    enabled: isVisible,
  });

  const handleClick = () => setIsVisible(!isVisible);
  useScrollVisibility(50, () => setIsVisible(false), isVisible);

  const searchForm = useSearchForm(query, undefined, {
    isOpenInNewTab: true,
  });

  return (
    <>
      {isVisible && (
        <div className={styles.searchBoxOverlay} onClick={handleClick}></div>
      )}
      <div
        className={`${styles.headerBackground} ${
          isVisible ? styles.searchBoxVisible : styles.searchBoxHidden
        }`}
      >
        <div className={styles.center}>
          <div className={styles.globalNavAndSearchButton}>
            {globalNav}
            <SearchButton onClick={handleClick} />
          </div>
          <div className={styles.searchBox}>
            <SearchBox
              searchForm={searchForm}
              initialData={data}
              rootClassName={styles.searchBoxInHeader}
            />
          </div>
        </div>
      </div>
    </>
  );
};
