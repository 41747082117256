import { FC } from "react";
import styles from "./Tabs.module.css";
import { Button } from "../Button";

interface TabsProps {
  tabs: Tab[];
  activeTabIndex: number;
  setActiveTabIndex: (index: number) => void;
  justify?: "start" | "center";
}

interface TabProps {
  label: string;
  isDisabled?: boolean;
  isActive?: boolean;
  onClick: () => void;
}

const Tab: FC<TabProps> = ({ isActive, isDisabled, label, onClick }) => (
  <li
    className={[
      styles.tab,
      isDisabled ? "" : isActive ? styles.active : styles.inactive,
    ].join(" ")}
  >
    <Button
      disabled={isDisabled}
      variant="ghost"
      color="default"
      onClick={onClick}
    >
      {label}
    </Button>
  </li>
);

export interface Tab {
  label: string;
  isDisabled?: boolean;
}

export const Tabs: FC<TabsProps> = ({
  tabs,
  activeTabIndex,
  setActiveTabIndex,
  justify = "center",
}) => {
  const tabsClassName = [styles.tabs, styles[justify]].join(" ");

  return (
    <div className={styles.root}>
      <ul className={tabsClassName}>
        {tabs.map((tab, index) => (
          <Tab
            key={tab.label}
            label={tab.label}
            isActive={activeTabIndex === index}
            isDisabled={tab.isDisabled}
            onClick={() => setActiveTabIndex(index)}
          />
        ))}
      </ul>
    </div>
  );
};
