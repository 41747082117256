import { useEffect, useRef, EffectCallback, DependencyList } from "react";

/** trigger only once on mount
 * BACKGROUND: useEffect is called twice from React 18
 */
export const useEffectOnce = (
  callback: EffectCallback,
  deps?: DependencyList
) => {
  const isFirstTime = useRef(true);
  useEffect(() => {
    if (isFirstTime.current) {
      isFirstTime.current = false;
      callback();
      return;
    }
    return;
  }, [callback, ...(deps ? deps : [])]);
};
