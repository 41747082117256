import RouterQueryUtils, {
  RouterQueryInput,
  RouterQueryParams,
} from "@utils/router/routerQuery";

export interface RegisterPageQuery extends RouterQueryInput {
  previousModuleType?: string;
}

export const toRegisterPageQuery = (
  query: RouterQueryParams
): RegisterPageQuery => {
  return {
    previousModuleType: RouterQueryUtils.getString(query.previousModuleType),
  };
};
