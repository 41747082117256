import { Container } from "../../Container";
import { Logo } from "../../Logo";
import { FC, ReactNode } from "react";
import styles from "./Header.module.css";

interface HeaderProps {
  transparent?: boolean;
  noFollow?: boolean;
  shadow?: boolean;
  left?: ReactNode;
  center?: ReactNode;
  right?: ReactNode;
  homePath?: string;
  menu?: ReactNode;
}

export const Header: FC<HeaderProps> = ({
  transparent = false,
  noFollow = false,
  shadow = false,
  homePath,
  left = (
    <a href={homePath || "/"} className={styles.brand}>
      <Logo color={transparent ? "white" : "primary"} variant="horizontal" />
    </a>
  ),
  center,
  right,
  menu,
}) => {
  const rootClasses = [
    styles.root,
    !shadow ? styles.shadow : "",
    transparent ? styles.transparent : "",
    noFollow ? styles.noFollow : "",
  ].join(" ");
  return (
    <header className={rootClasses}>
      <Container spacing="0">
        <div className={styles.inner}>
          <div className={styles.main}>{left}</div>
          {center && <div className={styles.action}>{center}</div>}
          <div className={styles.sub}>{right}</div>
        </div>
      </Container>
      {menu}
    </header>
  );
};
