import { FC, useState } from "react";
import { Modal, Button, Spacer } from "@newt/ui";
import {
  ReservationCheckoutIndexPage_BookingFragment,
  ReservationPriceCard_BookingPriceFragment,
} from "@graphql/types";
import { ReservationPriceCardModalSummary } from "./ReservationPriceCardModalSummary";
import { ReservationPriceCardModalBlock } from "./ReservationPriceCardModalBlock";
import styles from "./ReservationPriceCardModal.module.css";

interface ReservationPriceCardModalProps {
  price: ReservationPriceCard_BookingPriceFragment;
  remarksForLocalTax?: ReservationCheckoutIndexPage_BookingFragment["remarksForLocalTax"];
}

export const ReservationPriceCardModal: FC<ReservationPriceCardModalProps> = (
  props
) => {
  const { price, remarksForLocalTax } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button color="default" variant="outline" onClick={() => setIsOpen(true)}>
        内訳をみる
      </Button>
      <Modal
        isActive={isOpen}
        title="内訳"
        onClose={() => setIsOpen(false)}
        action={
          <Button
            variant="outline"
            color="default"
            onClick={() => setIsOpen(false)}
          >
            とじる
          </Button>
        }
      >
        <div className={styles.root}>
          {/** サマリー */}
          <ReservationPriceCardModalSummary
            summary={price.summary}
            totalPrice={price.totalPrice}
          />

          {/** 地方税 */}
          {remarksForLocalTax && remarksForLocalTax.length > 0 && (
            <>
              <Spacer y="4x" />
              {remarksForLocalTax.map((remark, index) => (
                <>
                  <span key={index} className={styles.remarksForLocalTax}>
                    {remark}
                  </span>
                  <Spacer y="2x" />
                </>
              ))}
            </>
          )}
          <Spacer y="10x" />

          {/** 詳細 */}
          {price.sections.map((section, index) => {
            return (
              <ReservationPriceCardModalBlock key={index} section={section} />
            );
          })}
        </div>
      </Modal>
    </>
  );
};
