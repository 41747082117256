import { FC, useCallback, useEffect, useState } from "react";
import { InputSlider } from "../InputSlider";
import styles from "./PriceSlider.module.css";

type SearchFilterPriceProps = {
  value: [number, number];
  prices: Readonly<number[]>;
  onChange(values: [number, number]): void;
};

export const PriceSlider: FC<SearchFilterPriceProps> = ({
  value,
  prices,
  onChange,
}) => {
  const getIndexes: () => [number, number] = useCallback(() => {
    const fromIndex = prices.findIndex((v) => v === value[0]);
    const toIndex = prices.findIndex((v) => v === value[1]);
    return [
      fromIndex !== -1 ? fromIndex : 0,
      toIndex !== -1 ? toIndex : prices.length - 1,
    ];
  }, [prices, value]);

  const [tmpIndexes, setTmpIndexes] = useState<[number, number]>(getIndexes());

  const min = prices[0];
  const max = prices[prices.length - 1];
  const fromPrice = prices[tmpIndexes[0]] || min;
  const toPrice = prices[tmpIndexes[1]] || max;

  useEffect(() => {
    setTmpIndexes(getIndexes());
  }, [getIndexes]);

  const handleMouseUp = () => {
    onChange([fromPrice, toPrice]);
  };

  return (
    <div className={styles.price}>
      <div className={styles.priceLabel}>
        <div>
          {fromPrice !== min ? `¥${fromPrice.toLocaleString()}` : "下限なし"}
        </div>
        <div className={styles.waveDash}>〜</div>
        <div>¥{toPrice.toLocaleString() + (toPrice === max ? "+" : "")}</div>
      </div>
      <InputSlider
        max={prices.length - 1}
        values={tmpIndexes}
        onChange={setTmpIndexes}
        onMouseUp={handleMouseUp}
      />
    </div>
  );
};
