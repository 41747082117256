import { useQuery } from "@tanstack/react-query";
import { graphql } from "@utils/graphql";
import { Button } from "@newt/ui";
import { CarouselBanner } from "@components/common/CarouselBanner";
import styles from "./RecommendationLinks.module.css";

export const RecommendationLinks = () => {
  const { data } = useQuery({
    queryKey: ["bannersInDestination"],
    queryFn: () => graphql.banners(),
    select: (data) => data.all.edges.map((edge) => edge.node),
  });

  if (!data) {
    return null;
  }

  return (
    <>
      <div className={styles.carousel}>
        <CarouselBanner items={data} />
      </div>
      <div className={styles.more}>
        <Button
          color="default"
          variant="outline"
          href="https://newt.net/spl-all"
          target="_blank"
          size="lg"
        >
          すべてみる
        </Button>
      </div>
    </>
  );
};
