import { FC } from "react";
import { Button } from "../Button";
import { Container } from "../Container";
import { Spacer } from "../Spacer";
import { Stack } from "../Stack";
import { Text } from "../Text";
import { NotFoundImage } from "./NotFoundImage";

interface NotFoundProps {
  topLink?: string;
}

export const NotFound: FC<NotFoundProps> = (props) => {
  const { topLink = "/" } = props;
  return (
    <Container size="2/3">
      <Spacer y="16x" />
      <Stack align="center" direction="column">
        <NotFoundImage />
        <div>
          <Text as="h2" weight="bold" align="center">
            ページが見つかりませんでした
          </Text>
        </div>
        <Spacer y="4x" />
        <Text align="center">
          申し訳ありませんが、
          <br />
          お探しのページは見つかりませんでした。
          <br />
          トップからほかのページをご覧ください。
        </Text>
        <Spacer y="6x" />
        <Button as="a" href={topLink} width="content" variant="outline">
          トップへもどる
        </Button>
      </Stack>
      <Spacer y="16x" />
    </Container>
  );
};
