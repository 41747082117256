import { Button } from "../Button";
import { FieldGroup, FieldGroupProps } from "../FieldGroup";
import { Form } from "../Form";
import { Stack } from "../Stack";
import { FormEventHandler, FC } from "react";
import { Control, Controller } from "react-hook-form";
import { Input } from "./Input";

export interface CreditCardFormValues {
  readonly holderName: string;
  readonly cardNumber: string;
  readonly cardExpiry: string;
  readonly cardCvc: number;
}

interface CreditCardFormProps {
  readonly control: Control<CreditCardFormValues, object>;
  readonly isSubmitting: boolean;
  readonly errors: Partial<
    Record<keyof CreditCardFormValues, FieldGroupProps["error"]>
  >;
  readonly isValid: boolean;
  readonly onSubmit: FormEventHandler<HTMLFormElement>;
}

export const CreditCardForm: FC<CreditCardFormProps> = ({
  control,
  onSubmit,
  isSubmitting,
  errors,
  isValid,
}) => {
  return (
    <Form onSubmit={onSubmit}>
      <FieldGroup label="カード名義人（ローマ字）" error={errors["holderName"]}>
        <Controller
          control={control}
          name="holderName"
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors["holderName"]}
              placeholder="SAKURA YAMADA"
              inputMode="text"
            />
          )}
        />
      </FieldGroup>
      <FieldGroup label="カード番号" error={errors["cardNumber"]}>
        <Controller
          control={control}
          name="cardNumber"
          render={({ field }) => (
            <Input
              {...field}
              error={!!errors["cardNumber"]}
              inputMode="numeric"
              placeholder="0000 0000 0000 0000"
              onChange={(e) => {
                return field.onChange(
                  e.currentTarget.value.replace(/\D/g, "").substring(0, 17)
                );
              }}
            />
          )}
        />
      </FieldGroup>
      <Stack spacing="6x">
        <div style={{ width: "150px" }}>
          <FieldGroup label="有効期限" error={errors["cardExpiry"]}>
            <Controller
              control={control}
              name="cardExpiry"
              render={({ field }) => (
                <Input
                  {...field}
                  error={!!errors["cardExpiry"]}
                  placeholder="MM / YY"
                  inputMode="numeric"
                  onChange={(e) => {
                    // This logic is for when a user tries to delete ` / `.
                    if (e.currentTarget.value.match(/^\d{2} \/$/)) {
                      return field.onChange(
                        e.currentTarget.value.substring(0, 2)
                      );
                    }

                    const numberStr = e.currentTarget.value.replace(/\D/g, "");
                    const value = (
                      numberStr === ""
                        ? ""
                        : ["0", "1"].includes(numberStr.charAt(0) ?? "")
                          ? numberStr
                          : `0${numberStr}`
                    ).substring(0, 4);

                    const formattedStr =
                      value.length >= 2
                        ? `${value.substring(0, 2)} / ${value.substring(2)}`
                        : value;

                    return field.onChange(formattedStr);
                  }}
                />
              )}
            />
          </FieldGroup>
        </div>
        <div style={{ width: "150px" }}>
          <FieldGroup label="セキュリティコード" error={errors["cardCvc"]}>
            <Controller
              control={control}
              name="cardCvc"
              render={({ field }) => (
                <Input
                  {...field}
                  error={!!errors["cardCvc"]}
                  inputMode="numeric"
                  onChange={(e) => {
                    return field.onChange(
                      e.currentTarget.value.replace(/\D/g, "").substring(0, 4)
                    );
                  }}
                />
              )}
            />
          </FieldGroup>
        </div>
      </Stack>
      <Button
        disabled={!isValid}
        isLoading={isSubmitting}
        width={{ tablet: "content" }}
      >
        追加する
      </Button>
    </Form>
  );
};
