import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import {
  UtmCampaign,
  UtmContent,
  getAppLink,
  getAppLinkForLP,
} from "./dynamicLink";

interface UseAppLinkArgs {
  path?: string;
  utmCampaign?: UtmCampaign;
  utmContent?: UtmContent;
}

export const useAppLink = ({
  path,
  utmCampaign,
  utmContent,
}: UseAppLinkArgs) => {
  const [url, setUrl] = useState(path);
  const router = useRouter();

  useEffect(() => {
    setUrl(
      getAppLink({
        path: path ?? router.basePath + router.asPath,
        utmCampaign,
        utmContent,
      })
    );
  }, [path, router, utmCampaign, utmContent]);

  return url;
};

export const useAppLinkForLP = (link?: string) => {
  const [url, setUrl] = useState(link);
  const router = useRouter();

  useEffect(() => {
    setUrl(getAppLinkForLP(link ?? router.basePath + router.asPath));
  }, [link, router]);

  return url;
};
