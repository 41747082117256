import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconHotel: FC<IconTypeProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12193_7220)">
        <path
          d="M19.7198 18.8351V9.45009H17.8348V4.75009C17.838 4.62366 17.8159 4.49787 17.7698 4.38009C17.7236 4.26789 17.6556 4.16595 17.5698 4.08013C17.484 3.99432 17.382 3.92633 17.2698 3.88009C17.1546 3.83032 17.0304 3.80479 16.9048 3.80509H5.24984C5.12175 3.80138 4.99422 3.82348 4.87484 3.87009C4.75721 3.91453 4.6497 3.98213 4.55866 4.06887C4.46763 4.15562 4.39492 4.25974 4.34484 4.37509C4.29824 4.49446 4.27613 4.62199 4.27984 4.75009V18.8351H3.33984V20.7201H20.6598V18.8351H19.7198ZM9.32484 17.0001H7.44484V15.1001L9.32484 15.0751V17.0001ZM9.32484 13.2351H7.44484V11.3351L9.32484 11.3101V13.2351ZM9.32484 9.47009H7.44484V7.57009L9.32484 7.54509V9.47009ZM17.8248 18.8551H16.0798V13.1901H14.1998V18.8351H12.4398V11.3351H17.8348L17.8248 18.8551Z"
          fill="#00CD68"
        />
      </g>
      <defs>
        <clipPath id="clip0_12193_7220">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
