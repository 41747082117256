import { useEffect } from "react";

export type KeyPressEvent = "keydown" | "keyup" | "keypress";

const KeyboardEventKeyMap = {
  Escape: ["Escape", "Esc"],
  Up: ["Up", "ArrowUp"],
  Down: ["Down", "ArrowDown"],
  Left: ["Left", "ArrowLeft"],
  Right: ["Right", "ArrowRight"],
  Enter: ["Enter"],
} as const;

export const useKeyPress = <Key extends keyof typeof KeyboardEventKeyMap>(
  key: Key | Key[],
  handler: (matchedKey: Key, e: KeyboardEvent) => void,
  event: KeyPressEvent = "keydown"
) => {
  const handleEvent = (e: KeyboardEvent) => {
    const keys = Array.isArray(key) ? key : [key];
    const matched = keys.find((k) =>
      KeyboardEventKeyMap[k].some((v) => v === e.key)
    );
    if (matched) {
      e.preventDefault();
      handler(matched, e);
    }
  };

  useEffect(() => {
    window.addEventListener(event, handleEvent);

    return () => window.removeEventListener(event, handleEvent);
  }, []);
};
