import Image from "next/image";
import AppDownloadIos from "./assets/app_download_ios.webp";
import AppDownloadAndroid from "./assets/app_download_android.webp";
import styles from "./DownloadAppButton.module.css";
import { HEADER_IOS_DOWNLOAD, HEADER_ANDROID_DOWNLOAD } from "../../constants";

interface DownloadAppButtonProps {
  className?: string;
  link?: string;
  variant: "ios" | "android";
  loading?: "eager" | "lazy" | undefined;
}

export function DownloadAppButton({
  className,
  link,
  variant,
  loading = "lazy",
}: DownloadAppButtonProps) {
  const imageProps =
    variant === "ios"
      ? {
          alt: "Download iOS App button",
          defaultLink: HEADER_IOS_DOWNLOAD,
          src: AppDownloadIos.src,
        }
      : {
          alt: "Download Android App button",
          defaultLink: HEADER_ANDROID_DOWNLOAD,
          src: AppDownloadAndroid.src,
        };

  return (
    <div className={[className, styles.root].join(" ")}>
      <a
        href={link || imageProps.defaultLink}
        className={styles.download}
        target="_blank"
      >
        <Image
          width={150}
          height={50}
          src={imageProps.src}
          alt={imageProps.alt}
          loading={loading}
        />
      </a>
    </div>
  );
}
