import { FC, ReactElement, ReactNode, useRef, useState } from "react";
import styles from "./Dropdown.module.css";
import { Button } from "../Button";
import {
  DropdownContainer,
  DropdownContainerPosition,
  DropdownContainerSize,
  DropdownContainerZIndex,
} from "./components/DropdownContainer";

interface DropdownProps {
  classNames?: Partial<{
    root: string;
    trigger: string;
    container: string;
  }>;
  triggerLabel?: string;
  triggerElement?: ReactElement;
  position?: DropdownContainerPosition;
  zIndex?: DropdownContainerZIndex;
  size?: DropdownContainerSize;
  fixed?: boolean;
  noPadding?: boolean;
  children?: ReactNode;
  render?: <T>(setIsActive: (value: boolean) => void) => ReactNode;
}

export const Dropdown: FC<DropdownProps> = ({
  classNames,
  children,
  triggerLabel,
  triggerElement,
  position,
  zIndex,
  fixed,
  size,
  noPadding,
  render,
}) => {
  const root = useRef<HTMLDivElement>(null);

  const [isActive, setIsActive] = useState(false);
  const [rect, setRect] = useState<DOMRect | null>(null);

  const Trigger: FC = () => {
    return triggerElement ? triggerElement : <Button>{triggerLabel}</Button>;
  };

  const handleClickTrigger = () => {
    if (!root.current) return;
    setRect(root.current.getBoundingClientRect());
    setIsActive(!isActive);
  };

  return (
    <div ref={root} className={`${styles.root} ${classNames?.root || ""}`}>
      <div className={classNames?.trigger || ""} onClick={handleClickTrigger}>
        <Trigger />
      </div>
      {isActive && rect && (
        <DropdownContainer
          className={classNames?.container || ""}
          rect={rect}
          position={position}
          zIndex={zIndex}
          size={size}
          closeDropdown={() => setIsActive(false)}
          fixed={fixed}
          noPadding={noPadding}
        >
          {render ? render(setIsActive) : children}
        </DropdownContainer>
      )}
    </div>
  );
};
