import { Button, useBreakPoints } from "@newt/ui";
import { SupportChatOpenFromValue } from "@utils/chat/context/SupportChatContext";
import { useSupportChat } from "@utils/chat/hooks/useSupportChat";
import { FC } from "react";

type Props = {
  openFrom: SupportChatOpenFromValue;
  tourCode?: string;
};

export const OpenSupportChatButton: FC<Props> = ({ openFrom, tourCode }) => {
  const { laptopWithUp } = useBreakPoints();
  const { onOpen } = useSupportChat();

  return (
    <Button
      size={laptopWithUp ? "lg" : "md"}
      variant="outline"
      onClick={() => onOpen(openFrom, tourCode)}
      icon="supportAgent"
    >
      チャットで相談する
    </Button>
  );
};
