import { FC } from "react";
import { useResponsiveProp } from "../../hooks/useResponsiveProp";
import { ResponsiveProp } from "../../types/component.type";
import styles from "./Divider.module.css";

export type SpaceSize =
  | "1"
  | "2"
  | "base"
  | "2x"
  | "3x"
  | "4x"
  | "5x"
  | "6x"
  | "8x"
  | "10x"
  | "12x"
  | "14x"
  | "16x"
  | "20x"
  | "30x";

interface DividerProps {
  className?: string;
  y?: ResponsiveProp<SpaceSize>;
}

export const Divider: FC<DividerProps> = ({ className, y }) => {
  const responsiveSpacingY = useResponsiveProp(y, "2");
  return (
    <div
      className={[
        className,
        styles.root,
        styles[`vertical--${responsiveSpacingY}`],
      ].join(" ")}
      role="separator"
    />
  );
};
