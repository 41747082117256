import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const Icon24Hours: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        fill="#1F1F1F"
        d="M6.5 28c-.533 0-1-.2-1.4-.6-.4-.4-.6-.867-.6-1.4V6c0-.533.2-1 .6-1.4.4-.4.867-.6 1.4-.6h20c.533 0 1 .2 1.4.6.4.4.6.867.6 1.4v20c0 .533-.2 1-.6 1.4-.4.4-.867.6-1.4.6h-20zm0-2h20V6h-20v20z"
      ></path>
      <path
        fill="#1F1F1F"
        d="M14.5 20H8.833v-3.2c0-.311.145-.611.434-.9.289-.289.589-.433.9-.433h2.666v-1.8h-4V12h4.334c.31 0 .61.144.9.433.289.29.433.59.433.9v1.9c0 .311-.144.611-.433.9-.29.29-.59.434-.9.434H10.5v1.766h4V20zM22.833 20h-1.666v-2.1H17.6V12h1.667v4.233h1.9V12h1.666v4.233h1.534V17.9h-1.534V20z"
      ></path>
    </svg>
  );
};
