import { useEffect, useRef } from "react";

export const useInterval = (callback: () => void, ms = 1000) => {
  const callbackRef = useRef<() => void>(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      callbackRef.current();
    };
    const id = setInterval(tick, ms);
    return () => {
      clearInterval(id);
    };
  }, []);
};
