import { FC } from "react"
import { IconTypeProps } from '../Icon'

export const IconPersonInfantOutline: FC<IconTypeProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.7637 9.99508C19.222 8.72293 18.3763 7.60319 17.3009 6.73416C16.2254 5.86514 14.9531 5.2734 13.5956 5.0109L14.8219 3.04882L13.5781 2.27148L11.96 4.86052C10.3117 4.86816 8.7018 5.35854 7.32915 6.27105C5.9565 7.18356 4.88127 8.47823 4.23629 9.99508C3.37236 10.0115 2.54936 10.3662 1.94419 10.983C1.33902 11.5998 1 12.4294 1 13.2935C1 14.1576 1.33902 14.9872 1.94419 15.6039C2.54936 16.2207 3.37236 16.5754 4.23629 16.5919C4.88401 18.115 5.96543 19.4139 7.34589 20.3269C8.72636 21.24 10.3449 21.7268 12 21.7268C13.6551 21.7268 15.2736 21.24 16.6541 20.3269C18.0346 19.4139 19.116 18.115 19.7637 16.5919C20.6276 16.5754 21.4506 16.2207 22.0558 15.6039C22.661 14.9872 23 14.1576 23 13.2935C23 12.4294 22.661 11.5998 22.0558 10.983C21.4506 10.3662 20.6276 10.0115 19.7637 9.99508ZM21.0082 14.5773C20.6727 14.9203 20.2156 15.1173 19.7358 15.1255L18.7859 15.1435L18.4141 16.0178C17.8791 17.2762 16.9857 18.3494 15.8452 19.1038C14.7047 19.8583 13.3675 20.2605 12 20.2605C10.6326 20.2605 9.29536 19.8583 8.15485 19.1038C7.01435 18.3494 6.12097 17.2762 5.58595 16.0178L5.21406 15.1435L4.26416 15.1255C3.78422 15.1165 3.32697 14.9195 2.99073 14.5769C2.65449 14.2344 2.46612 13.7735 2.46612 13.2935C2.46612 12.8135 2.65449 12.3526 2.99073 12.01C3.32697 11.6674 3.78422 11.4705 4.26416 11.4615L5.21406 11.4434L5.58595 10.5692C6.12098 9.31078 7.01437 8.23756 8.15487 7.48316C9.29537 6.72876 10.6326 6.32652 12 6.32652C13.3675 6.32652 14.7047 6.72876 15.8452 7.48316C16.9857 8.23756 17.8791 9.31078 18.4141 10.5692L18.7859 11.4434L19.7358 11.4615C20.0938 11.4682 20.4419 11.5797 20.7372 11.7822C21.0325 11.9847 21.262 12.2692 21.3974 12.6007C21.5327 12.9321 21.568 13.296 21.4988 13.6473C21.4297 13.9985 21.2591 14.3219 21.0082 14.5773Z" fill="#757575"/>
      <path d="M7.58398 12.3771C7.58398 11.6601 8.16594 11.0781 8.88299 11.0781C9.60004 11.0781 10.182 11.6601 10.182 12.3771C10.182 13.0942 9.60004 13.6761 8.88299 13.6761C8.16594 13.6761 7.58398 13.0942 7.58398 12.3771Z" fill="#757575"/>
      <path d="M13.8192 12.3771C13.8192 11.6601 14.4011 11.0781 15.1182 11.0781C15.8352 11.0781 16.4172 11.6601 16.4172 12.3771C16.4172 13.0942 15.8352 13.6761 15.1182 13.6761C14.4011 13.6761 13.8192 13.0942 13.8192 12.3771Z" fill="#757575"/>
      <path d="M12.0006 18.4264C12.4822 18.4264 12.959 18.3316 13.4038 18.1473C13.8487 17.9631 14.2529 17.693 14.5934 17.3525C14.9339 17.012 15.2039 16.6078 15.3882 16.1629C15.5725 15.7181 15.6673 15.2413 15.6673 14.7598H8.33398C8.33398 15.2413 8.42883 15.7181 8.61309 16.1629C8.79736 16.6078 9.06744 17.012 9.40793 17.3525C10.0956 18.0401 11.0282 18.4264 12.0006 18.4264Z" fill="#757575"/>
    </svg>
  )
}
