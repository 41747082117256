;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"24e908eeb0ddf3f748fad17047df6760e02517b0"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import * as Sentry from "@sentry/nextjs";
import {
  ignoreErrors,
  denyUrls,
  beforeSend,
  beforeSendTransaction,
  beforeBreadcrumb,
} from "@newt/config/sentry";
import { APP_ENV, USE_SENTRY } from "./src/constants";

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: APP_ENV,
  tracesSampleRate: 0.05,
  enabled: USE_SENTRY,
  // This sets the sample rate. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.1,
  integrations: [Sentry.replayIntegration()],
  ignoreErrors,
  denyUrls,
  beforeSendTransaction,
  beforeSend,
  beforeBreadcrumb,
});
