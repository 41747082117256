import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconBalcony: FC<IconTypeProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 12V10H10V12H8ZM14 12V10H16V12H14ZM3 22V14H4V10C4 8.9 4.20833 7.8625 4.625 6.8875C5.04167 5.9125 5.6125 5.0625 6.3375 4.3375C7.0625 3.6125 7.9125 3.04167 8.8875 2.625C9.8625 2.20833 10.9 2 12 2C13.1 2 14.1375 2.20833 15.1125 2.625C16.0875 3.04167 16.9375 3.6125 17.6625 4.3375C18.3875 5.0625 18.9583 5.9125 19.375 6.8875C19.7917 7.8625 20 8.9 20 10V14H21V22H3ZM5 20H7V16H5V20ZM9 20H11V16H9V20ZM6 14H11V4.075C9.56667 4.30833 8.375 4.97917 7.425 6.0875C6.475 7.19583 6 8.5 6 10V14ZM13 14H18V10C18 8.5 17.525 7.19583 16.575 6.0875C15.625 4.97917 14.4333 4.30833 13 4.075V14ZM13 20H15V16H13V20ZM17 20H19V16H17V20Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};
