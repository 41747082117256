import { SESSION_BOOKING_KEY } from "@constants";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { SessionStorageService } from "./webStorage";

export const useDeleteBookingSession = () => {
  const router = useRouter();
  useEffect(() => {
    if (router.isReady && !router.pathname.includes("/book/")) {
      SessionStorageService.removeItem(SESSION_BOOKING_KEY);
    }
  }, [router.isReady, router.pathname]);
};
