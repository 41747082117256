import { FC } from "react"
import { IconTypeProps } from '../Icon'

export const IconSmokeOff: FC<IconTypeProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 6L8.99 13H2V16H11.99L18.99 23L20.25 21.75L3.25 4.75L2 6ZM20.5 13H22V16H20.5V13ZM18 13H19.5V16H18V13ZM18.85 4.88C19.47 4.27 19.85 3.43 19.85 2.5H18.35C18.35 3.52 17.52 4.35 16.5 4.35V5.85C18.74 5.85 20.5 7.68 20.5 9.92V12H22V9.92C22 7.69 20.72 5.77 18.85 4.88ZM14.5 8.7H16.03C17.08 8.7 18 9.44 18 10.75V12H19.5V10.41C19.5 8.61 17.9 7.25 16.03 7.25H14.5C13.48 7.25 12.65 6.27 12.65 5.25C12.65 4.23 13.48 3.5 14.5 3.5V2C12.65 2 11.15 3.5 11.15 5.35C11.15 7.2 12.65 8.7 14.5 8.7ZM17 15.93V13H14.07L17 15.93Z" fill="#757575"/>
    </svg>
  )
}
