import { useQuery } from "@tanstack/react-query";
import { useAuth } from "@utils/auth";
import { GraphQLErrorFormat } from "@utils/form";
import { graphql } from "@utils/graphql";
import { LocalStorageService } from "@utils/webStorage";
import { gql } from "graphql-request";
import { ANONYMOUS_USER_TOKEN } from "@constants";
import { useLocalStorage } from "@utils/webStorage/useLocalStorage";

export const USE_ANONYMOUS_USER_QUERY = gql`
  query UseAnonymousUser_Query($first: Int, $last: Int) {
    anonymousUser {
      me {
        id
        token
        status
        ...SectionBrowsingHistory_AnonymousUser
        tourSearchHistory(first: $first, last: $last) {
          edges {
            node {
              ...HistoryContent_UserTourSearchHistory
            }
          }
        }
      }
    }
  }
`;

export const useAnonymousUser = () => {
  const { user, isLoading } = useAuth();
  const anonymousUserToken = useLocalStorage<string>(ANONYMOUS_USER_TOKEN);

  return useQuery({
    queryKey: ["AuthContext_anonymousUser_Query"],
    queryFn: async () => {
      try {
        return await graphql.UseAnonymousUser_Query({ first: 6 });
      } catch (error) {
        if (
          error &&
          (error as GraphQLErrorFormat).response?.errors?.[0].code ===
            "UNAUTHENTICATED"
        ) {
          LocalStorageService.removeItem(ANONYMOUS_USER_TOKEN);
        }
        throw error;
      }
    },
    enabled: !!anonymousUserToken && !user && !isLoading,
  });
};
