import { CSSProperties } from "react";
import styles from "./ArticleCard.module.css";

export interface ArticleCardModel {
  imageUrl: string | null;
  title: string;
  href: string;
}

interface ArticleCardProps {
  readonly model: ArticleCardModel;
  readonly width?: CSSProperties["width"];
}

export const ArticleCard = ({ model, width }: ArticleCardProps) => {
  return (
    <a
      className={styles.root}
      href={model.href}
      target="_blank"
      rel="noreferrer"
    >
      <section className={styles.inner} style={{ width }}>
        <figure className={styles.thumbnail}>
          <div className={styles.thumbnailInner}>
            {model.imageUrl && (
              <img src={model.imageUrl} alt={model.title} loading="lazy" />
            )}
          </div>
        </figure>
        <div className={styles.main}>
          <p className={styles.title}>{model.title}</p>
        </div>
      </section>
    </a>
  );
};

ArticleCard.displayName = "ArticleCard";
