import { FC } from "react";

export const IconHeart: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.864 20.9754C11.908 20.9918 11.956 21 12 21C12.048 21 12.092 20.9918 12.136 20.9754C12.184 20.959 13.352 20.5207 14.888 19.5908C14.916 19.5744 14.944 19.5539 14.968 19.5294L15.66 18.8289H14.532C13.872 18.8289 13.332 18.2758 13.332 17.5999V15.5926C13.332 14.9167 13.872 14.3637 14.532 14.3637H15.684V13.1798C15.684 12.5039 16.224 11.9508 16.884 11.9508H18.844C19.504 11.9508 20.044 12.5039 20.044 13.1798V14.3637H20.06L20.728 13.6878C20.756 13.6591 20.78 13.6222 20.8 13.5853C21.596 12.0164 22 10.3327 22 8.57943C22 5.50296 19.56 3 16.556 3C14.72 3 13 3.97497 12 5.53163C11.004 3.97497 9.288 3 7.444 3C4.444 3 2 5.50296 2 8.57943C2 17.223 11.768 20.9386 11.864 20.9754Z"
        fill="url(#paint0_linear_23825_56719)"
      />
      <path
        d="M16.884 20.4183H18.844C19.064 20.4183 19.244 20.234 19.244 20.0086V18.0096H21.196C21.42 18.0096 21.596 17.8252 21.596 17.5999V15.5926C21.596 15.3673 21.42 15.183 21.196 15.183H19.244V13.1798C19.244 12.9545 19.064 12.7701 18.844 12.7701H16.884C16.664 12.7701 16.484 12.9545 16.484 13.1798V15.183H14.532C14.308 15.183 14.132 15.3673 14.132 15.5926V17.5999C14.132 17.8252 14.308 18.0096 14.532 18.0096H16.484V20.0086C16.484 20.234 16.664 20.4183 16.884 20.4183Z"
        fill="url(#paint1_linear_23825_56719)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_23825_56719"
          x1="12.0233"
          y1="20.6853"
          x2="11.8086"
          y2="6.65364"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0520833" stopColor="#886FEE" />
          <stop offset="0.95" stopColor="#FF83CE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_23825_56719"
          x1="12.0233"
          y1="20.6853"
          x2="11.8086"
          y2="6.65364"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0520833" stopColor="#886FEE" />
          <stop offset="0.95" stopColor="#FF83CE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
