import { gtm } from "../../gtm";

export interface TourPurchaseEventVariables {
  tourId: number;
}

export const tourPurchaseBrazeEvent = (
  variables: TourPurchaseEventVariables
) => {
  gtm.pushEvent("braze_tour_purchase", variables);
};
