import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconLaundry: FC<IconTypeProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        fill="#1F1F1F"
        d="M8.103 29.532a2.182 2.182 0 01-1.6-.667 2.182 2.182 0 01-.667-1.6V4.732c0-.623.222-1.156.667-1.6a2.182 2.182 0 011.6-.667h17.2c.622 0 1.155.222 1.6.667.444.444.666.977.666 1.6v22.533c0 .622-.222 1.155-.666 1.6a2.182 2.182 0 01-1.6.667h-17.2zm0-2.267h17.2V4.732h-17.2v22.533zm8.6-1.967c1.777 0 3.288-.622 4.533-1.866 1.244-1.245 1.867-2.756 1.867-4.534s-.623-3.289-1.867-4.533c-1.245-1.245-2.756-1.867-4.533-1.867-1.778 0-3.29.622-4.534 1.867-1.244 1.244-1.866 2.755-1.866 4.533 0 1.778.622 3.29 1.866 4.534 1.245 1.244 2.756 1.866 4.534 1.866zm0-2.866c-.49 0-.961-.084-1.417-.25a3.318 3.318 0 01-1.217-.784l5.1-5.1c.378.311.65.7.817 1.167.167.466.25.944.25 1.433 0 .978-.345 1.811-1.033 2.5a3.405 3.405 0 01-2.5 1.034zM10.969 8.598c.311 0 .567-.105.767-.316.2-.212.3-.462.3-.75a1.01 1.01 0 00-.317-.767c-.21-.2-.46-.3-.75-.3-.31 0-.566.1-.766.3-.2.2-.3.455-.3.767 0 .288.1.538.3.75.2.21.455.316.766.316zm4.567 0c.311 0 .567-.105.767-.316.2-.212.3-.462.3-.75 0-.312-.1-.567-.3-.767-.2-.2-.456-.3-.767-.3s-.561.1-.75.3c-.189.2-.283.455-.283.767 0 .288.1.538.3.75.2.21.444.316.733.316z"
      ></path>
    </svg>
  );
};
