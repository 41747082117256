import { FC, ReactNode } from "react";
import styles from "./WebviewLayout.module.css";

interface WebviewLayoutProps {
  children?: ReactNode;
}

export const WebviewLayout: FC<WebviewLayoutProps> = ({ children }) => {
  return (
    <div className={styles.root}>
      <div className={styles.inner}>{children}</div>
    </div>
  );
};
