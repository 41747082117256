import { FC } from "react";
import { Icon } from "../../Icon";
import styles from "./TourCardPoint.module.css";
import { Text } from "../../Text";
import { Tooltip } from "../../Tooltip";
import { STATUS } from "../useTooltip";

export interface TooltipProps {
  point: string;
  pointRate?: number;
  isVisible?: boolean;
  tooltipStatus: STATUS;
  onClose?: () => void;
}

export const TourCardPoint: FC<TooltipProps> = ({
  point,
  pointRate,
  isVisible = false,
  tooltipStatus,
  onClose,
}) => {
  return (
    <div className={styles.pricePoint}>
      {isVisible && tooltipStatus === STATUS.OPEN ? (
        <Tooltip
          position="bottomLeft"
          triggerElement={
            <div className={styles.point}>
              <Icon icon="point" size="sm" color="green-40" />
              <Text size="sm">
                <span>{point}</span>たまる
              </Text>
            </div>
          }
          color="green"
          isVisible={isVisible}
          onClose={onClose}
        >
          <>
            {`旅行代金の${pointRate || 5}%分、次の旅行に使え`}
            <br />
            るNEWTポイントがたまる！
            <button
              className={styles.pointDetail}
              onClick={() => window.open("https://newt.net/point", "_blank")}
            >
              詳細
            </button>
          </>
        </Tooltip>
      ) : (
        <div className={styles.point}>
          <Icon icon="point" size="sm" color="green-40" />
          <Text size="sm">
            <span>{point}</span>たまる
          </Text>
        </div>
      )}
    </div>
  );
};
