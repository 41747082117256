import { FC } from "react"
import { IconTypeProps } from '../Icon'

export const IconArrowDropUp: FC<IconTypeProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 14L12 9L17 14H7Z" fill="#757575"/>
    </svg>
  )
}
