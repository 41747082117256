import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconPhotoLibrary: FC<IconTypeProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.13843 13.8634H19.1384L15.6884 9.36343L13.3884 12.3634L11.8384 10.3634L9.13843 13.8634ZM8.21018 18.0667C7.58046 18.0667 7.04386 17.8449 6.60038 17.4015C6.15691 16.958 5.93518 16.4214 5.93518 15.7917V3.93518C5.93518 3.30546 6.15691 2.76886 6.60038 2.32538C7.04386 1.8819 7.58046 1.66016 8.21018 1.66016H20.0667C20.6964 1.66016 21.233 1.8819 21.6765 2.32538C22.12 2.76886 22.3417 3.30546 22.3417 3.93518V15.7917C22.3417 16.4214 22.12 16.958 21.6765 17.4015C21.233 17.8449 20.6964 18.0667 20.0667 18.0667H8.21018ZM3.93518 22.3417C3.30546 22.3417 2.76886 22.1199 2.32538 21.6765C1.8819 21.233 1.66016 20.6964 1.66016 20.0667V5.93518H3.93518V20.0667H18.0667V22.3417H3.93518Z"
        fill="#FFF"
      />
    </svg>
  );
};
