import { openUrl, shareToInstagramStories } from "@newt/core";
import { shareLineURL, shareTwitterURL } from "@newt/ui";
import { getInstagramImgUrl } from "./getInstagramImgUrl";

const SHARE_TEXT = `NEWT（ニュート）でツアーを予約しました！\n`;

interface HandleShareClickArgs {
  shareUrl: string | null;
  variant: "x" | "line";
}

export const handleShareClick = (args: HandleShareClickArgs) => {
  const { shareUrl, variant } = args;
  if (!shareUrl) {
    return;
  }

  const shareUrlWithUtm = new URL(shareUrl);
  shareUrlWithUtm.searchParams.set("utm_medium", "viral");
  shareUrlWithUtm.searchParams.set("utm_source", variant);
  shareUrlWithUtm.searchParams.set("utm_campaign", "tour_thanks");
  shareUrlWithUtm.searchParams.set("utm_content", "top_modal");

  // open app
  const intentLink = SHARE_TEXT + shareUrlWithUtm.toString();
  if (variant === "x") {
    openUrl(shareTwitterURL(intentLink));
  } else if (variant === "line") {
    openUrl(shareLineURL(intentLink));
  }
};

interface HandleInstagramStoriesShareArgs {
  imageUrl?: string;
  title?: string;
}

export const handleInstagramStoriesShare = ({
  imageUrl,
  title,
}: HandleInstagramStoriesShareArgs) => {
  if (!imageUrl) {
    return;
  }

  const topBackgroundColor = ""; // "#00CD6B";
  const bottomBackgroundColor = ""; // "#0085FF";

  const stickerImageUrl = getInstagramImgUrl(imageUrl, title);
  const backgroundImageUrl =
    "https://newt.imgix.net/product/transparent_logo.png";

  // TODO: instagramがない場合の挙動のテスト
  shareToInstagramStories({
    stickerImageUrl,
    backgroundImageUrl,
    topBackgroundColor,
    bottomBackgroundColor,
  });
};
