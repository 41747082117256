import Image from "next/image";
import { FC } from "react";
import { LazyImage } from "../LazyImage";
import styles from "./Slider.module.css";

interface Image {
  readonly url: HTMLImageElement["src"];
  readonly caption?: HTMLImageElement["alt"] | null;
}

interface SliderProps {
  readonly images: Image[];
  readonly shape?: "square" | "oblong";
}

export const Slider: FC<SliderProps> = ({ images, shape = "oblong" }) => {
  const slideItemClasses = [styles.sliderItem, styles[`shape--${shape}`]].join(
    " "
  );

  return (
    <div className={styles.slider}>
      <ul className={styles.sliderList}>
        {images.map((v) => (
          <li key={v.url}>
            <div className={slideItemClasses}>
              <LazyImage
                src={v.url}
                alt={v.caption || ""}
                aspectRatio={shape === "square" ? "1/1" : "1.435/1"}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
