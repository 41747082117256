import { FC } from "react";
import styles from "./SearchBoxCalendar.module.css";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { SearchFormValues } from "@components/common/SearchBox/hooks/useSearchForm";
import {
  Button,
  DateRangePicker,
  DateRangePickerDateColor,
  Skeleton,
  Text,
  toDate,
  useBreakPoints,
} from "@newt/ui";
import { DestinationPriceCalendar } from "@graphql/types";
import { DateTime } from "luxon";
import {
  DateRangePickerLegendItem,
  DateRangePickerLegends,
} from "@newt/ui/src/components/DateRangePicker/DateRangePickerLegends";
import { TravelDateFlexibilitySegmentButton } from "./TravelDateFlexibilitySegmentButton";

interface SearchBoxCalendarProps {
  values: SearchFormValues;
  control: Control<SearchFormValues>;
  setValue: UseFormSetValue<SearchFormValues>;
  onClear?: () => void;
  initialMonth?: Date;
  onMonthChange?: (month: Date) => void;
  priceCalendar: DestinationPriceCalendar | null;
  isFetching: boolean;
}

export const SearchBoxCalendar: FC<SearchBoxCalendarProps> = ({
  values,
  control,
  setValue,
  onClear,
  initialMonth,
  onMonthChange,
  priceCalendar,
  isFetching,
}) => {
  const breakPoints = useBreakPoints();

  // TODO(curtis): Date系のライブラリを入れたら独自実装でなく、そちらに寄せる
  const format = (date: Date) => {
    const pad = (num: number) => num.toString().padStart(2, "0");
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${pad(month)}-${pad(day)}`;
  };
  const handleChange = (from?: Date, to?: Date) => {
    let newFrom = from ? format(from) : "";
    let newTo = to ? format(to) : "";

    if (newFrom && newTo && newFrom === newTo) {
      // fromとtoに同じ値を入力された場合はクリア
      newFrom = "";
      newTo = "";
    } else if (values.from && values.to) {
      // fromとtoが入力済みの状態で、いずれかが書きかわった場合は書きかわった方をfromにセットしtoをクリア
      newFrom = values.from !== newFrom ? newFrom : newTo;
      newTo = "";
    }

    setValue("from", newFrom);
    setValue("to", newTo);

    if (newFrom && newTo && !breakPoints.mobileOnly && onMonthChange) {
      onMonthChange(toDate(newFrom) as Date);
    }
  };

  const today = DateTime.now();

  // 最新の日付を取得
  const latestDate = priceCalendar?.dates.reduce(
    (latest: DateTime | undefined, current) => {
      const currentDateTime = DateTime.fromISO(current.date);

      if (!latest) return currentDateTime;

      return latest > currentDateTime ? currentDateTime : latest;
    },
    undefined
  );

  // もしpriceCalendarが取得できている（＝目的地選択がある）場合は、在庫日付の開始日（priceCalendarにおける最小の値）、そうでない場合は+1日を選択可能範囲とする
  const minSelectableDate =
    latestDate?.toJSDate() ?? today.plus({ day: 1 }).toJSDate();
  const minDate = today.toJSDate();
  const maxDate = today.plus({ year: 1, day: -1 }).toJSDate();

  const dateColors: DateRangePickerDateColor[] =
    priceCalendar?.dates.map((v) => ({
      date: new Date(v.date),
      level: v.level,
    })) ?? [];

  const legendItems: DateRangePickerLegendItem[] =
    priceCalendar?.levels.map((v) => ({
      label: [...Array(v.level)].map(() => `¥`).join(""),
      level: v.level,
    })) ?? [];

  return (
    <div className={styles.root}>
      <div className={styles.legends}>
        {isFetching ? (
          <Skeleton height={24} width={80} />
        ) : (
          <DateRangePickerLegends items={legendItems} />
        )}
      </div>
      <Controller
        name="from"
        control={control}
        render={({ field: fromField }) => (
          <Controller
            name="to"
            control={control}
            render={({ field: toField }) => (
              <>
                <DateRangePicker
                  minDate={minDate}
                  minSelectableDate={minSelectableDate}
                  maxDate={maxDate}
                  from={toDate(fromField.value)}
                  to={toDate(toField.value)}
                  dateColors={dateColors}
                  onChange={handleChange}
                  initialMonth={initialMonth || minDate}
                  onMonthChange={onMonthChange}
                  isScrollable={breakPoints.tabletWithDown}
                />
              </>
            )}
          />
        )}
      />
      {breakPoints.laptopWithUp && onClear && (
        <div className={styles.calendarFooter}>
          <TravelDateFlexibilitySegmentButton
            value={values.travelDateFlexibility}
            onChange={(value) => setValue("travelDateFlexibility", value)}
          />
          <button className={styles.clearButton} onClick={() => onClear()}>
            日付をクリア
          </button>
        </div>
      )}
    </div>
  );
};

export const SearchBoxCalendarModalAction: FC<{
  hasValue: boolean;
  display: string;
  value: SearchFormValues["travelDateFlexibility"];
  onChange: (value: SearchFormValues["travelDateFlexibility"]) => void;
  onClear(): void;
  onClick(): void;
}> = (props) => {
  const { hasValue, display, value, onChange, onClear, onClick } = props;

  return (
    <>
      <TravelDateFlexibilitySegmentButton value={value} onChange={onChange} />
      <Text size="sm" weight="bold" align="center">
        {display}
      </Text>
      <ul className={styles.calendarActions}>
        <li className={styles.clearButton}>
          <Button
            size="lg"
            color="default"
            variant="outline"
            onClick={hasValue ? onClear : onClick}
          >
            {hasValue ? "クリア" : "日付未定"}
          </Button>
        </li>
        <li>
          <Button size="lg" onClick={onClick}>
            決定する
          </Button>
        </li>
      </ul>
    </>
  );
};
