import { FC } from "react";
import { IconTypeProps } from "../Icon";

export const IconElectricCarChargingStation: FC<IconTypeProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.42578 18.6508L10.6258 15.1508H9.30078V12.5258L7.12578 16.0258H8.42578V18.6508ZM5.50078 10.2008H12.2258V4.50078H5.50078V10.2008ZM5.50078 19.5008H12.2258V11.7008H5.50078V19.5008ZM3.80078 21.2008V4.50078C3.80078 4.03411 3.96745 3.63411 4.30078 3.30078C4.63411 2.96745 5.03411 2.80078 5.50078 2.80078H12.2258C12.6924 2.80078 13.0924 2.96745 13.4258 3.30078C13.7591 3.63411 13.9258 4.03411 13.9258 4.50078V11.8508H15.6508C16.0008 11.8508 16.3008 11.9716 16.5508 12.2133C16.8008 12.4549 16.9258 12.7591 16.9258 13.1258V18.6258C16.9258 18.9758 17.0674 19.2674 17.3508 19.5008C17.6341 19.7341 17.9591 19.8508 18.3258 19.8508C18.7091 19.8508 19.0633 19.7341 19.3883 19.5008C19.7133 19.2674 19.8758 18.9758 19.8758 18.6258V8.87578H19.5258C19.2924 8.87578 19.0924 8.79661 18.9258 8.63828C18.7591 8.47995 18.6758 8.27578 18.6758 8.02578V5.70078H19.1758V4.40078H20.0258V5.70078H21.0258V4.40078H21.8508V5.70078H22.3508V8.02578C22.3508 8.27578 22.2716 8.47995 22.1133 8.63828C21.9549 8.79661 21.7591 8.87578 21.5258 8.87578H21.1758V18.6008C21.1758 19.3341 20.8883 19.9508 20.3133 20.4508C19.7383 20.9508 19.0758 21.2008 18.3258 21.2008C17.5758 21.2008 16.9341 20.9508 16.4008 20.4508C15.8674 19.9508 15.6008 19.3424 15.6008 18.6258V13.1008C15.6008 13.1174 15.6049 13.1299 15.6133 13.1383C15.6216 13.1466 15.6341 13.1508 15.6508 13.1508H13.9258V21.2008H3.80078Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};
