import * as Sentry from "@sentry/nextjs";
import type { ResponseMiddleware } from "graphql-request";

export const sentryResponseMiddleware = (
  response: Parameters<ResponseMiddleware>[0]
) => {
  if ("status" in response && response.status !== 200) {
    Sentry.addBreadcrumb({
      category: "response",
      level: "warning",
      data: response,
    });
  }
};
