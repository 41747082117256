import { FC } from "react"
import { IconTypeProps } from '../Icon'

export const IconFirstPage: FC<IconTypeProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.41 16.59L13.82 12L18.41 7.41L17 6L11 12L17 18L18.41 16.59ZM6 6H8V18H6V6Z" fill="#757575"/>
    </svg>
  )
}
