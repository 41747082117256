import { FC } from "react"
import { IconTypeProps } from '../Icon'

export const IconRemove: FC<IconTypeProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 13H3V11H21V13Z" fill="#757575"/>
    </svg>
  )
}
