declare global {
  interface Window {
    webkit: {
      messageHandlers: {
        [handler: string]: {
          postMessage(message: any): void;
        };
      };
    };
    [key: string]: any;
  }
}

interface IOSMessage {
  handler: string;
  message: object | string;
}

interface AndroidMessage {
  interfaceName: string;
  method: string;
  [key: string]: any;
}

export const isIOSMessageHandlerAvailable = ({ handler }: IOSMessage) => {
  return !!window.webkit?.messageHandlers[handler];
};

export const sendMessageToIOS = ({ handler, message }: IOSMessage) => {
  if (window.webkit && window.webkit.messageHandlers[handler]) {
    window.webkit.messageHandlers[handler].postMessage(message);
  }
};

export const isAndroidInterfaceAvailable = ({
  interfaceName,
  method,
}: AndroidMessage) => {
  return (
    !!window[interfaceName] &&
    typeof window[interfaceName][method] === "function"
  );
};

export const callAndroidMethod = ({
  interfaceName,
  method,
  ...params
}: AndroidMessage) => {
  if (
    window[interfaceName] &&
    typeof window[interfaceName][method] === "function"
  ) {
    window[interfaceName][method](...Object.values(params));
  }
};

export const requestInAppReview = () => {
  const iosMessage = {
    handler: "appControl",
    message: "enable_in_app_review",
  };
  sendMessageToIOS(iosMessage);

  const androidMessage = {
    interfaceName: "AppControlWebInterface",
    method: "enableInAppReview",
  };
  callAndroidMethod(androidMessage);
};

// ネイティブでチャットを開く
export const openChatMessage = () => {
  const iosMessage = {
    handler: "appControl",
    message: "open_messaging",
  };
  sendMessageToIOS(iosMessage);

  const androidMessage = {
    interfaceName: "AppControlWebInterface",
    method: "openMessaging",
  };
  callAndroidMethod(androidMessage);
};

export const openUrl = (url: string) => {
  const iosMessage = {
    handler: "appControl",
    message: {
      name: "open_url_in_external_browser",
      url: url,
    },
  };
  const androidMessage = {
    interfaceName: "AppControlWebInterface",
    method: "openUrlInExternalBrowser",
    url: url,
  };

  if (isIOSMessageHandlerAvailable(iosMessage)) {
    sendMessageToIOS(iosMessage);
  } else if (isAndroidInterfaceAvailable(androidMessage)) {
    callAndroidMethod(androidMessage);
  } else {
    window.open(url, "_blank");
  }
};

export const shareToInstagramStories = ({
  stickerImageUrl = "",
  backgroundImageUrl = "",
  topBackgroundColor = "",
  bottomBackgroundColor = "",
}: {
  stickerImageUrl?: string;
  backgroundImageUrl?: string;
  topBackgroundColor?: string;
  bottomBackgroundColor?: string;
}) => {
  const iosMessage = {
    handler: "appControl",
    message: {
      name: "share_to_instagram_stories",
      backgroundImageUrl: backgroundImageUrl,
      stickerImageUrl: stickerImageUrl,
      topBackgroundColor: topBackgroundColor,
      bottomBackgroundColor: bottomBackgroundColor,
    },
  };

  const androidMessage = {
    interfaceName: "AppControlWebInterface",
    method: "shareToInstagramStories",
    backgroundImageUrl: backgroundImageUrl,
    stickerImageUrl: stickerImageUrl,
    topBackgroundColor: topBackgroundColor,
    bottomBackgroundColor: bottomBackgroundColor,
  };
  if (isIOSMessageHandlerAvailable(iosMessage)) {
    sendMessageToIOS(iosMessage);
  } else if (isAndroidInterfaceAvailable(androidMessage)) {
    callAndroidMethod(androidMessage);
  } else {
    console.log("Instagram Stories is not available");
  }
};
