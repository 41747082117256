import { FC } from "react";
import Link from "next/link";
import { ROUTES } from "../../../routes";
import { Icon, Text, Spacer, Alert, Divider } from "@newt/ui";
import { CoreUserPoint_UserPointsFragment } from "@graphql/types";
import { VARIANT } from "./UserPoint";
import styles from "./UserPointForSp.module.css";

type UserPointForSpProps = {
  points: CoreUserPoint_UserPointsFragment;
};

export const UserPointForSp: FC<UserPointForSpProps> = ({ points }) => {
  return (
    <>
      <Link className={styles.pointRoot} href={ROUTES.account.point()}>
        <div className={styles.pointContent}>
          <Icon size="md" icon="point" color="green-40" />
          <span className={styles.point}>{points.amount.toLocaleString()}</span>
          <Text weight="bold" size="sm">
            ポイント
          </Text>
        </div>
        <div className={styles.chevronRight}>
          <Icon size="md" icon={"chevronRight"} />
        </div>
      </Link>
      {points.notice ? (
        <Alert
          className={styles.alert}
          title={points.notice.content}
          variant={VARIANT[points.notice.noticeLevel]}
        />
      ) : (
        <Divider y="2x" className={styles.divider} />
      )}
      <Spacer y="2x" />
    </>
  );
};
