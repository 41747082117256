import { gtm } from "../gtm";

export interface LogoutEventVariables {
  // NEWTユーザーID
  uid?: string;
  // NEWTユーザーID
  user_id?: string;
  // 会員ステータス
  user_status: "member" | "guest";
  // 認証の手法
  auth_method?: string;
}

export const logoutEvent = (variables?: LogoutEventVariables) => {
  gtm.pushEvent("logout", variables);
};
